import { InputHTMLAttributes, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>

const Checkbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const className = twMerge(
    'h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500 rounded',
    props.disabled && 'bg-gray-50',
    props.className
  )

  return <input {...props} className={className} ref={ref} type="checkbox" />
})

export default Checkbox
