import Link from '@/gf/components/Link'
import TaskCard from '@/gf/components/Onboarding/TaskCard'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const OnboardingTasks = ({ isInitialSetupComplete }: { isInitialSetupComplete }) => {
  const navigate = useNavigate()

  const dragConstraintRef = useRef(null)

  return (
    <section>
      {isInitialSetupComplete ? (
        <div ref={dragConstraintRef} className={classNames('w-full')}>
          <motion.div
            className="cursor-grab w-[fit-content]"
            drag="x"
            dragConstraints={dragConstraintRef}
          >
            <div className="flex gap-x-4">
              <TaskCard.Static
                title="Add Locations"
                text="Add more locations to find your local vendors. This will automate your request flow and location based notifications."
                onActionClick={() => navigate('/locations')}
              />

              <TaskCard.Static
                title="Add Fleet Brands"
                text="Add the brands that make up your machine and non machine parts. This will streamline adding your vendors."
                onActionClick={() => navigate('/brands')}
              />

              <TaskCard.Static
                title="Add Vendors"
                text="Find more of your vendors based on your locations and supported brands. Adding vendors will automate your request flow."
                onActionClick={() => navigate('/vendors/new?selectLocation=1')}
              />

              <TaskCard.Static
                title="Invite Your Team"
                text="Gearflow works best when your parts purchasing managers and field technicians are all onboard."
                onActionClick={() => navigate('/settings/users')}
              />
            </div>
          </motion.div>
        </div>
      ) : (
        <div className="space-y-4">
          <p className="text-base w-[37.8rem]">
            You’ll add your first shop location and some of the vendors that support it. It’s quick
            to get started.
          </p>
          <Link.P to="/setup" color="blue">
            Continue Initial Setup
          </Link.P>
        </div>
      )}
    </section>
  )
}

export default OnboardingTasks
