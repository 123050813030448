import Action from '@/gf/components/Action'
import Link from '@/gf/components/Link'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import FullScreenLayout from '../components/FullScreenLayout'
import Vendors from './Setup/Vendors'
import useVendorsForm from './Setup/Vendors/useForm'

const AddVendors = () => {
  const [locationId, setLocationId] = useQueryParam('locationId', StringParam)
  const navigate = useNavigate()

  const form = useVendorsForm()

  const onSave = () =>
    form.save(() => {
      navigate('/vendors')
    })

  return (
    <FullScreenLayout
      footer={[
        <Link.S to="/">Cancel</Link.S>,
        <Action.P color="blue" onClick={onSave} performing={form.saving}>
          Done Adding
        </Action.P>,
      ]}
    >
      <Vendors
        form={form}
        locationId={locationId ?? null}
        title="Add Vendors"
        locationSelectionEnabled
        onLocationIdChanged={(lid) => setLocationId(lid ?? undefined, 'replaceIn')}
        showBrandsFilter
        showAddNewVendor
        showMissingVendorAlerts
        addBrandsLink
      />
    </FullScreenLayout>
  )
}

export default AddVendors
