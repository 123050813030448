import { useRef, useState } from 'react'
import { PlusIcon, XIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

import useToggle from '../../hooks/useToggle'
import useUppy from '../../hooks/useUppy'
import MessageAttachment from '../MessageAttachment'
import AttachmentM from '../../modules/Attachment'
import SpinnerSmall from '../SpinnerSmall'

const UppyFilesManager = ({
  allowedFileTypes,
  fileUrls,
  onAdd,
  onRemove,
  onError,
  spinnerClassName,
}: {
  fileUrls: string[]
  allowedFileTypes: string[]
  onAdd: (fileUrls: string[]) => Promise<unknown>
  // Hide the remove button by not including the onRemove prop
  onRemove?: (fileUrl: string) => Promise<unknown>
  onError: (error: string) => void
  spinnerClassName?: string | undefined
}) => {
  const [addFileSpinnerForNames, setAddFileSpinnerForNames] = useState<string[]>([])
  const [removeFileSpinnerForUrls, setRemoveFileSpinnerForUrls] = useState<string[]>([])
  const [invoiceFileDropFocused, invoiceFileDropFocusedToggler] = useToggle()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const uppy = useUppy({
    allowedFileTypes,
    autoProceed: true,
    onFilesAdded: (files) =>
      setAddFileSpinnerForNames((prev) => [...prev, ...files.map((file) => file.name)]),
    onComplete: (newFiles) =>
      onAdd(newFiles.map((f) => f.url)).finally(() =>
        setAddFileSpinnerForNames((prev) =>
          prev.filter((url) => !newFiles.find((newFile) => newFile.name === url))
        )
      ),
  })

  const onFilesChange = (files: (File | null)[]) => {
    files.forEach((file) => {
      if (file) {
        try {
          uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
          })
        } catch (error) {
          const err = error as { isRestriction: boolean }
          onError(err.isRestriction ? `${err}` : 'Error uploading file')
          setAddFileSpinnerForNames((prev) => prev.filter((url) => url !== file.name))
        }
      }
    })
  }

  return (
    <>
      <div className="flex flex-row justify-start items-center rounded-md gap-x-6 gap-y-5 flex-wrap">
        {fileUrls.map((fileUrl) => (
          <div key={`new-attachment-${fileUrl}`} className="relative">
            <a href={fileUrl} target="_blank" rel="noreferrer">
              {AttachmentM.extensionFromUrl(fileUrl) === 'pdf' ? (
                <MessageAttachment
                  className="max-w-24 sm:max-w-32"
                  name={AttachmentM.nameFromUrl(fileUrl)}
                />
              ) : (
                <img
                  className="w-24 h-24 min-w-24 min-h-24 object-cover rounded-lg"
                  src={fileUrl}
                  alt={AttachmentM.nameFromUrl(fileUrl)}
                />
              )}
            </a>
            {onRemove && (
              <button
                type="button"
                className={classNames(
                  'p-1 absolute -top-2 -right-2 rounded-full bg-gray-100 text-gray-500 border border-gray-300 shadow flex justify-center items-center',
                  { 'hover:bg-gray-200': !removeFileSpinnerForUrls.includes(fileUrl) }
                )}
                title="Delete"
                onClick={() => {
                  setRemoveFileSpinnerForUrls((prev) => [...prev, fileUrl])
                  onRemove(fileUrl).finally(() =>
                    setRemoveFileSpinnerForUrls((prev) => prev.filter((url) => url !== fileUrl))
                  )
                }}
                disabled={removeFileSpinnerForUrls.includes(fileUrl)}
              >
                {removeFileSpinnerForUrls.includes(fileUrl) ? (
                  <SpinnerSmall className="!h-4 !w-4" />
                ) : (
                  <XIcon className="h-4 w-4 text-gray-700" />
                )}
              </button>
            )}
          </div>
        ))}
        {addFileSpinnerForNames.map((name) => (
          <div key={name} className="w-24 h-24 min-w-24 min-h-24 flex justify-center items-center">
            <SpinnerSmall className={spinnerClassName} />
          </div>
        ))}
        <button
          key="add-attachment"
          type="button"
          className={classNames(
            'w-24 h-24 min-w-24 min-h-24 bg-gray-200 hover:bg-gray-300 rounded-lg inline-flex justify-center items-center',
            { 'ring-2 ring-orange-500 border-orange-500': invoiceFileDropFocused }
          )}
          onClick={() => fileInputRef.current?.click()}
          onDragLeave={(e) => {
            e.preventDefault()
            invoiceFileDropFocusedToggler.off()
          }}
          onDragOver={(e) => {
            e.preventDefault()
            invoiceFileDropFocusedToggler.on()
          }}
          onDragEnter={(e) => e.preventDefault()}
          onDrop={(event) => {
            event.preventDefault()
            invoiceFileDropFocusedToggler.off()
            const files = event.dataTransfer.items
              ? Array.from(event.dataTransfer.items)
                  .filter((item) => item.kind === 'file')
                  .map((item) => item.getAsFile())
              : Array.from(event.dataTransfer.files)
            onFilesChange(files)
          }}
        >
          <PlusIcon className="w-5 h-5 text-gray-700" />
        </button>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept={allowedFileTypes?.join(',')}
        multiple
        onChange={(event) => onFilesChange(Array.from(event.target.files || []))}
        // Keep the input value cleared with an empty string
        value=""
      />
    </>
  )
}

export default UppyFilesManager
