import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import { App } from '@/types'
import { CheckCircleIcon } from '@heroicons/react/solid'

const Success = ({ onClose, app }: { onClose: () => void; app: App }) => (
  <div className="relative">
    <CloseModalButton onClick={onClose} className="absolute top-2 right-2" />

    <header className="bg-blue-100 h-40 flex justify-center items-center">
      <h3 className="text-blue-900 text-3xl font-medium flex gap-x-3">
        <CheckCircleIcon className="inline-block h-8 w-8 mt-0.5" /> Account Claimed!
      </h3>
    </header>
    <div className="px-8 pb-8 pt-6 space-y-6">
      <p className="text-lg">
        {app === 'parts-hub'
          ? ''
          : 'Welcome to your free Sales Hub account! You can now manage requests and orders for all your customers using Gearflow.'}
      </p>

      <div className="flex justify-center">
        <Action.P onClick={onClose} className="w-2/3">
          {app === 'parts-hub' ? 'Go to Parts Hub' : 'Go to Sales Hub'}
        </Action.P>
      </div>
    </div>
  </div>
)

export default Success
