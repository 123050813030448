import { useEffect, useState } from 'react'

import mapImage from '../pages/Setup/map.png'
import reportsImage from '../pages/Setup/reports.png'

import * as GE from '@/gf/modules/GrammarEvents'
import { useStartProTrialMutation } from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import useReloadSession from '../hooks/useReloadSession'
import useSession from '../hooks/useSession'

import Action from '@/gf/components/Action'

const FreeProTrialSplashContent = ({
  onClaimed,
  onClose,
}: {
  onClaimed: () => Promise<unknown>
  onClose: () => void
}) => {
  const { organization } = useSession()
  const reloadSession = useReloadSession()
  const gqlClient = useGqlClient()
  const [startProTrial] = useStartProTrialMutation({ client: gqlClient })
  const [isPerforming, setIsPerforming] = useState(false)

  useEffect(() => {
    GE.clicksButton('parts-hub-pro-free-trial-open')
  }, [])

  return (
    <div className="flex flex-col gap-y-12 items-center">
      <div className="flex flex-col gap-y-6 items-center">
        <div className="space-y-4">
          <h2 className="max-w-lg text-2xl font-medium">
            Try Parts Hub Pro with a 30 day free trial!
          </h2>

          <div className="max-w-md prose text-center">
            Reduce your costs, spend less time waiting on parts, and get the insights you need to
            fuel equipment uptime, so your org can make more money and win more jobs.
          </div>
        </div>

        <div className="max-w-md w-full flex gap-x-4 items-center">
          <Action.S
            className="w-full text-center text-base"
            onClick={() => {
              GE.clicksButton('parts-hub-pro-free-trial-maybe-later')
              onClose()
            }}
          >
            Maybe later
          </Action.S>
          <Action.P
            performing={isPerforming}
            onClick={() => {
              GE.clicksButton('parts-hub-pro-free-trial-start')
              setIsPerforming(true)
              startProTrial({ variables: { organizationId: organization.id } })
                .then(reloadSession)
                .then(onClaimed)
                // Run this after onClaimed, so it doesn't mess up the control logic
                .then(() => gqlClient.refetchQueries({ include: 'all' }))
                .finally(() => setIsPerforming(false))
            }}
            color="blue"
            className="w-full text-center text-base"
          >
            Start free trial
          </Action.P>
        </div>
      </div>

      <div className="flex flex-row gap-x-12 items-center">
        <div className="w-64 flex flex-col gap-y-4 items-center text-center">
          <img src={mapImage} alt="Vendors map" />
          <span className="text-base text-gray-900 font-medium">
            Work with all your current vendors
          </span>
        </div>
        <div className="w-64 flex flex-col gap-y-4 items-center text-center">
          <img src={reportsImage} alt="Parts report" />
          <span className="text-base text-gray-900 font-medium">
            Unlock parts purchasing insights
          </span>
        </div>
      </div>

      <div className="pt-4 border-t max-w-md text-base text-gray-600 text-center">
        This will start your trial automatically. After 30 days, you&apos;ll go back to your
        previous plan. No need to cancel or worry about extra charges.
      </div>
    </div>
  )
}

export default FreeProTrialSplashContent
