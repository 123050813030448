import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { useLocation } from 'react-router-dom'
import { useRollbarPerson } from '@rollbar/react'

import type { SessionUser, SessionOrganization } from '@/buyers/types'

import useConfig from '@/gf/hooks/useConfig'
import useLocalStorage from '@/gf/hooks/useLocalStorage'
import { updatePartsHubSession } from '@/gf/modules/Analytics'
import SessionContext from '@/buyers/contexts/SessionContext'
import ReloadSessionContext from '@/buyers/contexts/ReloadSession'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import { useSessionQuery, useTrackUserIsActiveMutation } from '@/buyers/_gen/gql'

declare const window: Window & { hj?: (fn: 'identify', userId: string, attrs: object) => unknown }

const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const config = useConfig()
  const gqlClient = useGqlClient()
  const result = useSessionQuery({ client: gqlClient })
  const newSession = result.data?.session
  const [session, setSession] = useState(newSession)
  const location = useLocation()
  const [trackUserIsActive] = useTrackUserIsActiveMutation()
  useRollbarPerson({ email: session?.user?.email })

  const { value: storageLastActiveAt, setValue: setStoreLastActiveAt } =
    useLocalStorage<DateTime | null>(
      'lastActiveAt',
      null,
      (rawValue) => DateTime.fromISO(rawValue),
      (value) => value?.toISO() ?? null
    )

  const reload = () =>
    result.refetch().then(({ data }) => {
      setSession(data.session)
    })

  useEffect(() => {
    if (newSession) setSession(newSession)
  }, [!newSession])

  useEffect(() => {
    // Setup Hotjar
    if (window.hj && session?.user) {
      const userName = session.user.name ?? null
      const companyName = session.organization?.name ?? null
      const role = session.user.userRole?.name ?? null

      if (!session.xrayUserId) {
        window.hj('identify', session.user.id, {
          user_name: userName,
          company_name: companyName,
          role,
          xray: false,
        })
      } else {
        window.hj('identify', `${session.xrayUserId}:${session.user.id}`, {
          user_name: `(xray) ${userName ?? ''}`,
          company_name: `(xray) ${companyName ?? ''}`,
          role: `(xray) ${role ?? ''}`,
          xray: true,
        })
      }
    }

    // Setup GA session
    if (session?.user) {
      updatePartsHubSession(
        session.organization?.id,
        session.organization?.name,
        session.user.id,
        session.user.name ?? undefined,
        session.user.userRole?.name,
        !!session.xrayUserId
      )
    }
  }, [session])

  // Update when the user was last active at
  useEffect(() => {
    // Refresh every hour
    if (
      session?.user &&
      (!storageLastActiveAt || storageLastActiveAt.diffNow('hours').hours < -1)
    ) {
      trackUserIsActive({ client: gqlClient }).then(() => {
        setStoreLastActiveAt(DateTime.now())
      })
    }
  }, [location, session, storageLastActiveAt])

  if (!session) return null

  // public url
  if (window.location.pathname.startsWith('/~/')) {
    const unauthenticatedSession = {
      ...session,
      user: null as unknown as SessionUser,
      orgId: null as unknown as string,
      organization: null as unknown as SessionOrganization,
      pendingInvites: [],
    }

    return (
      <ReloadSessionContext.Provider value={reload}>
        <SessionContext.Provider value={unauthenticatedSession}>{children}</SessionContext.Provider>
      </ReloadSessionContext.Provider>
    )
  }

  // unauthenticated
  if (!session.user || !session.organization) {
    window.location.href = `${config.gfBaseUrl}/login?return_path=${encodeURIComponent(
      window.location.href
    )}`
    return null
  }

  // rebuild session as a hack to convince TS it's an AuthenticatedSession
  const authenticatedSession = {
    ...session,
    user: session.user,
    orgId: session.orgId as string,
    organization: session.organization,
  }

  return (
    <ReloadSessionContext.Provider value={reload}>
      <SessionContext.Provider value={authenticatedSession}>{children}</SessionContext.Provider>
    </ReloadSessionContext.Provider>
  )
}

export default SessionProvider
