import { DateTime } from 'luxon'
import { useState } from 'react'

import DateTimeInput from '@/gf/components/inputs/DateTime'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import {
  StoreOrderProblemType,
  useMarkShipmentReceivedMutation,
  useReportStoreOrderProblemMutation,
} from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'

import Action from '@/gf/components/Action'
import ModalForm from '@/gf/components/ModalForm'
import Select from '@/gf/components/Select'
import TextArea from '@/gf/components/TextArea'
import StoreOrder from '@/gf/modules/StoreOrder'

const MarkShipmentReceivedButton = ({
  id,
  storeOrderId,
  onOk,
}: {
  id: string
  storeOrderId: string
  onOk: () => void
}) => {
  const gqlClient = useGqlClient()
  const [markReceived] = useMarkShipmentReceivedMutation({ client: gqlClient })
  const [reportStoreOrderProblem] = useReportStoreOrderProblemMutation({ client: gqlClient })
  const [accuracyOptionId, setAccuracyOptionId] = useState(StoreOrder.noneAccuracyOption.id)
  const reportProblem = accuracyOptionId !== StoreOrder.noneAccuracyOption.id
  const [accuracyDetails, setAccuracyDetails] = useState('')
  const [_msgs, msgsMgr] = useMsgs()
  const [modalOpen, modalToggle] = useToggle()
  const [submitting, submitToggle] = useToggle()
  const [receivedAt, setReceivedAt] = useState(DateTime.now())

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    submitToggle.on()

    markReceived({ variables: { shipmentId: id, receivedAt: receivedAt.toUTC() } })
      .then(() => {
        if (reportProblem) {
          return reportStoreOrderProblem({
            variables: {
              storeOrderProblem: {
                storeOrderId,
                shipmentId: id,
                type: accuracyOptionId as StoreOrderProblemType,
                details: accuracyDetails,
              },
            },
          })
        }
        return Promise.resolve(undefined)
      })
      .then(() => {
        onOk()
      })
      .catch(() => msgsMgr.addUnknownError())
      .finally(() => {
        modalToggle.off()
        submitToggle.off()
      })
  }

  return (
    <>
      <ModalForm
        title="Mark Received"
        open={modalOpen}
        onClose={modalToggle.off}
        onSubmit={onSubmit}
        submitButtonShowSpinner={submitting}
        submitButtonDisabled={submitting}
      >
        <div className="mt-4 flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-1">
            <h3 className="text-base text-gray-900">
              Approximately when did you receive this shipment?
            </h3>

            <DateTimeInput
              dateTime={receivedAt}
              updateDateTime={(ra) => ra && setReceivedAt(ra)}
              max={DateTime.now()}
              required
            />
          </div>

          <div className="flex flex-col gap-y-1">
            <h3 className="text-base text-gray-900">Accuracy</h3>

            <p>Is there a problem with your Order?</p>

            <Select
              currentId={accuracyOptionId}
              options={StoreOrder.accuracyOptions}
              onChange={(type) => setAccuracyOptionId(type as StoreOrderProblemType)}
            />

            {reportProblem && (
              <TextArea
                className="h-32"
                placeholder="Please describe the problem in detail. This information will be sent to your Vendor, and will help them resolve the issue."
                value={accuracyDetails}
                setValue={setAccuracyDetails}
                required
              />
            )}
          </div>
        </div>
      </ModalForm>

      <Action.P onClick={modalToggle.on} performing={submitting}>
        <span className="flex gap-1">
          <span>Mark Received</span>
        </span>
      </Action.P>
    </>
  )
}

export default MarkShipmentReceivedButton
