import React from 'react'
import ReactSelect from 'react-select'

import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/suppliers/hooks/useSession'
import useStores from '@/suppliers/hooks/useStores'
import useAccountMachineSearch from '../../hooks/useAccountMachineSearch'
import useConfig from '../../hooks/useConfig'
import useFetchOrgs from '../../hooks/useFetchOrganizations'
import useFetchSources from '../../hooks/useFetchSources'
import Time from '../../modules/Time'
import FilterSelect from '../FilterSelect'

import { useLocationsSelectQuery } from '@/buyers/_gen/gql'
import BigSelect from '../BigSelect'
import Button from '../ButtonOld'
import SelectField from '../SelectField'
import DateTimeInput from '../inputs/DateTime'

import { EqualsFilter, Filter, FilterField, FilterType, FiltersApi } from '../../../types'

const SourceSelect = ({
  filter,
  onChange,
  className = '',
}: {
  filter: EqualsFilter
  onChange: (id?: string) => void
  className?: string
}) => {
  const config = useConfig()
  const { store } = useSession()
  const { data } = useFetchSources({ storeId: config.app === 'suppliers' ? store.id : undefined })

  if (!data) return <>Loading&hellip;</>

  const { sources } = data

  const options = sources.map((source) => ({ value: source.id, label: source.name }))

  const defaultValue = options.find((o) => o.value === filter.text)

  return (
    <ReactSelect
      options={options}
      defaultValue={defaultValue}
      onChange={(option) => onChange(option?.value)}
      className={className}
    />
  )
}

const StoreSelect = ({
  selectedId,
  setSelectedId,
}: {
  selectedId: string
  setSelectedId: (id: string | null) => void
}) => {
  const allStores = useStores().data?.allStores
  const [stores, setStores] = React.useState(allStores)

  React.useEffect(() => {
    setStores(allStores)
  }, [!allStores])

  if (!allStores) return null

  const options = stores?.map((s) => ({
    value: s.id,
    label: `${s.name}${s?.alias ? ` (${s.alias})` : ''}`,
  }))

  const storeFilter = (term: string) => {
    const filteredStores = allStores
      ?.filter((s) => {
        if (s?.alias) {
          return (
            s.name.toLowerCase().includes(term.toLowerCase()) ||
            s.alias.toLowerCase().includes(term.toLowerCase())
          )
        }
        return s.name.toLowerCase().includes(term.toLowerCase())
      })
      .slice(0, 100)

    setStores(filteredStores)
  }

  return (
    <BigSelect
      options={options}
      filter={storeFilter}
      selectedValue={selectedId}
      setSelectedValue={setSelectedId}
    />
  )
}

const OrgSelect = ({
  filter,
  onChange,
  className = '',
}: {
  filter: EqualsFilter
  onChange: (id?: string) => void
  className?: string
}) => {
  const organizations = useFetchOrgs().data?.organizations

  if (!organizations) return <>Loading&hellip;</>

  const options = organizations.map((o) => ({ value: o.id, label: o.name }))

  const defaultValue = options.find((o) => o.value === filter.text)

  return (
    <ReactSelect
      options={options}
      defaultValue={defaultValue}
      onChange={(option) => onChange(option?.value)}
      className={className}
    />
  )
}

const ShippingLocationSelect = ({
  selectedId,
  setSelectedId,
}: {
  selectedId: string
  setSelectedId: (id: string | null) => void
}) => {
  const { data, loading, refetch } = useLocationsSelectQuery({ client: useGqlClient() })

  const options = data?.locations
    .sort((a, _b) => (a.defaultLocation ? -1 : 0)) // default location first
    .map((l) => ({
      label: `${l.name} ${l.defaultLocation ? `(default)` : ''}`,
      value: l.address.id,
    }))

  return (
    <BigSelect
      options={options}
      filter={(value) => refetch({ value })}
      loading={loading}
      selectedValue={selectedId}
      setSelectedValue={setSelectedId}
    />
  )
}

const MachineSelect = ({
  selectedId,
  setSelectedId,
}: {
  selectedId: string
  setSelectedId: (id: string | null) => void
}) => {
  const variables = { page: 1, filters: [] }
  const result = useAccountMachineSearch({ variables, notifyOnNetworkStatusChange: true })

  const options = result.data?.accountMachineSearch.machines?.map((m) => ({
    value: m.id,
    label: m.name,
  }))

  const filter = (term: string) =>
    result.refetch({ ...variables, filters: [['contains', 'unit_number', term]] })

  return (
    <BigSelect
      options={options}
      filter={filter}
      loading={result.loading}
      selectedValue={selectedId}
      setSelectedValue={setSelectedId}
    />
  )
}

const Filter = ({
  filter,
  fields,
  filterTypes,
  filtersApi,
  canRemove = true,
}: {
  filter: Filter
  fields: FilterField[]
  filterTypes: FilterType[]
  filtersApi: FiltersApi
  canRemove?: boolean
}) => {
  const updateField = (field: FilterField) => filtersApi.updateField(filter, field)
  const updateOption = (optionId: string) => filtersApi.updateOption(filter, optionId)

  const field = fields.find((f) => f.id === filter.fieldId)
  if (!field) {
    throw new Error(`Filter field id '${filter.fieldId}' not found in fields`)
  }
  const availableFilterTypes = filterTypes.filter((ft) => field?.filterTypeIds.includes(ft.id))
  const currentFilterType = filterTypes.find((ft) => ft.id === filter.typeId) as FilterType
  const filterTypeChanged = (filterType: FilterType) =>
    filtersApi.updateTypeId(filter, filterType.id)

  const onSourceChange = (id?: string) => {
    if (id) filtersApi.updateText(filter, id)
  }

  const setSelectedStoreId = (id: string | null) => filtersApi.updateText(filter, id || '')

  const onOrgChange = (id?: string) => {
    if (id) filtersApi.updateText(filter, id)
  }

  const setSelectedMachineId = (id: string | null) => filtersApi.updateText(filter, id || '')

  const setSelectedShippingLocationId = (id: string | null) =>
    filtersApi.updateText(filter, id || '')

  return (
    <div className="flex flex-col sm:flex-row space-y-0.5 space-x-0.5 sm:space-x-2 sm:items-center">
      <div className="flex space-x-0.5 sm:space-x-2 items-center">
        <FilterSelect options={fields} current={field} onChange={updateField} />

        {currentFilterType && (
          <FilterSelect
            options={availableFilterTypes}
            current={currentFilterType}
            onChange={filterTypeChanged}
          />
        )}
      </div>

      <div className="flex grow space-x-0.5 sm:space-x-2 items-center">
        {'text' in filter &&
          filter.fieldId !== 'source_id' &&
          filter.fieldId !== 'store_id' &&
          filter.fieldId !== 'organization_id' &&
          filter.fieldId !== 'shipping_location_address_id' &&
          filter.fieldId !== 'account_machine_id' &&
          filter.fieldId !== 'org_machine_id' && (
            <input
              value={filter.text}
              onChange={(e) => filtersApi.updateText(filter, e.target.value)}
              type="text"
              className="text-sm border border-gray-300 rounded-md grow text-gray-700"
            />
          )}

        {filter.typeId === 'date_time_in_range' && (
          <div className="flex flex-wrap gap-2 items-center">
            <DateTimeInput
              dateTime={filter.from ? Time.fromPayload(filter.from) : null}
              updateDateTime={(dateTime) => filtersApi.updateFrom(filter, Time.toPayload(dateTime))}
              max={filter.to ? Time.fromPayload(filter.to) : Time.now()}
            />
            <span className="text-sm text-gray-500">and</span>
            <DateTimeInput
              dateTime={filter.to ? Time.fromPayload(filter.to) : null}
              updateDateTime={(dateTime) => filtersApi.updateTo(filter, Time.toPayload(dateTime))}
              min={filter.from ? Time.fromPayload(filter.from) : undefined}
            />
          </div>
        )}

        {(filter.typeId === 'is' || filter.typeId === 'is_not') &&
          (() => {
            const options = filter.options.length !== 0 ? filter.options : field.options || []
            return (
              <div className="-mb-1 mt-0 flex-grow">
                <SelectField
                  required
                  label=""
                  placeholder={filter.options.length === 0 ? 'N/A' : 'Select...'}
                  currentId={filter.selectedOption}
                  options={
                    options.length === 0 ? options : [{ display: 'Select...', id: '' }, ...options]
                  }
                  onChange={updateOption}
                />
              </div>
            )
          })()}

        {filter.typeId === 'equals' && filter.fieldId === 'source_id' && (
          <SourceSelect filter={filter} onChange={onSourceChange} className="grow" />
        )}

        {filter.typeId === 'equals' && filter.fieldId === 'store_id' && (
          <div className="grow">
            <StoreSelect selectedId={filter.text} setSelectedId={setSelectedStoreId} />
          </div>
        )}

        {filter.typeId === 'equals' && filter.fieldId === 'organization_id' && (
          <OrgSelect filter={filter} onChange={onOrgChange} className="grow" />
        )}

        {filter.typeId === 'equals' &&
          (filter.fieldId === 'account_machine_id' || filter.fieldId === 'org_machine_id') && (
            <div className="grow">
              <MachineSelect selectedId={filter.text} setSelectedId={setSelectedMachineId} />
            </div>
          )}

        {filter.typeId === 'equals' && filter.fieldId === 'shipping_location_address_id' && (
          <div className="grow">
            <ShippingLocationSelect
              selectedId={filter.text}
              setSelectedId={setSelectedShippingLocationId}
            />
          </div>
        )}

        {canRemove && (
          <div>
            <Button onClick={() => filtersApi.remove(filter.id)} primary={false}>
              ➖
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Filter
