import { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { uniq } from 'lodash'

import {
  FetchOrganizationUsersDocument,
  FetchOrganizationUsersQueryResult,
  FetchOrganizationUsersQueryVariables,
  User,
} from '@/buyers/_gen/gql'

import useSession from '@/buyers/hooks/useSession'
import useGqlClient from '../hooks/useGqlClient'

import AutocompleteMultiInputNext from './AutocompleteMultiInputNext'
import Ghost from '@/gf/components/Ghost'
import useConversationMembers from '../hooks/useConversationMembers'

type UserT = Pick<User, 'id' | 'name' | 'email'>

type Props = {
  conversationId: string | undefined
  conversationStoreOrderId?: string
  conversationRfqId?: string
  onChangeSendToUserIds: (sendToUserIds: string[]) => void
  errorText?: string
  className?: string
}

const MessagesSendToInput = ({
  conversationId,
  conversationRfqId,
  conversationStoreOrderId,
  onChangeSendToUserIds,
  errorText,
  className,
}: Props) => {
  const { user } = useSession()
  const gqlClient = useGqlClient()
  const [sendToUsers, setSendToUsers] = useState<UserT[]>([])
  const { data, error } = useConversationMembers({
    conversationId,
    conversationRfqId,
    conversationStoreOrderId,
  })
  const conversationMembers = useMemo(() => data?.filter(({ id }) => id !== user.id), [data])
  const onChange = (users: UserT[]) => {
    setSendToUsers(users)
    onChangeSendToUserIds(uniq([...users, ...(conversationMembers ?? [])].map(({ id }) => id)))
  }
  useEffect(() => {
    // Reset the input when the member data changes
    if (data) onChange([])
  }, [user, data])
  return (
    <div className="w-full flex flex-col items-center gap-y-2">
      <div
        className={classNames(
          'py-2 px-6 w-full flex flex-row flex-wrap justify-center items-center gap-2 text-sm text-gray-700 bg-gray-50 border border-gray-100 rounded-full shadow-md',
          className
        )}
      >
        <span>Notify:</span>
        {error ? (
          <div className="py-2 flex gap-1 items-center">
            <ExclamationCircleIcon
              className="block h-4 w-4 flex-shrink-0 text-red-500"
              aria-hidden="true"
            />
            <span className="text-sm text-red-500 leading-4">
              Error loading Conversation Members
            </span>
          </div>
        ) : !conversationMembers ? (
          <>
            <Ghost className="inline-block w-24 h-6 flex bg-gray-300" />
            <Ghost className="inline-block max-w-sm grow h-[38px] flex bg-gray-300" />
          </>
        ) : (
          <>
            {conversationMembers.map(({ id, name, email }) => (
              <span key={id}>
                <span className="font-medium underline">{name || email}</span>
                <span className="ml-0.5">,</span>
              </span>
            ))}
            <div className="grow max-w-sm">
              <AutocompleteMultiInputNext<
                NonNullable<FetchOrganizationUsersQueryResult['data']>,
                FetchOrganizationUsersQueryVariables,
                UserT
              >
                gqlClient={gqlClient}
                query={FetchOrganizationUsersDocument}
                queryVars={(search) => ({ search })}
                onChange={onChange}
                transformQueryToData={(queryData) => queryData.fetchOrganizationUsers}
                transformDatumToOption={({ id, name, email }) => ({
                  value: id,
                  label: name ?? email,
                })}
                filterOption={(option) =>
                  option.value !== user.id &&
                  !conversationMembers.some(({ id }) => id === option.value)
                }
                selectedValues={sendToUsers}
                placeholder={
                  conversationMembers.length === 0 ? 'Search names or emails...' : 'Anyone else?'
                }
              />
            </div>
          </>
        )}
      </div>
      {errorText && (
        <div className="flex gap-1 items-center">
          <ExclamationCircleIcon
            className="block h-4 w-4 flex-shrink-0 text-red-500"
            aria-hidden="true"
          />
          <span className="text-sm text-red-500 leading-4">{errorText}</span>
        </div>
      )}
    </div>
  )
}

export default MessagesSendToInput
