import { ConversationRowContent, NewConversation } from '@/types'
import {
  Conversation as ConversationT,
  Organization,
  Store,
  Store as StoreT,
} from '@/buyers/_gen/gql'

import ConversationM from '@/gf/modules/Conversation'
import IsFilter from '@/gf/modules/filters/Is'

const internalOrgConversationName = (organization: Pick<Organization, 'name'>) => organization.name

const conversationName = (store: Pick<StoreT, 'name'>) => store.name

// Non-admin Conversations
const newNonAdminConversationRfq = <
  Rfq extends { id: string },
  Store extends { id: string; name: string }
>(
  requestForQuote: Rfq,
  store: Store
) => ({
  ...ConversationM.nonAdminConversationCommon,
  id: `new-conversation-${requestForQuote.id}-${store.id}`,
  name: conversationName(store),
  requestForQuote,
  storeOrder: undefined,
  store,
})

const newNonAdminConversationStoreOrder = <
  StoreOrder extends { id: string; store: { id: string; name: string } }
>(
  storeOrder: StoreOrder
) => ({
  ...ConversationM.nonAdminConversationCommon,
  id: `new-conversation-${storeOrder.id}-${storeOrder.store.id}`,
  name: conversationName(storeOrder.store),
  requestForQuote: undefined,
  storeOrder,
  store: storeOrder.store,
})

// Admin Conversations
const newAdminConversationRfq = <Rfq extends { id: string }>(
  orgId: string | undefined,
  requestForQuote: Rfq
) => ({
  ...ConversationM.adminConversationCommon,
  id: `new-conversation-${requestForQuote.id}-${orgId ?? 'customer'}-admin`,
  requestForQuote,
  storeOrder: undefined,
  store: undefined,
})

const newAdminConversationStoreOrder = <StoreOrder extends { id: string }>(
  storeOrder: StoreOrder
) => ({
  ...ConversationM.adminConversationCommon,
  id: `new-conversation-${storeOrder.id}-customer-admin`,
  requestForQuote: undefined,
  storeOrder,
  store: undefined,
})

// Internal Organization Conversations
const newInternalOrgConversationRfq = <Rfq extends { id: string }>(
  orgId: string | undefined,
  requestForQuote: Rfq,
  organization: Pick<Organization, 'name'>
) => ({
  ...ConversationM.nonAdminConversationCommon,
  id: `new-conversation-${requestForQuote.id}-${orgId ?? 'customer'}-internal`,
  name: internalOrgConversationName(organization),
  requestForQuote,
  storeOrder: undefined,
  store: undefined,
})

const newInternalOrgConversationStoreOrder = <StoreOrder extends { id: string }>(
  storeOrder: StoreOrder,
  organization: Pick<Organization, 'name'>
) => ({
  ...ConversationM.nonAdminConversationCommon,
  id: `new-conversation-${storeOrder.id}-customer-internal`,
  name: internalOrgConversationName(organization),
  requestForQuote: undefined,
  storeOrder,
  store: undefined,
})

const inboxConversationFilters = (userId: string) => [
  IsFilter.build({
    fieldId: 'buyerUserId',
    selectedOption: userId,
  }),
]

const inboxUnreadMessagesConversationFilters = (userId: string) => [
  ...inboxConversationFilters(userId),
  ConversationM.unreadMessagesConversationFilter(),
]

const defaultConversationRowContent = (
  conversation:
    | (Pick<ConversationT, 'admin' | 'name'> & { store: Pick<Store, 'id'> | null })
    | NewConversation
): ConversationRowContent => ({
  name: {
    type: conversation.admin ? 'admin' : conversation.store ? 'supplier' : 'internal_org',
    name: conversation.name,
  },
})

export default {
  ...ConversationM,
  conversationName,
  newNonAdminConversationRfq,
  newNonAdminConversationStoreOrder,
  newAdminConversationRfq,
  newAdminConversationStoreOrder,
  newInternalOrgConversationRfq,
  newInternalOrgConversationStoreOrder,
  inboxConversationFilters,
  inboxUnreadMessagesConversationFilters,
  defaultConversationRowContent,
}
