import { useVendorsCountQuery, Org } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'

const useVendorSelectionType = (
  org: { features: Pick<Org['features'], 'viewDealers'> } | undefined | null
) => {
  const { data: vendorsCountData } = useVendorsCountQuery({ client: useGqlClient() })

  const accountHasVendors = vendorsCountData
    ? vendorsCountData.nonDraftVendors.pagination.totalResults > 0
    : false

  return org?.features.viewDealers ? 'full' : accountHasVendors ? 'limited' : 'disabled'
}

export default useVendorSelectionType
