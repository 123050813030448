import { CheckIcon, XIcon } from '@heroicons/react/solid'
import { LineSvgProps, ResponsiveLine } from '@nivo/line'
import { pick, round, values } from 'lodash'
import { DateTime } from 'luxon'
import { ReactNode, useState } from 'react'

import { SortByInput, SortOrder } from '@/buyers/_gen/gql'

import { SecondDegreeStandardBooleanAggregateT } from '@/buyers/modules/Reporting'
import useChartPointSymbol, { pointToDate } from '@/gf/hooks/useChartPointSymbol'
import Chart from './Chart'
import useMetrics from './useMetrics'
import useReportingFormQueryParams from './useReportingFormQueryParams'
import useSelectedValues, { SelectedValue } from './useSelectedValues'
import useSortBy from './useSortBy'

import RequestsTableHeader from '@/buyers/modules/RequestsTableHeader'
import Ghost from '@/gf/components/Ghost'
import MachineDownIcon from '@/gf/components/MachineDownIcon'
import ChartBreakdownLayout from '@/gf/components/Reports/ChartBreakdownLayout'
import DurationInput, {
  defaultDurationDates,
  defaultDurationId,
} from '@/gf/components/Reports/DurationInput'
import NivoLineTooltip from '@/gf/components/Reports/NivoPointTooltip'
import ReportingTable, {
  Column,
  NoResults,
  getBreakdownSelectMessage,
} from '@/gf/components/Reports/ReportingTable'
import Tabs from '@/gf/components/next/Tabs'
import TitleMetric from '@/gf/components/next/TitleMetric'
import Tag from '../../../gf/components/Reports/Tag'
import { getOrgMachineName } from './OrgMachine'
import { OutlierBadgeValues, getReportingTableValue } from './ReportingTableValue'
import RequestsTable from './RequestsTable'
import Scorecards, { Unit, defaultFormatValue } from './Scorecards'
import StandardColumns from './StandardColumns'

const formatPercentValue = (value: number) => defaultFormatValue(value, 1, Unit.Percent)

// TODO: there's a bit of overlap here with defining the sortBy and setSortBy
const getColumnUrgentPercent = <
  T extends { urgent: { percent: number } | null },
  U extends { urgent: { percent: OutlierBadgeValues | null } }
>({
  aggregate,
}: {
  aggregate: U | undefined
}): Column<T> => ({
  header: 'Urgent',
  sortByField: 'urgent.percent',
  getValue: (row) =>
    getReportingTableValue<T, U>(row, (r) => r.urgent?.percent ?? null, formatPercentValue, {
      aggregate,
      getValues: (a) => a?.urgent.percent ?? null,
      downIsGood: true,
    }),
})

const getColumnRequestsCount = <
  T extends { urgent: { count: number } | null },
  U extends { urgent: { count: OutlierBadgeValues | null } }
>(): Column<T> => ({
  header: 'Requests',
  sortByField: 'urgent.count',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.urgent?.count ?? null,
      (value) => value,
      undefined
    ),
})

const getColumnMachineDownValue = ({ isUrgent }: { isUrgent: number | null }): ReactNode =>
  isUrgent === null ? (
    <>None</>
  ) : (
    <div className="flex items-center gap-x-2">
      {/* TODO: display this as a badge or something */}
      {isUrgent ? <MachineDownIcon /> : null}
    </div>
  )

const getColumnAccurateValue = ({ isAccurate }: { isAccurate: number | null }): ReactNode =>
  isAccurate === null ? (
    <>None</>
  ) : (
    <div className="flex items-center gap-x-2">
      {isAccurate ? (
        <div className="p-1 flex items-center justify-center bg-green-100 text-green-800 rounded-full">
          <CheckIcon className="w-5 h-5 flex shrink-0" />
        </div>
      ) : (
        <div className="p-1 flex items-center justify-center bg-red-100 text-red-800 rounded-full">
          <XIcon className="w-5 h-5 flex shrink-0" />
        </div>
      )}
    </div>
  )

const getColumnInternalFulfillmentValue = ({
  isInternalFulfillment,
}: {
  isInternalFulfillment: number | null
}): ReactNode =>
  isInternalFulfillment === null ? (
    <>None</>
  ) : (
    <div className="flex items-center gap-x-2">
      {/* TODO: display this as a badge or something */}
      {isInternalFulfillment ? (
        <div className="p-1 flex items-center justify-center bg-green-100 text-green-800 rounded-full">
          <CheckIcon className="w-5 h-5 flex shrink-0" />
        </div>
      ) : (
        <div className="p-1 flex items-center justify-center bg-red-100 text-red-800 rounded-full">
          <XIcon className="w-5 h-5 flex shrink-0" />
        </div>
      )}
    </div>
  )

type Tab = 'Vendor' | 'Requester' | 'Machine'
type Metric = 'urgency' | 'accuracy' | 'internal fulfillment'

const getMetricSortByField = (metric: Metric) =>
  metric === 'internal fulfillment'
    ? 'internalFulfillment.percent'
    : metric === 'accuracy'
    ? 'accuracy.percent'
    : 'urgency.percent'

const useReportingMetrics = <TabT extends Tab, MetricT extends Metric>({
  durationStart,
  durationEnd,
  metric,
  tab,
  urgentRequestsOnly,
  selectedValue,
  selectedDate,
  sortBy,
  setSortBy,
  requestsSortBy,
  toggleSelectedValue,
  clearSelectedValue,
}: {
  durationStart: DateTime
  durationEnd: DateTime
  metric: MetricT
  tab: TabT
  urgentRequestsOnly: boolean
  selectedValue: SelectedValue | undefined
  selectedDate: DateTime | undefined
  sortBy: SortByInput
  setSortBy: (prev: SortByInput) => void
  requestsSortBy: SortByInput
  toggleSelectedValue: ReturnType<typeof useSelectedValues>['toggleSelectedValue']
  clearSelectedValue: ReturnType<typeof useSelectedValues>['clearSelectedValue']
}) => {
  const {
    error,
    prevError,
    refetch,
    // Aggregates
    aggregatedRequestMetrics,
    prevAggregatedRequestMetrics,
    aggregatedStoreMetrics,
    aggregatedCreatorMetrics,
    aggregatedMachineMetrics,
    // Chart data
    chartData,
    chartDailyAverage,
    selectedChartData,
    // Table metrics
    orderedStoreMetrics,
    orderedCreatorMetrics,
    orderedMachineMetrics,
    filteredOrderedRequestMetrics,
  } = useMetrics({
    form: { durationStart, durationEnd, tab, urgentRequestsOnly },
    selectedValues: selectedValue ? [selectedValue] : [],
    selectedDate,
    sortBy,
    requestsSortBy,
    storeFilter: ({ store }) => !!store,
    creatorFilter: ({ creator }) => !!creator,
    purchaserFilter: ({ assignedUser }) => !!assignedUser,
    machineFilter: ({ orgMachine }) => !!orgMachine,
    urgencyFilter: () => true,
    requestFilter:
      metric === 'internal fulfillment'
        ? ({ isInternalFulfillment }) => isInternalFulfillment !== null
        : metric === 'accuracy'
        ? ({ isAccurate }) => isAccurate !== null
        : ({ isUrgent }) => isUrgent !== null,
    getChartValue:
      metric === 'internal fulfillment'
        ? ({ internalFulfillment }) => internalFulfillment?.sum
        : metric === 'accuracy'
        ? ({ accurate }) => accurate?.inverseSum
        : ({ urgent }) => urgent?.sum,
  })

  const chartSpec =
    metric === 'internal fulfillment'
      ? {
          yAxisLegend: 'Internal Fulfillment Requests',
        }
      : metric === 'accuracy'
      ? {
          yAxisLegend: 'Inaccurate Requests',
        }
      : {
          yAxisLegend: 'Urgent Requests',
        }
  const getMetricColumns = (
    aggregate:
      | {
          accurate: SecondDegreeStandardBooleanAggregateT
          urgent: SecondDegreeStandardBooleanAggregateT
          internalFulfillment: SecondDegreeStandardBooleanAggregateT
        }
      | undefined
  ) =>
    metric === 'internal fulfillment'
      ? {
          metric: StandardColumns.getInternalFulfillmentPercentColumn({ aggregate }),
          requests: StandardColumns.getInternalFulfillmentRequestsCountColumn(),
        }
      : metric === 'accuracy'
      ? {
          metric: StandardColumns.getAccuracyPercentColumn({ aggregate }),
          requests: StandardColumns.getAccuracyRequestsCountColumn(),
        }
      : {
          metric: getColumnUrgentPercent({ aggregate }),
          requests: getColumnRequestsCount(),
        }

  const breakdown =
    tab === 'Vendor' ? (
      <ReportingTable
        data={orderedStoreMetrics}
        sortBy={{ sortBy, setSortBy }}
        getRowKey={(row) => row.store?.id ?? ''}
        checkbox={{
          getChecked: (row) => row.store?.id === selectedValue?.value,
          onToggleRow: (row) =>
            row.store && toggleSelectedValue({ value: row.store.id, display: row.store.name }),
          onClear: clearSelectedValue,
        }}
        columns={[
          {
            header: 'Vendor',
            getValue: (row) => row.store?.name,
          },
          ...values(pick(getMetricColumns(aggregatedStoreMetrics), ['requests', 'metric'])),
        ]}
      />
    ) : tab === 'Requester' ? (
      <ReportingTable
        data={orderedCreatorMetrics}
        sortBy={{ sortBy, setSortBy }}
        getRowKey={(row) => row.creator?.id ?? ''}
        checkbox={{
          getChecked: (row) => row.creator?.id === selectedValue?.value,
          onToggleRow: (row) =>
            row.creator &&
            toggleSelectedValue({ value: row.creator.id, display: row.creator.name ?? '' }),
          onClear: clearSelectedValue,
        }}
        columns={[
          {
            header: 'Requester',
            getValue: (row) => row.creator?.name,
          },
          ...values(pick(getMetricColumns(aggregatedCreatorMetrics), ['requests', 'metric'])),
        ]}
      />
    ) : (
      <ReportingTable
        data={orderedMachineMetrics}
        sortBy={{ sortBy, setSortBy }}
        getRowKey={(row) => row.orgMachine?.id}
        checkbox={{
          getChecked: (row) => row.orgMachine?.id === selectedValue?.value,
          onToggleRow: (row) =>
            row.orgMachine &&
            toggleSelectedValue({
              value: row.orgMachine.id,
              display: getOrgMachineName(row.orgMachine),
            }),
          onClear: clearSelectedValue,
        }}
        columns={[
          {
            header: 'Machine',
            getValue: (row) => getOrgMachineName(row.orgMachine),
          },
          ...values(pick(getMetricColumns(aggregatedMachineMetrics), ['requests', 'metric'])),
        ]}
      />
    )

  if (error || prevError) return { error: error || prevError, refetch }
  return {
    refetch,
    current: {
      aggregate: aggregatedRequestMetrics,
      chart: {
        data: chartData,
        selectedCharts: selectedChartData,
        spec: chartSpec,
        dailyAverage: chartDailyAverage,
      },
      breakdown,
      orderedStoreMetrics,
      orderedCreatorMetrics,
      orderedMachineMetrics,
      filteredOrderedRequestMetrics,
    },
    prev: { aggregate: prevAggregatedRequestMetrics },
  }
}

const Performance = () => {
  const [selectedDate, setSelectedDate] = useState<DateTime>()
  const { form, updateForm } = useReportingFormQueryParams<Tab, Metric>({
    defaultDurationId,
    defaultDurationDates,
    defaultTab: 'Machine',
    defaultMetric: 'urgency',
  })
  const { selectedValue, toggleSelectedValue, clearSelectedValue } = useSelectedValues(form.tab)
  const { sortBy, setSortBy } = useSortBy(`${form.metric}:${form.tab}`, {
    field: getMetricSortByField(form.metric),
    order: form.tab === 'Requester' ? SortOrder.Asc : SortOrder.Desc,
  })
  const [requestsSortBy, setRequestsSortBy] = useState<SortByInput>({
    field: 'requestForQuote.insertedAt',
    order: SortOrder.Desc,
  })

  const clearFilters = () => {
    updateForm({ urgentRequestsOnly: false })
    setSelectedDate(undefined)
  }

  const days = form.durationEnd.diff(form.durationStart).as('days')
  const pointSymbol = useChartPointSymbol(selectedDate)

  const countGraph: Partial<LineSvgProps> = {
    ...Chart.getBaseGraph({ days, pointSymbol }),
    yFormat: (count) =>
      typeof count === 'number' ? round(count, 1).toLocaleString() : count.toLocaleString(),
  }

  const { error, current, prev } = useReportingMetrics({
    ...pick(form, ['durationStart', 'durationEnd', 'metric', 'tab', 'urgentRequestsOnly']),
    selectedValue,
    selectedDate,
    sortBy,
    setSortBy,
    requestsSortBy,
    toggleSelectedValue,
    clearSelectedValue,
  })

  return (
    <ChartBreakdownLayout
      error={!!error}
      titleMetric={
        <TitleMetric
          title={
            form.metric === 'urgency'
              ? 'Urgent'
              : form.metric === 'accuracy'
              ? 'Accuracy'
              : 'Internal Fulfillment'
          }
          value={
            typeof current?.aggregate === 'undefined'
              ? undefined
              : (form.metric === 'urgency'
                  ? current.aggregate.urgent?.percent
                  : form.metric === 'accuracy'
                  ? current.aggregate.accurate?.percent
                  : current.aggregate.internalFulfillment?.percent) ?? null
          }
          comparisonValue={
            typeof prev?.aggregate === 'undefined'
              ? undefined
              : (form.metric === 'urgency'
                  ? prev.aggregate.urgent?.percent
                  : form.metric === 'accuracy'
                  ? prev.aggregate.accurate?.percent
                  : prev.aggregate.internalFulfillment?.percent) ?? null
          }
          downIsGood={form.metric === 'urgency'}
          valueToDisplay={formatPercentValue}
          duration={form}
        />
      }
      durationInput={
        <DurationInput
          start={form.durationStart}
          end={form.durationEnd}
          durationId={form.durationId}
          onChange={({ start, end, durationId }) =>
            updateForm({ durationStart: start, durationEnd: end, durationId })
          }
        />
      }
      scorecards={
        <Scorecards
          className="max-w-screen-xl flex grow"
          scorecards={[
            {
              name: 'Urgent',
              value:
                typeof current?.aggregate === 'undefined'
                  ? undefined
                  : current.aggregate.urgent?.percent ?? null,
              fromValue:
                typeof prev?.aggregate === 'undefined'
                  ? undefined
                  : prev.aggregate.urgent?.percent ?? null,
              unit: Unit.Percent,
              downIsGood: true,
              active: form.metric === 'urgency',
              onClick: () => updateForm({ metric: 'urgency' }),
            },
            {
              name: 'Accuracy',
              value:
                typeof current?.aggregate === 'undefined'
                  ? undefined
                  : current.aggregate.accurate?.percent ?? null,
              fromValue:
                typeof prev?.aggregate === 'undefined'
                  ? undefined
                  : prev.aggregate.accurate?.percent ?? null,
              unit: Unit.Percent,
              active: form.metric === 'accuracy',
              onClick: () => updateForm({ metric: 'accuracy' }),
            },
            {
              name: 'Internal Fulfillment',
              value:
                typeof current?.aggregate === 'undefined'
                  ? undefined
                  : current.aggregate.internalFulfillment?.percent ?? null,
              fromValue:
                typeof prev?.aggregate === 'undefined'
                  ? undefined
                  : prev.aggregate.internalFulfillment?.percent ?? null,
              unit: Unit.Percent,
              active: form.metric === 'internal fulfillment',
              onClick: () => updateForm({ metric: 'internal fulfillment' }),
            },
          ]}
        />
      }
      chart={
        <div className="w-full h-full flex flex-col gap-y-6">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="min-h-[30px] w-full flex flex-row justify-end items-center gap-x-2">
              {selectedDate && (
                <Tag onRemove={() => setSelectedDate(undefined)}>
                  {selectedDate.toLocaleString(DateTime.DATE_MED)}
                </Tag>
              )}
              {selectedValue && (
                <Tag onRemove={() => clearSelectedValue()}>{selectedValue.display}</Tag>
              )}
            </div>
          </div>
          {!current?.chart.data ? (
            <Ghost className="w-full h-full flex bg-gray-300" />
          ) : (
            // Need a high z-index for the chart for the tooltips to show over the surrounding UI
            <div className="w-full h-full z-[1000]">
              <ResponsiveLine
                {...countGraph}
                data={[
                  {
                    id: form.metric,
                    data: current.chart.data,
                    color: Chart.getDataColor(0),
                  },
                  ...(typeof current.chart.dailyAverage !== 'undefined'
                    ? [
                        {
                          id: `Daily average ${form.metric}`,
                          data: current.chart.data.map(({ x }) => ({
                            x,
                            y: current.chart.dailyAverage,
                            noPoint: true,
                          })),
                          color: Chart.SECONDARY_LINE_COLOR,
                        },
                      ]
                    : []),
                  ...(current.chart.selectedCharts?.map((selected, index) => ({
                    ...selected,
                    color: Chart.getDataColor(index + 1),
                  })) || []),
                ].reverse()}
                tooltip={NivoLineTooltip}
                onClick={(point) => {
                  const pointDate = pointToDate(point.data.x)
                  if (selectedDate && pointDate?.equals(selectedDate)) setSelectedDate(undefined)
                  else setSelectedDate(pointDate)
                }}
              />
            </div>
          )}
        </div>
      }
      breakdownTable={
        <div className="flex grow flex-col gap-y-3 bg-white border border-gray-300 rounded-xl shadow-sm overflow-hidden">
          <div className="px-4 flex flex-col gap-y-3">
            <div className="pt-6 flex flex-row items-center gap-x-6">
              <Tabs
                tabs={[{ name: 'Machine' }, { name: 'Requester' }, { name: 'Vendor' }]}
                selectedTabName={form.tab}
                onTabSelect={(tab) => updateForm({ tab: tab as Tab })}
              />
            </div>
            <span className="text-sm text-gray-900">
              {form.tab === 'Machine'
                ? getBreakdownSelectMessage(current?.orderedMachineMetrics?.length, 'machine')
                : form.tab === 'Vendor'
                ? getBreakdownSelectMessage(current?.orderedStoreMetrics?.length, 'vendor')
                : getBreakdownSelectMessage(current?.orderedCreatorMetrics?.length, 'requester')}
            </span>
          </div>
          <div className="px-4 pb-0.5 overflow-x-scroll overflow-y-scroll">
            {current?.breakdown ? current.breakdown : null}
          </div>
        </div>
      }
      historyTable={
        <div className="pt-6 flex flex-col gap-y-3 bg-white border border-gray-300 rounded-lg shadow-sm">
          <div className="px-4 text-lg text-gray-900 font-medium">
            <RequestsTableHeader
              selectedValue={selectedValue}
              selectedDate={selectedDate}
              form={form}
            />
          </div>
          <div className="px-4 pb-3 max-h-96 overflow-x-scroll overflow-y-scroll">
            <ReportingTable
              data={current?.filteredOrderedRequestMetrics}
              sortBy={{ sortBy: requestsSortBy, setSortBy: setRequestsSortBy }}
              getRowKey={({ requestForQuote }) => requestForQuote.id}
              noResults={<NoResults onClearFilters={clearFilters} />}
              columns={[
                RequestsTable.getRequestColumn(),
                RequestsTable.getCreatedColumn(),
                form.metric === 'urgency'
                  ? {
                      header: 'Urgent',
                      getValue: getColumnMachineDownValue,
                      sortByField: 'isUrgent',
                    }
                  : form.metric === 'accuracy'
                  ? {
                      header: 'Accuracy',
                      // TODO: get the number of problems from the StoreOrders
                      getValue: getColumnAccurateValue,
                      sortByField: 'isAccurate',
                    }
                  : {
                      header: 'Internal',
                      getValue: getColumnInternalFulfillmentValue,
                      sortByField: 'isInternalFulfillment',
                    },
                RequestsTable.getCreatorColumn(),
                RequestsTable.getVendorColumn(),
              ]}
            />
          </div>
        </div>
      }
    />
  )
}

export default Performance
