import { ApolloError } from '@apollo/client'
import { useOrgMachineImagesQuery } from '../_gen/gql'
import useGqlClient from './useGqlClient'

interface Params {
  id: string
}

interface OrgMachineImage {
  url: string
}

interface OrgMachineImages {
  error?: ApolloError | null
  loading: boolean
  images: Array<OrgMachineImage>
}

const useOrgMachineImages = (params: Params) => {
  const { data, error, loading } = useOrgMachineImagesQuery({
    variables: { id: params.id },
    fetchPolicy: 'network-only',
    client: useGqlClient(),
  })

  const images = data?.machine?.images?.map((url) => ({ url } as OrgMachineImage)) || []

  return { error, loading, images } as OrgMachineImages
}

export default useOrgMachineImages
