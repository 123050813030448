import { DeliveryMethod } from '@/buyers/_gen/gql'
import Box from '@/buyers/components/Box'
import MarkShipmentReceivedAction from '@/buyers/components/MarkShipmentReceivedButton'
import A from '@/gf/components/A'
import Link from '@/gf/components/Link'
import useMsgs from '@/gf/hooks/useMsgs'
import { cn } from '@/gf/modules/utils'
import { ExclamationIcon } from '@heroicons/react/outline'
import { DateTime } from 'luxon'
import { TdHTMLAttributes, ThHTMLAttributes } from 'react'
import { useOrderContext } from '../context'
import { List, Row } from '../List'
import { Section } from '../YourOrder'

export const Th = ({ className, ...props }: ThHTMLAttributes<HTMLTableCellElement>) => (
  <th
    className={cn(
      'px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap text-left',
      className
    )}
    {...props}
  />
)

export const Td = ({ className, ...props }: TdHTMLAttributes<HTMLTableCellElement>) => (
  <td className={cn('text-sm px-6 py-3 align-top text-left', className)} {...props} />
)

const Shipments = () => {
  const [_, msgr] = useMsgs()
  const { storeOrder, refetchStoreOrder } = useOrderContext()

  const onMarkedReceived = () => {
    msgr.add('Shipment marked received.', 'positive')
    refetchStoreOrder()
  }

  if (storeOrder.deliveryMethod !== DeliveryMethod.Shipping || storeOrder.shipments.length === 0)
    return null

  return (
    <Box className="divide-y divide-gray-200">
      {storeOrder.shipments.map((shipment, index) => (
        <Section className="space-y-4" key={shipment.id}>
          <div className="flex gap-4 items-start justify-between">
            <div className="font-medium text-lg text-gray-900">
              Shipment {storeOrder.shipments.length > 1 && index + 1}
            </div>

            <div className="flex gap-2 items-center">
              {shipment.receivedAt && (
                <Link.S
                  to={`shipments/${shipment.id}/report-problem`}
                  className="flex gap-2 items-center"
                >
                  <ExclamationIcon className="h-5 w-5" />
                  <span>Report a Problem</span>
                </Link.S>
              )}

              {!shipment.receivedAt && (
                <MarkShipmentReceivedAction
                  id={shipment.id}
                  storeOrderId={storeOrder.id}
                  onOk={onMarkedReceived}
                />
              )}
            </div>
          </div>

          <List>
            {shipment.carrier && <Row>Carrier: {shipment.carrier}</Row>}
            {shipment.tracking && (
              <Row>
                Tracking number:
                {shipment.link ? (
                  <A.T href={shipment.link} target="_blank">
                    {shipment.tracking}
                  </A.T>
                ) : (
                  shipment.tracking
                )}
              </Row>
            )}
            <Row>Shipped: {shipment.shippedAt?.toLocaleString(DateTime.DATETIME_SHORT)}</Row>

            {shipment.receivedAt && (
              <Row>Received: {shipment.receivedAt?.toLocaleString(DateTime.DATETIME_SHORT)}</Row>
            )}
          </List>

          <div className="rounded-lg border overflow-hidden">
            <table className="divide-y-1 divide-gray-200 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <Th>Part number</Th>
                  <Th>Description</Th>
                  <Th className="text-center">Shipped quantity</Th>
                </tr>
              </thead>

              <tbody className="divide-y-1 divide-gray-200">
                {shipment.items.map((item) => (
                  <tr key={item.id}>
                    <Td>{item.lineItem.product.mpn}</Td>
                    <Td>{item.lineItem.product.name}</Td>
                    <Td className="text-center">{item.quantity}</Td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Section>
      ))}
    </Box>
  )
}

export default Shipments
