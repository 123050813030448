import { useParams } from 'react-router-dom'

import Frame from '@/buyers/components/Frame'
import Content from '@/buyers/components/LimitedFrame/Content'
import LimitedOrder from './LimitedOrder'

const Order = () => {
  const { storeOrderId } = useParams<{ storeOrderId: string }>() as { storeOrderId: string }

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'Orders', href: '/orders' },
      { name: 'Order details', href: `/orders/${storeOrderId}` },
    ],
  }

  return (
    <Frame breadcrumbs={breadcrumbs} contentClassName="flex flex-col">
      <Content className="flex flex-col grow">
        <LimitedOrder />
      </Content>
    </Frame>
  )
}

export default Order
