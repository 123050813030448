import Link from '@/gf/components/Link'
import SlideOver from '@/gf/components/SlideOver'
import classNames from 'classnames'
import { Fragment, ReactNode, useMemo } from 'react'
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom'
import OrgDetails from './OrgDetails'
import { CompanyDetails, MenuAction, NavItemGroup, SubscriptionDetails } from './types'

const NavItem = ({
  href,
  external = false,
  children,
}: {
  href: string
  external?: boolean
  children: ReactNode
}) => {
  const location = useLocation()
  const active = useMemo(
    () =>
      !!matchPath({ path: href }, location.pathname) ||
      !!matchPath({ path: `${href}/*` }, location.pathname),
    [href, location.pathname]
  )

  const className = classNames(
    'flex text-sm gap-x-2 items-center p-2 leading-6 rounded-md text-gray-900 [&>*:first-child]:text-gray-500',
    active && 'bg-gray-100 font-medium text-blue-600 [&>*:first-child]:text-blue-600'
  )

  return external ? (
    <a href={href} className={className} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <RouterLink to={href} className={className}>
      {children}
    </RouterLink>
  )
}

const NavMenu = ({
  className,
  companyDetails,
  subscriptionDetails,
  navItems,
  menuAction,
}: {
  companyDetails: CompanyDetails
  subscriptionDetails: SubscriptionDetails
  className: string
  navItems: NavItemGroup[]
  menuAction: MenuAction
}) => (
  <header className={className}>
    <OrgDetails companyDetails={companyDetails} subscriptionDetails={subscriptionDetails} />

    <div className="flex-grow flex flex-col overflow-y-auto ">
      <nav className="flex flex-col gap-y-4 py-4 flex-grow">
        {navItems.map((navGroup, i) => (
          <Fragment key={navGroup.name}>
            {i > 0 && <hr />}

            <div>
              {navGroup.items.map((navItem) => (
                <NavItem key={navItem.href} href={navItem.href} external={navItem.external}>
                  {navItem.children}
                </NavItem>
              ))}
            </div>
          </Fragment>
        ))}
      </nav>

      <SlideOver.FixedBottomElement className="w-full p-4" show>
        <Link.P to={menuAction.href} className="w-full font-medium text-center">
          {menuAction.label}
        </Link.P>
      </SlideOver.FixedBottomElement>
    </div>
  </header>
)

export default NavMenu
