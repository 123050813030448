import useMachineSuggestion from '@/buyers/hooks/useMachineSuggestion'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import AddAccountMachineModal from '@/gf/components/AddAccountMachineModal'
import useToggle from '@/gf/hooks/useToggle'

const AddMachineButton = ({ onMachineAdded }: { onMachineAdded(orgMachineId: string) }) => {
  const { organization } = useSession()
  const [open, toggle] = useToggle()
  const suggestMachines = useMachineSuggestion()

  return (
    <>
      <AddAccountMachineModal
        open={open}
        onClose={toggle.off}
        onAdd={(accountMachine) => {
          if (accountMachine) {
            onMachineAdded(accountMachine.id)
          }
        }}
        accountId={organization.id}
        // refetchQueries={[useAllAccountMachines.Query, useAccountMachines.Query]}
        autocompleteMachines={suggestMachines}
      />

      <Action.S className="flex-shrink-0" onClick={toggle.on}>
        Add Machine
      </Action.S>
    </>
  )
}

export default AddMachineButton
