const NewVendorAlert = () => (
  <div className="px-3 pt-3 pb-2 space-y-1 text-gray-700 border border-gray-700 border-opacity-10 bg-gray-50 rounded-md">
    <h3 className=" font-medium">New Vendor to Gearflow</h3>
    <p className="text-sm">
      Submit requests through email, sms, or landline - whichever way your vendor prefers.
    </p>
  </div>
)

export default NewVendorAlert
