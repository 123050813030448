import { Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom'

import Tabs from '@/gf/components/Tabs'
import Info from './Billing/Info'
import Details from './Billing/Details'
import Transactions from './Billing/Transactions'

const allTabs = [
  { id: 1, name: 'Billing Info', path: '/settings/billing/info' },
  { id: 2, name: 'Plan Details', path: '/settings/billing/details' },
  { id: 3, name: 'Direct Pay Transactions', path: '/settings/billing/transactions' },
]

const Billing = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const tabs = allTabs

  return (
    <>
      <Tabs
        tabs={tabs}
        currentTab={tabs.find((t) => t.path === location.pathname) || tabs[0]}
        onTabSelect={(name) => navigate(tabs.filter((t) => t.name === name)[0].path)}
        subTabs
      />

      <div className="mt-8">
        <Routes>
          <Route path="" element={<Navigate to="info" replace />} />
          <Route path="info" element={<Info />} />
          <Route path="details" element={<Details />} />
          <Route path="transactions" element={<Transactions />} />
        </Routes>
      </div>
    </>
  )
}

export default Billing
