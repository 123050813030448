import { GraphQLError } from 'graphql'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'

import { Maybe, Money } from '@/types'

import {
  CodeGeneratorConfigType,
  OrgSettingsQuery,
  useUpdateApprovalSettingsMutation,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useReloadSession from '@/buyers/hooks/useReloadSession'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'

import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import Checkbox from '@/gf/components/Checkbox'
import Field from '@/gf/components/Field'
import PriceInput from '@/gf/components/inputs/PriceV2'
import useSession from '../hooks/useSession'
import PurchaseOrderConfigInput from './ApprovalSettings/PurchaseOrderConfigInput'

export type PurchaseOrderGeneratorConfig = {
  sequence: number
  parts: {
    type: CodeGeneratorConfigType
    value: Maybe<string>
  }[]
}
interface ApprovalFormData {
  createRequestRequireWorkOrder: boolean
  showPurchaseOrder: boolean
  generatePurchaseOrderNumber: boolean
  generatePurchaseOrderNumberConfig: PurchaseOrderGeneratorConfig
  approvalThresholdEnabled: boolean
  approvalThreshold: Money | null
}

const ApprovalSettings = ({ org }: { org: Exclude<OrgSettingsQuery['org'], null> }) => {
  const reloadSession = useReloadSession()
  const { featureFlags } = useSession()
  const [loading, loader] = useToggle()
  const [_, msgr] = useMsgs()
  const [updateApprovalSettings] = useUpdateApprovalSettingsMutation({ client: useGqlClient() })

  const form = useForm<ApprovalFormData>({
    defaultValues: {
      createRequestRequireWorkOrder: org.createRequestRequireWorkOrder,
      showPurchaseOrder: org.showPurchaseOrder,
      generatePurchaseOrderNumber: org.generatePurchaseOrderNumber,
      generatePurchaseOrderNumberConfig: {
        sequence: org.generatePurchaseOrderNumberConfig?.sequence ?? 1,
        parts: org.generatePurchaseOrderNumberConfig?.config.map((c) => ({
          type: c.type,
          value: c.value,
        })) ?? [{ type: CodeGeneratorConfigType.Sequence, value: null }],
      },
      approvalThresholdEnabled: org.approvalThresholdEnabled,
      approvalThreshold: org.approvalThreshold,
    },
  })

  const values = useWatch({ control: form.control })

  const onUpdateSubmit: SubmitHandler<ApprovalFormData> = (data) => {
    loader.on()

    updateApprovalSettings({
      variables: {
        orgId: org.id,
        createRequestRequireWorkOrder: data.createRequestRequireWorkOrder,
        showPurchaseOrder: data.showPurchaseOrder,
        generatePurchaseOrderNumber: data.generatePurchaseOrderNumber,
        generatePurchaseOrderNumberConfig: {
          sequence: data.generatePurchaseOrderNumberConfig.sequence,
          config: data.generatePurchaseOrderNumberConfig.parts,
        },
        approvalThresholdEnabled: data.approvalThresholdEnabled,
        approvalThreshold: data.approvalThreshold,
      },
    })
      .then(() => {
        reloadSession()
        msgr.add('Updated account settings.', 'positive')
      })
      .catch((error: GraphQLError) => {
        if (error.message) msgr.add(error.message, 'negative')
        else msgr.addUnknownError()
      })
      .finally(() => loader.off())
  }

  const thresholdRequired = values.approvalThresholdEnabled || false

  const resetThreshold = (value) => {
    form.setValue('approvalThreshold', null, { shouldValidate: true })
    form.setValue('approvalThresholdEnabled', value, { shouldValidate: true })
  }

  const setShowPurchaseOrder = (showPurchaseOrder) => {
    form.setValue('showPurchaseOrder', showPurchaseOrder, { shouldValidate: true })
    if (!showPurchaseOrder) {
      form.setValue('generatePurchaseOrderNumber', false)
    }
  }

  return (
    <form onSubmit={form.handleSubmit(onUpdateSubmit)} id="approval-settings">
      <Card
        title="Approval Settings"
        primaryFooterAction={{
          id: 1,
          element: (
            <Action.P type="submit" performing={loading}>
              Save
            </Action.P>
          ),
        }}
      >
        <Card.Section>
          <div className="space-y-4">
            <Field desc="Require a Work Order Number when someone creates a Request.">
              <Checkbox
                inputClassName="mt-0.5 self-start"
                setChecked={(value) =>
                  form.setValue('createRequestRequireWorkOrder', value, { shouldValidate: true })
                }
                {...form.register('createRequestRequireWorkOrder')}
              >
                Work Order Number Required
              </Checkbox>
            </Field>

            <Field desc="Require a purchase order number to be submitted upon order approval.">
              <Checkbox
                label="PO Number Required"
                setChecked={setShowPurchaseOrder}
                {...form.register('showPurchaseOrder')}
              />
            </Field>

            {featureFlags.poNumberGenerator && (
              <>
                {values.showPurchaseOrder && (
                  <Field desc="Insert the format that you would like to use below if you want Gearflow to automatically generate the PO number for you.">
                    <Checkbox
                      label="Generate PO Number"
                      setChecked={(value) =>
                        form.setValue('generatePurchaseOrderNumber', value, {
                          shouldValidate: true,
                        })
                      }
                      {...form.register('generatePurchaseOrderNumber')}
                    />
                  </Field>
                )}

                {values.generatePurchaseOrderNumber && (
                  <Controller
                    control={form.control}
                    name="generatePurchaseOrderNumberConfig"
                    render={({ field }) => (
                      <PurchaseOrderConfigInput value={field.value} onChange={field.onChange} />
                    )}
                  />
                )}
              </>
            )}

            <Field desc="Require that orders above the below threshold be sent for approval by an admin user.">
              <Checkbox
                label="Order Approval Required"
                setChecked={resetThreshold}
                {...form.register('approvalThresholdEnabled')}
              />
            </Field>

            <Field
              label="Cost Threshold"
              desc="When order approval required setting is enabled, any orders above this threshold will require approval by an admin user."
              errorText={form.formState.errors.approvalThreshold?.message}
              className="w-90"
            >
              <PriceInput
                price={form.getValues('approvalThreshold')}
                disabled={!thresholdRequired}
                onChange={(approvalThreshold) =>
                  form.setValue('approvalThreshold', approvalThreshold || null, {
                    shouldValidate: true,
                  })
                }
              />
            </Field>
          </div>
        </Card.Section>
      </Card>
    </form>
  )
}

export default ApprovalSettings
