import {
  PaymentOption,
  SelectedPaymentMethod,
  StoreOrderProblem,
  StoreOrderProblemType,
  StoreOrderState,
} from '@/buyers/_gen/gql'
import {
  BaseStoreOrderPayload,
  CancelReason,
  LineItem,
  Money as MoneyT,
  SelectStoreOrderProblem,
  SelectStoreOrderProblemType,
  StoreOrder,
  Shipment as TShipment,
} from '@/types'
import hex from 'crypto-js/enc-hex'
import sha1 from 'crypto-js/sha1'
import { DateTime } from 'luxon'
import AccountMachine from './AccountMachine'
import AdditionalCharge from './AdditionalCharge'
import Discount from './Discount'
import Invoice from './Invoice'
import LineItemM from './LineItem'
import Money from './Money'
import Refund from './Refund'
import StoreOrderEvent from './StoreOrderEvent'
import Time from './Time'
import User from './User'
import Vendor from './Vendor'

const CANCEL_REASONS: CancelReason[] = [
  { id: '1', title: 'Out of stock/back ordered item' },
  { id: '2', title: 'Inaccurate shipping cost' },
  { id: '3', title: 'Inaccurate pricing' },
  { id: '4', title: 'Customer ordered incorrect item' },
  { id: '5', title: 'Other' },
]

const SUPPLIER_UNAPPROVED_STATES = ['RECEIVED', 'OPEN_QUOTE', 'QUOTE_DENIED', 'CANCELLED']
const QUOTE_STATES = ['OPEN_QUOTE', 'QUOTE_DENIED', 'CANCELLED']
const SHIPPING_PICKUP_LINE_ITEM = 'In Store Pickup'

const zeroPrice = Money.fromInt(0, 'USD')

const fromPayload = <Payload extends BaseStoreOrderPayload>(payload: Payload) => ({
  ...payload,
  shippingAmount: payload.shippingAmount && Money.fromPayload(payload.shippingAmount),
  salesTax: payload.salesTax && Money.fromPayload(payload.salesTax),
  lineItems: payload.lineItems && payload.lineItems.map(LineItemM.fromPayload),
  additionalCharges:
    payload.additionalCharges && payload.additionalCharges.map(AdditionalCharge.fromPayload),
  refunds: payload.refunds && payload.refunds.map(Refund.fromPayload),
  discounts: payload.discounts && payload.discounts.map(Discount.fromPayload),
  events: payload.events && payload.events.map(StoreOrderEvent.fromPayload),
  insertedAt: payload.insertedAt !== undefined ? Time.fromPayload(payload.insertedAt) : undefined,
  updatedAt: payload.updatedAt !== undefined ? Time.fromPayload(payload.updatedAt) : undefined,
  accountMachines:
    payload.accountMachines &&
    payload.accountMachines.map((accountMachinePayload) =>
      AccountMachine.fromPayload(accountMachinePayload)
    ),
  vendor: payload.vendor && Vendor.fromPayload(payload.vendor),
  invoices: payload.invoices && payload.invoices.map((invoice) => Invoice.fromPayload(invoice)),
  total: payload.total !== undefined ? Money.fromPayload(payload.total) : undefined,
})

// Returns the subtotal of an entire storeOrder.
const subtotal = (storeOrder: { lineItems: { extendedPrice: MoneyT }[] }) =>
  storeOrder.lineItems.map((li) => li.extendedPrice).reduce(Money.add, zeroPrice)

const paidAdditionalChargesPrice = (storeOrder: Pick<StoreOrder, 'additionalCharges'>) =>
  storeOrder.additionalCharges
    .filter((ac) => ac.state === 'charged')
    .map((ac) => ac.price)
    .reduce(Money.add, zeroPrice)

const total = (
  storeOrder: Pick<StoreOrder, 'shippingAmount' | 'convenienceFeeRate' | 'salesTax'> & {
    lineItems: Pick<StoreOrder['lineItems'][number], 'extendedPrice'>[]
    additionalCharges: Pick<StoreOrder['additionalCharges'][number], 'price'>[]
  }
) => {
  const totalWithoutConvenienceFee = [
    subtotal(storeOrder),
    storeOrder.shippingAmount,
    ...(storeOrder.salesTax ? [storeOrder.salesTax] : []),
    ...storeOrder.additionalCharges.map((ac) => ac.price),
  ].reduce(Money.add)

  const convenienceFee = Money.mult(totalWithoutConvenienceFee, storeOrder.convenienceFeeRate)

  return Money.add(totalWithoutConvenienceFee, convenienceFee)
}

// Returns the customer id associated with the given storeOrder
const customerId = (storeOrder: StoreOrder) =>
  storeOrder.order.user?.id ?? sha1(storeOrder.order.email).toString(hex)

// Returns true if the store order is in a not approved state
const isUnapprovedBySupplier = (storeOrder: Pick<StoreOrder, 'state'>) =>
  SUPPLIER_UNAPPROVED_STATES.includes(storeOrder.state)

const isApprovedBySupplier = (storeOrder: Pick<StoreOrder, 'state'>) =>
  !isUnapprovedBySupplier(storeOrder)

// Returns true if the store order has not been paid
const isUnpaid = (storeOrder: Pick<StoreOrder, 'state' | 'invoices'>) =>
  isUnapprovedBySupplier(storeOrder) ||
  (storeOrder.invoices && Invoice.isPaymentRequiredForInvoices(storeOrder.invoices))

// Returns true if the store order is a "quote"
const isQuote = (storeOrder: Pick<StoreOrder, 'state'>) => QUOTE_STATES.includes(storeOrder.state)

// Returns true if the store order quote has been approved
const isQuoteApproved = (storeOrder: Pick<StoreOrder, 'state'>) => !isQuote(storeOrder)

const totalRefunded = (storeOrder: Pick<StoreOrder, 'refunds'>) =>
  storeOrder.refunds.map((r) => r.amount).reduce(Money.add, zeroPrice)

// Returns each shipment and its line items with the updated quantity
// *** This includes shipments that have not be shipped yet ***
const getShipments = (storeOrder: Pick<StoreOrder, 'lineItems'>) =>
  Object.values(
    storeOrder.lineItems.reduce<Record<string, { shipment: TShipment; lineItems: LineItem[] }>>(
      (storeOrderShipments, lineItem) => ({
        ...storeOrderShipments,
        ...lineItem.shipmentItems.reduce<
          Record<string, { shipment: TShipment; lineItems: LineItem[] }>
        >(
          (lineItemShipments, { shipment, quantity }) => ({
            ...lineItemShipments,
            [shipment.id]: lineItemShipments[shipment.id]
              ? {
                  ...lineItemShipments[shipment.id],
                  lineItems: [
                    ...lineItemShipments[shipment.id].lineItems,
                    { ...lineItem, quantity },
                  ],
                }
              : {
                  lineItems: [{ ...lineItem, quantity }],
                  shipment,
                },
          }),
          storeOrderShipments
        ),
      }),
      {}
    )
  )

// Returns the line items with updated quantities that are not in a shipment
// *** This excludes shipments that have not be shipped yet ***
const getUnshipped = <LineItem extends { quantity: number; shipmentItems: { quantity: number }[] }>(
  lineItems: LineItem[]
) =>
  lineItems
    .map((lineItem) => ({
      ...lineItem,
      quantity:
        lineItem.quantity -
        lineItem.shipmentItems.reduce((acc, shipmentItem) => acc + shipmentItem.quantity, 0),
    }))
    .filter((li) => li.quantity > 0)

// Returns each shipment and its line items with the updated quantity
// *** This excludes shipments that have not be shipped yet ***
const getShipmentsStrict = (storeOrder: StoreOrder) =>
  getShipments(storeOrder).filter(({ shipment }) => shipment.shippedAt)

// Returns the line items with updated quantities that are not in a shipment that has shipped
// *** This includes items in shipments that have not been shipped ***
const getUnshippedStrict = (storeOrder: StoreOrder) =>
  storeOrder.lineItems
    .map((lineItem) => ({
      ...lineItem,
      quantity:
        lineItem.quantity -
        lineItem.shipmentItems.reduce(
          (acc, shipmentItem) =>
            shipmentItem.shipment.shippedAt ? acc + shipmentItem.quantity : acc,
          0
        ),
    }))
    .filter((lineItem) => lineItem.quantity > 0)

// Returns the name of the user who ordered this store order
// If the order was created from gearflow.com, returns the user who ordered from gearflow.com
// If the order was approved from a quote, returns the user who approved the quote
const orderedBy = (storeOrder: StoreOrder): { name: string; timestamp: DateTime } | undefined => {
  const createdEvent = storeOrder.events.find((event) => event.type === 'created')
  if (createdEvent)
    return { name: createdEvent.user?.name ?? '', timestamp: createdEvent.insertedAt }
  // Returns the earliest quote approved event (events are sorted most recent first)
  const quoteApprovedEvent = storeOrder.events
    .reverse()
    .find((event) => event.type === 'quote_approved')
  if (quoteApprovedEvent) {
    return { name: quoteApprovedEvent.user?.name ?? '', timestamp: quoteApprovedEvent.insertedAt }
  }
  return undefined
}

const copyLinkUrl = (
  storeOrder: Pick<StoreOrder, 'id' | 'token'>,
  gfBaseUrl: string,
  buyersUrl: string
) => {
  if (storeOrder.token) {
    const returnUrl = `${buyersUrl}/orders/${storeOrder.id}?storeOrderId=${storeOrder.id}`
    return `${gfBaseUrl}/login?return_path=${returnUrl}&token=${storeOrder.token}`
  }
  return ''
}

const approveUrl = (storeOrder: Pick<StoreOrder, 'id'>, gfBaseUrl: string) =>
  `${gfBaseUrl}/orders/${storeOrder.id}/approve`

const approveAdditionalChargeUrl = (additionalChargeId: string, gfBaseUrl: string) =>
  `${gfBaseUrl}/additional-charges/${additionalChargeId}/approve`

const shortenId = (id: string) => id.split('-')[0]

// Returns the reason for the most recent quote denied event
// Assumption: the storeOrder events are already sorted with most recent first
const quoteDeniedReason = (storeOrder: Pick<StoreOrder, 'events'>) =>
  storeOrder.events.find((storeOrderEvent) => storeOrderEvent.type === 'quote_denied')?.note

// Returns true if the StoreOrder state is in the given list of StoreOrder states
const stateIs = (state: StoreOrderState, isInList: string[]) => isInList.includes(state)

const canDisplayCustomerContactInfo = (
  storeOrder: Pick<StoreOrder, 'state'>,
  user: { role: string }
) => User.isAdmin(user) || isApprovedBySupplier(storeOrder)

const getStoreOrderProblemTypeDisplay = (type: StoreOrderProblemType | SelectStoreOrderProblem) =>
  type === StoreOrderProblemType.WrongPart
    ? 'Wrong part(s)'
    : type === StoreOrderProblemType.MissingPart
    ? 'Missing part(s)'
    : type === StoreOrderProblemType.Fitment
    ? 'Fitment issue'
    : type === StoreOrderProblemType.Other
    ? 'Other'
    : type === SelectStoreOrderProblemType.None
    ? 'None'
    : 'Error'

// Return true when the given StoreOrderProblem is not resolved
const isStoreOrderProblemOpen = (storeOrderProblem: Pick<StoreOrderProblem, 'resolvedAt'>) =>
  !storeOrderProblem.resolvedAt

const accuracyOptions: { id: SelectStoreOrderProblem; display: string }[] = [
  SelectStoreOrderProblemType.None,
  StoreOrderProblemType.WrongPart,
  StoreOrderProblemType.MissingPart,
  StoreOrderProblemType.Fitment,
  StoreOrderProblemType.Other,
].map((type) => ({
  id: type,
  display: getStoreOrderProblemTypeDisplay(type),
}))
const noneAccuracyOption = accuracyOptions[0]

const isPaymentMethodAllowed = (
  paymentMethod: SelectedPaymentMethod,
  paymentOption: PaymentOption
) =>
  (paymentMethod === SelectedPaymentMethod.Balance && paymentOption.balance) ||
  (paymentMethod === SelectedPaymentMethod.BalanceTerms && paymentOption.balanceTerms) ||
  (paymentMethod === SelectedPaymentMethod.Stripe && paymentOption.stripe) ||
  (paymentMethod === SelectedPaymentMethod.Direct && paymentOption.direct)

export default {
  CANCEL_REASONS,
  SUPPLIER_UNAPPROVED_STATES,
  QUOTE_STATES,
  SHIPPING_PICKUP_LINE_ITEM,
  fromPayload,
  subtotal,
  total,
  customerId,
  isUnpaid,
  isUnapprovedBySupplier,
  isApprovedBySupplier,
  isQuote,
  isQuoteApproved,
  paidAdditionalChargesPrice,
  totalRefunded,
  getShipments,
  getUnshipped,
  getShipmentsStrict,
  getUnshippedStrict,
  orderedBy,
  approveUrl,
  copyLinkUrl,
  approveAdditionalChargeUrl,
  shortenId,
  quoteDeniedReason,
  stateIs,
  canDisplayCustomerContactInfo,
  getStoreOrderProblemTypeDisplay,
  isStoreOrderProblemOpen,
  accuracyOptions,
  noneAccuracyOption,
  isPaymentMethodAllowed,
}
