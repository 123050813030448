import { useSuggestedLocationsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Box from '@/gf/components/Box'
import TextTag from '@/gf/components/next/TextTag'
import { US_CENTER } from '@/gf/modules/Map'
import { Maybe, Point } from '@/types'
import classNames from 'classnames'
import pick from 'lodash/pick'
import { useMemo } from 'react'

const SuggestedLocations = ({
  selectedLocationId,
  onLocationSelected,
}: {
  selectedLocationId: Maybe<string>
  onLocationSelected: (point: Point, locationId: string) => void
}) => {
  const client = useGqlClient()
  const { data } = useSuggestedLocationsQuery({ client })

  const locations = useMemo(() => {
    const defaultLocation = data?.defaultShippingLocation ?? null
    const others = data?.locations.filter((l) => !l.defaultLocation) ?? []

    return defaultLocation ? [defaultLocation, ...others] : others
  }, [data])

  if (locations.length === 0) {
    return null
  }

  return (
    <div className="bg-gray-50 border-0 rounded-xl shadow-base p-4 space-y-3">
      <h5 className="text-base text-gray-600 font-medium">Your recent locations</h5>

      {locations.map((l) => (
        <Box
          key={l.id}
          className={classNames(
            'p-3 cursor-pointer shadow-base transition duration-200',
            selectedLocationId === l.id ? 'border-2 border-blue-600' : 'hover:border-gray-400'
          )}
          onClick={() =>
            onLocationSelected(
              l.address.point ? pick(l.address.point, ['lat', 'lng']) : US_CENTER,
              l.id
            )
          }
        >
          <h6 className="flex justify-between items-center">
            <span className="text-base font-medium">{l.name}</span>
            {l.defaultLocation && <TextTag color="blue">Default Location</TextTag>}
          </h6>
          <p className="text-base">
            {l.address.city}, {l.address.state}
          </p>
        </Box>
      ))}
    </div>
  )
}

export default SuggestedLocations
