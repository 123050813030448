import { Invoice, InvoicePayload, Maybe } from '../../types'

import AdditionalCharge from './AdditionalCharge'
import Money from './Money'
import StoreOrder from './StoreOrder'
import Time from './Time'

const fromPayload = (payload: InvoicePayload): Invoice => ({
  ...payload,
  dueDate: payload.dueDate ? Time.fromPayload(payload.dueDate) : null,
  totalPrice: Money.fromPayload(payload.totalPrice),
  storeOrder: payload.storeOrder ? StoreOrder.fromPayload(payload.storeOrder) : undefined,
  additionalCharge: payload.additionalCharge
    ? AdditionalCharge.fromPayload(payload.additionalCharge)
    : undefined,
})

// Returns true if a payment is still required for the Invoice
// Any non-financed Balance Invoice should be a "Receipt" (no payment required)
const isPaymentRequired = (
  invoice: Pick<Invoice, 'isPaid' | 'isCanceled' | 'isFinanced' | 'balanceTransactionId'>
) => !invoice.isPaid && !invoice.isCanceled && (invoice.isFinanced || !invoice.balanceTransactionId)

// Returns true if a payment is still required for at least one of the Invoices
const isPaymentRequiredForInvoices = (
  invoices: Pick<Invoice, 'isPaid' | 'isCanceled' | 'isFinanced' | 'balanceTransactionId'>[]
) =>
  invoices.reduce(
    (paymentRequired, invoice) => paymentRequired || isPaymentRequired(invoice),
    false
  )

// Returns true if a payment is still required for at least one of the Invoices
const isPaymentRequiredForInvoicesV2 = (
  invoices: (Pick<Invoice, 'isPaid' | 'isCanceled' | 'isFinanced'> & {
    balanceTransactionId: string | null
  })[]
) =>
  invoices.reduce(
    (paymentRequired, invoice) =>
      paymentRequired ||
      (!invoice.isPaid &&
        !invoice.isCanceled &&
        (invoice.isFinanced || !invoice.balanceTransactionId)),
    false
  )

const isBalanceInvoice = (invoice: { balanceTransactionId?: Maybe<string> }) =>
  !!invoice.balanceTransactionId

// Returns Financed Invoices
const getFinanced = (invoices: Invoice[]) => invoices.filter((invoice) => invoice.isFinanced)
// Returns Not Financed Invoices
const getNotFinanced = (invoices: Invoice[]) => invoices.filter((invoice) => !invoice.isFinanced)

export default {
  fromPayload,
  isPaymentRequired,
  isPaymentRequiredForInvoices,
  isPaymentRequiredForInvoicesV2,
  isBalanceInvoice,
  getFinanced,
  getNotFinanced,
}
