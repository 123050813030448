import { CheckIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { useDeleteMachinesMutation, useFleetQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import useMsgs from '@/gf/hooks/useMsgs'
import usePage from '@/gf/hooks/usePage'
import useSearchQueryParam from '@/gf/hooks/useSearchQueryParam'
import useToggle from '@/gf/hooks/useToggle'
import Action from '@/gf/components/Action'
import AddAccountMachineModal from '@/gf/components/AddAccountMachineModal'
import Card from '@/gf/components/Card'
import Checkbox from '@/gf/components/Checkbox'
import DelayedSearch from '@/gf/components/inputs/DelayedSearch'
import Link from '@/gf/components/Link'
import Page from '@/gf/components/Page'
import Pagination from '@/gf/components/Pagination'
import { Td, Th, Tr } from '@/gf/components/Table'
import Frame from '../components/Frame'
import useMachineSuggestion from '../hooks/useMachineSuggestion'

const wasWere = (count: number) => (count === 1 ? 'was' : 'were')

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [{ name: 'Fleet', href: '/orders' }],
}

const Fleet = () => {
  const [_, msgr] = useMsgs()
  const [page, setPage] = usePage()
  const { search, updateSearch } = useSearchQueryParam()
  const { organization } = useSession()
  const [addMachineOpen, machineToggle] = useToggle()
  const [deleting, setDeleting] = useState(false)
  const [selection, setSelection] = useState<string[]>([])
  const client = useGqlClient()
  const [deleteMachines] = useDeleteMachinesMutation({ client })
  const suggestMachines = useMachineSuggestion()

  const queryResult = useFleetQuery({
    variables: { page, filters: [['equals', 'accountId', organization.id]], search },
    client,
  })

  const { paginatedMachines } = queryResult.data || {}

  const check = (id: string) => setSelection([...selection, id])

  const unCheck = (id: string) => setSelection(selection.filter((sid) => sid !== id))

  const actions = [
    <Action.P onClick={machineToggle.on} key="add-new">
      Add Machine
    </Action.P>,
    <Link.S to="/fleet/import" key="import">
      Import
    </Link.S>,
  ]

  const closeAddMachine = () => {
    machineToggle.off()
    queryResult.refetch()
  }

  const onDelete = () => {
    if (deleting) return
    setDeleting(true)

    deleteMachines({ variables: { ids: selection } })
      .then(({ data }) => {
        queryResult.refetch()

        if (data?.deleteMachines) {
          let msg = `${data.deleteMachines.oks} ${wasWere(data.deleteMachines.oks)} deleted.`

          if (data.deleteMachines.errors > 0) {
            msg = `${msg} ${data.deleteMachines.errors} ${wasWere(
              data.deleteMachines.errors
            )} not deleted due to being attached to an RFQ.`
          }

          msgr.add(msg, data.deleteMachines.errors === 0 ? 'positive' : 'neutral')
        }

        setSelection([])
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => setDeleting(false))
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Fleet" actionsNext={actions}>
        <AddAccountMachineModal
          open={addMachineOpen}
          onClose={closeAddMachine}
          accountId={organization.id}
          autocompleteMachines={suggestMachines}
        />

        <div className="space-y-4 mt-4">
          <DelayedSearch
            value={search}
            setValue={(term) => {
              updateSearch(term)
              setPage(1)
            }}
          />

          {selection.length > 0 && (
            <div className="p-3 sm:px-6 sm:py-3 bg-gray-50">
              <div className="flex gap-2 items-center text-sm">
                <div>{selection.length.toLocaleString()} selected:</div>

                <Action.S onClick={onDelete} size="sm" performing={deleting}>
                  {deleting ? <>Deleting&hellip;</> : <>Delete</>}
                </Action.S>
              </div>
            </div>
          )}

          <Card className="overflow-auto">
            <table className="min-w-full divide-y-1 divide-gray-200">
              <thead className="bg-gray-50 sticky top-0">
                <Tr>
                  <Th />
                  <Th>Unit #</Th>
                  <Th>Serial Number</Th>
                  <Th>Make</Th>
                  <Th>Model</Th>
                  <Th>Year</Th>
                  <Th>Description</Th>
                  <Th className="text-center">Owned Asset</Th>
                  <Th>Engine Make</Th>
                  <Th>Engine Model</Th>
                  <Th>Engine Serial Number</Th>
                </Tr>
              </thead>
              <tbody className="divide-y-1 divide-gray-200">
                {paginatedMachines?.machines.map((machine) => (
                  <Tr key={machine.name}>
                    <Td>
                      <Checkbox
                        checked={selection.includes(machine.id)}
                        setChecked={(checked) =>
                          checked ? check(machine.id) : unCheck(machine.id)
                        }
                      />
                    </Td>
                    <Td>
                      <Link.T to={`/machines/${machine.id}`}>{machine.name}</Link.T>
                    </Td>
                    <Td>{machine.serialNumber}</Td>
                    <Td>{machine.machine.make}</Td>
                    <Td>{machine.machine.model}</Td>
                    <Td>{machine.machine.year}</Td>
                    <Td className="truncate max-w-xs">{machine.description}</Td>
                    <Td className="flex justify-center">
                      {machine.owned && <CheckIcon className="w-5 h-5 text-green-500" />}
                    </Td>
                    <Td>{machine.engineMake}</Td>
                    <Td>{machine.engineModel}</Td>
                    <Td>{machine.engineSerialNumber}</Td>
                  </Tr>
                ))}
              </tbody>
            </table>
          </Card>

          <Pagination pagination={paginatedMachines?.pagination} page={page} updatePage={setPage} />
        </div>
      </Page>
    </Frame>
  )
}

export default Fleet
