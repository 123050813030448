import useDuplicateRfqs from '@/buyers/hooks/useDuplicateRfqs'
import Link from '@/gf/components/Link'
import Id from '@/gf/modules/Id'
import { Maybe } from '@/types'
import pluralize from 'pluralize'
import React from 'react'

const DuplicatedRfqsWarning = ({
  machineOrgId,
  partNumbers,
  ignoreRfqIds,
}: {
  machineOrgId: Maybe<string>
  partNumbers: string[]
  ignoreRfqIds?: string[]
}) => {
  const duplicateRfqs = useDuplicateRfqs(machineOrgId ?? undefined, partNumbers, ignoreRfqIds)

  return duplicateRfqs.length > 0 ? (
    <div className="text-sm  bg-orange-50 px-4 py-3 rounded-md flex flex-col gap-y-2 mb-4 text-orange-700">
      <p>
        There {pluralize('is', duplicateRfqs.length)} {duplicateRfqs.length} potential duplicate{' '}
        {pluralize('requests', duplicateRfqs.length)} associated with{' '}
        {pluralize('this', partNumbers.length)} part {pluralize('number', partNumbers.length)} and
        machine.
      </p>

      <ul className="flex flex-col space-y-0.5">
        {duplicateRfqs.map(({ id, name }) => (
          <li key={id}>
            <Link.T
              to={`/rfqs/${id}`}
              target="_blank"
              className="font-medium text-sm text-orange-800"
            >
              {Id.shorten(id)}
            </Link.T>

            <span>{` submitted by ${name}`}</span>
          </li>
        ))}
      </ul>
    </div>
  ) : null
}

export default React.memo(DuplicatedRfqsWarning)
