import { ResponsiveLine } from '@nivo/line'
import Chart from '../../Reporting/Chart'
import useChartPointSymbol from '@/gf/hooks/useChartPointSymbol'
import Section from './Section'
import Money from '@/gf/modules/Money'
import useMetrics from '../../Reporting/useMetrics'
import NivoPointTooltip from '@/gf/components/Reports/NivoPointTooltip'
import TitleMetric from '@/gf/components/next/TitleMetric'
import duration from './duration'
import { getOrgMachineName } from '../../Reporting/OrgMachine'
import { useNavigate } from 'react-router-dom'

const PartsSpend = ({
  chartDailyAverage,
  orderedMachineMetrics,
  chartData,
  aggregatedRequestMetrics,
  prevAggregatedRequestMetrics,
}: ReturnType<typeof useMetrics>) => {
  const navigate = useNavigate()
  const pointSymbol = useChartPointSymbol(undefined)

  if (!chartData) {
    return null
  }

  return (
    <Section>
      <div className="flex flex-col md:flex-row gap-4 lg:gap-8 max-w-full">
        <div className="flex-grow w-full md:w-[calc(100%-20rem-2rem)]">
          <TitleMetric
            title="Parts Spend"
            // keep null values to indicate loading is done
            value={
              typeof aggregatedRequestMetrics === 'undefined'
                ? undefined
                : aggregatedRequestMetrics.total === null
                ? null
                : aggregatedRequestMetrics.total.sum
            }
            comparisonValue={
              typeof prevAggregatedRequestMetrics === 'undefined'
                ? undefined
                : prevAggregatedRequestMetrics.total === null
                ? null
                : prevAggregatedRequestMetrics.total.sum
            }
            duration={duration}
            valueToDisplay={(value) =>
              Money.format(Money.fromInt(value, 'USD'), { maximumFractionDigits: 0 })
            }
            onClick={() => navigate('/reporting/cost')}
            downIsGood
          />

          <div className="w-full h-40">
            <ResponsiveLine
              {...Chart.getBaseGraph({ days: 30, pointSymbol })}
              yFormat={(cents) =>
                typeof cents === 'number'
                  ? Money.format(Money.fromInt(cents, 'USD'), { maximumFractionDigits: 0 })
                  : ''
              }
              data={[
                {
                  id: 'All Requests',
                  data: chartData,
                  color: Chart.getDataColor(0),
                },
                {
                  id: 'Daily average',
                  data: chartData.map(({ x }) => ({
                    x,
                    y: chartDailyAverage,
                    noPoint: true,
                  })),
                  color: Chart.SECONDARY_LINE_COLOR,
                },
              ].reverse()}
              tooltip={NivoPointTooltip}
            />
          </div>
        </div>

        <div className="bg-white rounded-xl border shadow-base p-2 lg:p-4 w-auto lg:w-80">
          <h5 className="text-lg font-medium">Top 5 Machine Spend</h5>

          <ul>
            {orderedMachineMetrics.slice(0, 5).map((mm) => (
              <li
                key={mm.orgMachine.name}
                title={mm.orgMachine.name}
                className="flex text-sm py-3 px-2 border-b last:border-b-0 gap-x-3"
              >
                <span className="flex-grow truncate">{getOrgMachineName(mm.orgMachine)}</span>
                <span className="flex-shrink-0">
                  {Money.format(Money.fromInt(mm.total?.sum ?? 0, 'USD'), {
                    maximumFractionDigits: 0,
                  })}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Section>
  )
}

export default PartsSpend
