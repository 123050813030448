import { useMutation, gql } from '@apollo/client'

interface Data {
  updatePartRequest: boolean
}

interface Form {
  partRequestId: string
  mpn: string
  description: string
  quantity: number | null
  externalId?: string | null
  taskNumber?: string | null
}

const mutation = gql`
  mutation UpdatePartRequest(
    $partRequestId: ID!
    $mpn: String!
    $description: String!
    $quantity: Int!
    $externalId: String
    $taskNumber: String
  ) {
    updatePartRequest(
      partRequestId: $partRequestId
      mpn: $mpn
      description: $description
      quantity: $quantity
      externalId: $externalId
      taskNumber: $taskNumber
    )
  }
`

const useUpdatePartRequest = () => {
  const [mutate] = useMutation<Data, Form>(mutation)
  return (variables: Form) => mutate({ variables })
}

export default useUpdatePartRequest
