import { DeliveryMethod } from '@/buyers/_gen/gql'
import { Address, Maybe, Point } from '@/types'
import { DateTime } from 'luxon'

export enum Priority {
  HIGH = 3,
  MEDIUM = 2,
  LOW = 1,
}

export enum SourcingType {
  VENDORS,
  GF_NETWORK,
}

export type Filters = {
  search: string
  location: Maybe<Point>
  brands: string[]
}

export type Urgency = { priority: Priority; machineDown: boolean; neededByDate: DateTime }

export type CreateRequestState = {
  billingCompanyId?: Maybe<string>
  workOrderNumber?: string
  machineOrgId?: Maybe<string>
  machineInvolved?: boolean
  urgency?: { priority: Priority; machineDown: boolean; neededByDate: DateTime }
  parts?: {
    partNumber: Maybe<string>
    description: Maybe<string>
    quantity: number
    externalId: Maybe<string>
    taskNumber: Maybe<string>
    pictures: string[]
  }[]
  comments?: string

  nearbyReferencePoint?: Point
  locationId?: string
  shippingAddress?: Address

  sourcing?: SourcingType
  dealerLocationIds: string[]
  vendors: {
    vendorId: string
    contactIds: string[]
    deliveryMethod: DeliveryMethod
    accountNumber: string
    fleetioId: number | null
  }[]
  brokerRfqId?: Maybe<string>
}

export type PartialRequestUpdate = {
  request: CreateRequestState
  updateRequest: (changes: Partial<CreateRequestState>) => void
}

export type VendorSelectionType = 'limited' | 'full' | 'disabled'
