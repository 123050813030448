import { useFetchForecastQuery, useSearchInvoiceItemsQuery } from '@/buyers/_gen/gql'
import Card from '@/gf/components/Card'
import Filters from '@/gf/components/Filters'
import LinkCell from '@/gf/components/LinkCell'
import PaginationC from '@/gf/components/Pagination'
import Spinner from '@/gf/components/Spinner'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import useFilters from '@/gf/hooks/useFilters'
import usePage from '@/gf/hooks/usePage'
import FilterContext from '@/gf/modules/Filter'
import FilterSet from '@/gf/modules/FilterSet'
import FormatFilters from '@/gf/modules/FormatFilters'
import MoneyM from '@/gf/modules/Money'
import StoreOrderM from '@/gf/modules/StoreOrder'
import Time from '@/gf/modules/Time'
import UserContext from '@/gf/modules/User'
import { CheckIcon } from '@heroicons/react/solid'
import { JsonParam, useQueryParams, withDefault } from 'use-query-params'
import { Filter, FilterField } from '../../../../types'
import useGqlClient from '../../../hooks/useGqlClient'
import useSession from '../../../hooks/useSession'

const filterFields: FilterField[] = [
  {
    id: 'search_order_id',
    display: 'Order ID',
    filterTypeIds: ['contains'],
  },
]

const useQueryParamsOpts = {
  filters: withDefault<Filter[], Filter[]>(JsonParam, []),
}

const Transactions = () => {
  const cols = 5
  const client = useGqlClient()
  const { user, featureFlags } = useSession()
  const [query, updateQuery] = useQueryParams(useQueryParamsOpts)
  const [page, setPage] = usePage()
  const [stagedFilters, filtersApi] = useFilters(query.filters)

  const updateFilters = (newFilters: Filter[]) => {
    updateQuery({ filters: newFilters })
    setPage(1)
  }

  const apiFilters = (() => FormatFilters.andFilters(FilterSet.toApiFilters(query.filters)))()

  const { data: forecast } = useFetchForecastQuery({ client })
  const forecastTotalPhp = forecast?.fetchForecast?.php || 0
  const forecastTotalDelivery = forecast?.fetchForecast?.delivery || 0
  const forecastTotal = forecastTotalDelivery + forecastTotalPhp

  const { loading, data } = useSearchInvoiceItemsQuery({
    variables: { page, filters: apiFilters },
    client: useGqlClient(),
  })

  const items = (data && data.searchInvoiceItems?.invoiceItems) || []

  const pagination = (data && data.searchInvoiceItems?.pagination) || {
    totalPages: 0,
    totalResults: 0,
  }

  const isAdmin = UserContext.isAdmin(user)
  const fields = FilterContext.availableFilterFields(filterFields, isAdmin, featureFlags)

  return (
    <div>
      <Card title="">
        <div className="p-4">
          {forecast?.fetchForecast && forecastTotal > 0 && (
            <div className="flex pb-3 justify-end font-semibold text-gray-500 uppercase">
              Amount due {MoneyM.format(MoneyM.fromInt(forecastTotal, 'USD'))}
            </div>
          )}
          <Filters
            filters={query.filters}
            stagedFilters={stagedFilters}
            fields={fields}
            filtersApi={filtersApi}
            onApply={() => updateFilters(stagedFilters)}
          />
          <Table>
            <Thead>
              <Tr>
                <Th>Created</Th>
                <Th>Amount</Th>
                <Th>Order ID</Th>
                <Th>Delivery</Th>
                <Th>Paid</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan={cols}>
                    <Spinner />
                  </Td>
                </Tr>
              ) : items.length === 0 ? (
                <Tr>
                  <Td colSpan={cols}>No direct pay charges.</Td>
                </Tr>
              ) : (
                items.map((item) => (
                  <Tr key={item.id}>
                    <Td>{Time.formatDateNoTime(item.insertedAt)}</Td>
                    <Td>{MoneyM.format(item.amount)}</Td>
                    <LinkCell pad to={`/orders/${item.storeOrderId}`}>
                      {StoreOrderM.shortenId(item.storeOrderId)}
                    </LinkCell>
                    <Td>
                      {item.curriDeliveryId && <CheckIcon className="w-4 h-4 text-green-600" />}
                    </Td>
                    <Td>{item.finalizedAt && Time.formatDateNoTime(item.finalizedAt)}</Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
          {!loading && items?.length > 0 && (
            <div className="p-4">
              <PaginationC pagination={pagination} page={page} updatePage={setPage} />
            </div>
          )}
        </div>
      </Card>
    </div>
  )
}

export default Transactions
