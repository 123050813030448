import { useMemo } from 'react'
import { Maybe } from '@/types'
import { useUserLocationsQuery, UserLocationsDocument as Query } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Select from '@/gf/components/next/Select'

export { Query }

const LocationSelector = ({
  value,
  onChange,
}: {
  value: Maybe<string>
  onChange: (newValue: Maybe<string>) => void
}) => {
  const { data, loading } = useUserLocationsQuery({ client: useGqlClient() })

  const userLocations = data?.locations ?? []

  const options = useMemo(() => {
    const userLocationsOptions = userLocations
      .sort((a, _b) => (a.defaultLocation ? -1 : 0)) // default location first
      .map((l) => ({
        label: `${l.name} ${l.defaultLocation ? `(default location)` : ''}`,
        value: l.id,
      }))

    return userLocationsOptions
  }, [userLocations])

  const selectedOption = useMemo(
    () => (value ? options.find((o) => o.value === value) : null),
    [value, options]
  )

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={(o) => (o ? onChange(o.value) : undefined)}
      isLoading={loading}
      className="w-full max-w-xs"
    />
  )
}

export default LocationSelector
