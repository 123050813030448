/**
 * Address Component
 * -----------------
 * displays an Address
 *
 * props
 * -----------------
 * address - the address to display
 */

import { Address } from '../../types'

type GqlAddress = {
  firstName: string | null
  lastName: string | null
  companyName: string | null
  lineOne: string | null
  lineTwo: string | null
  city: string | null
  state: string | null
  postalCode: string | null
  country: string | null
}

export type AddressT =
  | Pick<
      Address,
      | 'firstName'
      | 'lastName'
      | 'companyName'
      | 'lineOne'
      | 'lineTwo'
      | 'city'
      | 'state'
      | 'postalCode'
      | 'country'
    >
  | GqlAddress

const Address = ({
  address,
  className = '',
  hideName = false,
  hideCompany = false,
}: {
  address: AddressT
  className?: string
  hideName?: boolean
  hideCompany?: boolean
}) => {
  const name = [address.firstName || '', address.lastName || ''].filter((n) => n).join(' ')

  return (
    <div className={className}>
      {!hideName && name !== '' && <p>{name}</p>}
      {!hideCompany && address.companyName && <p>{address.companyName}</p>}
      {address.lineOne && <p>{address.lineOne}</p>}
      {address.lineTwo && <p>{address.lineTwo}</p>}
      <p>
        {address.city}, {address.state} {address.postalCode}
      </p>
      {address.country && <p>{address.country}</p>}
    </div>
  )
}

export default Address
