import { useMemo, useState } from 'react'
import { map, orderBy } from 'lodash'

import { SortByInput, SortOrder, useStoreRequestMetricsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useReportingFormQueryParams from './useReportingFormQueryParams'

import Reporting from '@/buyers/modules/Reporting'
import Sort from '@/gf/modules/Sort'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import StandardColumns from './StandardColumns'

import DurationInput, {
  defaultDurationDates,
  defaultDurationId,
} from '@/gf/components/Reports/DurationInput'
import RedAlert from '@/gf/components/RedAlert'

const DealerScorecardNext = () => {
  const gqlClient = useGqlClient()
  const { form, updateForm } = useReportingFormQueryParams({
    defaultDurationId,
    defaultDurationDates,
  })
  const [sortBy, setSortBy] = useState<SortByInput>({
    field: 'impact',
    order: SortOrder.Asc,
  })

  const { data, error } = useStoreRequestMetricsQuery({
    client: gqlClient,
    variables: {
      filter: JSON.stringify([form.urgentRequestsOnly ? ['machine_is_down'] : []]),
      fromDate: form.durationStart.toISODate(),
      toDate: form.durationEnd.toISODate(),
    },
    fetchPolicy: 'cache-and-network',
  })

  const metrics = useMemo(() => {
    if (error) return { error }
    if (!data) return { data: undefined }
    const storeMetrics = map(Reporting.getStoreMetrics(data.storeRequestMetrics))
    const aggregatedStoreMetrics = Reporting.aggregateSecondDegreeMetrics(storeMetrics)
    const storeMetricsWithImpact = storeMetrics.map((storeMetric) => ({
      ...storeMetric,
      impact: Reporting.getImpact(
        storeMetric,
        aggregatedStoreMetrics,
        [
          {
            getValue: (metric) => metric.responseDuration?.median,
            getAggregateValue: (aggregate) => aggregate.responseDuration.median,
            downIsGood: true,
            weight: 3,
          },
          {
            getValue: (metric) => metric.orderFulfillmentDuration?.median,
            getAggregateValue: (aggregate) => aggregate.orderFulfillmentDuration.median,
            downIsGood: true,
            weight: 3,
          },
          {
            getValue: (metric) => metric.accurate?.percent,
            getAggregateValue: (aggregate) => aggregate.accurate.percent,
            weight: 1,
          },
        ],
        {
          getValue: (metric) => metric.orderFulfillmentDuration?.count,
          getAggregateValue: (aggregate) => aggregate.orderFulfillmentDuration.count,
        }
      ),
    }))
    const orderedStoreMetrics = orderBy(
      storeMetricsWithImpact.filter(
        ({ store, lifespanDuration, accurate }) => !!store && !!lifespanDuration && !!accurate
      ),
      [Sort.sortFieldToLodash(sortBy.field)],
      [Sort.sortOrderToLodash(sortBy.order)]
    )
    return {
      data: { storeMetrics, orderedStoreMetrics, aggregatedStoreMetrics },
    }
  }, [data, error, sortBy])

  return (
    <div className="flex flex-col gap-y-8">
      <DurationInput
        start={form.durationStart}
        end={form.durationEnd}
        durationId={form.durationId}
        onChange={({ start, end, durationId }) =>
          updateForm({ durationStart: start, durationEnd: end, durationId })
        }
        showDates
      />

      {metrics.error ? (
        <RedAlert className="border border-red-200" title="Error, please contact support." />
      ) : (
        <div className="py-3 flex flex-col gap-y-3 bg-white border border-gray-300 rounded-lg shadow-sm">
          <div className="px-4 overflow-x-scroll overflow-y-scroll">
            <ReportingTable
              data={metrics.data?.orderedStoreMetrics}
              sortBy={{ sortBy, setSortBy }}
              getRowKey={(row) => row.store?.id ?? ''}
              columns={[
                { header: 'Vendor', getValue: (row) => row.store?.name },
                StandardColumns.getImpactColumn<{ impact: number | undefined }>({
                  domain: 'Vendor',
                  getValue: (row) => row.impact,
                  sortByField: 'impact',
                }),
                StandardColumns.getOrderFulfillmentDurationRequestsCountColumn(),
                StandardColumns.getResponseDurationMedianTimeColumn({
                  aggregate: metrics.data?.aggregatedStoreMetrics,
                  header: <StandardColumns.TimeToQuoteTooltipTitle />,
                }),
                StandardColumns.getOrderFulfillmentDurationMedianTimeColumn({
                  aggregate: metrics.data?.aggregatedStoreMetrics,
                  header: <StandardColumns.OrderFulfillmentTimeTooltipTitle />,
                }),
                StandardColumns.getAccuracyPercentColumn({
                  aggregate: metrics.data?.aggregatedStoreMetrics,
                }),
              ]}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default DealerScorecardNext
