import { TrashIcon } from '@heroicons/react/outline'
import { useState } from 'react'

import type { Key, Org } from '../Integrations'

import { useDeleteKeyMutation, useGenerateKeyMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useConfig from '@/gf/hooks/useConfig'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import Time from '@/gf/modules/Time'

import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'

const appNameById = {
  qtanium: 'Qtanium',
  fleetio: 'Fleetio',
}

const appName = (org: Org, key: Key) => {
  const orgApp = org.orgApps.find((oa) => oa.key.id === key.id)
  return orgApp && appNameById[orgApp.appId]
}

const ApiKeys = ({
  org,
  onGenerated,
  onDeleted,
}: {
  org?: Org
  onGenerated: () => Promise<unknown>
  onDeleted: () => Promise<unknown>
}) => {
  const config = useConfig()
  const [generating, generatingToggle] = useToggle()
  const [deletingId, setDeletingId] = useState<string | null>(null)
  const [_, msgr] = useMsgs()
  const client = useGqlClient()
  const [generateKey] = useGenerateKeyMutation({ client })
  const [deleteKey] = useDeleteKeyMutation({ client })

  if (!org) return null

  const onGenerate = () => {
    generatingToggle.on()

    generateKey({ variables: { orgId: org.id } })
      .then(() => {
        onGenerated().then(() => msgr.add('API Key generated.', 'positive'))
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => generatingToggle.off())
  }

  const onDelete = (keyId: string) => {
    setDeletingId(keyId)

    deleteKey({ variables: { orgId: org.id, id: keyId } })
      .then(() => {
        onDeleted().then(() => msgr.add('API Key deleted.', 'positive'))
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => setDeletingId(null))
  }

  return (
    <Card title="API Keys">
      <Card.Section>
        <div className="space-y-4">
          <div className="prose">
            To access the Gearflow API, include the API key as a request header:
          </div>
          <div className="font-mono">gearflow-key: [API_KEY]</div>
          <div className="flex gap-2">
            <A.T href={config.buyersGqlTestingUrl} target="_blank" rel="noreferrer">
              API Testing
            </A.T>
          </div>
        </div>
      </Card.Section>
      <Card.Section>
        <div className="space-y-4">
          <Action.S performing={generating} onClick={onGenerate}>
            Generate API Key
          </Action.S>
          <Table>
            <Thead>
              <tr>
                <Th>API Key</Th>
                <Th>App</Th>
                <Th>Generated</Th>
                <Th />
              </tr>
            </Thead>
            <Tbody>
              {org.keys.map((key) => (
                <Tr key={key.key}>
                  <Td>
                    <div className="flex">
                      <div className="font-mono px-4 py-2 border-1 bg-gray-50">{key.key}</div>
                    </div>
                  </Td>
                  <Td>{appName(org, key)}</Td>
                  <Td>{Time.formatDateTime(key.insertedAt)}</Td>
                  <Td>
                    {!appName(org, key) && (
                      <Action.S
                        onClick={() => onDelete(key.id)}
                        performing={deletingId === key.id}
                        size="sm"
                        className="text-gray-400 hover:text-red-700"
                      >
                        {deletingId !== key.id && <TrashIcon className="w-5 h-5" />}
                      </Action.S>
                    )}
                  </Td>
                </Tr>
              ))}

              {org.keys.length === 0 && (
                <Tr>
                  <Td colSpan={3}>
                    <div className="text-gray-500 italic text-center">No API keys generated.</div>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </div>
      </Card.Section>
    </Card>
  )
}

export default ApiKeys
