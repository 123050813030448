import classNames from 'classnames'
import { ExclamationIcon } from '@heroicons/react/outline'

import type { Rfq } from '@/buyers/_gen/gql'
import { RfqPriority } from '@/buyers/_gen/gql'

import Time from '@/gf/modules/Time'

import { SimpleTooltip } from './next/Tooltip'

const NeededByPill = ({
  rfq,
}: {
  rfq: Pick<Rfq, 'insertedAt' | 'neededBy' | 'priority'> & {
    machineDown: boolean | null
    timeline: Pick<Rfq['timeline'], 'fulfilledAt'>
  }
}) => {
  if (!rfq.neededBy) return null

  const className = classNames(
    'px-2 py-1 text-xs font-medium whitespace-nowrap flex shrink-0 gap-x-1 items-center',
    rfq.priority === RfqPriority.High && 'rounded-md bg-red-100 border border-red-200 text-red-700',
    rfq.priority === RfqPriority.Medium &&
      'rounded-md bg-yellow-100 border border-yellow-200 text-yellow-600',
    rfq.priority === RfqPriority.Low &&
      'rounded-md bg-green-100 border border-green-200 text-green-700'
  )

  const text =
    rfq.priority === RfqPriority.High
      ? `High priority${rfq.machineDown ? ' and Machine is down' : ''}`
      : rfq.priority === RfqPriority.Medium
      ? 'Medium priority'
      : 'Low priority'

  return (
    <SimpleTooltip text={text} placement="bottom-start">
      <div className="flex flex-row gap-x-1">
        <div className={className}>
          {rfq.machineDown && (
            <ExclamationIcon className="-m-0.5 w-[18px] h-[18px] inline-flex shrink-0" />
          )}
          {Time.formatDateNoYear(rfq.neededBy)}
        </div>
      </div>
    </SimpleTooltip>
  )
}

export default NeededByPill
