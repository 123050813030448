import { CheckCircleIcon } from '@heroicons/react/solid'
import { ModalSize } from '@/types'
import useUpdateRfqBuyerNotificationUsers from '@/buyers/hooks/useUpdateRfqBuyerNotificationUsers'
import useSession from '@/buyers/hooks/useSession'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import { useSuccessStepFormQuery } from '@/buyers/_gen/gql'
import UsersMultiSelectInput from '@/buyers/components/UsersMultiSelectInput'
import Modal from '@/gf/components/ModalNext'
import Reminder from './NewRFQMessageModal/Reminder'
import CloseModalButton from '@/gf/components/CloseModalButton'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'

const NewRFQMessageModal = ({ rfqId }: { rfqId: string }) => {
  const { organization } = useSession()
  const [open, setOpen] = useQueryParam('newRequest', withDefault(BooleanParam, false))

  const { data, refetch } = useSuccessStepFormQuery({
    variables: { orgId: organization.id, filter: JSON.stringify(['id_eq', rfqId]) },
    client: useGqlClient(),
  })

  const rfq = data?.rfqs[0]

  const [updateRfqBuyerNotificationUsers] = useUpdateRfqBuyerNotificationUsers()

  const onClose = () => {
    setOpen(undefined, 'replaceIn')
  }

  if (!rfq) {
    return null
  }

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.MD}>
      <div className="p-6 relative flex flex-col gap-y-6">
        <CloseModalButton onClick={onClose} className="absolute top-2 right-2" />
        <div className="flex items-center gap-3 py-8">
          <div className="w-12 h-12 flex justify-center items-center bg-green-100 rounded-full">
            <CheckCircleIcon className="w-10 h-10 text-green-500" />
          </div>

          <div>
            <h3 className="text-lg leading-6">Request Created</h3>
            <p className="prose leading-6 text-gray-600">
              {rfq.needsApproval ? (
                <>Your team will be notified to approve this request.</>
              ) : (
                <>You will be notified as soon as you have quotes ready for review.</>
              )}
            </p>
          </div>
        </div>

        {!rfq.needsApproval && <Reminder rfq={rfq} />}

        <div>
          <h3 className="font-medium">Keep anyone notified?</h3>

          <div className="space-y-2">
            <p className="prose text-sm text-gray-600 leading-6">
              Add users from your team to automatically keep them notified on this request.
            </p>

            <UsersMultiSelectInput
              users={rfq.buyerNotificationUsers}
              onChange={(users) => {
                updateRfqBuyerNotificationUsers({
                  variables: { rfqId, userIds: users.map((user) => user.id) },
                }).then(() => refetch())
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default NewRFQMessageModal
