import { LocationMarkerIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { Point } from '../../types'
import Action from './Action'

const Wrapper = ({ onClick, className, children }) =>
  onClick ? (
    <Action.T className={className} onClick={onClick}>
      {children}
    </Action.T>
  ) : (
    <p className={className}>{children}</p>
  )

const Point = ({
  className,
  point,
  onClick,
}: {
  className?: string
  point: Point
  onClick?: () => void
}) => {
  if (!point) {
    return null
  }

  return (
    <Wrapper className={classNames(onClick && 'hover:underline', className)} onClick={onClick}>
      <LocationMarkerIcon className="text-gearflow h-4 inline-block -mt-1" /> {point.lat.toFixed(6)}
      , {point.lng.toFixed(6)}
    </Wrapper>
  )
}

export default Point
