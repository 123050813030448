import { useNavigate } from 'react-router-dom'

import MarkReceivedModal from '@/buyers/pages/StoreOrder/Order/MarkReceivedModal'
import { useOrderContext } from '../context'

const MarkReceived = () => {
  const { storeOrder, refetchStoreOrder } = useOrderContext()
  const navigate = useNavigate()
  const close = () => navigate(`/orders/${storeOrder.id}`)

  return (
    <MarkReceivedModal
      open
      storeOrderId={storeOrder.id}
      close={close}
      onSuccess={refetchStoreOrder}
    />
  )
}

export default MarkReceived
