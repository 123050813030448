import { gql, useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import Button from '@/gf/components/ButtonOld'
import Card from '@/gf/components/Card'
import Page from '@/gf/components/Page'
import TextField from '@/gf/components/TextField'
import useMsgs from '@/gf/hooks/useMsgs'
import UserContext from '@/gf/modules/User'
import { Organization } from '../../types'
import Frame from '../components/Frame'
import useSession from '../hooks/useSession'

const createOrgMutation = gql`
  mutation CreateOrganization($name: String, $phoneNumber: String) {
    createOrganization(name: $name, phoneNumber: $phoneNumber) {
      name
    }
  }
`
const organizationSchema = yup.object({
  name: yup.string().required().label('Name'),
  phoneNumber: yup.string().required().label('Phone Number'),
})

const CreateOrg = () => {
  const { user } = useSession()
  const [createOrg] = useMutation(createOrgMutation, { refetchQueries: ['Organizations'] })
  const [_msgs, msgsMgr] = useMsgs()
  const navigate = useNavigate()

  const form = useForm<Organization>({
    shouldUnregister: true,
    defaultValues: { name: '' },
    resolver: yupResolver(organizationSchema),
  })

  React.useEffect(() => {
    if (!UserContext.isAdmin(user)) window.location.href = '/'
  }, [])

  return (
    <Frame>
      <Page title="Create Organization">
        <Card>
          <Card.Section>
            <form
              onSubmit={form.handleSubmit((account: Organization, e) => {
                e?.preventDefault()
                createOrg({ variables: account })
                  .then(() => {
                    navigate('/orgs')
                  })
                  .catch(() => {
                    msgsMgr.add(`Failed to create ${account.name}`, 'negative')
                  })
              })}
            >
              <TextField
                label="Name"
                errorText={form.formState.errors.name?.message}
                {...form.register('name')}
              />
              <TextField
                label="Phone Number"
                errorText={form.formState.errors.phoneNumber?.message}
                {...form.register('phoneNumber')}
              />
              <Button type="submit">Create</Button>
            </form>
          </Card.Section>
        </Card>
      </Page>
    </Frame>
  )
}
export default CreateOrg
