import { ExclamationCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import Label from './Label'

const Field = ({
  className,
  label,
  labelClassName,
  tooltip,
  desc,
  errorText,
  errors,
  children,
  inputId = '',
  disabled = false,
}: {
  className?: string
  label?: React.ReactNode
  labelClassName?: string
  tooltip?: JSX.Element
  desc?: string | JSX.Element
  errorText?: string | null
  errors?: string[] | null
  inputId?: string
  disabled?: boolean
  children: React.ReactNode
}) => (
  <div className={classNames('space-y-1', className)}>
    {label && (
      <div className="flex">
        <Label className={labelClassName} disabled={disabled} htmlFor={inputId}>
          {label}
        </Label>
        {tooltip}
      </div>
    )}
    {children}
    {desc && <div className="text-sm text-gray-500">{desc}</div>}
    {errorText && (
      <div className="flex gap-1 items-center">
        <ExclamationCircleIcon
          className="block h-4 w-4 flex-shrink-0 text-red-500"
          aria-hidden="true"
        />
        <span className="text-sm text-red-500 leading-4">{errorText}</span>
      </div>
    )}
    {errors && (
      <div className="flex gap-1 items-center">
        <ExclamationCircleIcon
          className="block h-4 w-4 flex-shrink-0 text-red-500"
          aria-hidden="true"
        />
        <span className="text-sm text-red-500 leading-4">{errors.join(' / ')}</span>
      </div>
    )}
  </div>
)

export default Field
