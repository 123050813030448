import Phone from '@/gf/modules/Phone'

import Card from '@/gf/components/Card'
import Desc from '@/gf/components/Desc'
import RolePermissions from '@/buyers/components/RolePermissions'

import type { AdminUserFormReducer } from '@/buyers/hooks/useAddUserForm'
import type { PermissionSummary, Role } from '@/buyers/_gen/gql'

const User = ({
  fields,
  roles,
  permissionSummaries,
}: {
  fields: AdminUserFormReducer['form']['fields']
  roles: Pick<Role, 'id' | 'name'>[] | undefined
  permissionSummaries: Pick<PermissionSummary, 'title' | 'roles'>[] | undefined
}) => {
  const role = roles?.find((r) => r.id === fields.roleId)

  return (
    <Card.Section title="New User">
      <Desc.List>
        <Desc.Row title="Name">{fields.name}</Desc.Row>
        <Desc.Row title="Email">{fields.email}</Desc.Row>
        <Desc.Row title="Phone number">{Phone.format(fields.phoneNumber)}</Desc.Row>
        <Desc.Row title="Title">
          {fields.title.length > 0 ? (
            fields.title
          ) : (
            <span className="text-gray-400 italic">(blank)</span>
          )}
        </Desc.Row>
        <Desc.Row title="Role">
          <div className="space-y-2">
            <div>{role?.name}</div>
            {role && <RolePermissions role={role} permissionSummaries={permissionSummaries} />}
          </div>
        </Desc.Row>
      </Desc.List>
    </Card.Section>
  )
}

export default User
