import { MinusIcon, PlusIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { useAddWebhookMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'

import Action from '@/gf/components/Action'
import Checkbox from '@/gf/components/Checkbox'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import Modal from '@/gf/components/ModalNext'
import Select from '@/gf/components/Select'
import TextInput from '@/gf/components/TextInput'

interface Header {
  id: string
  key: string
  value: string
}

interface Form {
  url: string
  auth: string
  basicAuth: { username: string; password: string }
  headers: Header[]
  eventIds: string[]
}

const events = [{ id: 'part_received', name: 'Part received (Collective Data custom event)' }]

const AddWebhook = ({ orgId, onAdded }: { orgId: string; onAdded: () => Promise<unknown> }) => {
  const [_, msgr] = useMsgs()
  const navigate = useNavigate()
  const [addWebhook] = useAddWebhookMutation({ client: useGqlClient() })
  const [adding, setAdding] = useState(false)

  const [form, setForm] = useState<Form>({
    url: '',
    auth: '',
    basicAuth: { username: '', password: '' },
    headers: [],
    eventIds: events.map((e) => e.id),
  })

  const hasBasicAuth =
    form.auth === 'basic' &&
    (form.basicAuth.username.length > 0 || form.basicAuth.password.length > 0)

  const close = () => navigate('/settings/integrations')

  const basicAuthValue = `Basic ${window.btoa(
    `${form.basicAuth.username}:${form.basicAuth.password}`
  )}`

  const add = () => {
    setAdding(true)

    const headers = [
      ...form.headers.map((h) => [h.key, h.value]),
      ...(hasBasicAuth ? [['Authorization', basicAuthValue]] : []),
    ]

    addWebhook({
      variables: {
        orgId,
        url: form.url,
        headers,
        eventIds: form.eventIds,
      },
    })
      .then(() => {
        onAdded().then(() => {
          msgr.add('Webhook added.', 'positive')
          setForm({ ...form, url: '' })
          close()
        })
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => setAdding(false))
  }

  const updateHeader = (id: string, updates: Partial<Header>) => {
    const headers = form.headers.map((h) => (h.id === id ? { ...h, ...updates } : h))
    setForm({ ...form, headers })
  }

  const removeHeader = (id: string) => {
    const headers = form.headers.filter((h) => h.id !== id)
    setForm({ ...form, headers })
  }

  const addHeader = () => {
    const headers = [...form.headers, { id: uuid(), key: '', value: '' }]
    setForm({ ...form, headers })
  }

  const addEventId = (id: string) =>
    setForm({ ...form, eventIds: [...new Set([...form.eventIds, id])] })

  const removeEventId = (id: string) =>
    setForm({ ...form, eventIds: form.eventIds.filter((eventId) => eventId !== id) })

  return (
    <Modal open onClose={close}>
      <div className="text-xl py-4 px-8 bg-gray-50">Add Webhook</div>
      <Form onSubmit={add}>
        <div className="p-8 space-y-4">
          <Field label="URL">
            <TextInput value={form.url} setValue={(url) => setForm({ ...form, url })} />
          </Field>
          <Field label="Authorization">
            <div className="space-y-2">
              <Select
                currentId={form.auth}
                onChange={(auth) => setForm({ ...form, auth })}
                options={[
                  { id: '', display: '-- None --' },
                  { id: 'basic', display: 'Basic Auth' },
                ]}
              />
              {form.auth === 'basic' && (
                <div className="flex gap-2">
                  <TextInput
                    value={form.basicAuth.username}
                    setValue={(username) =>
                      setForm({ ...form, basicAuth: { ...form.basicAuth, username } })
                    }
                    placeholder="Username"
                  />
                  <TextInput
                    value={form.basicAuth.password}
                    setValue={(password) =>
                      setForm({ ...form, basicAuth: { ...form.basicAuth, password } })
                    }
                    placeholder="Password"
                  />
                </div>
              )}
            </div>
          </Field>
          <Field label="Headers">
            <div className="space-y-2">
              {hasBasicAuth && (
                <div className="flex gap-2">
                  <TextInput value="Authorization" disabled />
                  <TextInput value={basicAuthValue} disabled />
                  <div className="w-32" />
                </div>
              )}
              {form.headers.map((header) => (
                <div className="flex gap-2" key={header.id}>
                  <TextInput
                    value={header.key}
                    setValue={(key) => updateHeader(header.id, { key })}
                    placeholder="Key"
                  />
                  <TextInput
                    value={header.value}
                    setValue={(value) => updateHeader(header.id, { value })}
                    placeholder="Value"
                  />
                  <Action.S onClick={() => removeHeader(header.id)}>
                    <MinusIcon className="w-4 h-4" />
                  </Action.S>
                </div>
              ))}
              <Action.S onClick={addHeader}>
                <PlusIcon className="w-4 h-4" />
              </Action.S>
            </div>
          </Field>
          <Field label="Events">
            {events.map((event) => (
              <div className="flex items-center gap-2" key={event.id}>
                <Checkbox
                  checked={form.eventIds.includes(event.id)}
                  value={event.id}
                  onChange={({ target }) =>
                    target.checked ? addEventId(event.id) : removeEventId(event.id)
                  }
                  id={event.id}
                />
                <label htmlFor={event.id} className="block text-sm cursor-pointer">
                  {event.name}
                </label>
              </div>
            ))}
          </Field>
        </div>
        <div className="bg-gray-50 py-4 px-8 flex gap-2 justify-end">
          <Action.S onClick={close}>Cancel</Action.S>
          <Action.P performing={adding} type="submit">
            Add
          </Action.P>
        </div>
      </Form>
    </Modal>
  )
}

export default AddWebhook
