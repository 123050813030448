import classNames from 'classnames'
import { forwardRef } from 'react'

import Ghost from './Ghost'
import Link from './LinkOld'

const Table = forwardRef<HTMLDivElement, React.TableHTMLAttributes<HTMLTableElement>>(
  ({ children, className, onScroll, ...props }, ref) => (
    <div ref={ref} className="overflow-auto" onScroll={onScroll}>
      <table
        {...props}
        className={classNames('divide-y divide-gray-200 bg-white min-w-full', className)}
      >
        {children}
      </table>
    </div>
  )
)

const TableV2 = forwardRef<HTMLTableElement, JSX.IntrinsicElements['table']>(
  ({ children, className, ...props }, ref) => (
    <table
      {...props}
      className={classNames('bg-white divide-y-1 divide-gray-200', className)}
      ref={ref}
    >
      {children}
    </table>
  )
)

const Thead = ({
  className,
  noBg = false,
  ...props
}: JSX.IntrinsicElements['thead'] & { noBg?: boolean }) => (
  <thead {...props} className={classNames(!noBg && 'bg-gray-50', className)} />
)

const Tr = (props: JSX.IntrinsicElements['tr']) => {
  const className = props.onClick && 'hover:bg-blue-50 cursor-pointer'
  return <tr {...props} className={`${props.className || ''} ${className || ''}`} />
}

const Th = ({
  className,
  wrap = false,
  pad = true,
  ...props
}: JSX.IntrinsicElements['th'] & { wrap?: boolean; pad?: boolean }) => (
  <th
    {...props}
    className={classNames(
      'text-left text-xs font-bold text-gray-500 uppercase tracking-wider',
      { 'whitespace-nowrap': !wrap, 'px-6': pad, 'py-3': pad },
      className
    )}
  />
)

const Tbody = ({ className, ...props }: JSX.IntrinsicElements['tbody']) => (
  <tbody {...props} className={classNames('divide-y-1 divide-gray-200', className)} />
)

type TdProps = JSX.IntrinsicElements['td'] & { wrap?: boolean; pad?: boolean }

const Td = ({ wrap = false, pad = true, ...props }: TdProps) => {
  const className = classNames(
    'text-sm align-middle',
    { 'whitespace-nowrap': !wrap, 'px-6': pad, 'py-3': pad },
    props.className
  )

  return <td {...props} className={className} />
}

const TdGhost = ({ ghostClassName, ...props }: TdProps & { ghostClassName?: string }) => (
  <Td {...props}>
    <Ghost className={classNames('inline-block w-20 h-5', ghostClassName)} />
  </Td>
)

const LinkTd = ({
  to,
  children,
  pad = false,
}: {
  to: string
  children: React.ReactNode
  pad?: boolean
}) => (
  <Td pad={pad}>
    <Link to={to}>{children}</Link>
  </Td>
)

const TableWrapper = ({
  children,
  className,
}: {
  className?: string
  children?: React.ReactNode
}) => (
  <div
    className={classNames('min-w-full rounded border border-gray-300 overflow-x-auto', className)}
  >
    {children}
  </div>
)

export { LinkTd, Table, TableV2, TableWrapper, Tbody, Td, TdGhost, Th, Thead, Tr }
