/**
 * Text Field Component
 * -----------------
 * Text input for use in forms
 *
 * props
 * -----------------
 * label - label over the input
 * desc - description of the input
 * type - text input field type
 * errorText - text displayed for an error with this field
 * className - styles for this TextField component
 * ref - input component ref
 */

import { forwardRef } from 'react'

import TextInput from './TextInput'
import Field from './Field'

type Props = Parameters<typeof TextInput>[0] & {
  label: string
  tooltip?: JSX.Element
  desc?: string | JSX.Element
  errorText?: string | null
  noErrorText?: boolean
  containerClassName?: string
}

const TextField = forwardRef<HTMLInputElement, Props>(
  ({ label, desc, tooltip, containerClassName, errorText, noErrorText = false, ...props }, ref) => {
    const id = label.toLowerCase().split(' ').join('-')

    return (
      <Field
        className={containerClassName}
        tooltip={tooltip}
        label={label}
        errorText={!noErrorText ? errorText : undefined}
        desc={desc}
        inputId={id}
      >
        <TextInput {...props} ref={ref} id={id} />
      </Field>
    )
  }
)

export default TextField
