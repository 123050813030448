import pick from 'lodash/pick'
import { Address, AddressPayload } from '../../types'
import GraphQL from './GraphQL'

const GEARFLOW_BILLIING: Address = {
  firstName: '',
  lastName: '',
  companyName: 'Gearflow',
  lineOne: '318 West Adams Street, Suite 1705',
  lineTwo: '',
  city: 'Chicago',
  state: 'IL',
  postalCode: '60606',
  country: 'United States',
}

const init = () => ({
  firstName: '',
  lastName: '',
  lineOne: '',
  lineTwo: '',
  city: '',
  state: '',
  postalCode: '',
  country: 'United States',
  companyName: '',
  latitude: null,
  longitude: null,
})

const formatCityStateString = (address: Address) => {
  if (!address) return ''
  const { city, state } = address
  if (city && state) return `${city}, ${state}`
  if (city && !state) return city
  if (!city && state) return state
  return ''
}

const formatStreetAddress = (address: Pick<Address, 'lineOne' | 'city' | 'state' | 'country'>) =>
  [address.lineOne, `${address.city} ${address.state}`, address.country].join(', ')

// Returns a formatted one line string for the given address
const formatOneLine = (
  address: Pick<Address, 'lineOne' | 'lineTwo' | 'city' | 'state' | 'postalCode' | 'country'>
) =>
  `${address.lineOne}${address.lineTwo ? ` ${address.lineTwo}` : ''} ${address.city}, ${
    address.state
  }${address.postalCode ? ` ${address.postalCode}` : ''} ${address.country}`

const fromPayload = (payload: AddressPayload): Address => ({
  ...payload,
  latitude: payload?.latitude ? Number(payload?.latitude) : undefined,
  longitude: payload?.longitude ? Number(payload?.longitude) : undefined,
})

const toPickupPayload = (address: Address) => {
  const addressInput = pick(GraphQL.cleanGraphQlInput(address), [
    'lineOne',
    'lineTwo',
    'city',
    'state',
    'postalCode',
    'country',
  ])

  const longitude = address?.point
    ? address.point.lng.toString()
    : address?.longitude
    ? address.longitude.toString()
    : null
  const latitude = address?.point
    ? address.point.lat.toString()
    : address?.latitude
    ? address.latitude.toString()
    : null
  return { ...addressInput, longitude, latitude }
}

export default {
  GEARFLOW_BILLIING,
  init,
  formatCityStateString,
  fromPayload,
  toPickupPayload,
  formatStreetAddress,
  formatOneLine,
}
