import Modal from '@/gf/components/ModalNext'
import { App, ModalSize } from '@/types'
import { ReactNode } from 'react'
import Form from './Form'
import Success from './Success'

const ClaimAccountModal = ({
  open,
  onClose,
  email,
  onSuccess,
  customHeader,
  cancelButtonEnabled = false,
  claimAccount,
  app,
}: {
  open: boolean
  onClose: () => void
  email: string
  onSuccess: () => void
  customHeader?: ReactNode
  cancelButtonEnabled?: boolean
  claimAccount: (form: Form['values']) => Promise<unknown>
  app: App
}) => (
  <Modal open={open} onClose={onClose} size={ModalSize.SM}>
    <Form
      onClose={onClose}
      email={email}
      success={<Success app={app} onClose={onSuccess} />}
      customHeader={customHeader}
      cancelButtonEnabled={cancelButtonEnabled}
      claimAccount={claimAccount}
    />
  </Modal>
)

export default ClaimAccountModal
