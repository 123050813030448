import { StoreOrder } from '@/buyers/pages/LimitedOrder'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import Money from '@/gf/modules/Money'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { useOrderContext } from '../../context'
import Availability from './Availability'

const PartsTable = ({
  title,
  items,
  canSelectParts,
  className,
}: {
  title: ReactNode
  items: StoreOrder['lineItems']
  canSelectParts: boolean
  className?: string
}) => {
  const { rejectedLineItemIds, toggleRejectedLineItemId } = useOrderContext()

  return (
    <div className={className}>
      <h2 className="text-lg font-medium flex items-center gap-x-1.5">{title}</h2>

      <div className="w-full overflow-x-auto fancy-scroll">
        <table className="w-full text-sm lg:text-base text-left border-b border-gray-200">
          <thead className="hidden lg:table-header-group border-b font-medium text-gray-500">
            <tr className="h-16 divide-x border-gray-200 [&>th]:align-middle">
              <th className="pr-3 py-2 w-28 min-w-28">Part Number</th>
              <th className="px-3 py-2">Description</th>
              <th className="px-3 py-2 w-16">Quantity</th>
              <th className="px-3 py-2 w-24">Price</th>
              <th className="px-3 py-2 w-30">Total</th>
              <th className="py-2 pl-3 min-w-48">Availability</th>
            </tr>
          </thead>

          <tbody>
            {items.map((item) => (
              <tr
                key={item.id}
                className={classNames(
                  'border-b last:border-0 flex flex-col gap-y-1.5',
                  'lg:table-row lg:divide-x [&>td]:align-middle'
                )}
              >
                <td
                  className={classNames(
                    "grid grid-cols-[12ch_auto] before:content-['Part_Number'] before:font-medium pt-4 break-all",
                    'lg:table-cell lg:before:content-none lg:py-2 lg:pr-3'
                  )}
                >
                  {items.length > 1 && canSelectParts && (
                    <SimpleTooltip
                      text={
                        rejectedLineItemIds.includes(item.id)
                          ? 'Add back to quote'
                          : 'Remove from quote'
                      }
                      placement="bottom"
                    >
                      <Checkbox
                        checked={!rejectedLineItemIds.includes(item.id)}
                        onChange={() => toggleRejectedLineItemId(item.id)}
                        className="mr-2 focus:ring-0 focus:ring-white cursor-pointer"
                      />
                    </SimpleTooltip>
                  )}
                  {item.product.mpn}
                  {item.rfqPart && item.rfqPart.mpn && item.rfqPart.mpn !== item.product.mpn && (
                    <div className="px-2 py-1 inline-flex shrink bg-gray-100 border border-gray-300 rounded-md text-sm text-gray-600 text-ellipsis whitespace-pre-wrap">
                      Requested: <span className="font-medium">{item.rfqPart.mpn}</span>
                    </div>
                  )}
                </td>
                <td
                  className={classNames(
                    "grid grid-cols-[12ch_auto] before:content-['Description'] before:font-medium",
                    'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                  )}
                >
                  <SimpleTooltip
                    placement="bottom-start"
                    text={item.product.name}
                    className="lg:max-w-56"
                  >
                    <span className="inline-block lg:max-w-[20ch] lg:truncate lg:text-ellipsis leading-5 text-left">
                      {item.product.name}
                    </span>
                  </SimpleTooltip>
                </td>
                <td
                  className={classNames(
                    "grid grid-cols-[12ch_auto] before:content-['Quantity'] before:font-medium",
                    'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                  )}
                >
                  {item.quantity}
                </td>
                <td
                  className={classNames(
                    "grid grid-cols-[12ch_auto] before:content-['Unit_Price'] before:font-medium",
                    'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                  )}
                >
                  {!Money.equals(item.unitPrice, item.discountedUnitPrice) && (
                    <span className="line-through text-gray-500 ">
                      {Money.format(item.unitPrice)}
                    </span>
                  )}

                  <span>{Money.format(item.discountedUnitPrice)}</span>
                </td>
                <td
                  className={classNames(
                    "grid grid-cols-[12ch_auto] before:content-['Total'] before:font-medium ",
                    'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                  )}
                >
                  {Money.format(item.extendedPrice)}
                </td>
                <td className="block lg:table-cell pb-4 lg:py-2 lg:px-3">
                  <Availability lineItem={item} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PartsTable
