import { CogIcon, InformationCircleIcon, RssIcon } from '@heroicons/react/outline'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import Page from '@/gf/components/Page'
import Frame from '../components/Frame'
import NotFound from './NotFound'
import useOrgMachineDetails from '@/gf/hooks/useOrgMachineDetails'
import Activity from './MachineDetails/Activity'
import Specs from './MachineDetails/Specs'
import UnitDetails from './MachineDetails/UnitDetails'
import DetailsPageWithSidebar from '@/gf/components/Layout/DetailsPageWithSidebar'
import Sidebar from './MachineDetails/Sidebar'
import RouterTabs from '@/gf/components/Layout/RouterTabs'

const MachineDetails = () => {
  const { machineId: id } = useParams<{ machineId: string }>() as { machineId: string }
  const machineDetails = useOrgMachineDetails({ id })
  const machine = machineDetails.data?.machine

  if (machine === null) return <NotFound />

  const title = machine
    ? `${machine?.machine?.make} ${machine?.machine?.model} #${machine.name}`
    : ''

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'Fleet', href: '/fleet' },
      { name: title, href: `/machines/${id}` },
    ],
  }

  const baseUrl = `/machines/${id}`

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Machine Details">
        <DetailsPageWithSidebar sidebar={<Sidebar machineDetails={machineDetails} />}>
          <RouterTabs
            tabs={[
              { label: 'Activity', path: `${baseUrl}/activity`, icon: RssIcon },
              { label: 'Machine Specs', path: `${baseUrl}/specs`, icon: CogIcon },
              {
                label: 'Unit Details',
                path: `${baseUrl}/unit-details`,
                icon: InformationCircleIcon,
              },
            ]}
          >
            <div className="p-4">
              <Routes>
                <Route path="" element={<Navigate to="activity" replace />} />
                <Route path="activity" element={<Activity machineId={id} />} />
                <Route path="specs" element={<Specs machineDetails={machineDetails} />} />
                <Route
                  path="unit-details"
                  element={<UnitDetails machineDetails={machineDetails} />}
                />
              </Routes>
            </div>
          </RouterTabs>
        </DetailsPageWithSidebar>
      </Page>
    </Frame>
  )
}

export default MachineDetails
