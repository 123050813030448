import Box from '@/gf/components/Box'
import classNames from 'classnames'

type Props = Parameters<typeof Box>[0] & { disabled?: boolean }

const FormSection = ({ children, className, disabled }: Props) => (
  <Box className={classNames('rounded-xl p-8 shadow-base', disabled && 'opacity-50', className)}>
    <div className="max-w-md">{children}</div>
  </Box>
)

export default FormSection
