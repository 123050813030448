import { gql, useMutation } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { useForm, useWatch } from 'react-hook-form'

import { SettingsBillingInfoQuery, useSettingsBillingInfoQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import AddressM from '@/buyers/modules/Address'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'

import {
  CompleteApproveNetTerms,
  PendingApproveNetTerms,
} from '@/buyers/components/ApproveNetTerms'
import AddressInputField from '@/gf/components/AddressInput'
import Button from '@/gf/components/ButtonOld'
import Card from '@/gf/components/Card'
import Field from '@/gf/components/Field'
import { SlideDownCard, SlideParentCard } from '@/gf/components/SlideDownCard'
import Spinner from '@/gf/components/Spinner'
import TextInput from '@/gf/components/inputs/Text'

const updateAccountingMutation = gql`
  mutation UpdateOrganizationAccounting(
    $id: String!
    $invoiceEmail: String!
    $billingAddress: AddressInput!
  ) {
    updateOrganizationAccounting(
      id: $id
      invoiceEmail: $invoiceEmail
      billingAddress: $billingAddress
    )
  }
`

const InfoLoaded = ({
  org,
  refetchOrg,
}: {
  org: Exclude<SettingsBillingInfoQuery['org'], null>
  refetchOrg: () => void
}) => {
  const [_msgs, msgsMgr] = useMsgs()
  const [loading, loader] = useToggle()
  const { organization } = useSession()
  const [updateAccounting] = useMutation(updateAccountingMutation)

  const form = useForm({
    defaultValues: {
      name: org.name,
      invoiceEmail: org.invoiceEmail,
      billingAddress: org.billingAddress || {
        ...AddressM.init(),
        companyName: org.name,
      },
    },
  })

  const formBillingAddress = useWatch({ control: form.control, name: 'billingAddress' })

  const onFormSubmit = () => {
    loader.on()
    const formData = form.getValues()

    updateAccounting({
      variables: {
        id: organization.id,
        invoiceEmail: formData.invoiceEmail,
        billingAddress: formData.billingAddress
          ? AddressM.toGraphQlAddressInput(formData.billingAddress)
          : undefined,
      },
    })
      .then(() => {
        msgsMgr.add('Updated accounting email', 'positive')
        refetchOrg()
      })
      .catch((error: GraphQLError) => {
        if (error.message) msgsMgr.add(error.message, 'negative')
        else msgsMgr.add('Error updating accounting email', 'negative')
      })
      .finally(() => {
        loader.off()
      })
  }

  return (
    <Card
      title="Invoice Settings"
      primaryFooterAction={{
        id: 1,
        element: (
          <Button title="Save" type="submit" onClick={() => onFormSubmit()} performing={loading} />
        ),
      }}
    >
      <Card.Section>
        <div className="flex items-center">
          <Field
            label="Accounts Payable Email"
            desc="Sends a copy of all invoices to this email. Typically an accounts payable distribution email."
          >
            <TextInput {...form.register('invoiceEmail')} required />
          </Field>
        </div>

        {org.approveNetTerm && (
          <SlideDownCard>
            <SlideParentCard className="mt-6" slideOpen={false}>
              {org.approveNetTerm.pending ? (
                <PendingApproveNetTerms />
              ) : (
                <CompleteApproveNetTerms />
              )}
            </SlideParentCard>
          </SlideDownCard>
        )}
      </Card.Section>

      <Card.Section title="Billing Address">
        <div className="space-y-4">
          <p className="prose">
            The billing address that will be put on invoices sent to your organization.
          </p>

          <AddressInputField
            requireFirstLastName={false}
            address={formBillingAddress}
            onChange={(newAddress) => form.setValue('billingAddress', newAddress)}
            hideFirstLastName
          />
        </div>
      </Card.Section>
    </Card>
  )
}

const Info = () => {
  const { organization } = useSession()

  const { data, refetch } = useSettingsBillingInfoQuery({
    variables: { orgId: organization.id },
    client: useGqlClient(),
  })

  return data?.org ? <InfoLoaded org={data.org} refetchOrg={refetch} /> : <Spinner />
}

export default Info
