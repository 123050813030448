import type { ReviewChargeQuery } from '@/buyers/_gen/gql'
import {
  CreditLimitStatus,
  useConfirmAdditionalChargeWithNetTermsMutation,
  useDenyAdditionalChargeMutation,
  usePayOnAccountAdditionalChargeMutation,
  usePayOnInvoiceAdditionalChargeMutation,
  useReviewChargeQuery,
  useSetPurchaseOrderAdditionalChargeMutation,
} from '@/buyers/_gen/gql'
import DenyModal from '@/buyers/components/DenyModal'
import useAdditionalCharge, { AdditionalCharge } from '@/buyers/hooks/useAdditionalCharge'
import useAdditionalChargeBalanceJson, {
  BalanceJson,
} from '@/buyers/hooks/useAdditionalChargeBalanceJson'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import BalanceTermsAndConditions from '@/gf/components/BalanceTermsAndConditions'
import Card from '@/gf/components/Card'
import LinkButton from '@/gf/components/LinkButtonOld'
import RedAlert from '@/gf/components/RedAlert'
import SlideOver from '@/gf/components/SlideOver'
import Spinner from '@/gf/components/Spinner'
import TextInput from '@/gf/components/TextInput'
import useBalanceModal from '@/gf/hooks/useBalanceModal'
import useConfig from '@/gf/hooks/useConfig'
import useToggle from '@/gf/hooks/useToggle'
import MoneyM from '@/gf/modules/Money'
import PhoneM from '@/gf/modules/Phone'
import UserM from '@/gf/modules/User'
import fetch from '@/gf/modules/fetch'
import { Balance, PaymentMethod } from '@/types'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import NotFound from '../../NotFound'
import PaymentMethodInputCard from './ReviewCharge/PaymentMethodInputCard'
import ApproveStoreOrderSubmitButtons from './ReviewCharge/SubmitButtons'

declare const window: { balanceSDK: Balance }

const defaultErrorMessage = 'Please contact support'

const getAvailablePaymentMethods = (
  additionalCharge: Pick<AdditionalCharge, 'storeOrder'>,
  balanceJson: Pick<BalanceJson, 'creditLimit'>
) => {
  const directPay = false
  const invoicePay = additionalCharge.storeOrder.payOnInvoice

  const result: PaymentMethod[] = []

  if (directPay) result.push(PaymentMethod.PayDirect)
  if (!directPay && invoicePay) result.push(PaymentMethod.PayInvoice)

  if (
    !directPay &&
    !invoicePay &&
    balanceJson.creditLimit &&
    balanceJson.creditLimit.status === CreditLimitStatus.Approved
  )
    result.push(PaymentMethod.NetTerms)

  if (!directPay && !invoicePay) result.push(PaymentMethod.CreditCardBank)

  return result
}

const denyPlaceholder =
  'Input your reason for denying the additional charge to inform the supplier on how they can adjust it to better suit your needs'

const approveChanges = async ({ approveUrl, additionalChargeId }) => {
  const resp = await fetch(approveUrl, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ additionalChargeId }),
  })

  return resp
}

const ApproveAdditionalChargeForm = ({
  user,
  additionalCharge,
  balanceJson,
  onSuccess,
}: {
  user: ReviewChargeQuery['user'] | undefined
  additionalCharge: AdditionalCharge
  balanceJson: Pick<BalanceJson, 'transactions' | 'creditLimit'>
  onSuccess: () => Promise<unknown>
}) => {
  const { organization } = useSession()
  const config = useConfig()
  const gqlClient = useGqlClient()
  const [error, setError] = useState<string | null>()
  const [spinnerLive, spinnerToggle] = useToggle()
  const [denyModalOpen, denyModalToggle] = useToggle()
  const availablePaymentMethods = getAvailablePaymentMethods(additionalCharge, balanceJson)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(availablePaymentMethods[0])

  const [purchaseOrder, setPurchaseOrder] = useState('')

  // Required form fields
  const formRequirePurchaseOrder =
    organization.showAddlPurchaseOrder && !additionalCharge.purchaseOrder

  const [denyChargeMutation] = useDenyAdditionalChargeMutation({ client: gqlClient })
  const [setPurchaseOrderAdditionalCharge] = useSetPurchaseOrderAdditionalChargeMutation({
    client: gqlClient,
  })
  const [payOnInvoiceAdditionalCharge] = usePayOnInvoiceAdditionalChargeMutation({
    client: gqlClient,
  })
  const [payOnAccountAdditionalCharge] = usePayOnAccountAdditionalChargeMutation({
    client: gqlClient,
  })
  const [confirmAdditionalChargeWithNetTerms] = useConfirmAdditionalChargeWithNetTermsMutation({
    client: gqlClient,
  })

  const handleError = (e) => {
    if (e.message) {
      setError(e.message)
    } else {
      setError(defaultErrorMessage)
    }
  }

  const approveAdditionalCharge = () =>
    approveChanges({
      approveUrl: `${config.gfBaseUrl}/api/additional_charge/approve`,
      additionalChargeId: additionalCharge.id,
    })

  const balanceModal = useBalanceModal({
    balance: window.balanceSDK,
    checkoutTokens: balanceJson.transactions.reduce(
      (acc: string[], txn) => (txn.token ? [...acc, txn.token] : acc),
      []
    ),
    onSuccess: () => {
      spinnerToggle.on()
      approveAdditionalCharge()
        .then(() => onSuccess())
        .catch(handleError)
        .finally(() => {
          spinnerToggle.off()
        })
    },
    isAuth: true,
  })

  const hasPermissionToApproveOrder =
    user &&
    (UserM.isAdmin(user) ||
      !organization.approvalThreshold ||
      organization.approvalThreshold.amount > additionalCharge.price.amount ||
      user.can.approveStoreOrders)

  const optionallySubmitPurchaseOrder = async () => {
    if (formRequirePurchaseOrder) {
      return setPurchaseOrderAdditionalCharge({
        variables: { additionalChargeId: additionalCharge.id, purchaseOrder },
      })
    }
    return Promise.resolve()
  }
  const submitOrder = async (): Promise<{ halt: boolean }> => {
    if (selectedPaymentMethod === PaymentMethod.CreditCardBank) {
      balanceModal.open()
      return { halt: true }
    }
    if (selectedPaymentMethod === PaymentMethod.NetTerms) {
      return confirmAdditionalChargeWithNetTerms({
        variables: { additionalChargeId: additionalCharge.id },
      })
        .then(() => approveAdditionalCharge())
        .then(() => ({ halt: false }))
    }
    if (selectedPaymentMethod === PaymentMethod.PayDirect) {
      return payOnAccountAdditionalCharge({
        variables: { additionalChargeId: additionalCharge.id },
      }).then(() => ({ halt: false }))
    }
    if (selectedPaymentMethod === PaymentMethod.PayInvoice) {
      return payOnInvoiceAdditionalCharge({
        variables: { additionalChargeId: additionalCharge.id },
      }).then(() => ({ halt: false }))
    }
    return Promise.reject(new Error(`No matching payment method ${selectedPaymentMethod}`))
  }
  const submitForm = () => {
    spinnerToggle.on()
    setError(null)
    optionallySubmitPurchaseOrder()
      .then(() => submitOrder())
      .then(({ halt }) => (halt ? Promise.resolve() : onSuccess()))
      .catch(handleError)
      .finally(() => {
        spinnerToggle.off()
      })
  }

  return (
    <>
      <DenyModal
        open={denyModalOpen}
        onClose={(args) => {
          denyModalToggle.off()
          if (args?.successful) onSuccess()
        }}
        denyPlaceholder={denyPlaceholder}
        onSubmit={(comments) =>
          denyChargeMutation({ variables: { additionalChargeId: additionalCharge.id, comments } })
        }
      />
      <form
        className="flex flex-col space-y-5"
        onSubmit={(e) => {
          e.preventDefault()
          submitForm()
        }}
      >
        <SlideOver.Layout>
          <div className="flex flex-col space-y-5">
            <Card title="Additional charge details">
              <Card.Section>
                <Card.SubSection>
                  <div className="text-sm">
                    <p>
                      {additionalCharge?.storeOrder?.store?.name} added an additional charge to your
                      order.
                    </p>
                    {additionalCharge.storeOrder.store.phoneNumber && (
                      <p className="text-gray-500">
                        Phone: {PhoneM.format(additionalCharge.storeOrder.store.phoneNumber)}
                      </p>
                    )}
                  </div>
                </Card.SubSection>
              </Card.Section>
              <Card.Section title="">
                <div className="flex justify-between text-sm text-gray-700">
                  <p>{additionalCharge?.name}</p>
                  <p>{MoneyM.format(additionalCharge?.price)}</p>
                </div>
              </Card.Section>
            </Card>
            {/* Purchase order */}
            {formRequirePurchaseOrder && (
              <Card title="Purchase order">
                <Card.Section>
                  <TextInput
                    required
                    placeholder="Purchase order"
                    value={purchaseOrder}
                    onChange={(e) => setPurchaseOrder(e.target.value)}
                  />
                </Card.Section>
              </Card>
            )}
            {/* Payment method */}
            <PaymentMethodInputCard
              paymentMethods={availablePaymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              storeName={additionalCharge.storeOrder.store.name}
              creditLimit={balanceJson.creditLimit?.creditLimit}
              maxCreditLimit={balanceJson.creditLimit?.maxCreditLimit}
            >
              <Card.Section>
                {error && (
                  <RedAlert
                    className="mb-3 w-full"
                    title={
                      error === defaultErrorMessage
                        ? defaultErrorMessage
                        : 'Please contact support if applicable.'
                    }
                  >
                    {error !== defaultErrorMessage && <>{error}</>}
                  </RedAlert>
                )}

                {hasPermissionToApproveOrder !== undefined && (
                  <ApproveStoreOrderSubmitButtons
                    storeOrderId={additionalCharge.storeOrder.id}
                    spinnerLive={spinnerLive}
                    hasPermissionToApproveOrder={hasPermissionToApproveOrder}
                    onDenyClick={denyModalToggle.on}
                  />
                )}
              </Card.Section>
            </PaymentMethodInputCard>
            {/* We only need to show the Balance terms for orders paid with net terms */}
            {availablePaymentMethods.find((pm) => pm === PaymentMethod.NetTerms) && (
              <div className="pt-2 flex justify-center">
                <BalanceTermsAndConditions className="max-w-md" buttonText="Confirm" />
              </div>
            )}
          </div>
        </SlideOver.Layout>
      </form>
    </>
  )
}

const ApproveAdditionalCharge = ({
  user,
  additionalChargeId,
  onSuccess,
}: {
  user: ReviewChargeQuery['user'] | undefined
  additionalChargeId: string
  onSuccess: () => Promise<unknown>
}) => {
  const { balanceJson, error: balanceJsonError } =
    useAdditionalChargeBalanceJson(additionalChargeId)
  const {
    refetch: additionalChargeRefetch,
    error: additionalChargeError,
    additionalCharge,
  } = useAdditionalCharge(additionalChargeId)
  const [resubmitForm, resubmitFormToggle] = useToggle()

  const deniedEvent = additionalCharge?.storeOrderEvents.find(
    (event) => event.type === 'additional_charge_denied'
  )

  return additionalCharge === null ? (
    <SlideOver.Layout>
      <NotFound />
    </SlideOver.Layout>
  ) : additionalChargeError || balanceJsonError ? (
    <SlideOver.Layout>
      <p>Error, please contact Support</p>
    </SlideOver.Layout>
  ) : !additionalCharge || !balanceJson ? (
    <Spinner className="mt-6" />
  ) : additionalCharge.state === 'charged' ? (
    <SlideOver.Layout>
      <div className="mt-6 flex flex-col items-center">
        <div className="w-20 h-20 flex justify-center items-center bg-green-100 rounded-full">
          <CheckCircleIcon className="w-16 h-16 text-green-500" />
        </div>
        <p className="mt-4 text-gray-900 text-2xl">Success!</p>
        <p className="mt-3 text-gray-700 text-lg">You paid the additional charge</p>
      </div>
    </SlideOver.Layout>
  ) : deniedEvent && !resubmitForm ? (
    <SlideOver.Layout>
      <div className="mt-6 flex flex-col items-center">
        <div className="w-20 h-20 flex justify-center items-center bg-red-100 rounded-full">
          <XCircleIcon className="w-16 h-16 text-red-500" />
        </div>
        <p className="mt-4 text-gray-900 text-2xl">Denied</p>
        <p className="mt-3 text-gray-700 text-lg">You denied the additional charge</p>
        {deniedEvent.note && (
          <div className="mt-4 px-6 py-2 text-gray-700 text-sm text-center max-w-lg border rounded-md space-y-1">
            <p className="font-medium">Reason:</p>
            <p className="whitespace-pre-wrap">{deniedEvent.note}</p>
          </div>
        )}
        <div className="mt-6">
          <LinkButton onClick={resubmitFormToggle.on}>Review additional charge again</LinkButton>
        </div>
      </div>
    </SlideOver.Layout>
  ) : (
    <ApproveAdditionalChargeForm
      user={user}
      additionalCharge={additionalCharge}
      balanceJson={balanceJson}
      onSuccess={() =>
        Promise.all([additionalChargeRefetch(), onSuccess()]).finally(() =>
          resubmitFormToggle.off()
        )
      }
    />
  )
}

const ReviewCharge = ({
  additionalChargeId,
  open,
  onClose,
  onSuccess,
}: {
  additionalChargeId: string | null
  open: boolean
  onClose: () => void
  onSuccess: () => Promise<unknown>
}) => {
  const user = useReviewChargeQuery({
    variables: { userId: useSession().user.id },
    client: useGqlClient(),
  }).data?.user

  return (
    <SlideOver open={open} onClose={onClose} title="Review Additional Charge">
      {additionalChargeId === null ? (
        <div className="mt-6 flex flex-col items-center">
          <p className="mt-3 text-gray-700 text-lg">No additional charge selected</p>
        </div>
      ) : (
        <ApproveAdditionalCharge
          user={user}
          additionalChargeId={additionalChargeId}
          onSuccess={onSuccess}
        />
      )}
    </SlideOver>
  )
}

export default ReviewCharge
