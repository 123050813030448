import { ModalFormProps } from '../../types'
import Id from '../modules/Id'

import A from './A'
import ModalForm from './ModalForm'

interface Props extends Omit<ModalFormProps, 'title'> {
  storeOrderId: string
  dashboardUrl: string
  title?: string
}
const InvoiceMarkAsPaidModal = ({
  storeOrderId,
  dashboardUrl,
  title = 'Mark as Paid',
  ...props
}: Props) => (
  <ModalForm title={title} {...props}>
    {storeOrderId && (
      <>
        <span>This will mark the Invoice for </span>
        <A.T href={`${dashboardUrl}/orders/${storeOrderId}`} target="_blank">
          Order {Id.shorten(storeOrderId)}
        </A.T>
        <span> as paid.</span>
      </>
    )}
  </ModalForm>
)

export default InvoiceMarkAsPaidModal
