import useQuoteTotals from '@/dealers/pages/Request/QuoteBuilder/useQuoteTotals'
import Summary from '@/dealers/pages/Request/Summary'
import Action from '@/gf/components/Action'
import AddAccountMachineModal from '@/gf/components/AddAccountMachineModal'
import Link from '@/gf/components/Link'
import Page from '@/gf/components/Page'
import PriceV2 from '@/gf/components/inputs/PriceV2'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import useToggle from '@/gf/hooks/useToggle'
import MoneyM from '@/gf/modules/Money'
import { Maybe } from '@/types'
import { PlusIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useAllBranchesQuery, useLogExternalOrderMutation } from '../_gen/gql'
import Box from '../components/Box'
import Frame from '../components/Frame'
import LocationModal from '../components/LocationModal'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import Address from '../modules/Address'
import CreateVendorModal from './CreateExternalOrder/CreateVendorModal'
import ExternalVendors from './CreateExternalOrder/ExternalVendors'
import LocationSelector, { Query as LocationsQuery } from './CreateExternalOrder/LocationSelector'
import MachineSelect from './CreateRequest/MachineSelect'

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [
    { name: 'Orders', href: '/orders' },
    { name: 'Log Order', href: '/orders/create-external' },
  ],
}

const newLineItem = () => ({
  partNumber: '',
  description: '',
  unitPrice: null,
  quantity: 1,
})

type FormValues = {
  taxCost: Maybe<number>
  shippingCost: Maybe<number>
  workOrderNumber: Maybe<string>
  purchaseOrderNumber: Maybe<string>
  items: { partNumber: string; description: string; unitPrice: Maybe<number>; quantity: number }[]
  vendorId: Maybe<string>
  machineInvolved: boolean
  orgMachineId: Maybe<string>
  locationId: Maybe<string>
}

const validationSchema = Yup.object().shape({
  taxCost: Yup.number().nullable().optional(),
  shippingCost: Yup.number().nullable().optional(),
  workOrderNumber: Yup.string().nullable(),
  purchaseOrderNumber: Yup.string().nullable(),
  machineInvolved: Yup.boolean().required(),
  orgMachineId: Yup.string().nullable(),
  items: Yup.array(
    Yup.object().shape({
      partNumber: Yup.string().required('Part Number is required'),
      description: Yup.string().required('Product Name is required'),
      unitPrice: Yup.number()
        .nullable()
        .min(0.01, 'Should be greater than 0.00')
        .required('Price is required'),
      quantity: Yup.number()
        .required('Quantity is required')
        .min(0, 'Quantity should be greater than 0'),
    })
  ).min(1),
  vendorId: Yup.string().nullable().required('Vendor is required'),
  locationId: Yup.string().nullable().required('Location is required'),
})

const CreateExternalOrder = () => {
  const navigate = useNavigate()
  const { featureFlags, organization } = useSession()
  const client = useGqlClient()
  const branches = useAllBranchesQuery({ variables: { value: '' }, client }).data?.allBranches
  const [logExternalOrder] = useLogExternalOrderMutation({ client })
  const [vendorModalOpen, vendorModalToggle] = useToggle()
  const [machineModalOpen, machineModalToggle] = useToggle()
  const [locationModalOpen, locationModalToggle] = useToggle()
  const { control, handleSubmit, formState, register, setValue } = useForm<FormValues>({
    shouldFocusError: true,
    defaultValues: {
      taxCost: null,
      shippingCost: null,
      workOrderNumber: '',
      purchaseOrderNumber: '',
      items: [newLineItem()],
      vendorId: null,
      machineInvolved: true,
      orgMachineId: null,
      locationId: null,
    },
    resolver: yupResolver(validationSchema),
  })

  const itemsForm = useFieldArray({ control, name: 'items' })
  const itemsValues = useWatch({ name: 'items', control })
  const taxCost = useWatch({ name: 'taxCost', control })
  const shippingCost = useWatch({ name: 'shippingCost', control })
  const machineInvolved = useWatch({ name: 'machineInvolved', control })
  const shippingAmount = shippingCost ? MoneyM.fromDecimal(shippingCost, 'USD') : null
  const taxAmount = taxCost ? MoneyM.fromDecimal(taxCost, 'USD') : null

  const { totals } = useQuoteTotals({
    items: itemsValues,
    taxAmount,
    shippingAmount,
    customerDiscount: 0,
    customerFeeRate: null,
  })

  const onFormSubmit = async (values) => {
    try {
      const result = await logExternalOrder({
        variables: {
          vendorId: values.vendorId,
          items: values.items,
          purchaseOrderNumber: values.purchaseOrderNumber,
          workOrderNumber: values.workOrderNumber,
          shippingCost: values.shippingCost,
          taxCost: values.taxCost,
          orgMachineId: values.machineInvolved ? values.orgMachineId : null,
          locationId: values.locationId,
        },
      })

      const storeOrderId = result.data?.logExternalOrder as string

      navigate(`/orders/${storeOrderId}`)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Log Order" previousPage="/orders">
        <CreateVendorModal
          open={vendorModalOpen}
          onClose={vendorModalToggle.off}
          onVendorAdded={(vendorId) => setValue('vendorId', vendorId)}
        />

        <AddAccountMachineModal
          open={machineModalOpen}
          onClose={machineModalToggle.off}
          onAdd={(accountMachine) => {
            if (accountMachine) {
              setValue('orgMachineId', accountMachine.id)
            }
          }}
          accountId={organization.id}
        />

        <LocationModal
          open={locationModalOpen}
          onClose={locationModalToggle.off}
          onComplete={(locationId) => setValue('locationId', locationId)}
          branches={(organization.requireBillingCompany ? branches : []) ?? []}
          buyers={[]}
          initialAddress={Address.init()}
          refetchQueries={[LocationsQuery]}
          showPersist
        />

        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className="flex flex-col sm:flex-row gap-x-6 mt-4 max-w-[66.5rem]"
        >
          <Box className="shadow-base flex-grow p-6 flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2 pl-4">
              <h4 className="text-lg font-medium">Vendor</h4>
              <Controller
                name="vendorId"
                control={control}
                render={({ field }) => (
                  <Field error={formState.errors?.vendorId?.message} className="">
                    <div className="flex items-center gap-x-4">
                      <ExternalVendors
                        value={field.value}
                        onChange={(vendorId) => field.onChange(vendorId)}
                      />
                      <Action.T onClick={vendorModalToggle.on}>Add Vendor</Action.T>
                    </div>
                  </Field>
                )}
              />
            </div>

            <div className="flex flex-col gap-y-2 pl-4">
              <h4 className="text-lg font-medium">Location</h4>
              <Controller
                name="locationId"
                control={control}
                render={({ field }) => (
                  <Field error={formState.errors?.locationId?.message} className="">
                    <div className="flex items-center gap-x-4">
                      <LocationSelector value={field.value} onChange={field.onChange} />
                      <Action.T onClick={locationModalToggle.on}>Add Location</Action.T>
                    </div>
                  </Field>
                )}
              />
            </div>

            <div className="flex flex-col gap-y-2 pl-4">
              <h4 className="text-lg font-medium">Machine</h4>
              <Controller
                name="machineInvolved"
                control={control}
                render={({ field }) => (
                  <label className="flex items-center gap-x-2 py-2 text-sm">
                    <Checkbox
                      checked={!field.value}
                      onChange={(e) => field.onChange(!e.target.checked)}
                    />
                    No machine involved
                  </label>
                )}
              />

              {machineInvolved && (
                <Controller
                  name="orgMachineId"
                  control={control}
                  render={({ field }) => (
                    <Field>
                      <div className="flex items-center gap-x-4">
                        <div className="w-full max-w-xs">
                          <MachineSelect value={field.value} onChange={field.onChange} />
                        </div>

                        <Action.T onClick={machineModalToggle.on}>Add Machine</Action.T>
                      </div>
                    </Field>
                  )}
                />
              )}
            </div>

            {itemsForm.fields.map((item, i) => (
              <div key={item.id} className="bg-gray-50 rounded-xl p-4 flex flex-col gap-y-3">
                <div className="flex justify-between">
                  <h4 className="text-lg font-medium">
                    Part {itemsValues.length > 1 ? i + 1 : null}
                  </h4>
                  {itemsValues.length > 1 && itemsValues[i] && (
                    <Action.T onClick={() => itemsForm.remove(i)}>Remove</Action.T>
                  )}
                </div>

                <div className="flex gap-x-2">
                  <Field
                    label="Part Number"
                    error={formState.errors?.items?.[i]?.partNumber?.message}
                    className="w-1/3"
                  >
                    <TextInput {...register(`items.${i}.partNumber`)} placeholder="ie. ES3897B" />
                  </Field>

                  <Field
                    label="Quantity"
                    error={formState.errors?.items?.[i]?.quantity?.message}
                    className="w-1/3"
                  >
                    <TextInput
                      type="number"
                      step={1}
                      min={1}
                      {...register(`items.${i}.quantity`, { valueAsNumber: true })}
                      placeholder="0"
                    />
                  </Field>

                  <Controller
                    control={control}
                    name={`items.${i}.unitPrice`}
                    render={({ field, fieldState }) => (
                      <Field label="Unit Price" error={fieldState.error?.message} className="w-1/3">
                        <PriceV2
                          price={field.value ? MoneyM.fromDecimal(field.value, 'USD') : undefined}
                          onChange={(value) =>
                            field.onChange(value ? MoneyM.toDecimal(value) : null)
                          }
                          ref={field.ref}
                        />
                      </Field>
                    )}
                  />
                </div>

                <Field
                  label="Part Description"
                  error={formState.errors?.items?.[i]?.description?.message}
                >
                  <TextInput
                    {...register(`items.${i}.description`)}
                    placeholder="ie. Air Filter, Fuel Pump"
                  />
                </Field>
              </div>
            ))}
            <div>
              <Action.S onClick={() => itemsForm.append(newLineItem())}>
                <PlusIcon className="inline-block h-5 w-5 text-gray-700 -mt-1" /> Add another part
              </Action.S>
            </div>
          </Box>

          <div className="relative">
            <div className="w-80 flex flex-col gap-y-4 sticky top-4">
              <Box className="shadow-base p-6 space-y-4">
                <Field
                  label={`PO number${
                    !organization.generatePurchaseOrderNumber ? ' (optional)' : ''
                  }`}
                >
                  {featureFlags.poNumberGenerator && organization.generatePurchaseOrderNumber ? (
                    <div className="text-sm block">
                      <div className="flex gap-x-3">
                        <p className="italic text-gray-500 flex-grow">Auto-Generated</p>

                        <Link.T to="/settings/organization#approval-settings">Edit</Link.T>
                      </div>
                    </div>
                  ) : (
                    <TextInput {...register('purchaseOrderNumber')} />
                  )}
                </Field>

                <Field label="Work Order number (optional)">
                  <TextInput {...register('workOrderNumber')} />
                </Field>
              </Box>

              <Box className="shadow-base p-6 space-y-4">
                <Controller
                  name="shippingCost"
                  control={control}
                  render={({ field }) => (
                    <Field
                      label="Shipping cost (optional)"
                      error={formState.errors?.shippingCost?.message}
                      className="mt-4"
                    >
                      <PriceV2
                        ref={field.ref}
                        price={field.value !== null ? MoneyM.fromDecimal(field.value, 'USD') : null}
                        onChange={(value) => field.onChange(value ? MoneyM.toDecimal(value) : null)}
                      />
                    </Field>
                  )}
                />

                <Controller
                  name="taxCost"
                  control={control}
                  render={({ field }) => (
                    <Field label="Sales tax (optional)">
                      <PriceV2
                        ref={field.ref}
                        price={field.value !== null ? MoneyM.fromDecimal(field.value, 'USD') : null}
                        onChange={(value) => field.onChange(value ? MoneyM.toDecimal(value) : null)}
                      />
                    </Field>
                  )}
                />

                <Summary
                  total={totals.total}
                  subtotal={totals.subtotal}
                  discount={totals.discount}
                  discountPercent={null}
                  shippingCost={shippingAmount}
                  taxCost={taxAmount ?? zeroPrice}
                  customerFee={totals.customerFee}
                  refunded={MoneyM.fromInt(0, 'USD')}
                  showShippingCost
                />
              </Box>

              <Action.P type="submit" className="w-full">
                Save Order
              </Action.P>
            </div>
          </div>
        </form>
      </Page>
    </Frame>
  )
}

export default CreateExternalOrder
