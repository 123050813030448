import classNames from 'classnames'
import { DateTime } from 'luxon'
import { ReactNode, useState } from 'react'

import {
  AccountMachine,
  Address as AddressT,
  Branch,
  Organization,
  PartRequest,
  Vendor,
  VendorContact,
} from '../../types'

import useMsgs from '../hooks/useMsgs'
import useUppy from '../hooks/useUppy'

import AddressM from '../modules/Address'
import AttachmentM from '../modules/Attachment'
import PhoneM from '../modules/Phone'

import { DeliveryMethod } from '@/buyers/_gen/gql'
import logo from '@/retail/components/Layout/logo.png'
import { DeliveryType } from '@/suppliers/_gen/gql'
import Action from './Action'
import Address from './Address'
import AddressModalForm from './AddressModalForm'
import Field from './Field'
import ModalForm from './ModalForm'
import SpinnerSmall from './SpinnerSmall'
import TextArea from './TextArea'
import TextField from './TextField'
import UppyFileInput from './UppyFileInput'
import YellowAlert from './YellowAlert'

const Wrapper = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div
    className={classNames(
      'px-4 py-6 w-full max-w-2xl flex flex-col grow self-center gap-y-4 border border-gray-400 rounded-sm shadow-lg overflow-x-scroll',
      className
    )}
  >
    {children}
  </div>
)

const OrganizationLogo = ({ url }: { url: string }) => (
  <img className="block max-w-1/4 h-auto max-h-12 object-contain" src={url} alt="Logo" />
)

const VendorRequestEmail = ({
  readonly,
  showEmailCopy,
  customerName,
  customerPhoneNumber,
  customerEmail,
  organization,
  branch,
  vendor,
  vendorContacts,
  machineDown,
  requestTimestamp,
  neededBy,
  shippingAddress,
  deliveryMethod,
  accountNumber,
  machine,
  partRequests,
  partRequestFileUrl,
  additionalDetails,
  imageUrls,
  organizationLogoUrl,
  onOrganizationLogoUrlChange,
  customerNote,
  onCustomerNoteChange,
  onOrganizationNameChange,
  onBillingAddressChange,
  onBranchBillingAddressChange,
}: {
  readonly: boolean
  showEmailCopy: boolean
  customerName: string | null
  customerPhoneNumber?: string
  customerEmail: string | null
  organization: Pick<Organization, 'name' | 'billingAddress'> | null
  branch: Pick<Branch, 'id' | 'billingAddress' | 'name'> | null
  vendor: Pick<Vendor, 'name'> | null
  vendorContacts?: Omit<VendorContact, 'preferred' | 'phoneNumber'>[]
  machineDown: boolean | null
  requestTimestamp: DateTime
  neededBy: DateTime | null
  shippingAddress: AddressT | null
  deliveryMethod?: DeliveryMethod | DeliveryType
  accountNumber: string
  machine: Pick<AccountMachine, 'name' | 'serialNumber' | 'make' | 'model' | 'year'> | null
  partRequests: Pick<PartRequest, 'mpn' | 'description' | 'quantity'>[]
  partRequestFileUrl?: string | null
  additionalDetails: string
  imageUrls: string[]
  organizationLogoUrl: string | undefined | null
  onOrganizationLogoUrlChange?: (organizationLogoUrl: string) => Promise<unknown>
  customerNote?: string | null
  onCustomerNoteChange?: (customerNote: string) => void
  onOrganizationNameChange?: (organizationName: string) => Promise<unknown>
  onBillingAddressChange?: (billingAddress: AddressT) => Promise<unknown>
  onBranchBillingAddressChange?: (billingAddress: AddressT, branchId: string) => Promise<unknown>
}) => {
  const [_msgs, msgsMgr] = useMsgs()
  const [showSpinner, setShowSpinner] = useState(false)
  const [showOrganizationNameModal, setShowOrganizationNameModal] = useState(false)
  const [editedOrganizationName, setEditedOrganizationName] = useState(organization?.name ?? '')
  const [showBillingAddressModal, setShowBillingAddressModal] = useState(false)
  const [addingOrganizationLogo, setAddingOrganizationLogo] = useState(false)
  const [editOrganizationLogo, setEditOrganizationLogo] = useState(false)
  const allowedFileTypes = ['image/*', '.jpg', '.jpeg', '.png']
  const uppy = useUppy({
    allowedFileTypes,
    maxNumberOfFiles: 1,
    onFilesAdded: () => setAddingOrganizationLogo(true),
    onComplete: ([file]) => {
      if (onOrganizationLogoUrlChange)
        onOrganizationLogoUrlChange(file.url).finally(() => {
          setAddingOrganizationLogo(false)
          setEditOrganizationLogo(false)
        })
      else setAddingOrganizationLogo(false)
    },
  })
  const exampleCustomerNote = `Hey ${
    vendorContacts?.[0]?.name ?? ''
  }, I'm using Gearflow to organize my parts orders. Give me a call if you have any questions about this request.`
  const billingAddress = branch?.billingAddress ?? organization?.billingAddress
  return (
    <>
      {readonly ? (
        organizationLogoUrl && (
          <div className="flex justify-end">
            <OrganizationLogo url={organizationLogoUrl} />
          </div>
        )
      ) : (
        <div className="flex justify-end flex-wrap">
          {typeof organizationLogoUrl === 'undefined' || addingOrganizationLogo ? (
            <div className="w-1/4">
              <SpinnerSmall />
            </div>
          ) : organizationLogoUrl && !editOrganizationLogo ? (
            <>
              <Action.T className="mr-4 text-sm" onClick={() => setEditOrganizationLogo(true)}>
                Change logo
              </Action.T>
              <OrganizationLogo url={organizationLogoUrl} />
            </>
          ) : (
            <>
              {organizationLogoUrl && (
                <Action.T className="mr-4 text-sm" onClick={() => setEditOrganizationLogo(false)}>
                  Cancel
                </Action.T>
              )}
              <Field label="Add Your Logo">
                <UppyFileInput
                  uppy={uppy}
                  allowedFileTypes={allowedFileTypes}
                  onFileInputError={(error) => msgsMgr.add(error, 'negative')}
                />
              </Field>
            </>
          )}
        </div>
      )}
      <div className="text-center">
        <h1 className="text-2xl font-medium text-gray-700">You Have A New Request!</h1>
        <div className="pt-1">Request #</div>
        <div className="pt-2 pb-4">
          {customerName} from {organization?.name} sent you a request.
        </div>
        <div className="flex pt-4 w-full pb-4">
          <div className="flex items-center w-full">
            <img
              alt="progress"
              className="mx-auto"
              src="https://mcusercontent.com/61f8da7602aa2107c25b65dcc/images/3148caa2-9ff8-3306-958c-80223b0f82bb.png"
              style={{ maxWidth: '360px', height: 'auto' }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-center items-center font-medium text-gray-700 border-t pb-0 pt-4" />
      {readonly ? (
        customerNote && (
          <p className="hidden pb-4 text-sm italic whitespace-pre-wrap">{customerNote}</p>
        )
      ) : (
        <div className="hidden relative max-w-prose">
          {!customerNote && (
            <Action.T
              className="absolute -top-5 sm:top-1 right-2 text-sm"
              onClick={() => onCustomerNoteChange && onCustomerNoteChange(exampleCustomerNote)}
            >
              Use example
            </Action.T>
          )}
          <TextArea
            className="h-[6.5rem] min-h-[6.5rem]"
            placeholder={`(optional) Add a personal message to ${
              vendor?.name ? vendor.name : 'your Vendor'
            }.\n\nExample: ${exampleCustomerNote}`}
            value={customerNote ?? ''}
            onChange={(e) => onCustomerNoteChange && onCustomerNoteChange(e.target.value)}
          />
        </div>
      )}
      {showEmailCopy && (
        <div className="hidden text-sm text-gray-500">
          {customerName} from {organization?.name} sent you a Quote Request through Gearflow.
          <br />
          After reading the request details, click{' '}
          <span className="text-gray-700 underline">here</span> to send a Quote.
        </div>
      )}
      <div className="grid grid-cols-2 gap-4">
        {/* Vendor */}
        <div className="text-sm text-gray-500">
          <p className="font-medium text-gray-700">Vendor</p>
          <p>{vendor?.name}</p>
          {vendorContacts && vendorContacts.length > 0 && (
            <p>
              <span className="text-gray-700">Contact name: </span> {vendorContacts[0].name}
            </p>
          )}
        </div>
        {/* Request Status */}
        <div className="text-sm text-gray-500">
          <p className="font-medium text-gray-700">Request Status</p>
          <p>
            <span className="text-gray-700">Requested date:</span>{' '}
            {requestTimestamp.setZone('GMT').toFormat("M/d/yy h:mma 'GMT'")}
          </p>
          <p>
            <span className="text-gray-700">Needed by:</span>{' '}
            {neededBy ? neededBy.setZone('GMT').toFormat("M/d/yy h:mma 'GMT'") : 'Unspecified'}
          </p>
          <p>
            <span className="text-gray-700">Machine down:</span> {machineDown ? 'Yes' : 'No'}
          </p>
        </div>
      </div>
      {/* Request From */}
      <div className="text-sm text-gray-500">
        <div className="flex flex-row space-x-3">
          <p className="font-medium text-gray-700">Request From</p>
          {!readonly && (
            <Action.T onClick={() => setShowOrganizationNameModal(true)}>Change</Action.T>
          )}
        </div>
        <p>{organization?.name}</p>
        <p>
          <span className="text-gray-700">Account number:</span> {accountNumber}
        </p>
        <p>
          <span className="text-gray-700">Requested by:</span> {customerName}
        </p>
        <p>
          <span className="text-gray-700">Phone:</span>{' '}
          {customerPhoneNumber && PhoneM.format(customerPhoneNumber)}
        </p>
        <p>
          <span className="text-gray-700">Email:</span> {customerEmail}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {/* Bill to */}
        <div className="text-sm text-gray-500">
          <div className="flex flex-row space-x-3">
            <p className="font-medium text-gray-700">Bill To</p>
            {!readonly && (
              <Action.T onClick={() => setShowBillingAddressModal(true)}>Change</Action.T>
            )}
          </div>
          {billingAddress ? (
            <Address address={billingAddress} />
          ) : shippingAddress ? (
            <Address address={shippingAddress} />
          ) : null}
        </div>
        {/* Ship to */}
        <div className="text-sm text-gray-500">
          <p className="font-medium text-gray-700">
            {deliveryMethod === DeliveryMethod.VendorDelivery
              ? 'Delivery To'
              : deliveryMethod === DeliveryMethod.Pickup
              ? 'Will Call'
              : 'Ship To'}
          </p>
          {deliveryMethod === DeliveryMethod.Pickup ? (
            'In Store Pickup'
          ) : shippingAddress ? (
            <Address address={shippingAddress} />
          ) : null}
        </div>
      </div>
      {/* Fitment for Machine */}
      <div className="text-sm text-gray-500">
        <p className="font-medium text-gray-700">Fitment for Machine</p>
        {machine ? (
          <table className="w-full table-auto border-spacing-x-2">
            <thead className="text-gray-700 whitespace-nowrap text-left">
              <tr>
                <th className="pr-1.5 py-0.5">Unit Number</th>
                <th className="px-1.5 py-0.5">Serial Number</th>
                <th className="px-1.5 py-0.5">Make</th>
                <th className="px-1.5 py-0.5">Model</th>
                <th className="pl-1.5 py-0.5 text-right">Year</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pr-1.5 py-0.5">{machine.name}</td>
                <td className="px-1.5 py-0.5">{machine.serialNumber}</td>
                <td className="px-1.5 py-0.5">{machine.make}</td>
                <td className="px-1.5 py-0.5">{machine.model}</td>
                <td className="pl-1.5 py-0.5 text-right">{machine.year}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          'N/A (stock order request)'
        )}
      </div>
      {/* Parts Requested */}
      <div className="text-sm text-gray-500">
        <p className="font-medium text-gray-700">Parts Requested</p>
        {partRequestFileUrl ? (
          <p>[The email will list your parts from {AttachmentM.nameFromUrl(partRequestFileUrl)}]</p>
        ) : (
          <table className="w-full table-auto">
            <thead className="text-gray-700 whitespace-nowrap text-left">
              <tr>
                <th className="pr-1.5 py-0.5">Part #</th>
                <th className="px-1.5 py-0.5">Description</th>
                <th className="pl-1.5 py-0.5 text-right">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {partRequests.map((partRequest) => (
                <tr key={`${partRequest.mpn}-${partRequest.quantity}`}>
                  <td className="pr-1.5 py-0.5">{partRequest.mpn}</td>
                  <td className="px-1.5 py-0.5">{partRequest.description}</td>
                  <td className="pl-1.5 py-0.5 text-right">{partRequest.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {/* Additional details */}
      <div className="text-sm text-gray-500">
        <p className="font-medium text-gray-700">Additional Details</p>
        <p>{additionalDetails || 'N/A'}</p>
      </div>
      {/* Images */}
      <div className="flex flex-col items-start text-sm text-gray-500">
        <p className="font-medium text-gray-700">Images</p>
        {imageUrls.length > 0 ? (
          imageUrls.map((imageUrl) => (
            <a
              key={imageUrl}
              href={imageUrl}
              target="_blank"
              rel="noreferrer"
              className="flex max-w-xs w-full"
            >
              <img src={imageUrl} alt={imageUrl} />
            </a>
          ))
        ) : (
          <p>N/A</p>
        )}
      </div>
      {/* What is Gearflow Info */}
      <div className="mt-4 text-sm text-gray-500">
        <div className="flex flex-row justify-center items-center font-medium text-gray-700 border-t py-4">
          <span>Powered by</span>
          <img className="ml-1.5 inline w-auto h-3" src={logo} alt="Gearflow" />
        </div>
        <div className="px-3 py-2 text-sm text-gray-500 rounded">
          <p className="font-medium text-gray-700">Why is this request coming from Gearflow?</p>
          <p>{customerName} is using Gearflow to organize their parts orders and track spending.</p>
        </div>
        <div className="px-3 py-2 text-sm text-gray-500 rounded">
          <p className="font-medium text-gray-700">What is Gearflow?</p>
          <p>
            Gearflow is software built to make it easier for contractors to work with their vendors
            on parts.
          </p>
        </div>
        <div className="px-3 py-2 text-sm text-gray-500 rounded">
          <p className="font-medium text-gray-700">What happens next?</p>
          <p>If {organization?.name} approves your quote, you will automatically receive a PO.</p>
        </div>
        <div className="px-3 py-2 text-sm text-gray-500 rounded">
          <p className="font-medium text-gray-700">What steps are required of me?</p>
          <p>
            We ask for 2 things - indicate price and availability on the parts being requested and
            to indicate when the order is ready for pickup or has shipped.
          </p>
        </div>
      </div>
      {/* Questions Text */}
      {showEmailCopy && (
        <div className="text-center text-xs text-gray-500">
          <span className="text-gray-700">Questions?</span>
          <br />
          Contact gearflow.com at orders@gearflow.com
          <br />
          or call at +1-800-554-3802
        </div>
      )}
      {/* Edit Organization Name Modal */}
      {!readonly && (
        <ModalForm
          title="Change Request From"
          submitButtonText="Save"
          open={showOrganizationNameModal}
          onClose={() => setShowOrganizationNameModal(false)}
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (onOrganizationNameChange) {
              setShowSpinner(true)
              onOrganizationNameChange(editedOrganizationName).finally(() => {
                setShowOrganizationNameModal(false)
                setShowSpinner(false)
              })
            }
          }}
          submitButtonShowSpinner={showSpinner}
          submitButtonDisabled={showSpinner}
        >
          <div className="mt-4">
            <TextField
              required
              label="Company Name"
              placeholder="Company Name"
              value={editedOrganizationName}
              onChange={(e) => setEditedOrganizationName(e.target.value)}
            />
          </div>
        </ModalForm>
      )}
      {/* Edit Billing Address Modal */}
      {!readonly && !!onBillingAddressChange && !!onBranchBillingAddressChange && (
        <AddressModalForm
          title={`Change Billing Address${branch ? ` (${branch.name})` : ''}`}
          hideFirstLastName
          initialAddress={
            billingAddress ?? { ...AddressM.init(), companyName: organization?.name ?? '' }
          }
          open={showBillingAddressModal}
          onClose={() => setShowBillingAddressModal(false)}
          onSubmit={(editedBillingAddress) => {
            setShowSpinner(true)
            const promise = branch
              ? onBranchBillingAddressChange(editedBillingAddress, branch.id)
              : onBillingAddressChange(editedBillingAddress)
            promise
              .catch(() =>
                msgsMgr.add(
                  'Error updating the billing address. Please contact support.',
                  'negative'
                )
              )
              .finally(() => {
                setShowBillingAddressModal(false)
                setShowSpinner(false)
              })
          }}
          submitButtonShowSpinner={showSpinner}
          submitButtonDisabled={showSpinner}
          details={
            branch ? (
              <YellowAlert
                title={`This will update the Billing Address of your Branch '${branch.name}'`}
              />
            ) : undefined
          }
        />
      )}
    </>
  )
}

VendorRequestEmail.Wrapper = Wrapper
export default VendorRequestEmail
