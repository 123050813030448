import ChangePassword from '../../components/ChangePassword'
import UpdateProfile from '../../components/UpdateProfile'

const Profile = () => (
  <div className="space-y-4 max-w-lg">
    <UpdateProfile />
    <ChangePassword />
  </div>
)

export default Profile
