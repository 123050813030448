import { useEffect, useState } from 'react'
import ReactSelect from 'react-select'

import ModalForm from './ModalForm'
import SpinnerSmall from './SpinnerSmall'
import Spinner from './Spinner'

import { AutocompleteOption, User } from '../../types'

const userToOption = (user: Pick<User, 'id' | 'name'>): AutocompleteOption => ({
  value: user.id,
  label: user.name || '(unknown)',
})

const SelectUsersModal = ({
  open,
  onClose,
  onSubmit,
  spinnerLive,
  users,
  usersLoading,
  usersError,
  initialSelectedUsers,
  titleText,
  detailsText,
}: {
  open: boolean
  onClose: () => void
  onSubmit: (userIds: string[]) => void
  spinnerLive: boolean
  users?: Pick<User, 'id' | 'name'>[]
  usersLoading: boolean
  usersError: boolean
  initialSelectedUsers: Pick<User, 'id' | 'name'>[]
  titleText: string
  detailsText?: string
}) => {
  const [selectedUsers, setSelectedUsers] = useState(initialSelectedUsers?.map(userToOption))

  useEffect(() => {
    setSelectedUsers(initialSelectedUsers?.map(userToOption))
  }, [initialSelectedUsers])

  return (
    <ModalForm
      title={titleText}
      open={open}
      onClose={onClose}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(selectedUsers.map((selectedUser) => selectedUser.value))
      }}
      submitButtonText="Save"
      submitButtonDisabled={spinnerLive}
    >
      {spinnerLive ? (
        <Spinner />
      ) : (
        <div>
          {usersError ? (
            <p className="text-sm text-gray-700">Failed to fetch users. Please contact support.</p>
          ) : usersLoading || !users ? (
            <SpinnerSmall />
          ) : (
            <div className="mt-2 space-y-2">
              {detailsText && <p className="text-sm text-gray-500">{detailsText}</p>}
              <ReactSelect
                classNamePrefix="fs"
                name="usersSelection"
                defaultValue={selectedUsers}
                options={users.map(userToOption)}
                onChange={(newItems) => setSelectedUsers(newItems.map((newItem) => newItem))}
                isSearchable
                isMulti
                isClearable
              />
            </div>
          )}
        </div>
      )}
    </ModalForm>
  )
}

export default SelectUsersModal
