import { gql, useMutation } from '@apollo/client'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useFetchOrganizationUsersQuery, useStoreOrderDetailsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import useUpdateStoreOrderBuyerNotificationUsers from '@/buyers/hooks/useUpdateStoreOrderBuyerNotificationUsers'
import useConfig from '@/gf/hooks/useConfig'
import useMsgs from '@/gf/hooks/useMsgs'
import useTimelineEvents, { TIMELINE_QUERY } from '@/gf/hooks/useTimelineEvents'
import useToggle from '@/gf/hooks/useToggle'
import RfqEventM from '@/gf/modules/RfqEvent'
import StoreOrderEventM from '@/gf/modules/StoreOrderEvent'
import Time from '@/gf/modules/Time'
import UserContext from '@/gf/modules/User'

import A from '@/gf/components/A'
import Button from '@/gf/components/ButtonOld'
import Card from '@/gf/components/Card'
import Layout from '@/gf/components/Layout'
import LinkButton from '@/gf/components/LinkButtonOld'
import SelectUsersModal from '@/gf/components/SelectUsersModal'
import StoreOrderEvents from '@/gf/components/StoreOrderEvents'
import TextArea from '@/gf/components/TextArea'
import { List, Row } from '../Order/List'

const Details = ({ refetchStoreOrder }: { refetchStoreOrder: () => void }) => {
  const config = useConfig()
  const navigate = useNavigate()
  const { user } = useSession()
  const { storeOrderId } = useParams<{ storeOrderId: string }>() as { storeOrderId: string }
  const [_msgs, msgsMgr] = useMsgs()
  const [notificationUsersModalOpen, notificationUsersModalToggler] = useToggle()
  const [spinnerLive, spinnerLiveToggle] = useToggle()
  const [commentText, setCommentText] = useState('')
  const eventsSearch = useTimelineEvents(storeOrderId).data?.eventsSearch
  const client = useGqlClient()
  const orgUsersResult = useFetchOrganizationUsersQuery({ client })

  const queryResult = useStoreOrderDetailsQuery({
    variables: { storeOrderFilter: JSON.stringify(['id_eq', storeOrderId]) },
    client,
  })

  const { storeOrder } = queryResult.data || {}

  const [updateStoreOrderBuyerNotificationUsers] = useUpdateStoreOrderBuyerNotificationUsers([
    TIMELINE_QUERY,
  ])

  const [addStoreOrderTimelineNote] = useMutation(
    gql`
      mutation AddStoreOrderTimelineNote($storeOrderId: String, $note: String, $privacy: Privacy) {
        addStoreOrderTimelineNote(storeOrderId: $storeOrderId, note: $note, privacy: $privacy)
      }
    `,
    { refetchQueries: [TIMELINE_QUERY] }
  )

  if (!storeOrder) return null

  const addTimelineNote = (privacy: 'BUYER_PROTECTED' | 'PUBLIC' | 'PRIVATE') => {
    spinnerLiveToggle.on()

    addStoreOrderTimelineNote({
      variables: {
        storeOrderId,
        note: commentText,
        privacy,
      },
    })
      .then(() => {
        msgsMgr.add('Added Comment', 'positive')
        setCommentText('')
      })
      .catch(() => {
        msgsMgr.add('Error adding comment', 'negative')
      })
      .finally(() => {
        spinnerLiveToggle.off()
      })
  }

  const events = eventsSearch?.storeOrderEvents
    .map(StoreOrderEventM.getBuyerEventDisplayData)
    .concat(eventsSearch?.rfqEvents.map(RfqEventM.getEventDisplayData(true, config)))
    .sort((a, b) => Time.sortDesc(a.datetime, b.datetime))

  return (
    <Layout marginTop={false}>
      <Layout.Section type="ipad" className="xl:col-span-4">
        <>
          {storeOrder.features.storeOrderTimelineV1 && (
            <Card title="Timeline">
              <Card.Section>
                <Card.SubSection>
                  <form className="space-y-2">
                    <TextArea
                      placeholder="Leave a comment..."
                      required
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                    />

                    <div className="flex justify-between items-start">
                      <Button
                        title="Post"
                        disabled={!commentText || spinnerLive}
                        performing={spinnerLive}
                        onClick={() =>
                          addTimelineNote(UserContext.isAdmin(user) ? 'PRIVATE' : 'BUYER_PROTECTED')
                        }
                      />

                      <div className="text-sm text-gray-500 space-x-2 space-y-0.5 text-right">
                        <p>Only you and your team can see comments</p>

                        <p>
                          To contact the vendor or Gearflow, go to the{' '}
                          <LinkButton onClick={() => navigate(`/orders/${storeOrderId}/messages`)}>
                            messages tab
                          </LinkButton>
                        </p>
                      </div>
                    </div>
                  </form>
                </Card.SubSection>

                <Card.SubSection>
                  <StoreOrderEvents events={events} state={storeOrder.state} />
                </Card.SubSection>
              </Card.Section>
            </Card>
          )}
        </>
      </Layout.Section>

      <Layout.Section type="ipad" className="xl:col-span-2">
        {storeOrder.fleetioPurchaseOrder && (
          <Card title="Info">
            <Card.Section>
              <List title={{ className: 'sm:col-span-2' }} desc={{ className: 'sm:col-span-1' }}>
                {storeOrder.order.user && (
                  <Row>
                    Created by
                    {storeOrder.order?.user?.name}
                  </Row>
                )}

                <Row>
                  Fleetio PO
                  <A.T
                    href={storeOrder.fleetioPurchaseOrder.url}
                    className="flex gap-x-2 items-center"
                    target="_blank"
                  >
                    <span>#{storeOrder.fleetioPurchaseOrder.number}</span>
                    <ExternalLinkIcon className="w-5 h-5" />
                  </A.T>
                </Row>
              </List>
            </Card.Section>
          </Card>
        )}

        <Card
          title="Notifications"
          headerButton={
            <button
              className="text-sm text-blue-500 underline cursor-pointer"
              type="button"
              onClick={notificationUsersModalToggler.on}
            >
              Edit
            </button>
          }
        >
          <Card.Section>
            {storeOrder.buyerNotificationUsers.length === 0 ? (
              <p className="text-sm text-gray-700">
                None. Click &apos;Edit&apos; to subscribe/unsubscribe users to/from notifications on
                this order.
              </p>
            ) : (
              storeOrder.buyerNotificationUsers.map((u) => (
                <p key={u.id} className="text-sm text-gray-700">
                  {u.name}
                </p>
              ))
            )}
          </Card.Section>
        </Card>
      </Layout.Section>
      <SelectUsersModal
        titleText="Update Notification Recipients"
        detailsText="Select the people who receive notifications for this order."
        open={notificationUsersModalOpen}
        onClose={notificationUsersModalToggler.off}
        onSubmit={(userIds) => {
          spinnerLiveToggle.on()
          updateStoreOrderBuyerNotificationUsers({
            variables: { storeOrderId, userIds },
          })
            .then(() => {
              queryResult.refetch()
              refetchStoreOrder()
            })
            .catch(() => {
              msgsMgr.add('Whoops, something went wrong. Please contact support.', 'negative')
            })
            .finally(() => {
              notificationUsersModalToggler.off()
              spinnerLiveToggle.off()
            })
        }}
        spinnerLive={spinnerLive}
        users={orgUsersResult.data?.fetchOrganizationUsers}
        usersLoading={orgUsersResult.loading}
        usersError={!!orgUsersResult.error}
        initialSelectedUsers={storeOrder.buyerNotificationUsers}
      />
    </Layout>
  )
}

export default Details
