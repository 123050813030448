import { useState } from 'react'
import AnimateShowIn from '@/gf/components/AnimateShowIn'
import { OrgMachineDetails } from '@/gf/hooks/useOrgMachineDetails'
import OrgMachineForm from './OrgMachineForm'
import OrgMachineView from './OrgMachineView'
import OrgMachineViewGhost from './OrgMachineViewGhost'

const Sidebar = ({ machineDetails }: { machineDetails: OrgMachineDetails }) => {
  const [editing, setEditing] = useState(false)

  const onEditClick = () => setEditing(true)
  const onAfterSave = () => setEditing(false)
  const onCancel = () => setEditing(false)

  if (machineDetails.loading) {
    return <OrgMachineViewGhost />
  }

  return (
    <>
      <AnimateShowIn visible={editing}>
        <OrgMachineForm
          machineDetails={machineDetails}
          onAfterSave={onAfterSave}
          onCancel={onCancel}
        />
      </AnimateShowIn>

      <AnimateShowIn visible={!editing}>
        <OrgMachineView machineDetails={machineDetails} onEditClick={onEditClick} />
      </AnimateShowIn>
    </>
  )
}

export default Sidebar
