import { UsersSettingsQuery, useUsersSettingsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'

import Plan from '@/buyers/pages/Settings/Billing/Plan'
import Card from '@/gf/components/Card'
import Link from '@/gf/components/LinkOld'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import PendingInvitesTable from './Users/PendingInvitesTable'
import UsersTable from './Users/Table'

export type User = UsersSettingsQuery['users'][number]

const Users = () => {
  const { organization } = useSession()
  const orgId = organization.id
  const queryResult = useUsersSettingsQuery({ variables: { orgId }, client: useGqlClient() })

  const org = queryResult.data?.org

  if (!org) return null

  const users =
    queryResult.data &&
    [...queryResult.data.users]?.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))

  const activeUsers = users?.filter((u) => u.active)
  const inactiveUsers = users?.filter((u) => !u.active)

  const planSubscription = queryResult.data?.planSubscription
  const usedLicenses = activeUsers ? activeUsers.length : 0
  const licenses = planSubscription ? planSubscription.quantity + organization.freeLicenses : 0
  const showAddButton =
    planSubscription && planSubscription.plan.subscriptionType === 'php_fixed'
      ? licenses > usedLicenses
      : true

  const headerButton = showAddButton ? (
    <Link to="/settings/users/add/details">Add User</Link>
  ) : (
    <SimpleTooltip
      placement="bottom-start"
      text="You have reached your license limit, please contact sales."
      className="lg:max-w-56"
    >
      <button type="button" disabled className="text-gray-300 underline underline-offset-2">
        Add User
      </button>
    </SimpleTooltip>
  )

  return (
    <div className="space-y-4">
      <PendingInvitesTable showAddButton={showAddButton} />

      {queryResult.data?.planSubscription && (
        <Plan planSubscription={queryResult.data.planSubscription} />
      )}

      <Card title="Users" headerButton={headerButton}>
        <Card.Section>
          <UsersTable
            users={activeUsers}
            roles={org.roles}
            permissionSummaries={org.permissionSummaries}
            refetchUsers={queryResult.refetch}
          />
        </Card.Section>
      </Card>

      {inactiveUsers && inactiveUsers.length > 0 && (
        <Card title="Deactivated Users">
          <Card.Section>
            <div className="space-y-4">
              <p className="prose">These users do not have access to the organization.</p>

              <UsersTable
                users={inactiveUsers}
                roles={org.roles}
                permissionSummaries={org.permissionSummaries}
                refetchUsers={queryResult.refetch}
              />
            </div>
          </Card.Section>
        </Card>
      )}
    </div>
  )
}

export default Users
