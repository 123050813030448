import classNames from 'classnames'
import Checkbox, { CheckboxProps } from './Checkbox'

const SHIPPING = 'SHIPPING'
const PICKUP = 'PICKUP'
const VENDOR_DELIVERY = 'VENDOR_DELIVERY'

type Props = Omit<CheckboxProps, 'children' | 'ref'> & { deliveryMethod: string }

const InStockCheckbox = ({ deliveryMethod, className, ...props }: Props) => (
  <Checkbox {...props} className={classNames('whitespace-nowrap', className)} color="blue">
    <span className="text-sm text-gray-700">
      Available {deliveryMethod === SHIPPING ? 'to' : deliveryMethod === PICKUP ? 'at' : 'for'}{' '}
      <span className="text-gray-900 font-medium">
        {deliveryMethod === SHIPPING
          ? 'ship'
          : deliveryMethod === PICKUP
          ? 'will call'
          : deliveryMethod === VENDOR_DELIVERY
          ? 'delivery'
          : null}
      </span>{' '}
      today
    </span>
  </Checkbox>
)

export default InStockCheckbox
