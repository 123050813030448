import { Option } from './types'

const Option = ({
  selected,
  select,
  children,
}: {
  selected: boolean
  select: () => void
  children: React.ReactNode
}) => (
  <li
    onClick={select}
    onMouseDown={(e) => e.preventDefault()}
    role="option"
    aria-selected={selected}
    className={`cursor-default select-none px-3 py-2 hover:bg-indigo-50 ${
      selected ? 'bg-indigo-100' : ''
    }`}
  >
    {children}
  </li>
)

export default Option
