import { DeliveryMethod } from '@/buyers/_gen/gql'
import Box from '@/buyers/components/Box'
import { useOrderContext } from '../context'
import { Td, Th } from './Shipments'

const UnshippedItems = () => {
  const { storeOrder } = useOrderContext()

  if (
    storeOrder.deliveryMethod !== DeliveryMethod.Shipping ||
    !storeOrder.completedAt ||
    storeOrder.refunds.length === 0
  )
    return null

  const shippedQtyByLineItemId = storeOrder.shipments.reduce<Record<string, number>>(
    (acc, shipment) =>
      shipment.items.reduce(
        (subAcc, item) => ({
          ...subAcc,
          [item.lineItem.id]: (subAcc[item.lineItem.id] || 0) + item.quantity,
        }),
        acc
      ),
    {}
  )

  const unshippedItems = storeOrder.lineItems
    .map((li) => {
      const unshippedQty = li.quantity - (shippedQtyByLineItemId[li.id] || 0)
      return { ...li, unshippedQty }
    })
    .filter((li) => li.unshippedQty > 0)

  if (unshippedItems.length === 0) return null

  return (
    <Box className="space-y-4">
      <div className="font-medium text-lg text-gray-900">Unshipped Parts</div>

      <div className="prose">
        This order contains a refund and the these parts will not be shipped.
      </div>

      <div className="rounded-lg border overflow-hidden">
        <table className="divide-y-1 divide-gray-200 w-full">
          <thead className="bg-gray-50">
            <tr>
              <Th>Part number</Th>
              <Th>Description</Th>
              <Th className="text-center">Unshipped quantity</Th>
            </tr>
          </thead>

          <tbody className="divide-y-1 divide-gray-200">
            {unshippedItems.map((item) => (
              <tr key={item.id}>
                <Td>{item.product.mpn}</Td>
                <Td>{item.product.name}</Td>
                <Td className="text-center">{item.unshippedQty}</Td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  )
}

export default UnshippedItems
