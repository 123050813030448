import { useParams } from 'react-router-dom'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import { useShippingLocationsSearchQuery, useLocationQuery } from '@/buyers/_gen/gql'
import Page from '@/gf/components/Page'
import Frame from '../components/Frame'
import NotFound from './NotFound'
import DetailsPageWithSidebar from '@/gf/components/Layout/DetailsPageWithSidebar'
import Sidebar from './ShippingLocation/Sidebar'
import SingleMarkerMap from '@/gf/components/SingleMarkerMap'
import LocationPlaceholder from '@/gf/components/Location/LocationPlaceholder'
import Activity from './ShippingLocation/Activity'

const ShippingLocation = () => {
  const gqlClient = useGqlClient()
  const { locationId } = useParams<{ locationId: string }>() as { locationId: string }

  const { refetch, data, loading } = useShippingLocationsSearchQuery({
    client: gqlClient,
    variables: { page: 1, filters: [['equals', 'id', locationId]] },
  })

  const shippingLocation =
    data?.shippingLocationsSearch.shippingLocations &&
    data.shippingLocationsSearch.shippingLocations.length > 0
      ? data.shippingLocationsSearch.shippingLocations[0]
      : undefined

  const queryResult = useLocationQuery({
    variables: {
      rfqFilter: JSON.stringify(['shipping_address_id_eq', shippingLocation?.addressId]),
    },
    client: useGqlClient(),
    skip: !shippingLocation,
  })

  if (loading && !shippingLocation) return null

  if (!shippingLocation) return <NotFound />

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'Locations', href: '/locations' },
      { name: shippingLocation.name, href: `/locations/${locationId}` },
    ],
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Location Details">
        <DetailsPageWithSidebar
          sidebar={<Sidebar onDataChanged={() => refetch()} shippingLocation={shippingLocation} />}
        >
          <div className="bg-white border rounded-lg w-full h-full p-4 flex-grow mt-4 md:mt-0">
            {shippingLocation.address?.point ? (
              <SingleMarkerMap
                lat={shippingLocation.address.point.lat}
                lng={shippingLocation.address.point.lng}
              />
            ) : (
              <LocationPlaceholder text="Coordinates currently not available for this shipping location" />
            )}

            {shippingLocation.addressId && (
              <Activity
                shippingLocationAddressId={shippingLocation.addressId}
                rfqs={queryResult.data?.searchRequests.requests}
                requestsLoading={queryResult.loading}
              />
            )}
          </div>
        </DetailsPageWithSidebar>
      </Page>
    </Frame>
  )
}

export default ShippingLocation
