import {
  LocationsSelectDocument,
  LocationsSelectQuery,
  LocationsSelectQueryVariables,
  useAddUserToLocationsMutation,
  usePendingInvitesQuery,
  useUsersQuery,
} from '@/buyers/_gen/gql'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { useMatch, useSearchParams } from 'react-router-dom'
import useGqlClient from '../../../../hooks/useGqlClient'
import useSession from '../../../../hooks/useSession'

import ShippingLocationM from '@/gf/modules/ShippingLocation'

import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import Link from '@/gf/components/Link'
import AutocompleteMultiInputNext from '../../../../components/AutocompleteMultiInputNext'

const Success = () => {
  const { organization } = useSession()
  const client = useGqlClient()
  const userId = useMatch('/settings/users/add/success/:userId')?.params.userId
  const [locationIds, setLocationIds] = useState<string[]>([])
  const [addUserToLocations] = useAddUserToLocationsMutation({ client })
  const fetchPendingInvites = usePendingInvitesQuery({ client })
  const fetchUsersResult = useUsersQuery({ variables: { orgId: organization.id }, client })
  const [query, setQuery] = useSearchParams()
  const gqlClient = useGqlClient()

  useEffect(() => {
    fetchUsersResult.refetch()
    fetchPendingInvites.refetch()
  }, [])

  const addToLocations = () => {
    if (!userId) return

    addUserToLocations({ variables: { userId, locationIds } }).then(() => {
      setQuery({ added: 'true' })
    })
  }

  return (
    <Card>
      <Card.Section title="Success!">
        <div className="space-y-4">
          <div className="prose">
            User was added to your account. They&apos;ll receive an invite email.
          </div>
          <div className="flex gap-2">
            <Link.S to="/settings/users">View Users</Link.S>
            <Link.S to="/settings/users/add/details">Add Another User</Link.S>
          </div>
        </div>
      </Card.Section>
      {query.get('added') !== 'true' ? (
        <Card.Section title="Let's add this user to locations.">
          <div className="space-y-4">
            <div className="prose">This will notify the user of activity in these locations.</div>
            <AutocompleteMultiInputNext<
              LocationsSelectQuery,
              LocationsSelectQueryVariables,
              LocationsSelectQuery['locations'][number]
            >
              gqlClient={gqlClient}
              query={LocationsSelectDocument}
              queryVars={(search) => ({ value: search })}
              onChange={(shippingLocations) =>
                setLocationIds(shippingLocations.map((shippingLocation) => shippingLocation.id))
              }
              transformQueryToData={(queryData) =>
                queryData.locations.map((shippingLocation) => shippingLocation)
              }
              transformDatumToOption={(shippingLocation) => ({
                value: shippingLocation.id,
                label: ShippingLocationM.label(shippingLocation),
              })}
            />
            <Action.P onClick={addToLocations}>Add</Action.P>
          </div>
        </Card.Section>
      ) : (
        <Card.Section>
          <div className="flex gap-2 items-center text-xl">
            <CheckCircleIcon className="text-green-500 w-8 h-8" />
            <div>Set user locations.</div>
          </div>
        </Card.Section>
      )}
    </Card>
  )
}

export default Success
