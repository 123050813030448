import type { FeatureFlags as BuyersFeatureFlags } from '@/buyers/types'
import { ApolloClient } from '@apollo/client'
import type {
  Event,
  EventDisplayData,
  FeatureFlags,
  Filter as FilterT,
  Pagination,
  RfqEvent,
  StoreOrderEvent,
} from '../../types'

import notificationFields from '@/buyers/data/notificationFields'

import A from './A'
import EventIcon from './EventIcon'
import Filters from './Filters'
import Layout from './Layout'
import PaginationC from './Pagination'
import Spinner from './Spinner'
import { Table, Tbody, Td, Thead, Tr } from './Table'

import { useFetchOrganizationUsersQuery } from '@/buyers/_gen/gql'
import useFilters from '../hooks/useFilters'

import Filter from '../modules/Filter'
import Select from '../modules/Select'
import Time from '../modules/Time'

type PropsWithoutFields = Omit<
  Props,
  'page' | 'filters' | 'updatePage' | 'updateFilters' | 'pagination' | 'featureFlags'
>
const NotificationTableWithoutFilters = ({
  events,
  loading,
  dashboardUrl,
  getStoreOrderEventDisplayData,
  getRequestForQuoteEventDisplayData,
}: PropsWithoutFields) => {
  const cols = 4
  return (
    <Table>
      <Thead>
        <Tr>
          <Td>Date</Td>
          <Td>Domain</Td>
          <Td>Event</Td>
          <Td>Details</Td>
        </Tr>
      </Thead>
      <Tbody>
        {loading ? (
          <Tr>
            <Td colSpan={cols}>
              <Spinner />
            </Td>
          </Tr>
        ) : events.length === 0 ? (
          <Tr>
            <Td colSpan={cols}>No events found.</Td>
          </Tr>
        ) : (
          events.map((event) => {
            const eventDisplayData = event.storeOrderEvent
              ? getStoreOrderEventDisplayData(event.storeOrderEvent)
              : getRequestForQuoteEventDisplayData(event.requestForQuoteEvent)
            return (
              <Tr key={(event.storeOrderEvent ?? event.requestForQuoteEvent).id}>
                <Td>
                  <time dateTime={Time.formatSimpleDateTime(eventDisplayData.datetime)}>
                    {Time.formatSimpleDateTime(eventDisplayData.datetime)}
                  </time>
                </Td>
                <Td>
                  {event.storeOrderEvent ? (
                    <>
                      <A.T href={`${dashboardUrl}/orders/${event.storeOrderEvent.storeOrderId}`}>
                        Order {event.storeOrderEvent.storeOrderShortId}
                      </A.T>
                    </>
                  ) : (
                    <A.T
                      href={`${dashboardUrl}/rfqs/${event.requestForQuoteEvent.requestForQuoteId}`}
                    >
                      RFQ {event.requestForQuoteEvent.requestForQuoteShortId}
                    </A.T>
                  )}
                </Td>
                <Td>
                  <div className="relative flex items-center space-x-2">
                    <EventIcon eventDisplayDataIcon={eventDisplayData} />
                    <div className="flex flex-col items-start">
                      <p className="text-sm text-gray-500">{eventDisplayData.content}</p>
                      {eventDisplayData.subContent && (
                        <p className="text-sm text-gray-500">{eventDisplayData.subContent}</p>
                      )}
                    </div>
                  </div>
                </Td>
                <Td>
                  <div className="flex flex-col space-y-1">
                    {eventDisplayData.details && (
                      <div className="text-sm text-gray-500">{eventDisplayData.details}</div>
                    )}
                    {eventDisplayData.hiddenDetails && (
                      <div className="text-sm text-gray-500">{eventDisplayData.hiddenDetails}</div>
                    )}
                  </div>
                </Td>
              </Tr>
            )
          })
        )}
      </Tbody>
    </Table>
  )
}

interface Props {
  page: number
  filters: FilterT[]
  updatePage: (newPage: number) => void
  updateFilters: (newFilters: FilterT[]) => void
  events: Event[]
  pagination: Pagination
  loading: boolean
  featureFlags: FeatureFlags | BuyersFeatureFlags
  dashboardUrl: string
  getStoreOrderEventDisplayData: (storeOrderEvent: StoreOrderEvent) => EventDisplayData
  getRequestForQuoteEventDisplayData: (requestForQuoteEvent: RfqEvent) => EventDisplayData
  gqlClient?: ApolloClient<object>
}
const NotificationsTable = ({
  page,
  filters,
  updatePage,
  updateFilters,
  events,
  pagination,
  loading,
  featureFlags,
  dashboardUrl,
  getStoreOrderEventDisplayData,
  getRequestForQuoteEventDisplayData,
  gqlClient,
}: Props) => {
  const [stagedFilters, filtersApi] = useFilters(filters)
  const { data: organizationUsers } = useFetchOrganizationUsersQuery({ client: gqlClient })
  const userOptions = Select.getSelectOptions({ items: organizationUsers?.fetchOrganizationUsers })

  const filterFields = Filter.availableFilterFields(
    notificationFields(userOptions),
    false,
    featureFlags
  )

  return (
    <>
      <Layout.Section type="table">
        <Filters
          filters={filters}
          stagedFilters={stagedFilters}
          fields={filterFields}
          filtersApi={filtersApi}
          onApply={() => updateFilters(stagedFilters)}
        />

        <NotificationTableWithoutFilters
          events={events}
          loading={loading}
          dashboardUrl={dashboardUrl}
          getStoreOrderEventDisplayData={getStoreOrderEventDisplayData}
          getRequestForQuoteEventDisplayData={getRequestForQuoteEventDisplayData}
        />
      </Layout.Section>
      <Layout.Section type="full">
        <PaginationC pagination={pagination} page={page} updatePage={updatePage} />
      </Layout.Section>
    </>
  )
}

NotificationsTable.WithoutFilters = NotificationTableWithoutFilters
export default NotificationsTable
