import { DateTime } from 'luxon'

import type { StoreOrder } from '../../../LimitedOrder'

import { DeliveryMethod } from '@/buyers/_gen/gql'
import { useOrderContext } from '../../context'
import classNames from 'classnames'

type LineItem = StoreOrder['lineItems'][number]

const Availability = ({ lineItem }: { lineItem: LineItem }) => {
  const { storeOrder } = useOrderContext()
  const now = DateTime.now()

  const how = [DeliveryMethod.Shipping, DeliveryMethod.VendorDelivery].includes(
    storeOrder.deliveryMethod
  )
    ? 'to ship'
    : 'at will call'

  const when =
    lineItem.inStock || lineItem.availableAt?.toISODate() === now.toISODate()
      ? 'today,'
      : lineItem.availableAt?.toISODate() === now.plus({ days: 1 }).toISODate()
      ? 'tomorrow,'
      : 'on'

  const date = (lineItem.inStock ? now : lineItem.availableAt)?.toLocaleString(DateTime.DATE_SHORT)

  return lineItem.inStock || lineItem.availableAt ? (
    <span className="inline-flex flex-col">
      <span
        className={classNames(
          'font-medium inline-block leading-5',
          !lineItem.inStock && 'text-red-500'
        )}
      >
        {lineItem.inStock ? 'In stock' : 'Backordered'}
      </span>
      <span className="inline-block leading-5 text-sm">
        Available {how} {when} {date}
      </span>
    </span>
  ) : null
}

export default Availability
