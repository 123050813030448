import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { useQueryParams, BooleanParam } from 'use-query-params'

import { CardStatus } from '@/types'

import useGqlClient from '@/buyers/hooks/useGqlClient'
import { useSetRfqFollowUpReminderMutation, Rfq } from '@/buyers/_gen/gql'

import Card from '@/gf/components/Card'
import Checkbox from '@/gf/components/Checkbox'
import Select from '@/gf/components/Select'
import DateTimeSelect from '@/gf/components/inputs/DateTime'

const options = [
  { id: '30-mins', display: '30 minutes' },
  { id: '1-hour', display: '1 hour' },
  { id: '2-hours', display: '2 hours' },
  { id: 'custom', display: 'Custom' },
]

type Fields = { followUp: boolean; duration: string; remindAt: DateTime }

const determineRemindAt = (now: DateTime, fields: Fields) => {
  if (!fields.followUp) return null
  if (fields.duration === '30-mins') return now.plus({ minutes: 30 }).toUTC()
  if (fields.duration === '1-hour') return now.plus({ hours: 1 }).toUTC()
  if (fields.duration === '2-hours') return now.plus({ hours: 2 }).toUTC()
  if (fields.duration === 'custom') return fields.remindAt.toUTC()
  throw new Error(`Unexpected duration: ${fields.duration}`)
}

const Reminder = ({ rfq }: { rfq: Pick<Rfq, 'id' | 'remindAt'> }) => {
  const now = DateTime.now()
  const isAfterHours = now.hour >= 17
  const [status, setStatus] = useState<CardStatus>()
  const [query, updateQuery] = useQueryParams({ init: BooleanParam })
  const [setReminder] = useSetRfqFollowUpReminderMutation({ client: useGqlClient() })

  const [fields, setFields] = useState<Fields>({
    followUp: !!rfq.remindAt,
    duration: isAfterHours || rfq.remindAt ? 'custom' : '1-hour',
    remindAt: rfq.remindAt || now.plus({ days: 1 }).set({ hour: 9, minute: 30, second: 0 }),
  })

  const updateFields = (updates: Partial<Fields>) => {
    setStatus('spinner')
    const newFields = { ...fields, ...updates }
    setFields(newFields)

    setReminder({ variables: { rfqId: rfq.id, remindAt: determineRemindAt(now, newFields) } }).then(
      () => setStatus('success')
    )
  }

  useEffect(() => {
    if (query.init) {
      updateFields({ followUp: true })
      updateQuery({ init: undefined })
    }
  }, [])

  return (
    <Card
      title={
        <Checkbox
          checked={fields.followUp}
          setChecked={(followUp) => updateFields({ followUp })}
          label="Remind me to follow up"
          className="text-gray-700 text-lg"
        />
      }
      status={status}
    >
      <Card.Section>
        {fields.followUp && (
          <div className="flex flex-wrap gap-2 items-center">
            <div className="prose">
              If I don&apos;t receive a quote, remind me to follow up with my vendors in:
            </div>

            <div className="flex gap-2 items-center">
              <Select
                options={options}
                currentId={fields.duration}
                onChange={(duration) => updateFields({ duration })}
                className="w-auto"
              />

              {fields.duration === 'custom' && (
                <DateTimeSelect
                  min={now}
                  dateTime={fields.remindAt}
                  updateDateTime={(remindAt) => remindAt && updateFields({ remindAt })}
                />
              )}
            </div>
          </div>
        )}
      </Card.Section>
    </Card>
  )
}

export default Reminder
