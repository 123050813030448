import { useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useToggle from '@/gf/hooks/useToggle'
import useMsgs from '@/gf/hooks/useMsgs'
import useSession from '@/buyers/hooks/useSession'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import { SortOrder, useHomeNextQuery } from '@/buyers/_gen/gql'
import Frame from '@/buyers/components/Frame'
import InviteModal from './Home/InviteModal'
import OnboardingTasks from './Home/OnboardingTasks'
import RequestFlowCallout from './Home/RequestFlowCallout'
import ReportsSection from './Home/ReportsSection'
import WelcomeMessage from './Home/WelcomeMessage'
import QuickMetrics from './Home/QuickMetrics'
import useMetrics from './Reporting/useMetrics'
import duration from './Home/ReportsSection/duration'
import { MIN_REQUESTS_FOR_REPORTING } from '../modules/Reporting'
import Ghost from '@/gf/components/Ghost'

const Home = () => {
  const { organization, user } = useSession()
  const [inviteModalOpen, inviteModalToggler] = useToggle()
  const navigate = useNavigate()
  const [queryParams, setQueryParams] = useSearchParams()
  const [_, msgr] = useMsgs()

  const queryData = useHomeNextQuery({
    variables: {
      orgId: organization.id,
      userId: user.id,
    },
    client: useGqlClient(),
  }).data

  const isProPlan = useMemo(
    () =>
      queryData
        ? !!organization.buyerDashboardAccess || !!queryData.planSubscription?.id
        : !!organization.buyerDashboardAccess,
    [queryData, organization]
  )
  // const isProPlan = false

  const org = queryData?.org
  const pendingInvites = queryData?.user.pendingInvites

  const onInviteModalClosed = () => {
    inviteModalToggler.off()

    if (org && !org.setupStartedAt) navigate('/setup')
  }

  useEffect(() => {
    if (org && pendingInvites) {
      if (pendingInvites.length > 0) inviteModalToggler.on()
      else if (!org.setupStartedAt) navigate('/setup')
    }
  }, [org, pendingInvites])

  useEffect(() => {
    if (queryParams.get('email-confirmed') !== null) {
      queryParams.delete('email-confirmed')
      setQueryParams(queryParams)
      msgr.add('Email confirmed.', 'positive')
    }
  }, [])

  const form = {
    ...duration,
    urgentRequestsOnly: false,
    tab: 'Machine',
    metric: '',
  }

  const sortBy = { field: 'total.sum', order: SortOrder.Desc }
  const requestsSortBy = {
    field: 'total',
    order: SortOrder.Desc,
  }

  const metrics = useMetrics({
    form,
    selectedValues: [],
    selectedDate: undefined,
    sortBy,
    requestsSortBy,
    storeFilter: ({ store, total }) => !!store && !!total,
    creatorFilter: ({ creator, total }) => !!creator && !!total,
    purchaserFilter: ({ assignedUser, total }) => !!assignedUser && !!total,
    machineFilter: ({ orgMachine, total }) => !!orgMachine && !!total,
    urgencyFilter: ({ urgency, total }) => !!urgency && !!total,
    requestFilter: ({ total }) => total !== null,
    getChartValue: ({ total }) => total?.sum,
  })

  const hasEnoughReportData = (queryData?.org?.stats.requestCount ?? 0) > MIN_REQUESTS_FOR_REPORTING

  return (
    <Frame>
      {pendingInvites && (
        <InviteModal
          pendingInvites={pendingInvites}
          open={inviteModalOpen}
          onClose={onInviteModalClosed}
        />
      )}

      {/* Need a bunch of extra divs to fix the scroll bug https://github.com/GearFlowDev/gf_platform/pull/6085 */}
      <div className="flex flex-col grow">
        <div className="flex flex-col grow overflow-x-hidden">
          <div className="p-4 lg:p-8 xl:p-12 space-y-4 lg:space-y-6 xl:space-y-8  max-w-[73.875rem] flex flex-col grow">
            {queryData ? (
              <WelcomeMessage org={queryData?.org ?? null} />
            ) : (
              <Ghost className="block h-9 w-2/3 bg-gray-50 rounded-lg" />
            )}

            {queryData !== undefined && (
              <OnboardingTasks isInitialSetupComplete={!!queryData.org?.setupCompletedAt} />
            )}

            {isProPlan && hasEnoughReportData && <QuickMetrics metrics={metrics} />}

            {!hasEnoughReportData && <RequestFlowCallout />}

            <ReportsSection
              hasEnoughReportData={hasEnoughReportData}
              isProPlan={isProPlan}
              metrics={metrics}
            />
          </div>
        </div>
      </div>
    </Frame>
  )
}

export default Home
