import Filter from '@/gf/modules/Filter'
import { FilterField } from '@/types'
import type { FeatureFlags as BuyersFeatureFlags } from '@/buyers/types'

const availableFilterFields = (fields: FilterField[], featureFlags: BuyersFeatureFlags) =>
  Filter.availableFilterFields(fields, false, featureFlags)

export default {
  ...Filter,
  availableFilterFields,
}
