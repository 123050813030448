import debounce from 'debounce-promise'
import AsyncSelect from 'react-select/async'
import { ApolloClient, DocumentNode, OperationVariables } from '@apollo/client'
import { AutocompleteOption } from '../../types'

// Query data transformation: QueryDataT -> DatumT[] -> AutocompleteOption[]
const AutocompleteMultiInputNext = <QueryDataT, QueryVarsT extends OperationVariables, DatumT>({
  name,
  label,
  query,
  queryVars,
  onChange,
  transformQueryToData,
  transformDatumToOption,
  defaultValues,
  selectedValues,
  filterOption,
  placeholder,
  reactSelectOptions = {},
  gqlClient,
}: {
  name?: string
  label?: string
  query: DocumentNode
  queryVars: (search: string) => QueryVarsT
  onChange: (data: DatumT[]) => void
  transformQueryToData: (response: QueryDataT) => DatumT[]
  transformDatumToOption: (data: DatumT) => AutocompleteOption
  defaultValues?: DatumT[]
  selectedValues?: DatumT[]
  filterOption?: (option: AutocompleteOption) => boolean
  placeholder?: string
  reactSelectOptions?: any
  gqlClient: ApolloClient<object>
}) => {
  const runQuery = async (search: string) => {
    const { loading, data } = await gqlClient.query<QueryDataT, QueryVarsT>({
      query,
      variables: queryVars(search),
    })
    return loading || !data
      ? []
      : transformQueryToData(data).map((datum) => ({
          ...transformDatumToOption(datum),
          datum,
        }))
  }

  const debouncedLoadOptions = debounce(runQuery, 300, { leading: true })

  return (
    <div className="w-full text-sm space-y-1">
      {label && (
        <label className="block text-sm text-gray-700" htmlFor={name}>
          {label}
        </label>
      )}
      <AsyncSelect
        className="shadow-sm"
        onChange={(options: (AutocompleteOption & { datum: DatumT })[]) => {
          // convert option to datum
          onChange(options.map((option) => option.datum))
        }}
        defaultOptions
        loadOptions={debouncedLoadOptions}
        defaultValue={defaultValues?.map((datum) => ({ ...transformDatumToOption(datum), datum }))}
        value={selectedValues?.map((datum) => ({ ...transformDatumToOption(datum), datum }))}
        isMulti
        filterOption={filterOption}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        menuPlacement="bottom"
        placeholder={placeholder}
        {...reactSelectOptions}
      />
    </div>
  )
}

export default AutocompleteMultiInputNext
