import Badge from './Badge'

import { Invoice } from '../../types'

const getBadgeProps = (
  invoice: Pick<Invoice, 'isPaid' | 'isCanceled' | 'isFinanced' | 'balanceTransactionId'>
) =>
  invoice.isPaid
    ? {
        title: 'Paid',
        color: 'bg-green-200',
        textColor: 'text-green-900',
      }
    : invoice.isCanceled
    ? {
        title: 'Canceled',
        color: 'bg-gray-200',
        textColor: 'text-gray-900',
      }
    : !invoice.isFinanced && !!invoice.balanceTransactionId
    ? {
        title: 'Processing',
        color: 'bg-blue-200',
        textColor: 'text-blue-900',
      }
    : {
        title: 'Not Paid',
        color: 'bg-red-200',
        textColor: 'text-red-900',
      }

const InvoiceStateBadge = ({
  invoice,
}: {
  invoice: Pick<Invoice, 'isPaid' | 'isCanceled' | 'isFinanced' | 'balanceTransactionId'>
}) => <Badge {...getBadgeProps(invoice)} />

export default InvoiceStateBadge
