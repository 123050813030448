import classNames from 'classnames'
import LocationPlaceholder from '@/gf/components/Location/LocationPlaceholder'
import SingleMarkerMap from '@/gf/components/SingleMarkerMap'
import { OrgMachineDetails } from '@/gf/hooks/useOrgMachineDetails'
import LabeledText from '@/gf/components/LabeledText'

interface UnitDetailProps {
  machineDetails: OrgMachineDetails
}

type TitleProps = {
  children: React.ReactNode
  className?: string
}

const Title: React.FC<TitleProps> = ({ children, className = '' }) => (
  <h3 className={classNames('flex items-center font-black text-xl text-slate-600 pb-2', className)}>
    {children}
  </h3>
)

const NAText = 'Not Available'
const notAvailableTooltip = (field: string) =>
  `${field} will be available once you start requesting quotes for parts`

const UnitDetails: React.FC<UnitDetailProps> = ({ machineDetails }) => {
  const orgMachine = machineDetails.data?.machine

  return (
    <div>
      <Title>Operating Information</Title>

      <LabeledText
        label="Engine Hours"
        value={orgMachine?.engineHours || NAText}
        tooltipText={!orgMachine?.engineHours ? notAvailableTooltip('Engine Hours') : null}
      />
      <LabeledText
        label="Operator Name"
        value={orgMachine?.operator || NAText}
        tooltipText={!orgMachine?.operator ? notAvailableTooltip('Operator Name') : null}
      />

      <Title className="pt-6">Current Location</Title>
      {orgMachine?.position ? (
        <SingleMarkerMap
          lat={Number(orgMachine?.position.lat)}
          lng={Number(orgMachine?.position.lng)}
        />
      ) : (
        <LocationPlaceholder text="Location currently not available for this machine" />
      )}
    </div>
  )
}

export default UnitDetails
