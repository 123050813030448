import { Controller, useForm } from 'react-hook-form'
import { MinusIcon, PlusIcon } from '@heroicons/react/solid'
import { gql, useMutation, InternalRefetchQueriesInclude } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Transition } from '@headlessui/react'

import useMsgs from '../hooks/useMsgs'

import ModalForm from './ModalForm'
import TextInput from './TextInput'
import Field from './Field'
import TextArea from './TextArea'
import Checkbox from './Checkbox'

import { AccountMachinePayload, Maybe, ModalSize } from '../../types'
import Engine from './svgs/Engine'
import useToggle from '../hooks/useToggle'
import Typeahead from './next/Typeahead'
import { useMemo } from 'react'

const emptyFetch = (_s: string) => Promise.resolve([])

const MachineAutoCompleteOption = ({ machine }: { machine: SuggestedMachine }) => (
  <div className="w-full space-y-0.5 text-sm leading-tight">
    <div className="flex items-center gap-x-2">
      <div className="truncate text-ellipsis flex-grow font-medium">{machine.model}</div>
      <div>{machine.year}</div>
    </div>
    <div className="flex justify-between items-end">
      <div>{machine.make}</div>
    </div>
  </div>
)

const addMachine = gql`
  mutation AddMachineToOrg(
    $orgId: String
    $name: String
    $serialNumber: String
    $machine: MachineInput
    $description: String
    $owned: Boolean
    $engineMake: String
    $engineModel: String
    $engineSerialNumber: String
  ) {
    addMachineToOrg(
      orgId: $orgId
      name: $name
      serialNumber: $serialNumber
      machine: $machine
      description: $description
      owned: $owned
      engineMake: $engineMake
      engineModel: $engineModel
      engineSerialNumber: $engineSerialNumber
    ) {
      id
      name
      serialNumber
      owned
      description
      engineMake
      engineModel
      engineSerialNumber
      machine {
        make
        model
        year
      }
    }
  }
`

interface Data {
  addMachineToOrg: AccountMachinePayload
}

// Helper function from this yup issue thread: https://github.com/jquense/yup/issues/298
const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') return null

  return value
}

type AccountMachineForm = {
  name: string
  serialNumber: string
  make: string
  model: string
  year?: number
  engineMake?: string | null
  engineModel?: string | null
  engineSerialNumber?: string | null
  description?: string
  owned: boolean
}

const accountMachineSchema = yup.object({
  name: yup.string().required().label('Unit #'),
  serialNumber: yup.string().required().label('Serial Number'),
  make: yup.string().required().label('Make'),
  model: yup.string().required().label('Model'),
  year: yup.number().nullable().transform(emptyStringToNull).label('Year'),
  engineMake: yup.string().optional().label('Engine Make'),
  engineModel: yup.string().optional().label('Engine Model'),
  engineSerialNumber: yup.string().optional().label('Engine Serial Number'),
  description: yup.string().nullable().label('Description'),
  owned: yup.boolean().label('Owned'),
})

type SuggestedMachine = { id: string; make: string; model: string; year: Maybe<number> }

const AddAccountMachineModal = ({
  open,
  onClose,
  onAdd,
  accountId,
  refetchQueries,
  autocompleteMachines,
}: {
  open: boolean
  onClose: () => void
  onAdd?: (accountMachine?: AccountMachinePayload) => void
  accountId: string
  refetchQueries?: InternalRefetchQueriesInclude
  autocompleteMachines?: (search: string) => Promise<SuggestedMachine[]>
}) => {
  const [engineDetailsOpen, { toggle: toggleEngineDetails }] = useToggle(false)
  const [_msgs, msgsMgr] = useMsgs()
  const [addMachineToOrg] = useMutation<Data>(addMachine, { refetchQueries })

  const accountMachineForm = useForm<AccountMachineForm>({
    shouldUnregister: true,
    defaultValues: {
      name: '',
      serialNumber: '',
      make: '',
      model: '',
      engineMake: '',
      engineModel: '',
      engineSerialNumber: '',
      description: '',
      owned: true,
    },
    resolver: yupResolver(accountMachineSchema),
  })

  const onSubmit = accountMachineForm.handleSubmit((formData, event) => {
    event?.preventDefault()
    event?.stopPropagation()
    msgsMgr.clear()

    addMachineToOrg({
      variables: {
        orgId: accountId,
        name: formData.name,
        serialNumber: formData.serialNumber,
        description: formData.description,
        owned: formData.owned,
        engineMake: formData.engineMake,
        engineModel: formData.engineModel,
        engineSerialNumber: formData.engineSerialNumber,
        machine: {
          make: formData.make,
          model: formData.model,
          year: formData.year,
        },
      },
    })
      .then((result) => {
        accountMachineForm.reset()
        if (onAdd) onAdd(result.data?.addMachineToOrg)
      })
      .catch((err: GraphQLError) => {
        msgsMgr.add(err.message || 'Failed Adding Machine', 'negative')
      })
      .finally(() => onClose())
  })

  const onFetchMachines = useMemo(() => autocompleteMachines ?? emptyFetch, [autocompleteMachines])

  const selectMachine = (m: SuggestedMachine) => {
    accountMachineForm.setValue('make', m.make)
    accountMachineForm.setValue('model', m.model)
    accountMachineForm.setValue('year', m.year ?? undefined)
    accountMachineForm.setValue('name', [m.year, m.make, m.model].filter((v) => !!v).join(' '))
  }

  return (
    <ModalForm
      open={open}
      onClose={onClose}
      title="Create Machine"
      onSubmit={onSubmit}
      size={ModalSize.MD}
      submitButtonText="Save"
    >
      <div className="space-y-4 w-full max-w-screen-lg">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            control={accountMachineForm.control}
            name="name"
            render={({ field }) => (
              <Field
                label="Unit #"
                errorText={accountMachineForm.formState.errors.name?.message}
                inputId="name"
              >
                <Typeahead
                  inputRef={field.ref}
                  value={field.value}
                  onChange={(name) => field.onChange(name)}
                  onFetchOptions={onFetchMachines}
                  placement="bottom-start"
                  renderOption={(o) => <MachineAutoCompleteOption machine={o} />}
                  transformSelection={(o) => o.model}
                  afterSelection={(o) => selectMachine(o)}
                  id="name"
                  menuWidth={350}
                  menuHeight={400}
                  menuTitle="Machines"
                  menuOpenOnClick
                  fetchWithEmptyPrefix
                />
              </Field>
            )}
          />

          <Controller
            control={accountMachineForm.control}
            name="make"
            render={({ field }) => (
              <Field
                label="Make"
                errorText={accountMachineForm.formState.errors.make?.message}
                inputId="make"
              >
                <Typeahead
                  inputRef={field.ref}
                  value={field.value}
                  onChange={(make) => field.onChange(make)}
                  onFetchOptions={onFetchMachines}
                  placement="bottom-start"
                  renderOption={(o) => <MachineAutoCompleteOption machine={o} />}
                  transformSelection={(o) => o.make}
                  afterSelection={(o) => selectMachine(o)}
                  id="make"
                  menuWidth={350}
                  menuHeight={400}
                  menuTitle="Machines"
                  menuOpenOnClick
                  fetchWithEmptyPrefix
                />
              </Field>
            )}
          />

          <Controller
            control={accountMachineForm.control}
            name="model"
            render={({ field }) => (
              <Field
                label="Model"
                errorText={accountMachineForm.formState.errors.make?.message}
                inputId="model"
              >
                <Typeahead
                  inputRef={field.ref}
                  value={field.value}
                  onChange={(model) => field.onChange(model)}
                  onFetchOptions={onFetchMachines}
                  placement="bottom-start"
                  renderOption={(o) => <MachineAutoCompleteOption machine={o} />}
                  transformSelection={(o) => o.model}
                  afterSelection={(o) => selectMachine(o)}
                  id="model"
                  menuWidth={350}
                  menuHeight={400}
                  menuTitle="Machines"
                  menuOpenOnClick
                  fetchWithEmptyPrefix
                />
              </Field>
            )}
          />

          <Field
            label="Year"
            errorText={accountMachineForm.formState.errors.year?.message}
            inputId="year"
          >
            <TextInput {...accountMachineForm.register('year')} id="year" />
          </Field>

          <Field
            label="Serial Number"
            errorText={accountMachineForm.formState.errors.serialNumber?.message}
            inputId="serialNumber"
          >
            <TextInput {...accountMachineForm.register('serialNumber')} id="serialNumber" />
          </Field>

          <Field className="pt-8">
            <Checkbox
              label="My organization owns this machine."
              {...accountMachineForm.register('owned')}
            />
          </Field>
        </div>

        <Field label="Description">
          <TextArea prose={false} {...accountMachineForm.register('description')} />
        </Field>

        <>
          <div
            className="flex gap-x-2 py-2 mb-1 hover:bg-slate-50 px-1 cursor-pointer border-b"
            onClick={toggleEngineDetails}
          >
            <Engine className="text-gearflow" />
            <h4 className="font-semibold flex items-center flex-grow">Machine Specs</h4>

            {engineDetailsOpen ? (
              <MinusIcon className="h-6 text-gearflow" />
            ) : (
              <PlusIcon className="h-6 text-gearflow" />
            )}
          </div>

          <Transition
            as="div"
            show={engineDetailsOpen}
            enter="transform transition duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-2 pb-4 origin-top"
          >
            <Field
              label="Engine Make"
              errorText={accountMachineForm.formState.errors.engineMake?.message}
            >
              <TextInput {...accountMachineForm.register('engineMake')} />
            </Field>

            <Field
              label="Engine Model"
              errorText={accountMachineForm.formState.errors.engineModel?.message}
            >
              <TextInput {...accountMachineForm.register('engineModel')} />
            </Field>

            <Field
              label="Engine Serial Number"
              errorText={accountMachineForm.formState.errors.engineSerialNumber?.message}
            >
              <TextInput {...accountMachineForm.register('engineSerialNumber')} />
            </Field>
          </Transition>
        </>
      </div>
    </ModalForm>
  )
}

export default AddAccountMachineModal
