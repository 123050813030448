import Action from '@/gf/components/Action'
import UploadButton from '@/gf/components/UploadButton'
import useToggle from '@/gf/hooks/useToggle'
import { PhotographIcon } from '@heroicons/react/solid'
import ImagesList from '../ImagesList'

const PartImages = ({
  value,
  desc,
  onChange,
  addDisabled,
}: {
  value: string[]
  desc?: React.ReactNode
  onChange: (newfiles) => void
  addDisabled: boolean
}) => {
  const [uploading, uploadToggle] = useToggle()

  return (
    <div className="space-y-2 mt-4">
      <div className="text-sm">
        <p>Pictures (optional)</p>
        {desc}
      </div>

      <ImagesList
        imageUrls={value}
        uploadInProgress={uploading}
        onRemoveClick={(imageUrl) => onChange(value.filter((i) => i !== imageUrl))}
      />

      <UploadButton
        allowedFileTypes={['.jpg', '.jpeg', '.png', '.gif']}
        onUploadComplete={(uploadedFiles) => {
          uploadToggle.off()

          onChange([...(value ?? []), ...uploadedFiles.map((f) => f.url)])

          return Promise.resolve()
        }}
        onUploadStarted={uploadToggle.on}
        onError={(err) => {
          uploadToggle.off()
          console.error(err)
        }}
        key={value.length} // this is necessary to update the component and properly add the new pictures
      >
        <Action.S className="font-medium text-sm mt-2 flex items-center" disabled={addDisabled}>
          <PhotographIcon className="mr-2 h-4 inline-block" /> Add Picture
        </Action.S>
      </UploadButton>
    </div>
  )
}

export default PartImages
