import {
  LimitedOrderQuery,
  useFetchConversationsQuery,
  useFleetioPurchaseOrderCreatedSubscription,
  useFleetioPurchaseOrderUpdatedSubscription,
  useLimitedOrderQuery,
} from '@/buyers/_gen/gql'
import Box from '@/buyers/components/Box'
import BrokerWarning from '@/buyers/components/BrokerWarning'
import useApolloWsClient from '@/buyers/hooks/useApolloWsClient'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import A from '@/gf/components/A'
import useConfig from '@/gf/hooks/useConfig'
import useMsgs from '@/gf/hooks/useMsgs'
import ConversationM from '@/gf/modules/Conversation'
import FilterSet from '@/gf/modules/FilterSet'
import FormatFilters from '@/gf/modules/FormatFilters'
import Id from '@/gf/modules/Id'
import StoreOrderM from '@/gf/modules/StoreOrder'
import EqualsFilter from '@/gf/modules/filters/Equals'
import { useCallback, useState } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { ArrayParam, useQueryParam, withDefault } from 'use-query-params'
import Details from './Order/Details'
import Header from './Order/Header'
import Messages from './Order/Messages'
import Nav from './Order/Nav'
import VendorDetails from './Order/VendorDetails'
import YourOrder from './Order/YourOrder'
import { OrderContext } from './Order/context'

export type StoreOrder = Exclude<LimitedOrderQuery['storeOrder'], null>
export type Org = Exclude<LimitedOrderQuery['org'], null>
export type User = Exclude<LimitedOrderQuery['user'], null>
export type ConvosResult = ReturnType<typeof useFetchConversationsQuery>

const Order = () => {
  const {
    user: { id: userId },
    orgId,
  } = useSession()

  const config = useConfig()
  const [_, msgr] = useMsgs()
  const client = useGqlClient()
  const { storeOrderId } = useParams<{ storeOrderId: string }>() as { storeOrderId: string }

  const [rejectedLineItemIdsParam, _setRejectedItemIds] = useQueryParam(
    'rejectedLineItemIds',
    withDefault(ArrayParam, [])
  )

  const [rejectedLineItemIds, setRejectedLineItemIds] = useState<string[]>(
    (rejectedLineItemIdsParam as string[]) ?? []
  )

  const toggleRejectedLineItemId = useCallback(
    (lineItemId: string) =>
      setRejectedLineItemIds((prev) =>
        prev.includes(lineItemId) ? prev.filter((id) => id !== lineItemId) : [...prev, lineItemId]
      ),
    [rejectedLineItemIds]
  )

  const queryResult = useLimitedOrderQuery({
    variables: { orgId, userId, storeOrderFilter: JSON.stringify(['id_eq', storeOrderId]) },
    client,
  })

  const { storeOrder, org, user } = queryResult.data || {}

  const convosResult = useFetchConversationsQuery({
    client,
    variables: {
      filters: FormatFilters.andFilters(
        FilterSet.toApiFilters([
          EqualsFilter.build({ fieldId: 'storeOrderId', text: storeOrderId }),
        ])
      ),
      page: 1,
      pageSize: ConversationM.PAGE_SIZE,
    },
    pollInterval: ConversationM.CONVERSATION_POLL_INTERVAL,
  })

  useFleetioPurchaseOrderCreatedSubscription({
    variables: { storeOrderId },
    client: useApolloWsClient(),
    onData: () => {
      msgr.add('Fleetio purchase order created.', 'positive')
      queryResult.refetch()
    },
  })

  useFleetioPurchaseOrderUpdatedSubscription({
    variables: { storeOrderId },
    client: useApolloWsClient(),
    onData: () => {
      msgr.add('Fleetio purchase order already existed and was updated.', 'positive')
      queryResult.refetch()
    },
  })

  if (storeOrder === undefined || org === undefined || user === undefined) return null

  if (storeOrder === null || org === null || (!user.claimed && !storeOrder.paymentOption.direct))
    return (
      <div className="py-8">
        <Box className="text-base space-y-2">
          <div className="text-lg">Sorry, we couldn&apos;t find that order.</div>
          <div>For help, contact support@gearflow.com</div>
        </Box>
      </div>
    )

  const unreadMessages = convosResult.data
    ? convosResult.data.fetchConversations.conversations.some((c) => c.unreadMessages)
    : false

  return (
    <OrderContext.Provider
      value={{
        storeOrderId,
        storeOrder,
        org,
        user,
        refetchStoreOrder: queryResult.refetch,
        rejectedLineItemIds,
        toggleRejectedLineItemId,
      }}
    >
      <div className="flex flex-col grow py-8">
        <Header />
        {storeOrder.order.requestForQuote?.brokerRequestForQuoteId && (
          <BrokerWarning className="mt-4 -mb-4">
            Fulfilling{' '}
            <A.T
              href={`${config.suppliersUrl}/rfqs/${storeOrder.order.requestForQuote.brokerRequestForQuoteId}`}
              target="_blank"
            >
              Request {Id.shorten(storeOrder.order.requestForQuote.brokerRequestForQuoteId)}
            </A.T>
            {!StoreOrderM.isQuoteApproved(storeOrder) && (
              <>
                {' '}
                -{' '}
                <A.T
                  href={`${config.suppliersUrl}/rfqs/${storeOrder.order.requestForQuote.brokerRequestForQuoteId}?copyStoreOrderId=${storeOrder.id}`}
                  target="_blank"
                >
                  Create Broker Quote
                </A.T>
              </>
            )}
          </BrokerWarning>
        )}
        <div className="mt-8">
          <Nav unreadMessages={unreadMessages} />
        </div>

        <Routes>
          <Route path="*" element={<YourOrder />} />
          <Route path="vendor" element={<VendorDetails />} />
          <Route path="details" element={<Details />} />
          <Route path="messages/*" element={<Messages convosResult={convosResult} />} />
        </Routes>
      </div>
    </OrderContext.Provider>
  )
}

export default Order
