import { useEffect, useMemo } from 'react'
import { useRequestForQuotesSearchLazyQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'

const useDuplicateRfqs = (
  machineId: string | undefined,
  partNumbers: string[],
  ignoreRfqIds?: string[]
) => {
  const [getDuplicateRfqs, { data }] = useRequestForQuotesSearchLazyQuery({
    client: useGqlClient(),
  })

  useEffect(() => {
    if (machineId && partNumbers.length > 0 && partNumbers[0] !== '') {
      const filter = JSON.stringify([
        ['machine_id_eq', machineId || ''],
        ...partNumbers.map((partNumber) => ['part_mpn_contains', partNumber]),
      ])

      getDuplicateRfqs({ variables: { filter } })
    }
  }, [machineId, partNumbers])

  const duplicateRfqs = useMemo(() => {
    const duplicatedRequests =
      data?.searchRequests.requests.map((rfq) => ({
        id: rfq.id,
        name: rfq.user?.name || '(unknown)',
      })) || []

    return ignoreRfqIds
      ? duplicatedRequests.filter(({ id }) => !ignoreRfqIds.includes(id))
      : duplicatedRequests
  }, [data?.searchRequests.requests])

  return duplicateRfqs
}

export default useDuplicateRfqs
