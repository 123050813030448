import { ListBrandsQuery, useListBrandsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Action from '@/gf/components/Action'
import TextTag from '@/gf/components/next/TextTag'
import SearchInput from '@/gf/components/SearchInput'
import { Maybe } from '@/types'
import { ArrowLeftIcon, RefreshIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import Loading from './Brands/Loading'
import useForm from './Brands/useForm'
import SelectableButton from './SelectableButton'

import Box from '@/gf/components/Box'
import Dropdown from '@/gf/components/next/Dropdown'
import DropdownAction from '@/gf/components/next/DropdownAction'

type Form = ReturnType<typeof useForm>

const Brands = ({
  form: { fields, isBrandSelected, toggleBrand },
  title = 'Which brands are in your fleet?',
  subtitle = 'You will be able to add all of your brands after setup.',
  goBackAction,
}: {
  form: Form
  title?: string
  subtitle?: Maybe<string>
  goBackAction?: { title: string; onClick: () => void }
}) => {
  const client = useGqlClient()
  const [brands, setBrands] = useState<ListBrandsQuery['paginatedBrands']['entries']>([])
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const { data, loading } = useListBrandsQuery({
    client,
    variables: { page, searchTerm, excludeIds: fields.previouslyAddedBrands.map((b) => b.id) },
    onCompleted: (d: ListBrandsQuery) => {
      setBrands((prev) =>
        page === 1 ? d.paginatedBrands.entries : [...prev, ...d.paginatedBrands.entries]
      )
    },
  })

  const onSearchChanged = (st: string) => {
    setSearchTerm(st)
    setPage(1)
    setBrands([])
  }

  const showPagination = data ? data.paginatedBrands.pagination.totalPages > page : false

  return (
    <div className="flex gap-x-6 justify-center">
      <div className="flex-grow basis-1/5 max-w-[15.75rem]" />
      <div className="max-w-md w-md flex-grow flex-shrink-0 space-y-6 pb-8">
        <hgroup className="space-y-2">
          {goBackAction && (
            <Action.T
              onClick={goBackAction.onClick}
              className="text-base text-gray-900 no-underline flex items-center gap-x-2"
            >
              <ArrowLeftIcon className="inline-block h-6 w-6" /> {goBackAction.title}
            </Action.T>
          )}
          <h2 className="text-2xl font-medium">{title}</h2>
          {subtitle && <p className="text-lg">{subtitle}</p>}
        </hgroup>

        <div>
          <SearchInput
            initialValue={searchTerm}
            placeholder="Type to find a brand"
            onChange={onSearchChanged}
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          {brands.map((b, i) => (
            <SelectableButton
              key={b.id}
              className="h-[7.5rem] font-medium p-px"
              onClick={() => toggleBrand(b, i, searchTerm)}
              selected={isBrandSelected(b)}
            >
              {b.logoUrl ? (
                <span className="block h-full w-full rounded-xl overflow-hidden">
                  <img src={b.logoUrl} alt="" className="w-full h-full object-contain" />
                </span>
              ) : (
                <span>{b.name}</span>
              )}
            </SelectableButton>
          ))}
          {!loading && brands.length === 0 && (
            <div className="col-span-3 text-sm text-gray-700">
              No brands matching <span className="text-gray-900 font-medium">{searchTerm}</span>
            </div>
          )}
          {loading && <Loading />}
        </div>

        {showPagination && (
          <div className="flex justify-center">
            <Action.S className="flex items-center" onClick={() => setPage((p) => p + 1)}>
              <RefreshIcon className="h-4 w-4 mr-2" /> Find more
            </Action.S>
          </div>
        )}
      </div>

      <div className="basis-1/5 max-w-[15.75rem] pt-5">
        <div className="pt-5 sticky top-0 space-y-6">
          {fields.brands.length > 0 && (
            <div className="space-y-4">
              <h3 className="text-base">Adding Brands</h3>
              <div className="flex gap-2 flex-wrap">
                {fields.brands.map((selectedBrand, i) => (
                  <TextTag
                    key={selectedBrand.id}
                    onRemoveClick={() => toggleBrand(selectedBrand, i, searchTerm)}
                  >
                    {selectedBrand.name}
                  </TextTag>
                ))}
              </div>
            </div>
          )}
          {fields.previouslyAddedBrands.length > 0 && (
            <Dropdown
              trigger={
                <DropdownAction>Your Brands ({fields.previouslyAddedBrands.length})</DropdownAction>
              }
            >
              <Box className="max-w-72 p-2 space-y-2">
                <h4 className="font-medium text-gray-500 text-sm">Previously Added</h4>
                <div className="flex flex-wrap gap-2 max-h-64 overflow-y-auto fancy-scroll scroll-shadows">
                  {fields.previouslyAddedBrands.map((b) => (
                    <TextTag key={b.id}>{b.name}</TextTag>
                  ))}
                </div>
              </Box>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  )
}

export default Brands
