import useGqlClient from '@/buyers/hooks/useGqlClient'
import {
  useConversationMembersQuery,
  useRequestForQuoteConversationMembersQuery,
  useStoreOrderConversationMembersQuery,
} from '../_gen/gql'

const useConversationMembers = ({
  conversationId,
  conversationStoreOrderId,
  conversationRfqId,
}: {
  conversationId: string | undefined
  conversationStoreOrderId?: string
  conversationRfqId?: string
}) => {
  const gqlClient = useGqlClient()
  const conversationResult = useConversationMembersQuery({
    client: gqlClient,
    variables: { conversationId: conversationId ?? '' },
    skip: !conversationId,
  })
  const rfqResult = useRequestForQuoteConversationMembersQuery({
    client: gqlClient,
    variables: { requestForQuoteId: conversationRfqId ?? '' },
    skip: !conversationRfqId || !!conversationId,
  })
  const storeOrderResult = useStoreOrderConversationMembersQuery({
    client: gqlClient,
    variables: { storeOrderId: conversationStoreOrderId ?? '' },
    skip: !conversationStoreOrderId || !!conversationId,
  })
  return conversationId
    ? { ...conversationResult, data: conversationResult.data?.conversationMembers }
    : conversationRfqId
    ? { ...rfqResult, data: rfqResult.data?.requestForQuoteConversationMembers }
    : conversationStoreOrderId
    ? { ...storeOrderResult, data: storeOrderResult.data?.storeOrderConversationMembers }
    : { data: undefined, error: true }
}

export default useConversationMembers
