import Action from '@/gf/components/Action'
import DelayedSearchInput from '@/gf/components/inputs/DelayedSearch'
import Layout from '@/gf/components/Layout'
import Link from '@/gf/components/Link'
import Page from '@/gf/components/Page'
import PaginationC from '@/gf/components/Pagination'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import useModal from '@/gf/hooks/useModal'
import usePage from '@/gf/hooks/usePage'
import useSearchQueryParam from '@/gf/hooks/useSearchQueryParam'
import Time from '@/gf/modules/Time'
import { Fragment } from 'react'
import {
  DealersListDocument,
  DealersListQuery,
  // PreferredSetting,
  useDealersListQuery,
} from '../_gen/gql'
import BrandsMissingVendors from '../components/BrandsMissingVendors'
import Frame from '../components/Frame'
import FullPagePaywall from '../components/FullPagePaywall'
import CompleteVendorModal from '../components/Vendors/CompleteVendorModal'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import ContactDropdown from './Setup/Vendors/ContactDropdown'
import TextTag from '@/gf/components/next/TextTag'
import Dropdown from '@/gf/components/next/Dropdown'
import Box from '@/gf/components/Box'

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [{ name: 'Vendors', href: '/vendors' }],
}

type Vendor = DealersListQuery['vendorsSearch']['vendors'][number]

const Row = ({ vendor }: { vendor: Vendor }) => {
  const finishVendorModal = useModal<{ vendor: Vendor }>()

  return (
    <Fragment key={vendor.id}>
      {finishVendorModal.props?.vendor && (
        <CompleteVendorModal
          open={finishVendorModal.isOpen}
          onClose={finishVendorModal.close}
          vendorId={finishVendorModal.props.vendor.id}
          refetchQueries={[DealersListDocument]}
        />
      )}

      <Tr>
        <Td>
          {vendor.draft ? (
            vendor.name
          ) : (
            <Link.T to={`/vendors/${vendor.id}`} className="text-base">
              {vendor.name}
            </Link.T>
          )}

          <p className="text-xs text-slate-400 italic mt-1">
            {!vendor.draft && (
              <>
                Account{' '}
                <span className="text-slate-500 font-medium">
                  #{vendor.accountNumbers.join(', ')}
                </span>
                {' - '}
              </>
            )}
            Created {Time.toString(vendor.insertedAt)}
          </p>
        </Td>

        <Td>
          <Dropdown
            placement="bottom-end"
            trigger={
              <p className="text-sm flex gap-1">
                {vendor.brands.slice(0, 3).map((b) => (
                  <TextTag>{b.name}</TextTag>
                ))}

                {vendor.brands.length > 3 && (
                  <TextTag color="gray">+{vendor.brands.length - 3}</TextTag>
                )}
              </p>
            }
          >
            <Box className="flex gap-2 flex-wrap p-2 mt-2 shadow-lg">
              {vendor.brands.slice(3).map((b) => (
                <span className="inline-block">
                  <TextTag>{b.name}</TextTag>
                </span>
              ))}
            </Box>
          </Dropdown>
        </Td>
        <Td>
          {vendor.draft ? (
            <Action.P type="button" onClick={() => finishVendorModal.open({ vendor })} color="blue">
              Finish Setup
            </Action.P>
          ) : vendor.contacts.length > 0 ? (
            <ContactDropdown contacts={vendor.contacts} />
          ) : null}
        </Td>
      </Tr>
    </Fragment>
  )
}

const Vendors = () => {
  const { orgId } = useSession()
  const { search, updateSearch } = useSearchQueryParam()
  const [page, setPage] = usePage()

  const { data, previousData } = useDealersListQuery({
    variables: { orgId, page, search },
    client: useGqlClient(),
  })

  const { org } = data || previousData || {}
  const vendors = (data ?? previousData)?.vendorsSearch.vendors ?? []
  const pagination = (data ?? previousData)?.vendorsSearch.pagination

  if (org && !org.features.viewDealers)
    return <FullPagePaywall breadcrumbs={breadcrumbs} title="Vendors" />

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page
        title={
          <div className="inline-flex items-center gap-x-4">
            Vendors
            <BrandsMissingVendors />
          </div>
        }
        titleRightContent={<Link.P to="/vendors/new?selectLocation=1">Add Vendor</Link.P>}
      >
        <div className="space-y-4 mt-4">
          <DelayedSearchInput
            value={search}
            autoFocus
            setValue={(term) => {
              updateSearch(term)
              setPage(1)
            }}
          />

          <Layout.Section type="table">
            <Table>
              <Thead>
                <Tr>
                  <Th>Vendor</Th>
                  <Th>Brands</Th>
                  <Th>Contact</Th>
                </Tr>
              </Thead>
              <Tbody>
                {vendors.length === 0 ? (
                  <Tr>
                    <Td colSpan={3}>No vendors found.</Td>
                  </Tr>
                ) : (
                  vendors.map((vendor) => <Row key={vendor.id} vendor={vendor} />)
                )}
              </Tbody>
            </Table>
          </Layout.Section>
          <Layout.Section type="full">
            <PaginationC pagination={pagination} page={page} updatePage={setPage} />
          </Layout.Section>
        </div>
      </Page>
    </Frame>
  )
}

export default Vendors
