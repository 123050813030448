import { gql } from '@apollo/client'
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query'

import { money, pagination, storeOrderEvent } from '@/gf/queries/commonGqlTypes'

const SEARCH_STORE_ORDERS = gql(
  jsonToGraphQLQuery(
    {
      query: {
        __variables: {
          page: 'Int',
          filters: '[[String]]',
        },
        storeOrdersSearch: {
          __args: { page: new VariableType('page'), filters: new VariableType('filters') },
          pagination,
          storeOrders: {
            id: true,
            shortId: true,
            insertedAt: true,
            updatedAt: true,
            state: true,
            deliveryMethod: true,
            shippingAmount: money,
            salesTax: money,
            convenienceFeeRate: true,
            gearflowShipping: true,
            purchaseOrder: true,
            payOnInvoice: true,
            timingDetails: true,
            pickup: true,
            pickupAddress: {
              lineOne: true,
              lineTwo: true,
              city: true,
              state: true,
              country: true,
              postalCode: true,
              latitude: true,
              longitude: true,
            },
            shippingLocation: {
              id: true,
              name: true,
              code: true,
              deleted: true,
              address: {
                lineOne: true,
                lineTwo: true,
                city: true,
                state: true,
                country: true,
                postalCode: true,
              },
            },
            shippingAddress: {
              firstName: true,
              lastName: true,
              lineOne: true,
              lineTwo: true,
              city: true,
              state: true,
              country: true,
              postalCode: true,
              companyName: true,
              deliverable: true,
              rdi: true,
              point: {
                lat: true,
                lng: true,
              },
            },
            vendor: {
              id: true,
              accountNumbers: true,
            },
            accountMachines: {
              id: true,
              name: true,
              serialNumber: true,
              owned: true,
              engineMake: true,
              engineModel: true,
              engineSerialNumber: true,
              machine: {
                make: true,
                model: true,
                year: true,
              },
            },
            store: {
              id: true,
              name: true,
              address: {
                firstName: true,
                lastName: true,
                lineOne: true,
                lineTwo: true,
                city: true,
                state: true,
                country: true,
                postalCode: true,
                companyName: true,
                latitude: true,
                longitude: true,
              },
              email: true,
              phoneNumber: true,
              shipsFree: true,
              gearflowPaymentsEnabled: true,
              returnPolicy: true,
              shippingInfo: true,
            },
            order: {
              id: true,
              shortId: true,
              insertedAt: true,
              updatedAt: true,
              state: true,
              phoneNumber: true,
              email: true,
              comment: true,
              user: {
                id: true,
                name: true,
                email: true,
                organization: {
                  id: true,
                },
              },
              billingAddress: {
                firstName: true,
                lastName: true,
                lineOne: true,
                lineTwo: true,
                city: true,
                state: true,
                country: true,
                postalCode: true,
                companyName: true,
              },
              requestForQuote: {
                id: true,
                shortId: true,
                fullName: true,
                phoneNumber: true,
                emailAddress: true,
                partsRequest: true,
                machineInformation: true,
              },
            },
            balanceTransaction: { id: true },
            additionalCharges: {
              id: true,
              name: true,
              price: money,
              state: true,
            },
            discounts: {
              percent: true,
            },
            refunds: {
              id: true,
              amount: money,
            },
            lineItems: {
              id: true,
              quantity: true,
              product: {
                id: true,
                name: true,
                mpn: true,
                altMpn: true,
                salePrice: true,
                enabled: true,
                offline: true,
                images: {
                  variants: {
                    name: true,
                    url: true,
                  },
                },
                path: true,
              },
              unitPrice: money,
              discountedUnitPrice: money,
              extendedPrice: money,
              showMpn: true,
              shipmentItems: {
                id: true,
                quantity: true,
                shipment: {
                  id: true,
                  link: true,
                  carrier: true,
                  tracking: true,
                  shippedAt: true,
                  receivedAt: true,
                  label: {
                    id: true,
                    url: true,
                    cost: money,
                    shipDate: true,
                  },
                },
              },
              inStock: true,
              availableAt: true,
            },
            events: storeOrderEvent,
            features: {
              txns: true,
              storeOrderTimelineV1: true,
            },
            curriDeliveries: {
              curriDeliveryId: true,
              trackingUrl: true,
              price: money,
            },
          },
        },
      },
    },
    { pretty: true }
  )
)

export default SEARCH_STORE_ORDERS
