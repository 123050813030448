import Form from '@/gf/components/ClaimAccount/Form'
import Success from '@/gf/components/ClaimAccount/Success'
import logo from '@/retail/components/Layout/logo.png'
import { Navigate, useNavigate } from 'react-router-dom'
import { useClaimAccountMutation } from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import useReloadSession from '../hooks/useReloadSession'
import useSession from '../hooks/useSession'

const ActivateAccount = () => {
  const { user } = useSession()
  const client = useGqlClient()
  const navigate = useNavigate()
  const reloadSession = useReloadSession()
  const [claimAccountMutation] = useClaimAccountMutation({ client })

  const claimAccount = async (fields: {
    password: string
    passwordConfirmation: string
    emailOptIn: boolean
  }) => {
    await claimAccountMutation({ variables: { userId: user.id, ...fields } })
    reloadSession().then(() => navigate('/setup'))
  }

  if (user.claimed) {
    return <Navigate to="/" replace />
  }

  return (
    <div className="fixed inset-0 bg-white border-t-8 border-gearflow flex flex-col">
      <header className="flex px-8 py-6">
        <div className="basis-1/2 md:basis-1/4">
          <img src={logo} alt="Gearflow" className="w-36" />
        </div>
      </header>

      <div className="flex-1 sm:flex sm:justify-center">
        <div className="sm:max-w-md">
          <Form
            onClose={() => undefined}
            email={user.email}
            phone={user.phoneNumber}
            success={<Success app="parts-hub" onClose={reloadSession} />}
            closeButtonEnabled={false}
            customHeader={
              <div className="p-6 flex flex-col gap-y-2">
                <h2 className="font-medium text-2xl">Welcome to Gearflow!</h2>
                <p className="text-gray-600 text-base">Claim your account to get started.</p>
              </div>
            }
            claimAccount={claimAccount}
            showSubtitle={false}
            showSpinnerOnSuccess
          />
        </div>
      </div>
    </div>
  )
}

export default ActivateAccount
