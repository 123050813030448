// From Headless Combobox (https://headlessui.com/react/combobox)
import { Fragment, ReactNode, useRef, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { isEqual } from 'lodash'

type CreateOption = {
  isCreateOption: boolean
  value: string
}

const getIsFreeformOptionSelected = <TValue,>(option: TValue, freeformOption: TValue | undefined) =>
  !!freeformOption && isEqual(option, freeformOption)

export default <TValue,>({
  value,
  options,
  onChange,
  getOptionDisplay,
  getOptionSearch,
  showAllOptions,
  queryValue,
  onQueryChange,
  placeholder,
  freeformOption,
  createOption,
  onCreateOptionSelected,
  onFocus,
  onBlur,
  open,
  hideButton = false,
  emptyValue = 'Nothing found.',
  className,
  containerClassName,
}: {
  value: TValue | null
  options: TValue[]
  onChange: (selected: TValue | null) => unknown
  getOptionDisplay: (option: TValue) => string
  getOptionSearch?: (option: TValue) => string
  showAllOptions?: boolean
  queryValue?: string
  onQueryChange?: (query: string) => unknown
  placeholder?: string
  freeformOption?: TValue & { isFreeformOption: boolean }
  createOption?: boolean
  onCreateOptionSelected?: (value: string) => unknown
  onFocus?: () => unknown
  onBlur?: () => unknown
  open?: boolean
  hideButton?: boolean
  emptyValue?: ReactNode
  className?: string
  containerClassName?: string
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [query, setQuery] = useState(queryValue ?? '')
  const [isFreeformOptionSelected, setIsFreeformOptionSelected] = useState(
    getIsFreeformOptionSelected(value, freeformOption)
  )
  const isStatic = open !== undefined

  const onChangeSelected = (option: TValue | CreateOption | null) => {
    if (
      option &&
      typeof option === 'object' &&
      'isCreateOption' in option &&
      option.isCreateOption
    ) {
      if (onCreateOptionSelected) onCreateOptionSelected(option.value)
    } else {
      onChange(option as TValue)
      // Update the freeform option UI when the selection changes
      const freeformSelected = getIsFreeformOptionSelected(option, freeformOption)
      setIsFreeformOptionSelected(freeformSelected)
      // Keep focus on the input if the user still needs to type the freeform value
      if (freeformSelected && query === '') inputRef.current?.focus()
    }
  }

  const allOptions = freeformOption ? [...options, freeformOption] : options

  const filteredOptions = [
    ...(query === '' || showAllOptions
      ? allOptions
      : allOptions.filter((option) =>
          // Fallback by searching on the display value
          (getOptionSearch ?? getOptionDisplay)(option)
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )),
    ...(createOption && (queryValue ?? query)
      ? [{ isCreateOption: true, value: `Create "${queryValue ?? query}"` }]
      : []),
  ]

  const optionDisplay = (option: TValue | CreateOption) =>
    option && typeof option === 'object' && 'isCreateOption' in option && option.isCreateOption
      ? option.value
      : getOptionDisplay(option as TValue)

  return (
    <Combobox value={value} onChange={onChangeSelected} nullable>
      <div className={classNames('relative', containerClassName)}>
        <div
          className={classNames(
            'relative w-full cursor-default overflow-hidden rounded-md bg-white text-left shadow-sm border border-gray-300 focus:border-blue-600 focus:ring-blue-600',
            className
          )}
        >
          {isFreeformOptionSelected && (
            <div className="absolute inset-y-0 left-0 pl-2 flex items-center">
              <div className="px-1 py-0.5 flex items-center text-sm font-medium text-gray-700 border border-gray-300 rounded bg-gray-50">
                Other:
              </div>
            </div>
          )}
          <Combobox.Input
            ref={inputRef}
            className={classNames(
              'w-full border-none py-2 pr-10 text-sm leading-5 text-gray-900 placeholder:text-gray-400 focus:ring-0',
              isFreeformOptionSelected ? 'pl-16' : 'pl-3'
            )}
            displayValue={(option) => (option ? optionDisplay(option as TValue) : '')}
            placeholder={placeholder}
            value={queryValue}
            onChange={(event) => {
              setQuery(event.target.value)
              if (onQueryChange) onQueryChange(event.target.value)
            }}
            onKeyDown={(e) => {
              // Cancel the freeform input if backspacing on an empty field
              if (e.key === 'Backspace' && e.target.value === '' && freeformOption) {
                onChangeSelected(null)
              }
            }}
            onFocus={onFocus}
            // Don't want the blur to interfere with selecting an option
            onBlur={onBlur ? () => setTimeout(onBlur, 100) : undefined}
          />
          {!hideButton && (
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          )}
        </div>
        <Transition
          show={!isStatic ? undefined : open}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => {
            setQuery('')
          }}
        >
          <Combobox.Options
            className="absolute z-[9999999] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
            static={isStatic}
          >
            {filteredOptions.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                {emptyValue}
              </div>
            ) : (
              filteredOptions.map((option) => (
                <Combobox.Option
                  key={`${optionDisplay(option)}${
                    isEqual(option, freeformOption) ? '-freeform' : ''
                  }`}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-blue-600 text-white' : 'text-gray-900'
                    )
                  }
                  value={option}
                >
                  {({ active }) => {
                    const selected = isEqual(option, value)
                    return (
                      <>
                        <span className={classNames('block', selected && 'font-semibold')}>
                          {freeformOption && isEqual(option, freeformOption) && '(Other) '}
                          {optionDisplay(option)}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-blue-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )
                  }}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}
