import { ExclamationIcon } from '@heroicons/react/outline'
import { DateTime } from 'luxon'

import { DeliveryMethod, StoreOrderStep } from '@/buyers/_gen/gql'
import { useOrderContext } from '../context'

import Box from '@/buyers/components/Box'
import Link from '@/gf/components/Link'
import { List, Row } from '../List'
import { Section } from '../YourOrder'

const Deliveries = () => {
  const { storeOrder } = useOrderContext()

  if (
    ![StoreOrderStep.Fulfilling, StoreOrderStep.Fulfilled].includes(storeOrder.step) ||
    storeOrder.deliveryMethod !== DeliveryMethod.VendorDelivery
  )
    return null

  return (
    <Box className="divide-y divide-gray-200">
      <Section className="space-y-4">
        <div className="flex gap-4 items-start justify-between">
          <div className="font-medium text-lg text-gray-900">Delivery</div>

          <div className="flex gap-2 items-center">
            {storeOrder.step === StoreOrderStep.Fulfilled && (
              <Link.S to="report-problem" className="flex gap-2 items-center">
                <ExclamationIcon className="h-5 w-5" />
                <span>Report a Problem</span>
              </Link.S>
            )}

            {!storeOrder.receivedAt && <Link.P to="mark-received">Mark Received</Link.P>}
          </div>
        </div>

        <p className="text-base">
          {storeOrder.store.name} is delivering this to you directly, so there is no tracking
          information.
        </p>

        <List>
          <Row>
            Out for delivery: {storeOrder.outForDeliveryAt?.toLocaleString(DateTime.DATETIME_SHORT)}
          </Row>

          {storeOrder.receivedAt && (
            <Row>Received: {storeOrder.receivedAt?.toLocaleString(DateTime.DATETIME_SHORT)}</Row>
          )}
        </List>
      </Section>
    </Box>
  )
}

export default Deliveries
