import { useSuggestedVendorsQuery } from '@/buyers/_gen/gql'
import { VendorBadgeType } from '@/buyers/components/Vendors/VendorBadge'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import { Maybe, Point } from '@/types'
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { useMemo } from 'react'
import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import VendorCard from './VendorCard'

const SuggestedVendors = ({
  brandId,
  point,
  excludeVendorIds,
  selectedDealerLocationIds,
  onAddDealer,
  onRemoveDealer,
}: {
  brandId: Maybe<string>
  point: Point
  excludeVendorIds: string[]
  selectedDealerLocationIds: string[]
  onAddDealer: (dealerLocarionId: string) => void
  onRemoveDealer: (dealerLocarionId: string) => void
}) => {
  const client = useGqlClient()

  const { data } = useSuggestedVendorsQuery({
    client,
    variables: {
      brandIds: brandId ? [brandId] : [],
      nearPoint: {
        location: point,
        distance: 100,
      },
    },
    skip: !brandId || !point,
  })

  const preferredVendors = useMemo(
    () =>
      data?.dealerLocations.entries.filter((v) =>
        excludeVendorIds.length > 0 ? !excludeVendorIds.includes(v.id) : true
      ) ?? [],
    [data, excludeVendorIds]
  )

  const isSelected = (comparingDealerLocationId: string) =>
    !!selectedDealerLocationIds.find(
      (dealerLocationId) => dealerLocationId === comparingDealerLocationId
    )

  if (preferredVendors.length === 0) {
    return null
  }

  return (
    <div className="p-4 bg-gray-50 space-y-4 rounded-xl">
      <h4 className="text-base font-medium text-gray-600 flex items-center justify-between">
        Suggested Vendors
        <div className="flex gap-x-2">
          <button type="button" className="arrow-left">
            <ChevronLeftIcon className="inline-block w-5 h-5" />
          </button>
          <button type="button" className="arrow-right">
            <ChevronRightIcon className="inline-block w-5 h-5" />
          </button>
        </div>
      </h4>

      <Swiper
        modules={[Navigation, A11y]}
        spaceBetween={15}
        slidesPerView={1}
        navigation={{
          enabled: true,
          nextEl: '.arrow-right',
          disabledClass: 'text-gray-400',
          prevEl: '.arrow-left',
        }}
        breakpoints={{
          '1440': {
            slidesPerView: 2,
          },
          '1736': {
            slidesPerView: 3,
          },
          '2048': {
            slidesPerView: 4,
          },
        }}
      >
        {preferredVendors.map((dl) => (
          <SwiperSlide key={dl.id}>
            <Box key={dl.id} className="shadow-base p-4">
              <VendorCard
                name={dl.name}
                distance={dl.distance}
                logoUrl={dl.logoUrl}
                numberOfQuotes={dl.numOfQuotes}
                responseTimeSec={dl.timeToQuoteSec}
                badges={dl.vendor?.draft ? [VendorBadgeType.DraftVendor] : []}
                address={{
                  city: dl.address?.city ?? dl.cityState,
                  state: dl.address?.city ? dl.address?.state : null,
                }}
                actionButton={
                  isSelected(dl.id) ? (
                    <Action.S
                      className="flex-shrink-0 w-9 h-9"
                      onClick={() => onRemoveDealer(dl.id)}
                    >
                      <div className="flex flex-row justify-center items-center gap-x-1">
                        <CheckIcon className="flex flex-shrink-0 w-5 h-5" />
                      </div>
                    </Action.S>
                  ) : (
                    <Action.P
                      className="flex-shrink-0 w-[4.5rem] h-9"
                      color="blue"
                      onClick={() => onAddDealer(dl.id)}
                    >
                      Select
                    </Action.P>
                  )
                }
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SuggestedVendors
