import Ghost from '@/gf/components/Ghost'

const OrgMachineViewGhost: React.FC = () => (
  <div className="w-full sm:w-64">
    <div className="flex flex-col items-center p-2 gap-2 border-b border-slate-200 relative">
      <div>
        <div className="block rounded-full h-20 w-20 border border-2 border-white shadow bg-slate-200" />
      </div>
      <div>
        <Ghost className="block w-40 h-6 mb-2 mx-auto" />
        <Ghost className="block h-4 w-16 mx-auto" />
      </div>
    </div>

    <div className="p-2 flex flex-col gap-y-2">
      <Ghost className="w-12 h-4" />
      <Ghost className="w-24 h-4" />
      <Ghost className="w-12 h-4" />
      <Ghost className="w-24 h-4" />
      <Ghost className="w-12 h-4" />
      <Ghost className="w-24 h-4" />
      <Ghost className="w-12 h-4" />
      <Ghost className="w-36 h-4" />
      <Ghost className="w-12 h-4" />
      <Ghost className="w-40 h-4" />
      <Ghost className="w-12 h-4" />
      <Ghost className="w-20 h-4" />
      <Ghost className="w-12 h-4" />
      <Ghost className="w-24 h-4" />
    </div>
  </div>
)

export default OrgMachineViewGhost
