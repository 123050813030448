import { GraphQLError } from 'graphql'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'

import Button from '@/gf/components/ButtonOld'
import Card from '@/gf/components/Card'
import TextField from '@/gf/components/TextField'
import ProfileImage from './ProfileImage'

import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import Phone from '@/gf/modules/Phone'
import { useUpdateProfileMutation } from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import useReloadSession from '../hooks/useReloadSession'
import useSession from '../hooks/useSession'

const UpdateProfile = () => {
  const [_msgs, msgsMgr] = useMsgs()
  const { user } = useSession()
  const reloadSession = useReloadSession()
  const [updateUserSpinnerLive, updateUserSpinnerToggler] = useToggle()
  const [updateUser] = useUpdateProfileMutation({ client: useGqlClient() })

  const profileForm = useForm({
    shouldUnregister: true,
    defaultValues: { name: user.name, email: user.email, phoneNumber: user.phoneNumber || '' },
  })

  // Format/validate phone number
  const phoneNumber = useWatch({ control: profileForm.control, name: 'phoneNumber' })

  useEffect(() => {
    if (phoneNumber) profileForm.setValue('phoneNumber', Phone.format(phoneNumber))
  }, [phoneNumber])

  const onSubmit = profileForm.handleSubmit((profileData) => {
    updateUserSpinnerToggler.on()

    updateUser({ variables: { ...profileData, id: user.id } })
      .then(() => {
        reloadSession()
        msgsMgr.add('Updated user', 'positive')
      })
      .catch((e: GraphQLError) => msgsMgr.add(e.message, 'negative'))
      .finally(() => updateUserSpinnerToggler.off())
  })

  return (
    <form className="space-y-4" onSubmit={onSubmit}>
      <Card title="Personal Information">
        <Card.Section>
          <div className="flex gap-8">
            <div className="flex-1 space-y-4">
              <TextField label="Name" {...profileForm.register('name', { required: false })} />
              <TextField
                label="Phone Number"
                type="tel"
                {...profileForm.register('phoneNumber', { required: false })}
              />
              <TextField label="Email" {...profileForm.register('email', { required: false })} />
              <div className="flex justify-end">
                <Button title="Save" type="submit" performing={updateUserSpinnerLive} />
              </div>
            </div>
            <div className="space-y-4 text-center">
              <ProfileImage size="lg" showEditAlways />
              <div>Profile Picture</div>
            </div>
          </div>
        </Card.Section>
      </Card>
    </form>
  )
}

export default UpdateProfile
