import { DeliveryMethod, PaymentMethod, StoreOrderState, StoreOrderStep } from '@/buyers/_gen/gql'
import Box from '@/buyers/components/Box'
import Link from '@/gf/components/Link'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useOrderContext } from '../context'

const formatTimestamp = (dateTime: DateTime) =>
  `${dateTime.toLocaleString(DateTime.DATE_SHORT)} at ${dateTime.toLocaleString(
    DateTime.TIME_SIMPLE
  )}`

const buildNote = ({
  storeOrder,
  selectedDirect,
}: ReturnType<typeof useOrderContext> & { selectedDirect: boolean }) => {
  if (storeOrder.step === StoreOrderStep.Quoted)
    return {
      title: 'Quoted',
      body: (
        <>
          <p className="text-base">
            Review your quote and either accept or reject it.{' '}
            <>
              If there are parts in the quote that you don&apos;t want to purchase, uncheck them in
              your quote.
            </>
          </p>

          {selectedDirect && (
            <p className="text-base">
              If accepted, you&apos;ll receive an invoice from {storeOrder.store.name} and will pay
              them directly.
            </p>
          )}

          {storeOrder.insertedAt && (
            <p className="text-xs mt-2">
              Quote submitted: {formatTimestamp(storeOrder.insertedAt)}
            </p>
          )}
        </>
      ),
      classNames: {
        title: 'text-blue-700',
        box: 'border-blue-200 bg-blue-100',
      },
    }

  if (storeOrder.step === StoreOrderStep.PoSent)
    return {
      title: 'PO Sent',
      body: (
        <div className="space-y-4 text-base">
          {storeOrder.paymentMethod === PaymentMethod.Direct ? (
            <p>
              When your order{' '}
              {storeOrder.deliveryMethod === DeliveryMethod.Pickup
                ? 'is ready at will call'
                : 'ships'}
              , you will be notified.
              {storeOrder.invoices.length === 0 && (
                <> You&apos;ll also receive an invoice from your vendor.</>
              )}
            </p>
          ) : (
            <p>
              You will be notified when {storeOrder.store.name} approves and begins processing your
              order.
            </p>
          )}

          <div className="space-y-2">
            <p>
              Once{' '}
              {storeOrder.deliveryMethod === DeliveryMethod.Pickup
                ? 'you pick up your order'
                : 'your order arrives'}
              , please mark it received.
            </p>

            {storeOrder.approvedAt && (
              <p className="text-xs">PO Sent: {formatTimestamp(storeOrder.approvedAt)}</p>
            )}

            {storeOrder.paymentMethod === PaymentMethod.Direct && (
              <Link.P to="mark-received" className="w-full">
                Mark Received
              </Link.P>
            )}
          </div>
        </div>
      ),
      classNames: {
        title: 'text-green-700',
        box: 'border-green-200 bg-green-100',
      },
    }

  if (storeOrder.step === StoreOrderStep.Fulfilling) {
    const invoicesText =
      storeOrder.invoices.length === 0 ? (
        <p>You&apos;ll be notified when {storeOrder.store.name} sends the invoice.</p>
      ) : null
    return {
      title: 'Fulfilling',
      body: (
        <div className="flex flex-col space-y-4 items-stretch text-base">
          {storeOrder.deliveryMethod === DeliveryMethod.Pickup ? (
            <>
              <p>Your order is ready at will call.</p>
              {invoicesText}
              {storeOrder.readyAt && (
                <p className="text-xs">Ready at will call: {formatTimestamp(storeOrder.readyAt)}</p>
              )}
            </>
          ) : (
            <>
              <p>Your order is on the way.</p>
              {invoicesText}
              {storeOrder.readyAt && (
                <p className="text-xs">Order Shipped: {formatTimestamp(storeOrder.readyAt)}</p>
              )}
            </>
          )}

          {storeOrder.deliveryMethod === DeliveryMethod.Pickup && (
            <Link.P to="mark-received">Mark Picked Up</Link.P>
          )}

          {storeOrder.deliveryMethod === DeliveryMethod.Shipping &&
            storeOrder.shipments.length === 0 && (
              <Link.P to="mark-received" className="w-full">
                Mark Received
              </Link.P>
            )}
        </div>
      ),
      classNames: {
        title: 'text-yellow-600',
        box: 'border-yellow-200 bg-yellow-100',
      },
    }
  }

  if (storeOrder.step === StoreOrderStep.Fulfilled)
    return {
      title: 'Fulfilled',
      body: (
        <>
          <p className="text-base">
            Your order was{' '}
            {storeOrder.deliveryMethod === DeliveryMethod.Pickup ? 'picked up' : 'received'}.
          </p>
          {storeOrder.receivedAt && (
            <p className="text-xs mt-2">Received at: {formatTimestamp(storeOrder.receivedAt)}</p>
          )}
        </>
      ),
      classNames: {
        title: 'text-orange-500',
        box: 'border-orange-200 bg-orange-100',
      },
    }

  const isPartialApproval = storeOrder.lineItems.some((li) => !!li.rejectedAt)
  if (storeOrder.step === StoreOrderStep.Canceled && storeOrder.state === StoreOrderState.Refunded)
    return {
      title: 'Refunded',
      body: <p>Your order was refunded.</p>,
      classNames: {
        title: 'text-red-700',
        box: 'border-red-200 bg-red-100',
      },
    }
  if (storeOrder.step === StoreOrderStep.Canceled && storeOrder?.rejection)
    return {
      title: 'Quote Denied',
      body: (
        <div className="space-y-4 text-base">
          <p>
            {isPartialApproval
              ? 'The quote was sent back to the vendor to be updated with only the part(s) approved.'
              : 'Quote was denied.'}
          </p>
          <div className="space-y-2">
            {!isPartialApproval && (
              <>
                <div className="font-medium">Reason:</div>
                <div className="text-base whitespace-pre-wrap">{storeOrder.rejection.reason}</div>
              </>
            )}

            {storeOrder.rejection.rejectedAt && (
              <p className="text-sm">Denied: {formatTimestamp(storeOrder.rejection.rejectedAt)}</p>
            )}
          </div>
        </div>
      ),
      classNames: {
        title: 'text-purple-700',
        box: 'border-purple-200 bg-purple-100',
      },
    }

  if (storeOrder.step === StoreOrderStep.Canceled)
    return {
      title: 'Canceled',
      body: storeOrder.rejection ? (
        <div className="space-y-4">
          <div>Quote was rejected.</div>

          <div className="space-y-2">
            <div className="font-medium">Reason:</div>
            {storeOrder.rejection.reason ? (
              <div className="text-base whitespace-pre-wrap">{storeOrder.rejection.reason}</div>
            ) : (
              <div className="italic text-gray-500">(not provided)</div>
            )}
          </div>
          {storeOrder.rejection.rejectedAt && (
            <p className="text-sm">Rejected: {formatTimestamp(storeOrder.rejection.rejectedAt)}</p>
          )}
        </div>
      ) : (
        <p className="text-base">Your order was canceled.</p>
      ),
      classNames: {
        title: 'text-red-700',
        box: 'border-red-200 bg-red-100',
      },
    }

  throw new Error('Unexpected step', storeOrder.step)
}

const StepNote = ({ selectedDirect }: { selectedDirect: boolean }) => {
  const note = buildNote({ ...useOrderContext(), selectedDirect })

  return (
    <Box className={classNames('space-y-2 text-center', note.classNames.box)}>
      <h3 className={classNames('text-lg font-medium', note.classNames.title)}>{note.title}</h3>
      {note.body}
    </Box>
  )
}

export default StepNote
