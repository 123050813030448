import type { SessionQuery } from './_gen/gql'

export type Session = SessionQuery['session']
export type SessionUser = Exclude<Session['user'], null>
export type SessionOrganization = Exclude<Session['organization'], null>
export type FeatureFlags = Session['featureFlags']

export enum VendorTypeFilter {
  All,
  ActiveOnGf,
  MyDealers,
  Preferred,
}
