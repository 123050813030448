import React from 'react'
import nth from 'lodash/nth'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { DateTime } from 'luxon'

import { TableV2 as Table, Thead, Tr, Th, Tbody, Td } from '@/gf/components/Table'
import Dropdown from '@/gf/components/Dropdown'

const backorderedIcon = <div className="rounded-full bg-yellow-300 h-3 w-3" />

type Part = {
  id: string
  backordered: boolean
  availableAt: DateTime | null
  description: string
  mpn: string
  quantity: number
}

const PartsCell = ({
  parts,
  areLineItems,
  children,
}: {
  parts: Part[]
  areLineItems: boolean
  children: React.ReactNode
}) => {
  const someBackordered = parts.some((p) => p.backordered)
  const firstPart = nth(parts, 0)

  const title = firstPart && (
    <div className="flex gap-6">
      <div className="flex gap-2">
        <div className="flex gap-2 items-center">
          {someBackordered && backorderedIcon}
          <span className="truncate max-w-72">{firstPart.description || firstPart.mpn}</span>
        </div>

        <span>x {firstPart.quantity}</span>
      </div>

      {parts.length > 1 && (
        <div className="flex-shrink-0">
          <span className="font-medium text-xs bg-slate-100 text-slate-500 rounded-full px-2 py-1">
            +{parts.length - 1}
          </span>
        </div>
      )}
    </div>
  )

  return (
    <Td pad={false}>
      {parts.length === 0 ? (
        <div className="px-6 py-3" />
      ) : (
        <Dropdown title={title} stopEventPropagation dropdownPad={false}>
          <div className="space-y-6">
            <Table>
              <Thead>
                {someBackordered && <Th />}
                {areLineItems && <Th>Available</Th>}
                <Th>MPN</Th>
                <Th>Desc.</Th>
                <Th>Qty</Th>
              </Thead>

              <Tbody>
                {parts.map((part) => (
                  <Tr key={part.id}>
                    {someBackordered && <Td>{part.backordered && backorderedIcon}</Td>}

                    {areLineItems && (
                      <Td>
                        {part.backordered ? (
                          part.availableAt?.toLocaleString(DateTime.DATETIME_SHORT)
                        ) : (
                          <div className="flex gap-1 items-center justify-center text-green-600">
                            <CheckCircleIcon className="w-5 h-5" />
                            <span>In Stock</span>
                          </div>
                        )}
                      </Td>
                    )}

                    <Td>{part.mpn}</Td>

                    <Td wrap className="max-w-72">
                      {part.description}
                    </Td>

                    <Td>{part.quantity}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            {someBackordered && (
              <div className="flex gap-2 items-center text-sm text-gray-500 py-2 px-4 bg-gray-50">
                {backorderedIcon}
                <div>Part is backordered</div>
              </div>
            )}
          </div>
        </Dropdown>
      )}
      {children}
    </Td>
  )
}

export default PartsCell
