import classNames from 'classnames'
import { nth, uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import { Fragment, useEffect, useMemo, useRef } from 'react'
import Attachment from '../../modules/Attachment'
import ConversationM from '../../modules/Conversation'
import Time from '../../modules/Time'
import User from '../../modules/User'
import ConversationRowIcon from '../ConversationRowIcon'
import type { Message } from '../Inbox'
import MessageAttachment from '../MessageAttachment'
import Spinner from '../Spinner'

type Conversation = {
  id: string
  admin: boolean
  requestForQuote: { user: { organization: { id: string } | null } | null } | null
  storeOrder: { order: { user: { organization: { id: string } | null } | null } | null } | null
}

type ReadReceipt = {
  id: string
  updatedAt: DateTime
  user: {
    id: string
    name: string | null
    role: string
    organization: { id: string } | null
  }
}

const InboxMessages = ({
  conversation,
  messages,
  readReceiptsForConversation,
  userId,
  userOrganizationId,
  displayCustomerContactInfo,
  containerClassName,
}: {
  conversation: Conversation
  messages: Message[] | undefined
  readReceiptsForConversation: ReadReceipt[] | undefined
  userId: string
  userOrganizationId?: string
  displayCustomerContactInfo: boolean
  containerClassName?: string
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null)

  // Scroll to the bottom of the messages when the messages load and there's a new message
  // TODO: figure out a better way to do this
  useEffect(() => {
    if (messages) messagesEndRef.current?.scrollIntoView()
  }, [messagesEndRef, messages, readReceiptsForConversation])

  const getDisplayName = (user: { name: string | null; organization: { id: string } | null }) =>
    displayCustomerContactInfo ||
    (conversation.requestForQuote
      ? conversation.requestForQuote.user?.organization?.id !== user.organization?.id
      : conversation.storeOrder?.order?.user?.organization?.id !== user.organization?.id)
      ? user.name
      : ConversationM.getHiddenCustomerName(user.name)

  const readReceiptsDisplay = useMemo(() => {
    if (!messages || !readReceiptsForConversation) return undefined

    const lastMessageUserId = nth(messages, 0)?.user.id
    // Get users who have sent a message in the conversation thread
    const activeUsers = uniqBy(
      messages.map((message) => message.user),
      ({ id }) => id
    )
    const { active: activeReadReceipts, inactive: inactiveReadReceipts } =
      readReceiptsForConversation.reduce(
        (
          {
            active: activeAcc,
            inactive: inactiveAcc,
          }: { active: ReadReceipt[]; inactive: ReadReceipt[] },
          readReceipt
        ) =>
          // Filter out the current user and the users not in the conversation thread
          readReceipt.user.id !== userId && activeUsers.some(({ id }) => id === readReceipt.user.id)
            ? { active: [...activeAcc, readReceipt], inactive: inactiveAcc }
            : { active: activeAcc, inactive: [...inactiveAcc, readReceipt] },
        { active: [], inactive: [] }
      )
    // Add read receipts for users who haven't sent a message. Limit to one read receipt, and only add if user is from
    // the opposite party in the conversation
    const inactiveReadReceipt =
      activeReadReceipts.length === 0
        ? inactiveReadReceipts.find(
            (readReceipt) =>
              readReceipt.user.id !== userId &&
              readReceipt.user.id !== lastMessageUserId &&
              readReceipt.user.organization?.id !== userOrganizationId &&
              (!User.isAdmin(readReceipt.user) || conversation.admin)
          )
        : undefined
    return [
      ...activeReadReceipts.map(({ updatedAt, user }) => ({
        updatedAt,
        name: getDisplayName(user),
        userId: user.id,
      })),
      ...(inactiveReadReceipt
        ? [
            {
              updatedAt: inactiveReadReceipt.updatedAt,
              name: '',
              userId: inactiveReadReceipt.user.id,
            },
          ]
        : []),
      // Don't show read receipt for the sender if it's the most recent message
    ].filter((readReceipt) => readReceipt.userId !== lastMessageUserId)
  }, [readReceiptsForConversation, messages])

  return (
    <div
      className={classNames(
        'pt-4 pb-1 px-4 flex flex-col grow overflow-y-scroll',
        containerClassName
      )}
    >
      {!messages ? (
        <div className="flex grow justify-center items-center">
          <Spinner />
        </div>
      ) : messages.length === 0 ? (
        <div className="flex grow text-sm text-gray-500 justify-center items-center">
          Send a message
        </div>
      ) : (
        <>
          <div className="flex justify-center mx-1 mb-2 text-xs text-gray-500">
            Beginning of conversation
          </div>
          {[...messages].reverse().map((message, index, array) => {
            const messageFromUser = message.user?.id === userId
            const messageUserName = getDisplayName(message.user)
            const nextMessage = index !== array.length - 1 ? array[index + 1] : undefined
            const messageReadReceipts = uniqBy(
              readReceiptsDisplay
                ?.filter(
                  (readReceipt) =>
                    message.insertedAt < readReceipt.updatedAt &&
                    (!nextMessage || readReceipt.updatedAt < nextMessage.insertedAt)
                )
                .sort((a, b) => (a.updatedAt < b.updatedAt ? -1 : 1)),
              ({ name }) => name
            )
            return (
              <Fragment key={message.id}>
                <div
                  className={classNames('my-2 flex flex-row justify-start space-x-1', {
                    'self-start items-start': !messageFromUser,
                    'self-end items-end': messageFromUser,
                  })}
                >
                  {/* User icon */}
                  {!messageFromUser && (
                    <ConversationRowIcon.User
                      type={
                        User.isAdmin(message.user)
                          ? 'admin'
                          : message.user.organization?.id &&
                            message.user.organization.id ===
                              (
                                conversation.requestForQuote?.user ||
                                conversation.storeOrder?.order?.user
                              )?.organization?.id
                          ? 'buyer'
                          : 'supplier'
                      }
                      name={messageUserName}
                    />
                  )}

                  <div
                    className={classNames('flex flex-col space-y-0.5', {
                      'self-start items-start': !messageFromUser,
                      'self-end items-end': messageFromUser,
                    })}
                  >
                    {/* Attachments */}
                    {message.attachmentUrls.map((attachmentUrl) => (
                      <a
                        key={`${message.id}-${attachmentUrl}`}
                        className={classNames(
                          'inline-flex',
                          messageFromUser ? 'self-end items-end' : 'self-start items-start'
                        )}
                        href={attachmentUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {attachmentUrl.includes('.pdf') ? (
                          <MessageAttachment
                            className="max-w-44 xs:max-w-56 sm:max-w-72"
                            name={Attachment.nameFromUrl(attachmentUrl)}
                            blue={messageFromUser}
                          />
                        ) : attachmentUrl.includes('.mov') || attachmentUrl.includes('.mp4') ? (
                          // eslint-disable-next-line jsx-a11y/media-has-caption
                          <video controls>
                            <source src={attachmentUrl} />
                          </video>
                        ) : (
                          <img
                            className="rounded-lg max-w-44 xs:max-w-56 sm:max-w-72 max-h-52"
                            src={attachmentUrl}
                            alt={attachmentUrl}
                          />
                        )}
                      </a>
                    ))}

                    {message.text !== '' && (
                      <div
                        className={classNames(
                          messageFromUser
                            ? 'bg-blue-500 text-gray-50 ml-12 mr-0'
                            : 'bg-gray-200 text-gray-900 ml-0 mr-12',
                          'py-2 px-3 inline-flex text-sm rounded-lg whitespace-pre-wrap'
                        )}
                      >
                        {message.text}
                      </div>
                    )}
                    <div className="mx-1 text-xs text-gray-500">
                      {Time.formatDateTimeSmartYear(message.insertedAt)}
                    </div>
                    {message.user && message.user.id !== userId && (
                      <div className="mx-1 text-xs text-gray-500">
                        {messageUserName}
                        {User.isAdmin(message.user) ? ' (Gearflow Admin)' : ''}
                      </div>
                    )}
                  </div>
                </div>
                {messageReadReceipts && messageReadReceipts.length > 0 && (
                  <div
                    key={`${message.id}-read-receipts`}
                    className="w-full flex flex-col gap-y-1 justify-center items-center"
                  >
                    {messageReadReceipts.map((readReceipt) => (
                      <div
                        key={`read-receipt-${readReceipt.userId}`}
                        className="max-w-sm flex flex-row justify-center items-center gap-x-1 text-xs text-gray-500"
                      >
                        <div className="w-8 border-b border-gray-300" />
                        <div className="text-center">
                          Seen {readReceipt.name ? `by ${readReceipt.name}` : ''}{' '}
                          {Time.formatDateTimeSmartYear(readReceipt.updatedAt)}
                        </div>
                        <div className="w-8 border-b border-gray-300" />
                      </div>
                    ))}
                  </div>
                )}
              </Fragment>
            )
          })}
          <div ref={messagesEndRef} key="messages-end" aria-hidden="true" />
        </>
      )}
    </div>
  )
}

export default InboxMessages
