import { XCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

const RedAlert = ({
  title = 'Error',
  action,
  className,
  children,
}: {
  title?: string
  action?: React.ReactNode
  className?: string | undefined
  children?: React.ReactNode
}) => (
  <div className={classNames('rounded-md bg-red-50 p-4', className)}>
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3 flex flex-col grow">
        <div className="flex flex-row grow justify-between items-center">
          <h3
            className={classNames(
              'text-sm text-red-800',
              children ? 'text-red-800 font-medium' : 'text-red-700'
            )}
          >
            {title}
          </h3>
        </div>
        {children && <div className="mt-2 text-sm text-red-700">{children}</div>}
      </div>
      {action && <div className="self-center">{action}</div>}
    </div>
  </div>
)

export default RedAlert
