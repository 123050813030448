import { DateTime } from 'luxon'

import { getDurationDatesString, getDurationDisplay } from '@/gf/components/Reports/DurationInput'

const getSelectedValueRequestsTableDisplay = (display: string, tab: string) =>
  tab === 'Urgency' ? `${display === 'None' ? 'No' : display} Urgency` : display

const RequestsTableHeader = ({
  selectedValue,
  selectedDate,
  form,
}: {
  selectedValue?: { display: string }
  selectedDate?: DateTime
  form: {
    tab: string
    durationId: string
    durationStart: DateTime
    durationEnd: DateTime
  }
}) => (
  <>
    {selectedValue
      ? `${getSelectedValueRequestsTableDisplay(selectedValue.display, form.tab)} `
      : ''}
    Part Requests{' '}
    {selectedDate
      ? selectedDate.toLocaleString(DateTime.DATE_MED)
      : form.durationId !== 'custom'
      ? getDurationDisplay(form.durationId)
      : getDurationDatesString(form)}
  </>
)
export default RequestsTableHeader
