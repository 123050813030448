import nth from 'lodash/nth'
import { useNavigate } from 'react-router-dom'

import { useFetchOrganizationUsersQuery, useRfqDetailsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import { useTimelineEventsForRfq } from '@/gf/hooks/useTimelineEvents'
import useToggle from '@/gf/hooks/useToggle'
import Phone from '@/gf/modules/Phone'
import StoreOrderEventContext from '@/gf/modules/StoreOrderEvent'
import Time from '@/gf/modules/Time'
import UserContext from '@/gf/modules/User'
import useSession from '../../hooks/useSession'
import useUpdateRfqBuyerNotificationUsers from '../../hooks/useUpdateRfqBuyerNotificationUsers'
import { useContext } from '../RFQ'

import Address from '@/gf/components/Address'
import Card from '@/gf/components/Card'
import Desc from '@/gf/components/Desc'
import Layout from '@/gf/components/Layout'
import LinkButton from '@/gf/components/LinkButtonOld'
import RfqEvents from '@/gf/components/RfqEvents'
import SelectUsersModal from '@/gf/components/SelectUsersModal'
import RFQCommentForm from '../../components/RFQCommentForm'
import EditWorkOrderModal from './EditWorkOrderModal'

const Details = ({ refetch }: { refetch: () => Promise<unknown> }) => {
  const {
    user,
    organization: { id: orgId },
  } = useSession()

  const { rfq } = useContext()
  const navigate = useNavigate()
  const [_msgs, msgsMgr] = useMsgs()
  const eventsSearch = useTimelineEventsForRfq(rfq.id).data?.eventsSearch
  const [notificationUsersModalOpen, notificationUsersModalToggler] = useToggle()
  const [workOrderOpen, workOrderToggle] = useToggle()
  const [spinnerLive, spinnerLiveToggle] = useToggle()
  const gqlClient = useGqlClient()
  const usersResult = useFetchOrganizationUsersQuery({ client: gqlClient })

  const { data: rfqNext, refetchRequest } = (() => {
    const { refetch: refetchNext, data } = useRfqDetailsQuery({
      variables: { orgId, filter: JSON.stringify(['id_eq', rfq.id]) },
      client: gqlClient,
    })

    const request = data && nth(data.rfqs, 0)
    return { data: request, refetchRequest: refetchNext }
  })()

  const [updateRfqBuyerNotificationUsers] = useUpdateRfqBuyerNotificationUsers([
    useTimelineEventsForRfq.Query,
  ])

  if (!rfqNext) return null

  const shippingAddress = rfq.shippingAddress ?? rfq.user?.shippingAddress

  return (
    <Layout>
      <EditWorkOrderModal
        refetch={refetchRequest}
        onClose={workOrderToggle.off}
        open={workOrderOpen}
        requestId={rfq.id}
        woNumber={rfqNext.workOrderNumber}
      />
      <SelectUsersModal
        titleText="Update Notification Recipients"
        detailsText="Select the people who receive notifications for this RFQ."
        open={notificationUsersModalOpen}
        onClose={notificationUsersModalToggler.off}
        onSubmit={(userIds) => {
          spinnerLiveToggle.on()
          updateRfqBuyerNotificationUsers({ variables: { rfqId: rfq.id, userIds } })
            .then(() => refetch())
            .catch(() => {
              msgsMgr.add('Whoops, something went wrong. Please contact support.', 'negative')
            })
            .finally(() => {
              notificationUsersModalToggler.off()
              spinnerLiveToggle.off()
            })
        }}
        spinnerLive={spinnerLive}
        users={usersResult.data?.fetchOrganizationUsers}
        usersLoading={usersResult.loading}
        usersError={!!usersResult.error}
        initialSelectedUsers={rfq.buyerNotificationUsers}
      />
      <Layout.Section type="ipad" className="xl:col-span-4">
        <Card title="Timeline">
          <Card.Section>
            <Card.SubSection>
              <RFQCommentForm
                rfqId={rfq.id}
                refetch={refetch}
                isAdmin={UserContext.isAdmin(user)}
                openMessagesTab={() => navigate('messages')}
              />
            </Card.SubSection>
            <Card.SubSection>
              <RfqEvents
                events={eventsSearch?.rfqEvents}
                storeOrderEvents={eventsSearch?.storeOrderEvents}
                storeOrderEventMapper={StoreOrderEventContext.getBuyerEventDisplayData}
                showStoreNames
              />
            </Card.SubSection>
          </Card.Section>
        </Card>
      </Layout.Section>
      <Layout.Section type="ipad" className="xl:col-span-2">
        {rfq.user && (
          <Card title="Info.">
            <Card.Section>
              <Desc.List>
                <Desc.Row title="Created by">{rfq.user.name}</Desc.Row>
                <Desc.Row title="Created on">{Time.formatDateTime(rfq.insertedAt)}</Desc.Row>
                <Desc.Row title="Contact">
                  <div>
                    <p>{rfq.fullName}</p>
                    {rfq.emailAddress && <p className="break-words">{rfq.emailAddress}</p>}
                    <p>{Phone.format(rfq.phoneNumber)}</p>
                  </div>
                </Desc.Row>
                <Desc.Row title="Shipping address">
                  {shippingAddress ? (
                    <Address address={shippingAddress} />
                  ) : (
                    <p className="text-gray-400 italic">(none)</p>
                  )}
                </Desc.Row>
                <Desc.Row title="Work order number">
                  {rfqNext.workOrderNumber}{' '}
                  <LinkButton onClick={workOrderToggle.on}>Edit</LinkButton>
                </Desc.Row>
              </Desc.List>
            </Card.Section>
          </Card>
        )}

        <Card
          title="Notifications"
          headerButton={<LinkButton onClick={notificationUsersModalToggler.on}>Edit</LinkButton>}
        >
          <Card.Section>
            {rfq.buyerNotificationUsers.length === 0 ? (
              <p className="text-sm text-gray-700">
                None. Click &apos;Edit&apos; to subscribe/unsubscribe users to/from notifications on
                this RFQ.
              </p>
            ) : (
              rfq.buyerNotificationUsers.map((u) => (
                <p key={u.id} className="text-sm text-gray-700">
                  {u.name}
                </p>
              ))
            )}
          </Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  )
}

export default Details
