import { useEffect, useState } from 'react'
import { US_CENTER } from '../modules/Map'

export default function useCurrentPosition(): {
  loading: boolean
  coords: GeolocationCoordinates | null
} {
  const [position, setPosition] = useState<GeolocationPosition | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!window.navigator) {
      return
    }

    navigator.geolocation.getCurrentPosition(
      (currentPosition) => {
        setPosition(currentPosition)
        setLoading(false)
      },
      (err) => {
        console.error(err)
        // permission denied error on local environment returns US center
        if (err.code === 1 && window.location.hostname.indexOf('.local.gearflow')) {
          setPosition({
            coords: {
              accuracy: 0,
              latitude: US_CENTER.lat,
              longitude: US_CENTER.lng,
              altitude: null,
              altitudeAccuracy: null,
              heading: null,
              speed: null,
            },
            timestamp: new Date().getMilliseconds(),
          })
        }
        setLoading(false)
      },
      {
        timeout: 1000,
      }
    )
  }, [])

  return { loading, coords: position?.coords ?? null }
}
