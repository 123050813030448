import Box from '@/buyers/components/Box'
import Link from '@/gf/components/Link'
import Phone from '@/gf/modules/Phone'
import { ChatAltIcon } from '@heroicons/react/outline'
import { useOrderContext } from './context'

const InfoLine = ({ label, content }) => (
  <div className="flex gap-x-8 text-sm">
    <div className="w-full max-w-[8.125rem] leading-5">{label}</div>
    <div className="leading-5">{content}</div>
  </div>
)

const VendorDetails = () => {
  const { storeOrder } = useOrderContext()
  const { store } = storeOrder

  const request = storeOrder.order.requestForQuote
  const vendorLink = request?.vendorLinks.find((vl) => vl.vendor.storeId === store.id)

  return (
    <Box className="space-y-6">
      <div className="flex gap-x-4 items-center">
        {store.logo?.url && (
          <img
            src={store.logo.url}
            alt=""
            className="h-12 w-12 rounded-full p-1 object-contain bg-white shadow-base"
          />
        )}
        <div>
          <h5 className="text-lg font-medium">{store.name}</h5>
          {store.address && (
            <p>
              {store.address.city}, {store.address.state}
            </p>
          )}
        </div>
        <Link.S to={`../messages/store/${store.id}`}>
          <ChatAltIcon className="inline-block w-4 h-4" /> Send Message
        </Link.S>
      </div>

      <div>
        <h4 className="text-lg font-medium mb-2">Vendor Info</h4>
        <InfoLine label="Name:" content={store.name} />
        <InfoLine label="Account number:" content={vendorLink?.accountNumber ?? 'N/A'} />
        <InfoLine label="Created by:" content={storeOrder?.createdBy?.name} />

        <InfoLine
          label="Phone:"
          content={
            storeOrder?.createdBy?.phoneNumber
              ? Phone.format(storeOrder.createdBy.phoneNumber)
              : store.phoneNumber
              ? Phone.format(store.phoneNumber)
              : ''
          }
        />
      </div>

      {request && (
        <div>
          <h4 className="text-lg font-medium mb-2">Request Status</h4>
          <InfoLine label="Requested date:" content={request.insertedAt.toFormat('D')} />
          <InfoLine label="Needed by:" content={request.neededBy?.toFormat('D')} />
        </div>
      )}
    </Box>
  )
}

export default VendorDetails
