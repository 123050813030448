import UploadInventory from '@/buyers/components/UploadInventory'
import useApolloWsClient from '@/buyers/hooks/useApolloWsClient'
import ExportProductsModal from '@/dealers/components/ExportProducts'
import AvailabilityStatus from '@/dealers/components/Products/AvailabilityStatus'
import Button from '@/gf/components/ButtonOld'
import Ghost from '@/gf/components/Ghost'
import DelayedSearchInput from '@/gf/components/inputs/DelayedSearch'
import Layout from '@/gf/components/Layout'
import Page from '@/gf/components/Page'
import PaginationC from '@/gf/components/Pagination'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import usePage from '@/gf/hooks/usePage'
import useSearchQueryParam from '@/gf/hooks/useSearchQueryParam'
import useToggle from '@/gf/hooks/useToggle'
import {
  useAllInventoryQuery,
  useInventoryUploadProgressQuery,
  useInventoryUpsertProgressSubscription,
} from '../_gen/gql'
import Frame from '../components/Frame'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [{ name: 'Inventory', href: '/inventory' }],
}

const Loading = () => (
  <>
    {[1, 2, 3].map((n) => (
      <Tr key={n}>
        <Td>
          <Ghost className="inline-block w-32 h-4" />
        </Td>
        <Td>
          <Ghost className="inline-block w-56 h-4" />
        </Td>
      </Tr>
    ))}
  </>
)

const Inventory = () => {
  const [showUpload, uploadToggle] = useToggle()
  const [showExport, exportToggle] = useToggle()
  const { organization, featureFlags } = useSession()
  const { search, updateSearch } = useSearchQueryParam()
  const [page, setPage] = usePage()

  const {
    refetch: refetchInventory,
    data,
    loading,
  } = useAllInventoryQuery({
    variables: { page, search },
    client: useGqlClient(),
  })

  const products = data?.allInventory.results ?? []
  const pagination = data?.allInventory.pagination

  const { refetch, data: progressData } = useInventoryUploadProgressQuery({
    client: useGqlClient(),
    variables: {
      orgId: organization.id,
    },
  })

  const progressComplete = progressData?.inventoryUploadProgress ?? null

  useInventoryUpsertProgressSubscription({
    variables: { orgId: organization.id },
    client: useApolloWsClient(),
    onData: () => {
      refetchInventory()
      refetch()
    },
  })

  const onCloseUpload = () => {
    refetch()
    uploadToggle.off()
  }

  const actions = featureFlags.hideInventoryUpload
    ? undefined
    : products.length > 0
    ? [
        <Button
          key="export-products"
          type="button"
          primary={false}
          onClick={() => exportToggle.on()}
        >
          Export
        </Button>,
        <Button
          key="upload-products"
          type="button"
          primary={false}
          onClick={() => uploadToggle.on()}
        >
          Upload
        </Button>,
      ]
    : [
        <Button
          key="upload-products"
          type="button"
          primary={false}
          onClick={() => uploadToggle.on()}
        >
          Upload
        </Button>,
      ]

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Inventory" titleRightContent={actions}>
        <div className="space-y-4 mt-4">
          <DelayedSearchInput
            value={search}
            autoFocus
            setValue={(term) => {
              updateSearch(term)
              setPage(1)
            }}
          />

          <Layout.Section type="table">
            <ExportProductsModal show={showExport} close={exportToggle.off} label="inventory" />
            <UploadInventory show={showUpload} close={onCloseUpload} />
            {(progressComplete === 0 || progressComplete) && (
              <div className="px-4 py-4">
                <h4 className="sr-only">Status</h4>
                <p className="text-sm text-gray-900">Importing inventory...</p>
                <div className="mt-6" aria-hidden="true">
                  <div className="overflow-hidden rounded-full bg-gray-200">
                    <div
                      className="h-2 rounded-full bg-indigo-600"
                      style={{ width: `${progressComplete}%` }}
                    />
                  </div>
                </div>
              </div>
            )}
            <Table>
              <Thead>
                <Tr>
                  <Th className="w-40">Part Number</Th>
                  <Th>Name</Th>
                  <Th>Quantity</Th>
                  <Th>Availability</Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  <Loading />
                ) : products.length === 0 ? (
                  <Tr>
                    <Td colSpan={2}>No inventory.</Td>
                  </Tr>
                ) : (
                  products.map((product) => (
                    <Tr key={product.id}>
                      <Td>{product.mpn}</Td>
                      <Td>{product.name}</Td>
                      <Td>{product.quantity}</Td>
                      <Td className="px-6" pad={false}>
                        {product.availability ? (
                          <AvailabilityStatus
                            availability={product.availability}
                            leadTime={product.leadTime}
                            leadTimeDate={product.leadTimeDate}
                          />
                        ) : (
                          <span className="text-xs italic text-slate-400">
                            Unknown availability status
                          </span>
                        )}
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </Layout.Section>
          <Layout.Section type="full">
            <PaginationC pagination={pagination} page={page} updatePage={setPage} />
          </Layout.Section>
        </div>
      </Page>
    </Frame>
  )
}

export default Inventory
