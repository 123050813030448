import { StoreOrdersSearchLiteQuery } from '@/buyers/_gen/gql'
import StoreOrderStepBadge from '@/buyers/components/StoreOrderStepBadge'
import Money from '@/gf/modules/Money'
import Time from '@/gf/modules/Time'
import { PlusIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import OrderCardGhost from './OrderCardGhost'

type StoreOrder = StoreOrdersSearchLiteQuery['paginatedStoreOrders']['storeOrders'][number]

const LineItem = ({
  item,
  showTopBorder = false,
}: {
  item: StoreOrder['lineItems'][number]
  showTopBorder: boolean
}) => (
  <div
    className={classNames('flex w-full gap-x-3 items-center px-3 py-2', {
      'border-t': showTopBorder,
    })}
  >
    {/* <img
      src={ProductM.primaryImageURL(item.product, 'card')}
      alt={item.product.name}
      className="w-16 h-16 border-2 border-white shadow rounded-md overflow-hidden"
    /> */}
    <div className="flex flex-1 flex-col">
      <p className="text-slate-600">{item.product.name}</p>
      {item.showMpn && (
        <p className="text-sm text-gray-500 font-medium mt-1">
          MPN: <span className="text-slate-600 font-semibold">{item.product.mpn}</span>
        </p>
      )}
    </div>
    <p className="text-sm text-gray-700">
      {Money.format(item.discountedUnitPrice)} x {item.quantity}
    </p>
    <p className="text-sm text-gray-700">{Money.format(item.extendedPrice)}</p>
  </div>
)

const OrderCard = ({
  loading,
  storeOrder,
  className,
}: {
  storeOrder: StoreOrder | null
  loading: boolean
  className?: string
}) => {
  if (loading) return <OrderCardGhost />
  if (storeOrder === null) return null

  return (
    <div className={classNames('border rounded-lg', className)}>
      <div className="flex justify-between items-center px-3">
        <div className="pb-2 pt-2">
          <h3 className="text-xl text-slate-600 leading-5">Latest Order</h3>
          <p className="text-gray-500 italic text-xs mt-1">
            {Time.toString(storeOrder.insertedAt)}
          </p>
        </div>
        <div>
          <StoreOrderStepBadge step={storeOrder.step} />
        </div>
      </div>

      <div className="border-t">
        {/* Only show the first two items in an order */}
        {storeOrder.lineItems.slice(0, 2).map((item, index) => (
          <React.Fragment key={item.product.id}>
            <LineItem item={item} showTopBorder={index > 0} />
            {/* Show ellipses if there are more line items */}
            {index === 1 && storeOrder.lineItems.length > 2 && (
              <Link
                className="py-2 text-center flex justify-center items-center -mb-2 border-t text-blue-500 hover:bg-slate-50 underline text-sm"
                to={`/orders/${storeOrder.id}`}
              >
                <PlusIcon className="h-5" /> {storeOrder.lineItems.length - 2} items
              </Link>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="border-t rounded-b-lg bg-slate-50 flex justify-between items-center py-2 px-4">
        <Link
          className="text-blue-500 underline"
          to={`/orders/${storeOrder.id}`}
          title={`Order ID ${storeOrder.shortId}`}
        >
          View Order
        </Link>

        <p className="font-semibold text-zinc-800  text-right">
          {Money.format(storeOrder.total)} total
        </p>
      </div>
    </div>
  )
}

export default OrderCard
