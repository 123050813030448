import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { nth, pick } from 'lodash'

import { Maybe } from '@/types'

import {
  useUserLocationsQuery,
  useShippingLocationsSearchQuery,
  Location,
  ShippingLocationsSearchDocument,
  UserLocationsDocument,
  ShippingLocationsSearchQueryResult,
  UserLocationsQueryResult,
  Point,
  Address,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useDebounce from '@/gf/hooks/useDebounce'
import AddressM from '@/gf/modules/Address'

import Combobox from '@/gf/components/next/Combobox'

export const Queries = [ShippingLocationsSearchDocument, UserLocationsDocument]

type LocationT = Pick<Location, 'id' | 'name' | 'deleted'> & {
  address: Pick<Address, 'lineOne' | 'lineTwo' | 'city' | 'state' | 'postalCode' | 'country'> & {
    point: Maybe<Pick<Point, 'lat' | 'lng'>>
  }
}

const toStandardLocation = (
  location:
    | NonNullable<UserLocationsQueryResult['data']>['locations'][number]
    | NonNullable<
        ShippingLocationsSearchQueryResult['data']
      >['shippingLocationsSearch']['shippingLocations'][number]
): LocationT => ({
  ...pick(location, ['id', 'name', 'deleted']),
  address: {
    ...pick(location.address, ['lineOne', 'lineTwo', 'city', 'state', 'postalCode', 'country']),
    point: location.address.point ? pick(location.address.point, ['lat', 'lng']) : null,
  },
})

const LocationSelector = ({
  value,
  onChange,
  className,
  containerClassName,
}: {
  value: Maybe<string>
  onChange: (newValue: LocationT) => void
  className?: string
  containerClassName?: string
}) => {
  const client = useGqlClient()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const { data: valueData } = useShippingLocationsSearchQuery({
    client,
    variables: { filters: [['equals', 'id', value]], page: 1 },
    skip: !value,
  })
  const valueDataLocation = value
    ? nth(valueData?.shippingLocationsSearch.shippingLocations, 0) ?? null
    : null
  const valueLocation = valueDataLocation ? toStandardLocation(valueDataLocation) : null
  const debouncedSearch = useDebounce(search, 200)
  const { data, loading } = useUserLocationsQuery({
    client,
    variables: { search: debouncedSearch },
  })
  const [options, setOptions] = useState(data?.locations.map(toStandardLocation) ?? [])

  useEffect(() => {
    if (data) setOptions(data.locations.map(toStandardLocation))
  }, [data])

  return (
    <Combobox
      containerClassName={twMerge('w-86', containerClassName)}
      className={className}
      open={open}
      value={valueLocation}
      options={options}
      onChange={(newValue) => {
        if (newValue) {
          onChange(newValue)
          setSearch('')
          setOpen(false)
        }
      }}
      onQueryChange={setSearch}
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
      getOptionDisplay={(location) =>
        !location.deleted ? location.name : AddressM.formatOneLine(location.address)
      }
      showAllOptions
      placeholder="Search..."
      emptyValue={loading ? 'Loading...' : undefined}
    />
  )
}

export default LocationSelector
