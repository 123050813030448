import { useState } from 'react'

import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import useUppy from '@/gf/hooks/useUppy'
import Attachment from '@/gf/modules/Attachment'
import { useFetchOrgQuery, useImportFleetMutation } from '../../_gen/gql'
import useGqlClient from '../../hooks/useGqlClient'
import useSession from '../../hooks/useSession'

import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import Desc from '@/gf/components/Desc'
import Layout from '@/gf/components/Layout'
import Page from '@/gf/components/Page'
import Spinner from '@/gf/components/Spinner'
import UppyFileInput from '@/gf/components/UppyFileInput'
import Frame from '../../components/Frame'

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [
    { name: 'Fleet', href: '/fleet' },
    { name: 'Import', href: `/fleet/import` },
  ],
}

const Import = () => {
  const { organization } = useSession()
  const client = useGqlClient()
  const [_msgs, msgsMgr] = useMsgs()
  const [importFleet] = useImportFleetMutation({ client })
  const [replacing, setReplacing] = useState(false)
  const [spinnerLive, spinnerToggle] = useToggle()
  const { data, refetch } = useFetchOrgQuery({ variables: { id: organization.id }, client })
  const fleetImportUrl = data?.organization?.fleetImportUrl

  const allowedFileTypes = ['.csv', '.xlsx', '.xls', '.pdf']
  const uppy = useUppy({
    onFilesAdded: () => spinnerToggle.on(),
    onComplete: ([file]) => {
      importFleet({ variables: { orgId: organization.id, url: file.url } }).then(() =>
        refetch()
          .then(() => setReplacing(false))
          .finally(() => spinnerToggle.off())
      )
    },
    allowedFileTypes,
    maxNumberOfFiles: 1,
  })

  if (fleetImportUrl === undefined) return null

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Import Fleet">
        <Layout>
          <Layout.Section type="primary">
            <Card>
              {fleetImportUrl && (
                <Card.Section title="Current Import">
                  <Desc.List>
                    <Desc.Row title="File">
                      <div className="flex gap-12 items-center">
                        <A.T href={fleetImportUrl} target="_blank">
                          {Attachment.nameFromUrl(fleetImportUrl)}
                        </A.T>
                        <Action.S size="sm" onClick={() => setReplacing(true)}>
                          Replace
                        </Action.S>
                      </div>
                    </Desc.Row>
                    <Desc.Row title="Status">Processing</Desc.Row>
                  </Desc.List>
                </Card.Section>
              )}
              {(!fleetImportUrl || replacing) && (
                <Card.Section>
                  {spinnerLive ? (
                    <Spinner />
                  ) : (
                    <div className="space-y-6">
                      <div className="space-y-2">
                        <div className="prose">
                          Please include the following in your{' '}
                          <span className="font-bold">.csv</span> file:
                        </div>
                        <ul className="list-disc list-inside text-base">
                          <li>Unit Number</li>
                          <li>Year</li>
                          <li>Make</li>
                          <li>Model</li>
                          <li>Serial Number</li>
                          <li>Description</li>
                        </ul>
                      </div>
                      <UppyFileInput
                        uppy={uppy}
                        allowedFileTypes={allowedFileTypes}
                        onFileInputError={(error) => msgsMgr.add(error, 'negative')}
                      />
                    </div>
                  )}
                </Card.Section>
              )}
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  )
}

export default Import
