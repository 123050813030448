import { DealerLocationDetailsQuery } from '@/buyers/_gen/gql'

import VendorBadge, { VendorBadgeType } from '@/buyers/components/Vendors/VendorBadge'
import VendorLogo from '@/buyers/components/Vendors/VendorLogo'

const DealerLocationModalHeader = ({
  dealerLocation,
}: {
  dealerLocation: DealerLocationDetailsQuery['dealerLocations']['entries'][number]
}) => (
  <div className="flex flex-col gap-y-2">
    <VendorLogo url={dealerLocation.logoUrl} size="md" />

    <h2 className="font-medium text-2xl">{dealerLocation.name}</h2>
    {dealerLocation.address && (
      <>
        <p className="text-lg">
          {dealerLocation.address.lineOne} <br />
          {dealerLocation.address.city}, {dealerLocation.address.state}{' '}
          {dealerLocation.address.postalCode}
        </p>
      </>
    )}
    {dealerLocation.vendor || (dealerLocation.numOfQuotes && dealerLocation.numOfQuotes > 0) ? (
      <div className="flex gap-x-2">
        {dealerLocation.vendor && <VendorBadge type={VendorBadgeType.MyDealer} />}
        {dealerLocation.numOfQuotes && dealerLocation.numOfQuotes > 0 ? (
          <VendorBadge type={VendorBadgeType.ActiveOnGf} />
        ) : null}
      </div>
    ) : null}
  </div>
)

export default DealerLocationModalHeader
