import { useState, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { StringParam, NumberParam, useQueryParams, withDefault } from 'use-query-params'
import debounce from 'lodash/debounce'

import useConfig from '@/gf/hooks/useConfig'
import FormatFilters from '@/gf/modules/FormatFilters'
import FilterSet from '@/gf/modules/FilterSet'
import UserM from '@/gf/modules/User'
import ConversationM from '@/buyers/modules/Conversation'
import useSession from '@/buyers/hooks/useSession'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useAbsoluteMessagesSendToInput, {
  noUsersErrorText,
  threadContainerClassName,
} from '@/buyers/hooks/useAbsoluteMessagesSendToInput'
import {
  ConversationMembersDocument,
  FetchMessagesForConversationDocument,
  useCreateConversationMutation,
  useCreateMessageMutation,
  useInboxQuery,
  useFetchMessagesForConversationQuery,
  useReadReceiptsForConversationQuery,
  InboxDocument,
} from '@/buyers/_gen/gql'

import InboxCommon from '@/gf/components/Inbox'
import Layout from '@/gf/components/Layout'
import Page from '@/gf/components/Page'
import SearchInput from '@/gf/components/SearchInput'
import Frame from '@/buyers/components/Frame'
import InboxDetails from '@/buyers/components/InboxDetails'
import ScopeAction from './Inbox/ScopeAction'

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [{ name: 'Inbox', href: '/inbox' }],
}

const Inbox = () => {
  const gqlClient = useGqlClient()
  const { conversationId } = useParams<{ conversationId: string }>()
  const navigate = useNavigate()
  const { adminUrl } = useConfig()
  const { user, organization } = useSession()
  const location = useLocation()
  const [sendToUserIds, setSendToUserIds] = useState<string[]>([])
  const [sendToErrorText, setSendToErrorText] = useState<string>()

  const absoluteThreadContent = useAbsoluteMessagesSendToInput({
    errorText: sendToErrorText,
    onChangeErrorText: setSendToErrorText,
    onChangeSendToUserIds: setSendToUserIds,
  })

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    search: StringParam,
    scope: StringParam,
  })

  const setPage = (newPage: number) => setQuery({ page: newPage })

  const queryResult = useInboxQuery({
    variables: {
      filters: [
        ...(query.search ? [['contains', 'search', query.search]] : []),
        ...FormatFilters.andFilters(
          FilterSet.toApiFilters(
            query.scope === 'all' ? [] : ConversationM.inboxConversationFilters(user.id)
          )
        ),
      ],
      page: query.page,
      pageSize: ConversationM.PAGE_SIZE,
    },
    pollInterval: ConversationM.CONVERSATION_POLL_INTERVAL,
    client: gqlClient,
  })

  const conversations = queryResult.data?.fetchConversations.conversations

  const [createMessage] = useCreateMessageMutation({
    client: gqlClient,
    refetchQueries: [
      FetchMessagesForConversationDocument,
      InboxDocument,
      ConversationMembersDocument,
    ],
  })

  const [createConversation] = useCreateConversationMutation({
    client: gqlClient,
    refetchQueries: [InboxDocument],
  })

  const onSearchChanged = useMemo(
    () =>
      debounce((search: string, currScope: string | null | undefined) => {
        const scope = search ? 'all' : currScope
        setQuery({ search: search || undefined, scope })
      }, 300),
    []
  )

  const selectedConversation = conversations && conversations.find((c) => c.id === conversationId)

  const refetchConversations = () => gqlClient.refetchQueries({ include: [InboxDocument] })

  const { error: messagesError, ...messagesQueryResult } = useFetchMessagesForConversationQuery({
    client: gqlClient,
    variables: {
      conversationId: selectedConversation?.id as string,
    },
    pollInterval: ConversationM.MESSAGES_POLL_INTERVAL,
    // Only refetch if the conversation is unread, so we can fetch the correct "read" status
    onCompleted: selectedConversation?.unreadMessages ? refetchConversations : undefined,
    skip: !conversationId || !selectedConversation || 'newConversation' in selectedConversation,
  })

  const { fetchMessagesForConversation: messages } = messagesQueryResult.data || {}

  const { readReceiptsForConversation } =
    useReadReceiptsForConversationQuery({
      client: gqlClient,
      variables: { conversationId: selectedConversation?.id as string },
      pollInterval: ConversationM.MESSAGES_POLL_INTERVAL,
      skip: !conversationId || !selectedConversation || 'newConversation' in selectedConversation,
    }).data || {}

  return (
    <Frame breadcrumbs={breadcrumbs} contentClassName="flex">
      {UserM.isAdmin(user) ? (
        <Page title="Oops">
          <Layout>
            <Layout.Section type="full">
              <p className="text-sm text-gray-700">
                Go to the{' '}
                <a
                  href={`${adminUrl}/inbox${conversationId ? `/messages/${conversationId}` : ''}`}
                  className="text-blue-500 underline"
                >
                  admin inbox page
                </a>
              </p>
            </Layout.Section>
          </Layout>
        </Page>
      ) : (
        <Page title="Inbox">
          <div className="mt-4 space-y-2 flex flex-col">
            <div className="flex gap-2">
              <div className="rounded-md shadow-sm text-sm border-1 overflow-hidden">
                <ScopeAction
                  selected={query.scope === 'all'}
                  onClick={() => setQuery({ scope: 'all' })}
                >
                  All
                </ScopeAction>

                <ScopeAction
                  selected={query.scope !== 'all'}
                  onClick={() => setQuery({ scope: undefined })}
                >
                  My Messages
                </ScopeAction>
              </div>

              <div className="grow">
                <SearchInput
                  initialValue={query.search || ''}
                  onChange={(search) => onSearchChanged(search, query.scope)}
                  placeholder="filter conversations by order id, request id or message content"
                />
              </div>
            </div>

            {query.search && query.search.length > 0 && conversations?.length === 0 && (
              <div className="prose text-sm">No messages found 🫤</div>
            )}
          </div>

          <InboxCommon
            className="mt-2"
            user={user}
            userOrganizationId={organization.id}
            conversations={conversations}
            selectedConversation={selectedConversation}
            selectedConversationId={conversationId}
            messages={messages}
            messagesError={messagesError}
            readReceiptsForConversation={readReceiptsForConversation}
            onSelectedConversationIdChange={(selectedConversationId) =>
              selectedConversationId
                ? navigate(`/inbox/messages/${selectedConversationId}${location.search}`)
                : navigate(`/inbox${location.search}`)
            }
            page={query.page}
            setPage={setPage}
            pagination={queryResult.data?.fetchConversations.pagination}
            newConversations={[]}
            DetailsForConversation={InboxDetails.Conversation}
            conversationRowContent={ConversationM.defaultConversationRowContent}
            createMessage={(variables) =>
              createMessage({ variables: { ...variables, to: sendToUserIds } })
            }
            createConversation={(variables) => {
              if (
                ConversationM.isInternalOrgConversation({
                  admin: variables.conversationInput.admin,
                  store: variables.conversationInput.storeId
                    ? { id: variables.conversationInput.storeId }
                    : null,
                }) &&
                sendToUserIds.length === 0
              ) {
                setSendToErrorText(noUsersErrorText)
                return Promise.resolve(undefined)
              }
              return createConversation({
                variables: { ...variables, to: sendToUserIds },
              }).then((resp) => resp.data?.createConversation)
            }}
            absoluteThreadContent={absoluteThreadContent}
            threadContainerClassName={threadContainerClassName}
          />
        </Page>
      )}
    </Frame>
  )
}

export default Inbox
