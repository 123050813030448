import {
  InvoicesAndCreditMemosListDocument,
  InvoicesAndCreditMemosListQuery,
  PaymentMethod,
  useInvoiceMarkAsPaidMutation,
  useMarkCreditMemoReceivedMutation,
} from '@/buyers/_gen/gql'
import StoreOrderStepBadge from '@/buyers/components/StoreOrderStepBadge'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import FilterDropdown from '@/dealers/pages/Requests/FilterDropdown'
import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Badge from '@/gf/components/Badge'
import ButtonLink from '@/gf/components/ButtonLinkOld'
import CreditMemoStateBadge from '@/gf/components/CreditMemoStateBadge'
import InvoiceMarkAsPaidModal from '@/gf/components/InvoiceMarkAsPaidModal'
import InvoiceStateBadge from '@/gf/components/InvoiceStateBadge'
import Layout from '@/gf/components/Layout'
import OrderByHeaderButton from '@/gf/components/OrderByHeaderButton'
import PaginationC from '@/gf/components/Pagination'
import SearchInput from '@/gf/components/SearchInput'
import Spinner from '@/gf/components/Spinner'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import TableLineItemsDropdown from '@/gf/components/TableLineItemsDropdown'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import useConfig from '@/gf/hooks/useConfig'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import * as GE from '@/gf/modules/GrammarEvents'
import InvoiceM from '@/gf/modules/Invoice'
import MoneyM from '@/gf/modules/Money'
import Time from '@/gf/modules/Time'
import type { OrderBy, Pagination, Product } from '@/types'
import { ApolloError } from '@apollo/client'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { DateTime } from 'luxon'
import { useState } from 'react'
import MarkCreditMemoReceivedModal from './MarkCreditMemoReceivedModal'

type Record = NonNullable<
  InvoicesAndCreditMemosListQuery['invoicesAndCreditMemos']
>['entries'][number]
type Invoice = NonNullable<Record['invoice']>
type CreditMemo = NonNullable<Record['creditMemo']>

const PayDirectActions = ({
  invoice,
  onMarkAsPaid,
}: {
  invoice: Invoice
  onMarkAsPaid: () => void
}) => (
  <>
    {invoice.link && (
      <ButtonLink href={invoice.link} primary={false} target="_blank" rel="noreferrer">
        Download
      </ButtonLink>
    )}

    {InvoiceM.isPaymentRequired(invoice) && (
      <Action.P type="button" onClick={onMarkAsPaid}>
        Mark as Paid
      </Action.P>
    )}
  </>
)

const BalanceActions = ({
  invoice,
  dashboardUrl,
  canPay,
}: {
  invoice: Invoice
  dashboardUrl: string
  canPay: boolean
}) => (
  <>
    <ButtonLink href={`${dashboardUrl}/pdf/download/invoice/${invoice.id}`} primary={false}>
      {invoice.isFinanced ? 'Download' : 'Receipt'}
    </ButtonLink>

    {invoice.isFinanced && InvoiceM.isPaymentRequired(invoice) && canPay && invoice.link && (
      <ButtonLink href={invoice.link} primary target="_blank" rel="noreferrer">
        Pay Online
      </ButtonLink>
    )}
  </>
)

const InvoiceDueDateBadge = ({
  dueDate,
  paymentRequired,
}: {
  dueDate: DateTime
  paymentRequired: boolean
}) => {
  const timeElement = (
    <time dateTime={Time.formatDateNoTime(dueDate)}>{Time.formatDateNoTime(dueDate)}</time>
  )
  if (paymentRequired && dueDate < DateTime.now().plus({ days: 7 })) {
    return (
      <Badge
        title={timeElement}
        {...(dueDate < DateTime.now()
          ? { color: 'bg-red-200', textColor: 'text-red-900' }
          : { color: 'bg-yellow-200', textColor: 'text-yellow-900' })}
      />
    )
  }
  return timeElement
}

const InvoiceRow = ({
  invoice,
  storeOrder,
  buyersUrl,
  canPay,
  onMarkAsPaid,
}: {
  invoice: Invoice
  storeOrder: NonNullable<Record['storeOrder']>
  buyersUrl: string
  canPay: boolean
  onMarkAsPaid: () => void
}) => (
  <Tr>
    <Td className="py-1.5">
      <div className="flex space-x-2 justify-start">
        {InvoiceM.isBalanceInvoice(invoice) ? (
          <BalanceActions invoice={invoice} dashboardUrl={buyersUrl} canPay={canPay} />
        ) : (
          <PayDirectActions invoice={invoice} onMarkAsPaid={onMarkAsPaid} />
        )}

        {storeOrder?.paymentMethod === PaymentMethod.Stripe && (
          <A.S href={`${buyersUrl}/pdf/download/invoice/${invoice.id}`}>Download</A.S>
        )}
      </div>
    </Td>
    <Td>
      <InvoiceStateBadge invoice={invoice} />
    </Td>
    <Td>
      {invoice.dueDate && (
        <InvoiceDueDateBadge
          dueDate={invoice.dueDate}
          paymentRequired={InvoiceM.isPaymentRequired(invoice)}
        />
      )}
    </Td>
    <Td>
      {storeOrder && (
        <time dateTime={Time.formatDateNoTime(storeOrder.insertedAt)}>
          {Time.formatDateNoTime(storeOrder.insertedAt)}
        </time>
      )}
    </Td>
    <Td>{invoice.totalPrice ? MoneyM.format(invoice.totalPrice) : null}</Td>
    <Td>{storeOrder?.order.requestForQuote?.workOrderNumber}</Td>
    <Td>{invoice.additionalCharge?.purchaseOrder || storeOrder?.purchaseOrder}</Td>
    <Td>
      {storeOrder && (
        <div className="space-y-1">
          <div className="flex gap-x-2 items-center">
            <div className="w-20">
              <StoreOrderStepBadge step={storeOrder?.step} />
            </div>

            <A.T href={`${buyersUrl}/orders/${storeOrder.id}`}>{storeOrder.shortId}</A.T>
          </div>
          {storeOrder.vendor && (
            <p className="text-xs text-gray-500 italic">Vendor: {storeOrder?.vendor?.name}</p>
          )}
        </div>
      )}
    </Td>

    {invoice.additionalCharge ? (
      <Td className="max-w-[13rem] text-ellipsis overflow-hidden">
        {invoice.additionalCharge.name}
      </Td>
    ) : (
      storeOrder && (
        <td className="flex whitespace-nowrap text-sm text-gray-500 hover:text-gray-600">
          <TableLineItemsDropdown
            lineItems={storeOrder.lineItems.map((li) => ({
              ...li,
              product: li.product as Product,
            }))}
            linkToMarketplace
          />
        </td>
      )
    )}
  </Tr>
)

const CreditMemoRow = ({
  creditMemo,
  storeOrder,
  buyersUrl,
  onMarkAsReceived,
}: {
  creditMemo: CreditMemo
  storeOrder: NonNullable<Record['storeOrder']>
  buyersUrl: string
  onMarkAsReceived: () => void
}) => (
  <Tr>
    <Td className="py-1.5">
      {!creditMemo.receivedAt && (
        <Action.P type="button" onClick={onMarkAsReceived}>
          Mark as Received
        </Action.P>
      )}
    </Td>
    <Td>
      <CreditMemoStateBadge receivedAt={creditMemo.receivedAt} />
    </Td>
    <Td />
    <Td>
      {storeOrder && (
        <time dateTime={Time.formatDateNoTime(storeOrder.insertedAt)}>
          {Time.formatDateNoTime(storeOrder.insertedAt)}
        </time>
      )}
    </Td>
    <Td>
      <div className="inline-flex items-center gap-x-2">
        <span>({MoneyM.format(MoneyM.abs(creditMemo.amount))})</span>
        {creditMemo.description && (
          <Tooltip>
            <TooltipTrigger>
              <InformationCircleIcon className="w-6 h-6 inline-flex shrink-0 text-gray-600" />
            </TooltipTrigger>
            <TooltipContent className="max-w-prose p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900 whitespace-pre-wrap">
              {creditMemo.description}
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </Td>
    <Td>{storeOrder?.order.requestForQuote?.workOrderNumber}</Td>
    <Td>{storeOrder?.purchaseOrder}</Td>
    <Td>
      {storeOrder && (
        <div className="space-y-1">
          <div className="flex gap-x-2 items-center">
            <div className="w-20">
              <StoreOrderStepBadge step={storeOrder?.step} />
            </div>

            <A.T href={`${buyersUrl}/orders/${storeOrder.id}`}>{storeOrder.shortId}</A.T>
          </div>
          {storeOrder.vendor && (
            <p className="text-xs text-gray-500 italic">Vendor: {storeOrder?.vendor?.name}</p>
          )}
        </div>
      )}
    </Td>

    <td className="flex whitespace-nowrap text-sm text-gray-500 hover:text-gray-600">
      {storeOrder && (
        <TableLineItemsDropdown
          lineItems={storeOrder.lineItems.map((li) => ({
            ...li,
            product: li.product as Product,
          }))}
          linkToMarketplace
        />
      )}
    </td>
  </Tr>
)

interface PropsWithoutFields {
  entries: Record[]
  loading: boolean
  orderBy: OrderBy
  setOrderBy: (newOrderBy: OrderBy) => void
  canPay: boolean
}

const InvoicesTable = ({
  search,
  page,
  updateSearch,
  updatePage,
  entries,
  pagination,
  loading,
  orderBy,
  setOrderBy,
  canPay,
  invoiceStatus,
  updateInvoiceStatus,
  creditMemoStatus,
  updateCreditMemoStatus,
}: PropsWithoutFields & {
  search: string
  page: number
  updateSearch: (value: string) => void
  updatePage: (newPage: number) => void
  pagination?: Pagination
  invoiceStatus: string
  updateInvoiceStatus: (newStatus: string | null) => void
  creditMemoStatus: string
  updateCreditMemoStatus: (newStatus: string | null) => void
}) => {
  const { featureFlags } = useSession()
  const client = useGqlClient()
  const { buyersUrl } = useConfig()
  const [spinnerLive, spinnerToggler] = useToggle()
  const [_msgs, msgsMgr] = useMsgs()
  const [selectedRecord, setSelectedRecord] = useState<Record>()
  const [invoiceMarkAsPaid] = useInvoiceMarkAsPaidMutation({
    client,
    refetchQueries: [InvoicesAndCreditMemosListDocument],
  })
  const [creditMemoMarkAsReceived] = useMarkCreditMemoReceivedMutation({
    client,
    refetchQueries: [InvoicesAndCreditMemosListDocument],
  })

  return (
    <>
      {selectedRecord?.invoice && (
        <InvoiceMarkAsPaidModal
          storeOrderId={selectedRecord.storeOrder?.id ?? ''}
          dashboardUrl={buyersUrl}
          open={!!selectedRecord.invoice}
          submitButtonShowSpinner={spinnerLive}
          onClose={() => setSelectedRecord(undefined)}
          onSubmit={(e) => {
            e.preventDefault()
            spinnerToggler.on()
            invoiceMarkAsPaid({ variables: { invoiceId: selectedRecord.invoice?.id ?? '' } })
              .then(() => msgsMgr.add('Invoice marked as paid', 'positive'))
              .catch((err: ApolloError) =>
                msgsMgr.add(`Error marking Invoice as paid: ${err.message}`, 'negative')
              )
              .finally(() => {
                setSelectedRecord(undefined)
                spinnerToggler.off()
              })
          }}
        />
      )}

      {selectedRecord?.creditMemo && (
        <MarkCreditMemoReceivedModal
          storeOrderId={selectedRecord.storeOrder?.id ?? ''}
          amount={selectedRecord.creditMemo.amount}
          open={!!selectedRecord.creditMemo}
          submitButtonShowSpinner={spinnerLive}
          onClose={() => setSelectedRecord(undefined)}
          onSubmit={(e) => {
            e.preventDefault()
            spinnerToggler.on()
            creditMemoMarkAsReceived({
              variables: { creditMemoId: selectedRecord.creditMemo?.id ?? '' },
            })
              .then(() => msgsMgr.add('Credit Memo marked as received', 'positive'))
              .catch((err: ApolloError) =>
                msgsMgr.add(`Error marking Credit Memo as received: ${err.message}`, 'negative')
              )
              .finally(() => {
                setSelectedRecord(undefined)
                if (selectedRecord.creditMemo?.id) {
                  GE.receivesCreditMemo(selectedRecord.creditMemo.id)
                }
                spinnerToggler.off()
              })
          }}
        />
      )}
      <div className="col-span-6 flex flex-col gap-y-4">
        <SearchInput
          initialValue={search}
          onChange={updateSearch}
          placeholder="Search by po number, work order, vendor name..."
        />

        <div className="flex gap-x-2 items-center">
          <label className="text-sm text-gray-500">Filter by:</label>
          <FilterDropdown
            title="Invoice Status"
            value={invoiceStatus ?? null}
            onChange={updateInvoiceStatus}
            steps={[
              { label: 'All', value: null },
              { label: 'Paid', value: 'paid' },
              { label: 'Not Paid', value: 'not_paid' },
            ]}
          />

          {featureFlags.buyersCreditMemos && (
            <FilterDropdown
              title="Credit Memo Status"
              value={creditMemoStatus ?? null}
              onChange={updateCreditMemoStatus}
              steps={[
                { label: 'All', value: null },
                { label: 'Received', value: 'received' },
                { label: 'Not Received', value: 'not_received' },
              ]}
            />
          )}
        </div>
      </div>

      <Layout.Section type="table">
        <Table>
          <Thead>
            <Tr>
              <Th>Actions</Th>
              <Th>Status</Th>
              <Th>
                <OrderByHeaderButton
                  id="due_date"
                  display="Due Date"
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              </Th>
              <Th>
                <OrderByHeaderButton
                  id="store_order_inserted_at"
                  display="Order Date"
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              </Th>
              <Th>Total</Th>
              <Th>
                <OrderByHeaderButton
                  id="work_order"
                  display="Work Order"
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              </Th>
              <Th>
                <OrderByHeaderButton
                  id="purchase_order"
                  display="PO"
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              </Th>
              <Th>Order</Th>
              <Th>Content</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={9}>
                  <Spinner />
                </Td>
              </Tr>
            ) : entries.length === 0 ? (
              <Tr>
                <Td colSpan={9}>
                  No invoices {featureFlags.buyersCreditMemos && <>or credit memos</>} found.
                </Td>
              </Tr>
            ) : (
              entries.map((record) =>
                record.invoice ? (
                  <InvoiceRow
                    key={record.id}
                    invoice={record.invoice}
                    storeOrder={record.storeOrder}
                    onMarkAsPaid={() => setSelectedRecord(record)}
                    buyersUrl={buyersUrl}
                    canPay={canPay}
                  />
                ) : record.creditMemo ? (
                  <CreditMemoRow
                    key={record.id}
                    creditMemo={record.creditMemo}
                    storeOrder={record.storeOrder}
                    buyersUrl={buyersUrl}
                    onMarkAsReceived={() => setSelectedRecord(record)}
                  />
                ) : null
              )
            )}
          </Tbody>
        </Table>
      </Layout.Section>
      <Layout.Section type="full">
        <PaginationC pagination={pagination} page={page} updatePage={updatePage} />
      </Layout.Section>
    </>
  )
}

export default InvoicesTable
