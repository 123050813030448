import { ChatAlt2Icon, OfficeBuildingIcon } from '@heroicons/react/outline'
import { CheckIcon, ClipboardCheckIcon, ClockIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { DateTime } from 'luxon'

import { RequestQuery, StoreOrderStep } from '@/buyers/_gen/gql'
import useToggle from '@/gf/hooks/useToggle'
import Money from '@/gf/modules/Money'
import StoreOrderM from '@/gf/modules/StoreOrder'
import TimeM from '@/gf/modules/Time'
import { useContext } from '../../RFQ'

import Action from '@/gf/components/Action'
import Link from '@/gf/components/Link'
import Pill from '@/gf/components/Pill'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import OrderProgressLabel from './OrderProgressLabel'
import StoreInfoModal from './StoreInfoModal'
import VendorDetails from './VendorDetails'

type RequestForQuote = RequestQuery['requestForQuotesSearch']['requestForQuotes'][number]

const Order = ({
  storeOrder,
  onClick,
}: {
  storeOrder: RequestForQuote['storeOrders'][number]
  onClick: () => void
}) => {
  const { rfq } = useContext()
  const [infoOpen, { toggle: toggleInfo }] = useToggle(false)
  const allInStock = storeOrder.lineItems.every((li) => li.inStock)

  const latestAvailableAt = storeOrder.lineItems.reduce(
    (acc: DateTime | null, lineItem) =>
      !acc
        ? lineItem.availableAt
        : lineItem.availableAt && lineItem.availableAt > acc
        ? lineItem.availableAt
        : acc,
    null
  )

  const storeOrderItems = storeOrder.lineItems.reduce((acc, lineItem) => acc + lineItem.quantity, 0)
  const rfqItems = rfq.partRequests.reduce((acc, partRequest) => acc + partRequest.quantity, 0)
  const percentageOrderRfqItems = rfqItems > 0 ? storeOrderItems / rfqItems : undefined

  const rfqVendor = rfq.requestForQuoteVendors.find(
    (rfqv) => rfqv.vendor.store?.id === storeOrder.store.id
  )

  const vendorId = rfqVendor?.vendor.id ?? null

  const contactNames =
    rfqVendor?.vendorContacts.filter((c) => !!c.name).map((c) => c.name as string) ?? []

  return (
    <>
      <div
        className={classNames(
          'group border-2 border-slate-200 rounded-md flex flex-col sm:flex-row gap-2 p-4 pt-6 relative',
          'hover:border-gearflow hover:bg-slate-50 cursor-pointer transform duration-200 shadow-md'
        )}
        onClick={onClick}
      >
        {storeOrder.step === StoreOrderStep.Canceled && storeOrder?.rejection && rfq.enabled ? (
          <div className="text-sm rounded-md py-1 px-3 group-hover:shadow transform duration-200 group-hover:scale-110 bg-purple-200 text-purple-900 absolute -top-4 left-4">
            Quote Denied
          </div>
        ) : (
          <OrderProgressLabel storeOrder={storeOrder} className="absolute -top-4 left-4" />
        )}

        <div className="flex-grow flex gap-x-3">
          <VendorDetails
            name={storeOrder.store.name}
            address={storeOrder.pickupAddress ?? storeOrder.store.address}
            distance={rfqVendor?.distanceFromShippingAddress || null}
            contactNames={contactNames}
          >
            <div
              className="mt-3 flex flex-row flex-wrap items-center self-start gap-x-4 gap-y-2"
              onClick={(e) => e.stopPropagation()}
            >
              <Link.T
                to={`/rfqs/${rfq.id}/messages/store/${storeOrder.store.id}`}
                className="inline-flex gap-x-0.5 items-center text-sm whitespace-nowrap"
              >
                <ChatAlt2Icon className="h-5 inline-flex shrink-0 text-gearflow" /> Send Message
              </Link.T>

              {vendorId && (
                <Action.T
                  onClick={toggleInfo}
                  className="inline-flex gap-x-0.5 items-center text-sm whitespace-nowrap"
                >
                  <OfficeBuildingIcon className="h-5 inline-flex shrink-0 text-slate-600" />
                  Store Info
                </Action.T>
              )}
            </div>
          </VendorDetails>
        </div>

        <div className="flex-shrink-0 flex flex-row sm:flex-col items-center sm:items-end justify-end sm:justify-between gap-y-2 gap-x-4 ">
          <div>
            <div className="flex flex-col md:flex-row items-end md:items-center md:justify-end gap-x-2 text-slate-700 font-medium text-sm">
              <div className="inline-flex gap-x-1.5 items-center text-slate-700 font-medium text-sm text-right">
                {rfqItems > 0 && percentageOrderRfqItems && (
                  <div
                    className={classNames(
                      'w-4 h-4 inline-flex justify-center items-center border-4 rounded-full',
                      percentageOrderRfqItems >= 1
                        ? 'border-blue-600 bg-blue-600'
                        : percentageOrderRfqItems > 0.625
                        ? 'border-t-gray-200 border-blue-600'
                        : percentageOrderRfqItems > 0.375
                        ? 'border-t-gray-200 border-l-gray-200 border-blue-600'
                        : 'border-r-blue-600 border-gray-200'
                    )}
                  >
                    {percentageOrderRfqItems >= 1 && (
                      <CheckIcon className="w-3.5 h-3.5 inline-flex shrink-0 text-white" />
                    )}
                  </div>
                )}
                {storeOrderItems}
                {rfqItems > 0 && <> / {rfqItems}</>} items
              </div>

              {(allInStock || latestAvailableAt) && (
                <div className="hidden md:inline-flex w-1 h-1 bg-slate-700 rounded-full" />
              )}

              {allInStock ? (
                <Tooltip>
                  <TooltipTrigger>
                    <Pill className="bg-gray-100 text-gray-700 flex-row gap-x-1">
                      <ClipboardCheckIcon className="-ml-1 w-5 h-5 flex shrink-0 text-gray-700" />
                      In Stock
                      {latestAvailableAt && ` on ${TimeM.formatDateNoYear(latestAvailableAt)}`}
                    </Pill>
                  </TooltipTrigger>
                  {latestAvailableAt ? (
                    <TooltipContent className="max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                      The vendor estimated every part would be available by{' '}
                      {TimeM.formatDateNoYear(latestAvailableAt)}.
                    </TooltipContent>
                  ) : storeOrder.timeline.approvedAt && StoreOrderM.isQuoteApproved(storeOrder) ? (
                    <TooltipContent className="max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                      PO sent {TimeM.formatDateNoYear(storeOrder.timeline.approvedAt)}.
                    </TooltipContent>
                  ) : null}
                </Tooltip>
              ) : latestAvailableAt ? (
                <Tooltip>
                  <TooltipTrigger>
                    <Pill className="bg-amber-100 text-amber-800 flex-row gap-x-1">
                      <ClockIcon className="-ml-1 w-5 h-5 flex shrink-0 text-amber-800" />
                      In Stock on {TimeM.formatDateStringNoYear(latestAvailableAt)}
                    </Pill>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                    The vendor estimated every part would be available by{' '}
                    {TimeM.formatDateNoYear(latestAvailableAt)}. View the Quote for the estimated
                    date for each part.
                  </TooltipContent>
                </Tooltip>
              ) : null}
            </div>

            {storeOrder.timingDetails && (
              <div className="truncate gap-x-1 text-slate-700 font-medium text-sm max-w-xs text-right">
                {storeOrder.timingDetails}
              </div>
            )}
          </div>

          <span className="font-bold text-gray-900 text-2xl">{Money.format(storeOrder.total)}</span>
        </div>
      </div>

      {vendorId && <StoreInfoModal open={infoOpen} onClose={toggleInfo} vendorId={vendorId} />}
    </>
  )
}

export default Order
