import PartsSpend from './ReportsSection/PartsSpend'
import RequestFulfillment from './ReportsSection/RequestFulfillment'
import Performance from './ReportsSection/Performance'
import useMetrics from '../Reporting/useMetrics'
import ReportsPreview from '@/buyers/components/ReportsPreview'

const ReportsSection = ({
  hasEnoughReportData,
  isProPlan,
  metrics,
}: {
  hasEnoughReportData: boolean
  isProPlan: boolean
  metrics: ReturnType<typeof useMetrics>
}) =>
  isProPlan && hasEnoughReportData ? (
    <section className="bg-white rounded-xl shadow-base border border-gray-200 overflow-hidden">
      <div className="px-4 lg:px-6 xl:px-8 pb-4 lg:pb-6 xl:pb-8 pt-4 xl:pt-6 space-y-6">
        <div className="space-y-2 max-w-[38rem]">
          <h2 className="text-2xl">Your Reports</h2>
          <p className="text-lg">
            Once you start submitting requests and completing orders, you’ll be able to track your
            performance, see trends, and take action to improve your process.
          </p>
        </div>

        <PartsSpend {...metrics} />
        <RequestFulfillment {...metrics} />
        <Performance {...metrics} />
      </div>
    </section>
  ) : (
    <ReportsPreview isProPlan={isProPlan} hasEnoughReportData={hasEnoughReportData} />
  )

export default ReportsSection
