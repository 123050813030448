import React from 'react'

import type { Session, SessionUser, SessionOrganization } from '@/buyers/types'

type AuthenticatedSession = Omit<Session, 'user' | 'organization' | 'orgId'> & {
  user: SessionUser
  orgId: string
  organization: SessionOrganization
}

const SessionContext = React.createContext<AuthenticatedSession | undefined>(undefined)

export default SessionContext
