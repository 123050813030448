import { LockClosedIcon } from '@heroicons/react/outline'
import { useState } from 'react'

import Action from '@/gf/components/Action'
import FreeProTrialModal from './FreeProTrialModal'

const NoAccess = () => {
  const [isFreeProTrialModalOpen, setIsFreeProTrialModalOpen] = useState(false)
  return (
    <div className="flex items-center gap-2 px-5 py-3 bg-white rounded-full text-gray-600 border-x-1 border-b-1 border-gray-200 drop-shadow-sm">
      <FreeProTrialModal
        open={isFreeProTrialModalOpen}
        onClose={() => setIsFreeProTrialModalOpen(false)}
        onClaimed={() => {
          setIsFreeProTrialModalOpen(false)
          return Promise.resolve()
        }}
      />
      <LockClosedIcon className="w-6 h-6 flex-none" />
      <div>
        <Action.T onClick={() => setIsFreeProTrialModalOpen(true)}>
          Upgrade to Parts Hub Pro
        </Action.T>{' '}
        to access this feature.
      </div>
    </div>
  )
}

export default NoAccess
