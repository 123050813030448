import { useEffect } from 'react'
import { useParams, useNavigate, Routes, Route, Navigate } from 'react-router-dom'
import pluralize from 'pluralize'
import { DateTime } from 'luxon'

import RequestForQuoteContext from '@/gf/modules/RequestForQuote'
import useMsgs from '@/gf/hooks/useMsgs'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import useRfqShippingAddressCoordinates from '@/buyers/hooks/useRfqShippingAddressCoordinates'
import {
  useAddVendorsToRfqMutation,
  ShippingOptions,
  useRfqAddVendorsQuery,
} from '@/buyers/_gen/gql'
import useVendorSelectionType from './CreateRequest/useVendorSelectionType'

import Page from '@/gf/components/Page'
import Frame from '@/buyers/components/Frame'
import NoAccess from '@/buyers/components/NoAccess'
import PartHubProPitch from '@/buyers/components/PartHubProPitch'
import { SourcingType } from './CreateRequest/types'
import MyVendors from './CreateRequest/SelectVendorsStep/MyVendors'
import ReviewStep from './CreateRequest/ReviewStep'
import useAddVendorsForm from './RFQAddVendors/useAddVendorsForm'
import { evalProprity } from './ApproveRequestNext'
import NotFound from './NotFound'

const RFQAddVendors = () => {
  const { orgId } = useSession()
  const { rfqId } = useParams() as { rfqId: string }
  const navigate = useNavigate()
  const [_, msgr] = useMsgs()

  const { org, requestForQuote: rfq } =
    useRfqAddVendorsQuery({
      variables: { orgId, rfqId },
      client: useGqlClient(),
      fetchPolicy: 'network-only',
    }).data || {}

  const [addVendorsToRfq] = useAddVendorsToRfqMutation({ client: useGqlClient() })
  const form = useAddVendorsForm()
  const vendorSelectionType = useVendorSelectionType(org)

  const location = useRfqShippingAddressCoordinates({
    addressId: rfq?.shippingAddress?.id ?? null,
    locationId: null,
  })

  useEffect(() => {
    if (rfq)
      form.onChange({
        broadcast: rfq.broadcast === false,
        locationId: rfq.shippingLocationId,
        shippingAddress: rfq.shippingAddress,
      })
  }, [rfq?.id])

  useEffect(() => {
    if (location) form.onChange({ nearbyReferencePoint: location })
  }, [location])

  if (rfq === undefined || !org) return null
  if (rfq === null) return <NotFound />

  const rfqUrl = `/rfqs/${rfqId}`
  const close = () => navigate(rfqUrl, { replace: true })
  const orgMachineId = rfq.orgMachines[0]?.id ?? null

  const vendorStoreIds: Record<string, string> = rfq.requestForQuoteVendors.reduce(
    (values, rfqv) =>
      rfqv.vendor.store && rfqv.vendor.store.id
        ? { ...values, [rfqv.vendor.store.id]: rfqv.vendor.id }
        : values,
    {}
  )

  const vendorsWithQuotes = rfq.storeOrders.map((so) => vendorStoreIds[so.store.id])
  const contactsInRfq = rfq.requestForQuoteVendors
    .map((rfqv) => rfqv.vendorContacts.map((vc) => vc.id))
    .flat()

  const save = () => {
    form.loadingOn()

    addVendorsToRfq({
      variables: {
        rfqId: rfq.id,
        shippingOption: ShippingOptions.Standard,
        broadcast: form.value.broadcast,
        vendors: form.value.vendors.map((v) => ({
          deliveryMethod: v.deliveryMethod,
          id: v.vendorId,
          vendorContactIds: v.contactIds,
          pickup: null,
          accountNumber: v.accountNumber,
        })),
      },
    })
      .then(() => {
        msgr.add(`${pluralize('Vendor', form.value.vendors.length)} added.`, 'positive')
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => {
        close()
        form.loadingOff()
      })
  }

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'Requests', href: '/rfqs' },
      { name: `Request #${RequestForQuoteContext.shortenId(rfqId)}`, href: rfqUrl },
      { name: 'Add Vendors', href: `/rfqs/${rfqId}/add-vendors` },
    ],
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Add Vendors" previousPage={rfqUrl}>
        {vendorSelectionType !== 'disabled' ? (
          <div className="pt-4 pb-12">
            <Routes>
              <Route
                path="/review"
                element={
                  <ReviewStep
                    request={{
                      dealerLocationIds: form.value.dealerLocationIds,
                      vendors: form.value.vendors,
                      locationId: form.value.locationId ?? undefined,
                      shippingAddress: form.value.shippingAddress ?? undefined,
                      nearbyReferencePoint: form.value.nearbyReferencePoint,
                      machineInvolved: !!orgMachineId,
                      machineOrgId: orgMachineId,
                      sourcing:
                        form.value.vendors.length > 0
                          ? SourcingType.VENDORS
                          : SourcingType.GF_NETWORK,
                      urgency: {
                        neededByDate: rfq.neededBy ?? DateTime.now().plus({ day: 1 }),
                        machineDown: rfq.machineDown,
                        priority: evalProprity(rfq.insertedAt, rfq.neededBy, rfq.machineDown),
                      },
                      parts: rfq.partRequests.map((pr, i) => ({
                        partNumber: pr.mpn,
                        description: pr.description,
                        quantity: pr.quantity,
                        externalId: pr.externalId,
                        taskNumber: pr.taskNumber,
                        pictures: i === 0 ? rfq.images.map((img) => img.url) : [],
                      })),
                    }}
                    updateRequest={() => undefined}
                    onBackClicked={() => navigate('select')}
                    submitInProgress={form.loading}
                    onCreateRequestClicked={save}
                    submitButtonText={
                      form.value.vendors.length > 0 ? 'Send All Requests' : 'Send Request'
                    }
                    updateLocationEnabled={false}
                  />
                }
              />
              <Route
                path="/*"
                element={
                  <>
                    <Routes>
                      <Route path="" element={<Navigate to="select" replace />} />
                    </Routes>
                    <MyVendors
                      dealerLocationIds={form.value.dealerLocationIds}
                      locationId={form.value.locationId}
                      nearbyReferencePoint={form.value.nearbyReferencePoint}
                      machineOrgId={orgMachineId}
                      vendors={form.value.vendors}
                      onSubmit={() => navigate('review')}
                      vendorSelectionType={vendorSelectionType}
                      onDealerLocationsChanged={(selectedDealerLocationIds) =>
                        form.onChange({ dealerLocationIds: selectedDealerLocationIds })
                      }
                      onLocationChanged={(point, selectedLocationId) =>
                        form.onChange({
                          nearbyReferencePoint: point,
                          locationId: selectedLocationId,
                        })
                      }
                      onContactAndDeliveryChanged={(values) => form.onChange(values)}
                      locationSelectorEnabled={false}
                      broadcast={rfq.broadcast === true ? undefined : form.value.broadcast}
                      onBroadcastChanged={(value) => form.onChange({ broadcast: value })}
                      excludeVendorIds={vendorsWithQuotes}
                      disabledContactIds={contactsInRfq}
                    />
                  </>
                }
              />
            </Routes>
          </div>
        ) : (
          <div className="space-y-4">
            <div className="w-106">
              <NoAccess />
            </div>

            <PartHubProPitch />
          </div>
        )}
      </Page>
    </Frame>
  )
}

export default RFQAddVendors
