import { ApolloQueryResult } from '@apollo/client'
import { useCallback, useEffect, useMemo } from 'react'
import {
  Navigate,
  Route,
  Link as RouterLink,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom'

import { SetupQuery, useSetupQuery, useSetupStartedMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import * as GE from '@/gf/modules/GrammarEvents'
import PlanM from '../modules/Plan'
import useBrandsForm from './Setup/Brands/useForm'
import useBusinessForm from './Setup/Business/useForm'
import useVendorsForm from './Setup/Vendors/useForm'

import Action from '@/gf/components/Action'
import logo from '@/retail/components/Layout/logo.png'
import Brands from './Setup/Brands'
import Business from './Setup/Business'
import FreeProTrial from './Setup/FreeProTrial'
import Vendors from './Setup/Vendors'
import VendorsSummary from './Setup/VendorsSummary'
import Welcome from './Setup/Welcome'

export type Org = Exclude<SetupQuery['org'], null>
type User = Exclude<SetupQuery['user'], null>

const pages = [
  {
    step: 'welcome',
    progress: null,
  },
  {
    step: 'business',
    progress: { title: 'Business Details', minLeft: 5, width: 'w-1/4' },
  },
  {
    step: 'brands',
    progress: { title: 'Add Brands', minLeft: 4, width: 'w-2/4' },
  },
  {
    step: 'vendors',
    progress: { title: 'Add Vendors', minLeft: 3, width: 'w-3/4' },
  },
  {
    step: 'parts-hub-pro-trial',
    progress: { title: 'Parts Hub Pro', minLeft: 1, width: 'w-full' },
  },
  {
    step: 'vendors-summary',
    progress: { title: 'Add Vendors', minLeft: 1, width: 'w-full' },
  },
]

const SetupLoaded = ({
  org,
  user,
  refetch,
  promptProTrial,
}: {
  org: Org
  user: User
  refetch: () => Promise<ApolloQueryResult<SetupQuery>>
  promptProTrial: boolean
}) => {
  const navigate = useNavigate()
  const step = useMatch('/setup/:step')?.params.step
  const client = useGqlClient()
  const [setupStarted] = useSetupStartedMutation({ client })

  useEffect(() => {
    if (!org.setupStartedAt) setupStarted({ variables: { orgId: org.id } })
  }, [])

  useEffect(() => {
    if (!GE.getFlowId(GE.UserFlow.AccountSetup)) {
      GE.createFlowId(GE.UserFlow.AccountSetup)
    }
  }, [])

  const page = pages.filter((p) => p.step === step)[0]

  useEffect(() => {
    if (step) {
      GE.viewsAccountSetupStep(step)
    }
  }, [step])

  const forms = {
    welcome: null,
    business: useBusinessForm(org),
    brands: useBrandsForm(org.brands),
    vendors: useVendorsForm(true),
    'vendors-summary': {
      save: (_onOk?: () => void) => {
        navigate('/')

        GE.completesAccountSetup()
        GE.clearFlowId(GE.UserFlow.AccountSetup)
      },
      saving: false,
      submitDisabled: false,
    },
  }

  const form = forms[page.step as keyof typeof forms]

  const saveAndExit = () => {
    if (form) form.save()
    navigate('/')

    GE.clicksButtonOnFlow('save-and-exit', GE.UserFlow.AccountSetup)
  }

  const previous = useCallback(() => {
    switch (page.step) {
      case 'business':
        navigate('/')
        break
      case 'brands':
        navigate('business')
        break
      case 'vendors':
        navigate('brands')
        break
      case 'vendors-summary':
        navigate('vendors')
        break
      default:
    }
  }, [page])

  const navigateToNext = useCallback(() => {
    switch (page.step) {
      case 'welcome':
        navigate('business')
        break
      case 'business':
        navigate('brands')
        break
      case 'brands':
        navigate('vendors')
        break
      case 'vendors':
        if (promptProTrial) navigate('parts-hub-pro-trial')
        else navigate('/')
        break
      default:
        navigate('/')
    }
  }, [page.step])

  const next = () => form?.save(() => refetch().then(() => navigateToNext()))

  const progress = useMemo(
    () =>
      page && page.progress ? (
        <div className="max-w-xl space-y-2 mx-auto">
          <div className="flex items-center justify-between">
            <div>{page.progress.title}</div>
            <div className="text-gray-500 text-sm">{page.progress.minLeft} min remaining</div>
          </div>

          <div className="bg-gray-100 rounded-full">
            <div className={`${page.progress.width} h-2 bg-blue-600 rounded-full`} />
          </div>
        </div>
      ) : null,
    [page?.step, page?.progress]
  )

  return (
    <div className="min-h-screen bg-white border-t-8 border-gearflow flex flex-col">
      <header className="flex px-8 py-6">
        <div className="basis-1/2 md:basis-1/4">
          <RouterLink to="/">
            <img src={logo} alt="Gearflow" className="w-36" />
          </RouterLink>
        </div>

        <div className="basis-0 hidden md:basis-1/2 md:block">{progress}</div>

        <div className="basis-1/2 flex justify-end md:basis-1/4">
          {!['vendors-summary', 'vendors', 'parts-hub-pro-trial'].includes(page.step) && (
            <div className="bg-gray-100 px-4 py-3 rounded-md">
              <Action.T onClick={saveAndExit}>Save &amp; Exit</Action.T>
            </div>
          )}
        </div>
      </header>

      <div className="md:hidden px-8">{progress}</div>

      <div className="flex-1 pt-8 pb-4">
        <Routes>
          <Route
            path="welcome"
            element={
              <Welcome
                beginPath={
                  user.orgUser.setupStep
                    ? `/setup/${user.orgUser.setupStep.toLowerCase()}`
                    : '/setup/brands'
                }
              />
            }
          />

          <Route path="business" element={<Business form={forms.business} />} />
          <Route path="brands" element={<Brands form={forms.brands} />} />
          <Route
            path="vendors"
            element={
              <Vendors
                form={forms.vendors}
                locationId={org.locations[0]?.id ?? null}
                showBrandsFilter
              />
            }
          />
          <Route path="parts-hub-pro-trial" element={<FreeProTrial endPath="/" />} />
          <Route path="vendors-summary" element={<VendorsSummary org={org} />} />
        </Routes>
      </div>

      {form && (
        <div className="sticky bottom-0 bg-white border-t-2 border-gray-100 px-8 py-2">
          <div className="max-w-xl mx-auto flex justify-between">
            <div>
              {page.step !== 'welcome' && <Action.S onClick={() => previous()}>Back</Action.S>}
            </div>

            <div>
              <Action.P
                onClick={next}
                color="blue"
                disabled={form.submitDisabled ?? false}
                performing={form.saving}
              >
                {page.step === 'vendors-summary' || page.step === 'vendors'
                  ? 'Finish Setup'
                  : 'Next'}
              </Action.P>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const Setup = () => {
  const {
    user: { id: userId },
    organization: { id: orgId },
    plan,
  } = useSession()

  const { data, refetch } = useSetupQuery({
    variables: { orgId, userId },
    client: useGqlClient(),
  })
  const { org, user } = data || {}

  if (!org || !user) return null

  return (
    <Routes>
      <Route path="" element={<Navigate to="welcome" replace />} />
      <Route
        path="*"
        element={
          <SetupLoaded
            org={org}
            user={user}
            refetch={refetch}
            promptProTrial={!!plan && !PlanM.isPro(plan)}
          />
        }
      />
    </Routes>
  )
}

export default Setup
