import { Fragment } from 'react'
import {
  CheckCircleIcon,
  ClipboardCheckIcon,
  InboxIcon,
  PaperAirplaneIcon,
  ReplyIcon,
} from '@heroicons/react/outline'
import { nth } from 'lodash'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Maybe } from '@/types'

import Time from '@/gf/modules/Time'

import Variation, { formatTime } from '../Variation'
import { DurationDataPoint, DurationStep } from '../RequestFulfillment'
import Ghost from '@/gf/components/Ghost'
import StandardColumns from '@/buyers/pages/Reporting/StandardColumns'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import { TooltipUnderlineText } from '@/gf/components/Tooltip'
import { NO_DATA_VALUE } from '@/buyers/pages/Reporting/Scorecards'

const timelineSteps = [
  { id: 'inbox', icon: InboxIcon },
  { id: 'plane', icon: PaperAirplaneIcon, className: 'rotate-90' },
  { id: 'reply', icon: ReplyIcon },
  { id: 'clipboard', icon: ClipboardCheckIcon },
  { id: 'check', icon: CheckCircleIcon },
]

const StepData = ({
  name,
  tooltip,
  value,
  href,
  active,
  onClick,
  className,
}: {
  name: string
  tooltip?: string
  // undefined is loading, null is no value
  value: Maybe<DurationDataPoint> | undefined
  href: Maybe<string>
  active?: boolean
  onClick?: () => void
  className?: string
}) => {
  const navigate = useNavigate()
  const clickable = !!href || !!onClick
  const nameClassName = classNames(
    'text-xs lg:text-sm font-medium text-left',
    active ? 'text-blue-700' : 'text-gray-900'
  )
  return (
    <div
      className={classNames(
        'mt-1 px-3 py-2 flex flex-col items-start rounded-lg',
        clickable && 'cursor-pointer',
        clickable && !active && 'hover:bg-gray-100 transition duration-150',
        active && 'bg-blue-50 outline outline-2 -outline-offset-1 outline-blue-500',
        className
      )}
      onClick={href ? () => href && navigate(href) : onClick}
    >
      {tooltip ? (
        <Tooltip>
          <TooltipTrigger className="inline-flex shrink">
            <TooltipUnderlineText className={nameClassName}>{name}</TooltipUnderlineText>
          </TooltipTrigger>
          <TooltipContent className="z-[5000] max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
            {tooltip}
          </TooltipContent>
        </Tooltip>
      ) : (
        <h5 className={nameClassName}>{name}</h5>
      )}
      <span
        className={classNames(
          'block text-lg lg:text-2xl font-medium',
          active ? 'text-blue-700' : 'text-gray-900'
        )}
      >
        {typeof value === 'undefined' ? (
          <Ghost className={classNames('inline-block h-6 w-24', active && 'bg-blue-200')} />
        ) : value === null ? (
          NO_DATA_VALUE
        ) : (
          Time.secondsToString(value.value)
        )}
      </span>
      <div className="mt-1 mdplus:mt-auto xl:mt-2">
        {typeof value === 'undefined' ||
        (value !== null && typeof value.variation === 'undefined') ? (
          <Ghost className={classNames('inline-block h-5 w-16', active && 'bg-blue-200')} />
        ) : value === null ||
          value.variation === null ||
          typeof value.variation === 'undefined' ? null : (
          <Variation
            value={value.variation}
            toformat={formatTime}
            className="text-xs lg:text-sm"
            positiveDirection="down"
          />
        )}
      </div>
    </div>
  )
}

const Timeline = ({
  showReviewStep = true,
  reviewRequest,
  receiveQuote,
  reviewQuote,
  orderProcessing,
  links = false,
}: {
  showReviewStep?: boolean
  reviewRequest: DurationStep
  receiveQuote: DurationStep
  reviewQuote: DurationStep
  orderProcessing: DurationStep
  links?: boolean
}) => {
  // Make the step active if the section after it is active. So, if index i is active, then highlight steps i to i+1.
  const stepsActive = [
    reviewRequest?.active,
    receiveQuote?.active,
    reviewQuote?.active,
    orderProcessing?.active,
  ]
  const stepsWithActive = timelineSteps.map((s, i) => ({
    ...s,
    active: nth(stepsActive, i),
  }))
  // Make all steps active if none of them are
  const allStepsActive = !stepsActive.some((a) => a)
  const steps = showReviewStep ? stepsWithActive : stepsWithActive.slice(1)
  return (
    <div>
      <div className="flex items-center flex-shrink-0">
        {steps.map((s, i) => (
          <Fragment key={s.id}>
            {i > 0 && (
              <span
                className={classNames(
                  'w-full border-2',
                  allStepsActive || nth(steps, i - 1)?.active
                    ? 'border-blue-300'
                    : 'border-gray-200'
                )}
              />
            )}

            <span
              className={classNames(
                'rounded-full flex items-center justify-center w-[1.863rem] h-[1.863rem] flex-shrink-0',
                allStepsActive || s.active || nth(steps, i - 1)?.active
                  ? 'text-blue-900 bg-gradient-to-t from-blue-400 to-blue-200'
                  : 'text-gray-400 bg-gray-200'
              )}
            >
              <s.icon className={classNames('w-[1.11rem] h-[1.11rem]', s.className)} />
            </span>
          </Fragment>
        ))}
      </div>

      <div
        className={classNames(
          '-mt-2 px-[1.863rem] grid gap-x-[1.863rem]',
          showReviewStep ? 'grid-cols-4' : 'grid-cols-3'
        )}
      >
        {showReviewStep && (
          <StepData
            className="flex grow"
            name={StandardColumns.approveTimeTitle}
            tooltip={StandardColumns.approveTimeTooltip}
            value={reviewRequest?.data}
            href={links ? '/reporting/time?metric=time to review request' : null}
            active={reviewRequest?.active}
            onClick={reviewRequest?.onClick}
          />
        )}
        <StepData
          className="flex grow"
          name={StandardColumns.timeToQuoteTitle}
          tooltip={StandardColumns.timeToQuoteTooltip}
          value={receiveQuote?.data}
          href={links ? '/reporting/time?metric=response time' : null}
          active={receiveQuote?.active}
          onClick={receiveQuote?.onClick}
        />
        <StepData
          className="flex grow"
          name={StandardColumns.reviewQuotesTimeTitle}
          tooltip={StandardColumns.reviewQuotesTimeTooltip}
          value={reviewQuote?.data}
          href={links ? '/reporting/time?metric=review quotes time' : null}
          active={reviewQuote?.active}
          onClick={reviewQuote?.onClick}
        />
        <StepData
          className="flex grow"
          name={StandardColumns.orderFulfillmentTimeTitle}
          tooltip={StandardColumns.orderFulfillmentTimeTooltip}
          value={orderProcessing?.data}
          href={links ? '/reporting/time?metric=order fulfillment time' : null}
          active={orderProcessing?.active}
          onClick={orderProcessing?.onClick}
        />
      </div>
    </div>
  )
}

export default Timeline
