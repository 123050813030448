import { useState } from 'react'
import { SearchIcon, XIcon } from '@heroicons/react/solid'

import TextInput from '@/gf/components/TextInput'
import { twMerge } from 'tailwind-merge'

const SearchInput = ({
  initialValue,
  onChange,
  placeholder,
  autoFocus = false,
  className,
}: {
  initialValue: string
  onChange: (newValue: string) => void
  placeholder?: string
  autoFocus?: boolean
  className?: string
}) => {
  const [value, setValue] = useState(initialValue)

  const update = (newValue: string) => {
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <label className={twMerge('relative flex items-center', className)}>
      <TextInput
        className="pl-8"
        value={value}
        onChange={(e) => update(e.target.value)}
        placeholder={placeholder}
        id="search-input"
        autoFocus={autoFocus}
      />

      <SearchIcon className="absolute left-2 h-5 text-gray-500" />

      {!!value && (
        <button
          onClick={() => update('')}
          className="absolute right-2 text-slate-500"
          type="button"
        >
          <XIcon className="h-5" />
        </button>
      )}
    </label>
  )
}

export default SearchInput
