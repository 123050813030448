import {
  CreateRequestPageQuery,
  useAdvancedCreateRfqMutation,
  useCreateRequestPageQuery,
} from '@/buyers/_gen/gql'
import Page from '@/gf/components/Page'
import * as GE from '@/gf/modules/GrammarEvents'
import { useEffect, useMemo } from 'react'
import { Navigate, Route, Routes, matchPath, useLocation, useNavigate } from 'react-router-dom'
import BrokerWarning from '../components/BrokerWarning'
import Frame from '../components/Frame'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import PartDetailsStep from './CreateRequest/PartDetailsStep'
import ProgressBar from './CreateRequest/ProgressBar'
import ReviewStep from './CreateRequest/ReviewStep'
import SelectVendorsStep from './CreateRequest/SelectVendorsStep'
import { CreateRequestState, SourcingType } from './CreateRequest/types'
import usePersistedState from './CreateRequest/usePersistedState'
import useVendorSelectionType from './CreateRequest/useVendorSelectionType'
import pick from 'lodash/pick'

type Org = NonNullable<CreateRequestPageQuery['org']>
type DefaultLocation = CreateRequestPageQuery['defaultShippingLocation']

type Step = {
  label: string
  stepPath: string
  index: number
  progressFn: (request: CreateRequestState) => number
}

const BASE_PATH = '/rfqs/create'

const locationAndReferencePoint = (location: NonNullable<DefaultLocation>) => ({
  locationId: location.id,
  nearbyReferencePoint: location?.address?.point
    ? pick(location?.address?.point, ['lat', 'lng'])
    : undefined,
})

const partsDetailsProgress = (request: CreateRequestState) => {
  const machineProgress = !request.machineInvolved ? 40 : request.machineOrgId ? 40 : 0
  const partsProgress = request.parts?.[0]?.partNumber || request.parts?.[0]?.description ? 50 : 0
  const urgencyProgress = request.urgency?.priority ? 10 : 0
  return machineProgress + partsProgress + urgencyProgress
}

const vendorsProgress = (request: CreateRequestState) => {
  const selectedDealers = request.dealerLocationIds.length > 0 ? 50 : 0
  const location = request.locationId ? 20 : 0
  const dealersProgress = selectedDealers + location

  const gfNwProgress = request.locationId ? 100 : 0

  return request.sourcing === SourcingType.GF_NETWORK ? gfNwProgress : dealersProgress
}

const createStepsConfig = (includeVendors: boolean) =>
  [
    { label: 'Add Part Details', stepPath: 'part-details', progressFn: partsDetailsProgress },
    includeVendors
      ? { label: 'Select Vendors', stepPath: 'vendors/*', progressFn: vendorsProgress }
      : null,
    { label: 'Review', stepPath: 'review', progressFn: (_request: CreateRequestState) => 100 },
  ]
    .filter((s) => s !== null)
    .map((s, index) => ({ ...s, index } as Step))

const Redirect = () => {
  const { user } = useSession()

  useEffect(() => {
    GE.initiatesRequest()
  }, [user.id])

  return <Navigate to="part-details" replace />
}

const CreateRequestLoaded = ({
  org,
  sourcing,
  defaultLocation,
}: {
  org: Org
  sourcing: SourcingType
  defaultLocation: DefaultLocation
}) => {
  const client = useGqlClient()
  const location = useLocation()
  const navigate = useNavigate()
  const { user, organization } = useSession()

  const vendorsSectionEnabled = !(
    organization.requestApproval && user.userRole?.name === 'Requester'
  )

  const gearflowNetworkSectionEnabled =
    !organization.requestApproval || user.userRole?.name !== 'Requester'

  const { values, update, reset } = usePersistedState('new-request-data')

  const [createRfq, { loading: createRequestInProgress }] = useAdvancedCreateRfqMutation({ client })

  const resetForm = () => {
    GE.clicksButtonOnFlow('start-over', GE.UserFlow.CreateRequest)
    reset()
    update({ sourcing })
    GE.initiatesRequest()
    navigate('part-details')

    if (!vendorsSectionEnabled && defaultLocation) {
      update(locationAndReferencePoint(defaultLocation))
    }

    // Clear the state if the form is still on the first step
    if (location.pathname.includes('part-details')) window.location.reload()
  }

  const stepsConfig = useMemo(
    () => createStepsConfig(vendorsSectionEnabled),
    [vendorsSectionEnabled]
  )

  const currentStep = useMemo(() => {
    const matchingStep = stepsConfig.find(
      (step) => !!matchPath({ path: `${BASE_PATH}/${step.stepPath}` }, location.pathname)
    )
    const step = matchingStep ?? stepsConfig[0]

    return { ...step, progress: matchingStep?.progressFn(values) ?? 0 }
  }, [location.pathname, values, vendorsSectionEnabled])

  const onPartDetailsSubmitted = () => {
    const nextUrl = vendorsSectionEnabled ? 'vendors' : 'review'

    navigate(nextUrl)
  }

  const vendorSelectionType = useVendorSelectionType(org)

  const onVendorsSubmitted = () => navigate(`review`)

  const onCreateRequest = async () => {
    const createResult = await createRfq({
      variables: {
        name: user.name || '',
        email: user.email,
        phoneNumber: user.phoneNumber,
        billingCompanyId: values.billingCompanyId ?? null,
        splitOrder: false,
        partsRequest: values.comments ?? null,
        accountMachineId: values.machineOrgId || null,
        vendorIds:
          values.sourcing === SourcingType.VENDORS ? values.vendors.map((vi) => vi.vendorId) : [],
        vendors:
          values.sourcing === SourcingType.VENDORS
            ? values.vendors.map((v) => ({
                vendorContactIds: v.contactIds,
                deliveryMethod: v.deliveryMethod,
                id: v.vendorId,
                customerNote: null,
                vendorContactId: null,
                pickup: null,
                accountNumber: v.accountNumber,
              }))
            : [],
        shippingLocationIds: [values.locationId as string],
        shippingAddress: null,
        neededBy: values.urgency?.neededByDate.toUTC() ?? null,
        imageUrls: values.parts?.map((p) => p.pictures).flat() ?? [],
        partRequests:
          values.parts?.map((part) => ({
            mpn: part.partNumber,
            description: part.description,
            quantity: part.quantity,
            externalId: part.externalId,
            taskNumber: part.taskNumber,
          })) ?? null,
        operator: null,
        engineHours: null,
        lat: null,
        lng: null,
        machineDown: values.urgency?.machineDown ?? false,
        workOrderNumber: values.workOrderNumber ?? null,
        brokerRequestForQuoteId: values.brokerRfqId ?? null,
      },
    })

    if (createResult.data) {
      reset()

      GE.createsRequest(createResult.data.advancedCreateRfq.id)

      navigate(`/rfqs/${createResult.data.advancedCreateRfq.id}?newRequest=1&init=1`)
    } else {
      console.error(createResult.errors)
    }
  }

  useEffect(() => {
    if (values.sourcing === undefined && sourcing !== undefined) {
      update({ sourcing: vendorsSectionEnabled ? sourcing : SourcingType.VENDORS })
    }
  }, [sourcing])

  useEffect(() => {
    if (!vendorsSectionEnabled && defaultLocation) {
      update(locationAndReferencePoint(defaultLocation))
    }
  }, [vendorsSectionEnabled, defaultLocation])

  return (
    <div className="flex flex-col gap-y-8 mt-8 pb-12">
      {values.brokerRfqId && <BrokerWarning.Request fulfillingRfqId={values.brokerRfqId} />}
      <ProgressBar
        steps={stepsConfig.map((s) => s.label)}
        currentStep={currentStep.index}
        currentStepProgress={currentStep.progress}
      />
      <div className="space-y-4">
        <h3 className="text-2xl text-gray-900">{currentStep.label}</h3>
        <Routes>
          <Route path="" element={<Redirect />} />
          <Route
            path="part-details"
            element={
              <PartDetailsStep
                reset={resetForm}
                request={values}
                updateRequest={update}
                onSubmit={onPartDetailsSubmitted}
                billingCompanyRequired={organization.requireBillingCompany ?? false}
                workOrderNumberRequired={organization.createRequestRequireWorkOrder ?? false}
                submitButtonText={vendorsSectionEnabled ? 'Save and Select Vendors' : 'Next'}
                onOrgMachineSelected={(orgMachineId) => GE.selectsOrgMachine(orgMachineId)}
                onAddPartsClicked={() =>
                  GE.clicksButtonOnFlow('add-part', GE.UserFlow.CreateRequest)
                }
                onPriorityChanged={(value) => GE.selectsPriority(value)}
              />
            }
          />
          <Route
            path="vendors/*"
            element={
              <SelectVendorsStep
                reset={resetForm}
                request={values}
                updateRequest={update}
                onSubmit={onVendorsSubmitted}
                onBackClicked={() => navigate('part-details')}
                vendorSelectionType={vendorSelectionType}
                gearflowNetworkSectionEnabled={gearflowNetworkSectionEnabled}
              />
            }
          />
          <Route
            path="review"
            element={
              <ReviewStep
                reset={resetForm}
                request={values}
                updateRequest={update}
                onBackClicked={() => navigate(vendorsSectionEnabled ? 'vendors' : 'part-details')}
                onCreateRequestClicked={onCreateRequest}
                submitInProgress={createRequestInProgress}
              />
            }
          />
        </Routes>
      </div>
    </div>
  )
}

const CreateRequest = () => {
  const client = useGqlClient()
  const { orgId } = useSession()

  const { org, planSubscription, vendorsSearch, defaultShippingLocation } =
    useCreateRequestPageQuery({ variables: { orgId }, client }).data || {}

  const hasVendors = (vendorsSearch?.pagination.totalResults ?? 0) > 0

  return (
    <Frame>
      <Page className="mt-4" title="Create Request">
        {planSubscription !== undefined && org && defaultShippingLocation !== undefined && (
          <CreateRequestLoaded
            org={org}
            sourcing={
              planSubscription || hasVendors ? SourcingType.VENDORS : SourcingType.GF_NETWORK
            }
            defaultLocation={defaultShippingLocation}
          />
        )}
      </Page>
    </Frame>
  )
}

export default CreateRequest
