import { InformationCircleIcon, PlusCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import pick from 'lodash/pick'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import type { Part } from './useForm'

import { DeliveryMethod, RfqCreateQuoteQuery, useCreateQuoteMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import MoneyM from '@/gf/modules/Money'
import useForm from './useForm'

import Action from '@/gf/components/Action'
import AddressInput from '@/gf/components/AddressInput'
import Card from '@/gf/components/Card'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import InStockCheckbox from '@/gf/components/InStockCheckbox'
import TextInput from '@/gf/components/TextInput'
import DateInput from '@/gf/components/inputs/DateNext'
import PriceInput from '@/gf/components/inputs/PriceV2'
import QuantityInput from '@/gf/components/inputs/Quantity'
import PricesForm from '../PricesForm'

type Rfq = RfqCreateQuoteQuery['rfqs'][number]
type VendorLink = RfqCreateQuoteQuery['rfqs'][number]['vendorLinks'][number]
type Vendor = VendorLink['vendor']

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

const CreateQuoteForm = ({
  rfq,
  vendor,
  vendorLink,
}: {
  rfq: Rfq
  vendor: Vendor
  vendorLink: VendorLink
}) => {
  const navigate = useNavigate()
  const [_, msgr] = useMsgs()
  const [adding, setAdding] = useState(false)
  const client = useGqlClient()
  const [createQuote] = useCreateQuoteMutation({ client })

  const buildPart = (attrs?: Partial<Part>) => ({
    id: uuid(),
    mpn: '',
    rfqPartMpn: '',
    rfqPartId: null,
    name: '',
    description: '',
    quantity: 1,
    unitPrice: null,
    inStock: true,
    availableAt: null,
    ...(attrs || {}),
  })

  const { form, errors, updateForm, updateErrors, addPart, removePart, updatePart } = useForm({
    parts: [buildPart()],
    quoteNumber: '',
    shippingCost: zeroPrice,
    taxCost: zeroPrice,
    pickupAddress: {
      firstName: '',
      lastName: '',
      companyName: '',
      lineOne: '',
      lineTwo: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'United States',
    },
  })

  useEffect(() => {
    const pickupAddress = vendorLink?.pickupAddress || vendor.address || vendor.store?.address
    const pickupAddressUpdate = pickupAddress ? { pickupAddress } : {}

    const parts = rfq.parts.map((p) =>
      buildPart({
        mpn: p.mpn,
        rfqPartMpn: p.mpn,
        rfqPartId: p.id,
        name: p.description,
        quantity: p.quantity,
      })
    )

    updateForm({ ...pickupAddressUpdate, parts })
  }, [])

  const subtotal = form.parts
    .map((part) => MoneyM.mult(part.unitPrice || zeroPrice, part.quantity || 1))
    .reduce(MoneyM.add)

  const onSubmit = () => {
    setAdding(true)

    const parts = form.parts.map((part) => {
      const availableAt = !part.inStock && part.availableAt ? part.availableAt.toISODate() : null

      return {
        mpn: part.mpn,
        name: part.name,
        quantity: part.quantity,
        unitPrice: part.unitPrice,
        inStock: part.inStock,
        availableAt,
        rfqPartId: part.rfqPartId,
      }
    })

    createQuote({
      variables: {
        rfqId: rfq.id,
        vendorId: vendor.id,
        parts,
        quoteNumber: form.quoteNumber,
        shippingCost: form.shippingCost,
        taxCost: form.taxCost,
        pickupAddress: vendorLink?.pickup
          ? pick(form.pickupAddress, [
              'companyName',
              'firstName',
              'lastName',
              'lineOne',
              'lineTwo',
              'city',
              'state',
              'country',
              'postalCode',
            ])
          : null,
      },
    })
      .then(() => {
        msgr.add('Quote created.', 'positive')
        navigate(`/rfqs/${rfq.id}/request`)
      })
      .catch((err) => {
        const gqlError = err.graphQLErrors[0]

        if (gqlError) {
          updateErrors(gqlError)
          msgr.add(gqlError.message, 'negative')
        } else msgr.addUnknownError()
      })
      .finally(() => setAdding(false))
  }

  return (
    <Form onSubmit={onSubmit}>
      <Card>
        <Card.Section>
          <div className="flex flex-row gap-x-1 items-center text-sm text-gray-700">
            <InformationCircleIcon className="w-5 h-5 flex shrink-0" />
            Call your Vendor and enter the quote information in the form below. Or enter your order
            information from an OEM portal.
          </div>
        </Card.Section>

        <Card.Section>
          <div className="space-y-8">
            <div className={form.parts.length > 1 ? 'divide-y' : ''}>
              {form.parts.map((part, index) => (
                <div
                  className="flex flex-wrap gap-8 justify-between items-center py-6 first:pt-0"
                  key={part.id}
                >
                  <div className="flex flex-col gap-y-2 flex-wrap grow">
                    <div className="flex gap-x-4 gap-y-2 flex-wrap grow">
                      <div className="grow space-y-2">
                        <div className="flex gap-x-4 gap-y-2 flex-wrap grow">
                          <Field
                            label="Part Number"
                            errorText={errors.parts[index]?.mpn}
                            inputId={`${part.id}-mpn`}
                          >
                            <div className="w-52">
                              <TextInput
                                value={part.mpn}
                                setValue={(mpn) => updatePart(part.id, { mpn })}
                                id={`${part.id}-mpn`}
                              />
                            </div>
                          </Field>

                          <div className="grow">
                            <Field
                              label="Name"
                              errorText={errors.parts[index]?.name}
                              inputId={`${part.id}-name`}
                            >
                              <div className="min-w-72">
                                <TextInput
                                  value={part.name}
                                  setValue={(name) => updatePart(part.id, { name })}
                                  id={`${part.id}-name`}
                                />
                              </div>
                            </Field>
                          </div>
                        </div>

                        {part.rfqPartId && part.rfqPartMpn && part.mpn !== part.rfqPartMpn && (
                          <div className="flex px-2 py-1 bg-gray-100 border border-gray-300 rounded-md text-sm max-w-prose">
                            <div>
                              Requested part: <span className="font-medium">{part.rfqPartMpn}</span>
                              . If this is different from the requested part, remove it and add a
                              new part.
                            </div>
                          </div>
                        )}
                      </div>

                      <Field
                        label="Quantity"
                        errorText={errors.parts[index]?.quantity}
                        inputId={`${part.id}-quantity`}
                      >
                        <div className="w-24">
                          <QuantityInput
                            value={part.quantity}
                            setValue={(quantity) => updatePart(part.id, { quantity })}
                            min={1}
                            id={`${part.id}-quantity`}
                          />
                        </div>
                      </Field>

                      <Field
                        label="Unit Price"
                        errorText={errors.parts[index]?.unitPrice}
                        inputId={`${part.id}-unit-price`}
                      >
                        <div className="w-32">
                          <PriceInput
                            price={part.unitPrice}
                            onChange={(unitPrice) =>
                              updatePart(part.id, { unitPrice: unitPrice ?? null })
                            }
                            min={0}
                            id={`${part.id}-unit-price`}
                          />
                        </div>
                      </Field>

                      <Field label="Price">
                        <div className="text-base leading-4 py-2.5">
                          {MoneyM.format(
                            MoneyM.mult(part.unitPrice || zeroPrice, part.quantity || 1)
                          )}
                        </div>
                      </Field>
                    </div>

                    <div className="flex flex-wrap gap-x-4 justify-between items-center">
                      <div className="flex gap-x-4 gap-y-2 flex-wrap">
                        <Field inputId={`${part.id}-in-stock`}>
                          <InStockCheckbox
                            className={classNames(
                              'whitespace-nowrap',
                              part.inStock ? 'mt-2' : 'mt-8'
                            )}
                            checked={part.inStock}
                            onChange={(e) => updatePart(part.id, { inStock: e.target.checked })}
                            deliveryMethod={
                              vendorLink.pickup ? DeliveryMethod.Pickup : DeliveryMethod.Shipping
                            }
                          />
                        </Field>

                        {!part.inStock && (
                          <Field
                            label="Available at"
                            inputId={`${part.id}-available`}
                            errorText={errors.parts[index]?.availableAt}
                          >
                            <DateInput
                              value={part.availableAt}
                              onChange={(availableAt) =>
                                updatePart(part.id, { availableAt: availableAt ?? null })
                              }
                              id={`${part.id}-available`}
                            />
                          </Field>
                        )}
                      </div>

                      {form.parts.length > 1 && (
                        <div className="flex grow justify-end">
                          <Action.T
                            className={classNames(
                              'flex gap-1 items-center whitespace-nowrap',
                              !part.inStock && 'mt-6'
                            )}
                            onClick={() => removePart(part.id)}
                          >
                            <XCircleIcon className="w-5 h-5" />
                            <span>Remove</span>
                          </Action.T>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              <div className={form.parts.length > 1 ? 'pt-6' : ''}>
                <Action.T
                  className="flex gap-1 items-center whitespace-nowrap"
                  onClick={() => addPart(buildPart())}
                >
                  <PlusCircleIcon className="w-5 h-5" />
                  <span>Add part</span>
                </Action.T>
              </div>
            </div>

            <div className="flex flex-wrap gap-8 justify-between">
              <Field label="Quote number (optional)">
                <div className="w-52">
                  <TextInput
                    value={form.quoteNumber}
                    setValue={(quoteNumber) => updateForm({ quoteNumber })}
                  />
                </div>
              </Field>

              <PricesForm
                subtotal={subtotal}
                shipping={form.shippingCost}
                tax={form.taxCost}
                pickup={vendorLink?.pickup}
                setTax={(taxCost) => updateForm({ taxCost })}
                setShipping={(shippingCost) => updateForm({ shippingCost })}
              />
            </div>

            {vendorLink?.pickup && (
              <div className="space-y-4 max-w-lg">
                <h2>Pickup Address</h2>
                <AddressInput
                  address={form.pickupAddress}
                  onChange={(pickupAddress) => updateForm({ ...form, pickupAddress })}
                  hideFirstLastName
                  hideCompany
                />
              </div>
            )}

            <Action.P type="submit" performing={adding}>
              Create
            </Action.P>
          </div>
        </Card.Section>
      </Card>
    </Form>
  )
}

export default CreateQuoteForm
