import { User as BaseUser } from '../../types'

import {
  FetchOrganizationUsersDocument,
  FetchOrganizationUsersQueryResult,
  FetchOrganizationUsersQueryVariables,
} from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'

import UserM from '@/gf/modules/User'

import AutocompleteMultiInputNext from './AutocompleteMultiInputNext'

type User = Pick<BaseUser, 'id' | 'name' | 'email'>

const UsersMultiSelectInput = ({
  onChange,
  users,
}: {
  onChange: (newUsers: User[]) => void
  users?: User[]
}) => (
  <div>
    <AutocompleteMultiInputNext<
      NonNullable<FetchOrganizationUsersQueryResult['data']>,
      FetchOrganizationUsersQueryVariables,
      User
    >
      gqlClient={useGqlClient()}
      query={FetchOrganizationUsersDocument}
      queryVars={(search) => ({ search })}
      onChange={(newUsers) => onChange(newUsers)}
      transformQueryToData={(queryData) => queryData.fetchOrganizationUsers}
      transformDatumToOption={(user) => ({
        value: user.id,
        label: UserM.label(user),
      })}
      defaultValues={users}
    />
  </div>
)

export default UsersMultiSelectInput
