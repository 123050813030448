import { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

const Box = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} className={twMerge('rounded-lg bg-white border border-gray-200', className)}>
    {children}
  </div>
)

export default Box
