import React from 'react'

import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { UNKNOWN_ERROR } from '@/gf/providers/MsgsProvider'
import useUpdatePartRequest from '../hooks/useUpdatePartRequest'

import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import QuantityInput from '@/gf/components/inputs/Quantity'
import TextInput from '@/gf/components/inputs/Text'
import Modal from '@/gf/components/Modal'
import TextArea from '@/gf/components/TextArea'

import { PartRequest } from '../../types'

interface Form {
  mpn: string
  description: string
  quantity: number | null
  externalId?: string | null
  taskNumber?: string | null
}

interface Errors {
  mpn?: string
  description?: string
  quantity?: string
}

interface GqlError {
  message: string
}

const initForm = {
  mpn: '',
  description: '',
  quantity: 1,
  externalId: null,
  taskNumber: null,
}

const Errors = (() => {
  const fromGqlErrors = (gqlErrors: GqlError[]): Errors =>
    gqlErrors.reduce((acc, gqlError) => {
      if (gqlError.message === 'mpn_or_description_required')
        return { ...acc, mpn: 'Part Number or Description is required.' }
      return acc
    }, {})

  return { fromGqlErrors }
})()

const EditPartModal = ({
  partRequest,
  onClose,
  onEdited,
}: {
  partRequest?: PartRequest
  onClose: () => void
  onEdited: () => void
}) => {
  const [_, msgr] = useMsgs()
  const [submitting, submittingToggler] = useToggle(false)
  const [errors, setErrors] = React.useState<Errors>({})
  const [form, setForm] = React.useState<Form>(initForm)
  const updatePartRequest = useUpdatePartRequest()

  React.useEffect(() => {
    const newForm = partRequest
      ? {
          mpn: partRequest.mpn,
          description: partRequest.description,
          quantity: partRequest.quantity,
          externalId: partRequest.externalId,
          taskNumber: partRequest.taskNumber,
        }
      : initForm

    setForm(newForm)
  }, [!partRequest])

  const onSubmit = () => {
    if (!partRequest || submitting) return
    submittingToggler.on()

    updatePartRequest({ partRequestId: partRequest.id, ...form })
      .then(() => {
        onEdited()
        setForm(initForm)
        setErrors({})
        msgr.add('Part saved.', 'positive')
      })
      .catch((err) => {
        const newErrors = Errors.fromGqlErrors(err.graphQLErrors)
        setErrors(newErrors)

        if (Object.keys(newErrors).length === 0) {
          msgr.add(err.message || UNKNOWN_ERROR, 'negative')
        }
      })
      .finally(() => {
        submittingToggler.off()
      })
  }

  return (
    <Modal
      title="Edit Part"
      open={!!partRequest}
      onClose={onClose}
      footerAction={
        <Action.P type="submit" form="edit-part" disabled={submitting}>
          {submitting ? <>Saving&hellip;</> : 'Save'}
        </Action.P>
      }
    >
      <Form onSubmit={onSubmit} id="edit-part">
        <div className="space-y-4">
          <Field label="Part Number" errorText={errors.mpn}>
            <TextInput value={form.mpn} setValue={(mpn) => setForm({ ...form, mpn })} />
          </Field>
          <Field label="Description" errorText={errors.description}>
            <TextArea
              rows={4}
              value={form.description}
              setValue={(description) => setForm({ ...form, description })}
            />
          </Field>
          <Field label="Quantity" errorText={errors.quantity}>
            <div className="w-48">
              <QuantityInput
                min={1}
                value={form.quantity}
                onBlur={() => {
                  if (form.quantity === null) setForm({ ...form, quantity: 1 })
                }}
                setValue={(quantity) => setForm({ ...form, quantity })}
              />
            </div>
          </Field>
        </div>
      </Form>
    </Modal>
  )
}

export default EditPartModal
