import { ArrowRightIcon } from '@heroicons/react/outline'
import { LockClosedIcon } from '@heroicons/react/solid'
import { useState } from 'react'

import { MIN_REQUESTS_FOR_REPORTING } from '../modules/Reporting'

import Action from '@/gf/components/Action'
import FreeProTrialModal from './FreeProTrialModal'
import PartsSpend from './ReportsPreview/PartsSpend'
import Performance from './ReportsPreview/Performance'
import RequestFulfillmentTime from './ReportsPreview/RequestFulfillmentTime'

const ReportsPreview = ({
  isProPlan,
  hasEnoughReportData,
}: {
  isProPlan: boolean
  hasEnoughReportData: boolean
}) => {
  const [isFreeProTrialModalOpen, setIsFreeProTrialModalOpen] = useState(false)
  return (
    <section className="bg-white rounded-xl shadow-base border border-gray-200 overflow-hidden">
      {!isProPlan && (
        <div className="text-white bg-orange-400 px-12 py-2 flex justify-between items-center min-h-[3rem]">
          <p className="flex items-center gap-x-1">
            <LockClosedIcon className="inline-block h-5 w-5 flex-shrink-0" />
            Your Reports is a Pro Account Feature. To access, you will need to upgrade to a Parts
            Hub Pro Account
          </p>
          <Action.T
            className="text-white no-underline flex flex-shrink-0 gap-x-1 items-center"
            onClick={() => setIsFreeProTrialModalOpen(true)}
          >
            Start free trial
            <ArrowRightIcon className="inline-block h-5 w-5" />
          </Action.T>
          <FreeProTrialModal
            open={isFreeProTrialModalOpen}
            onClose={() => setIsFreeProTrialModalOpen(false)}
            onClaimed={() => {
              setIsFreeProTrialModalOpen(false)
              return Promise.resolve()
            }}
          />
        </div>
      )}
      {isProPlan && !hasEnoughReportData && (
        <p className="text-blue-900 bg-blue-300 px-12 py-2 flex justify-center items-center min-h-[3rem]">
          Metrics below are illustrative. Your data will display once {MIN_REQUESTS_FOR_REPORTING}{' '}
          requests have been fulfilled.
        </p>
      )}
      <div className="px-4 lg:px-6 xl:px-8 pb-4 lg:pb-6 xl:pb-8 pt-4 xl:pt-6 space-y-6">
        <div className="space-y-2 max-w-[38rem]">
          <h2 className="text-2xl">Your Reports</h2>
          <p className="text-lg">
            {isProPlan
              ? 'Once you start submitting requests and completing orders, you’ll be able to track your performance, see trends, and take action to improve your process.'
              : 'To track your performance, see trends, and take action to improve your process, you will need to upgrade to a Parts Hub Pro Account.'}
          </p>
          {!isProPlan && (
            <Action.S onClick={() => setIsFreeProTrialModalOpen(true)}>Try It Now!</Action.S>
          )}
        </div>

        <PartsSpend />
        <RequestFulfillmentTime />
        <Performance />
      </div>
    </section>
  )
}

export default ReportsPreview
