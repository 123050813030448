import { useState } from 'react'
import { DateTime } from 'luxon'
import { InformationCircleIcon } from '@heroicons/react/outline'

import { SelectStoreOrderProblem } from '@/types'

import StoreOrder from '@/gf/modules/StoreOrder'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import {
  StoreOrderProblemType,
  useMarkStoreOrderReceivedMutation,
  useReportStoreOrderProblemMutation,
  useMarkReceivedQuery,
  DeliveryMethod,
} from '@/buyers/_gen/gql'

import ModalForm from '@/gf/components/ModalForm'
import DateTimeInput from '@/gf/components/inputs/DateTime'
import Select from '@/gf/components/Select'
import TextArea from '@/gf/components/TextArea'

const MarkReceivedModal = ({
  open,
  close,
  storeOrderId,
  onSuccess,
}: {
  open: boolean
  close: () => void
  storeOrderId: string
  onSuccess: () => void
}) => {
  const [_, msgr] = useMsgs()
  const [submitting, submitToggle] = useToggle()
  const gqlClient = useGqlClient()
  const [markStoreOrderReceived] = useMarkStoreOrderReceivedMutation({ client: gqlClient })
  const [reportStoreOrderProblem] = useReportStoreOrderProblemMutation({ client: gqlClient })

  const storeOrder = useMarkReceivedQuery({
    variables: { storeOrderFilter: JSON.stringify(['id_eq', storeOrderId]) },
    client: gqlClient,
    skip: !open,
  }).data?.storeOrder

  const [fields, setFields] = useState({
    receivedAt: DateTime.now(),
    accuracyOptionId: StoreOrder.noneAccuracyOption.id,
    accuracyDetails: '',
  })

  if (!storeOrder) return null

  const reportProblem = fields.accuracyOptionId !== StoreOrder.noneAccuracyOption.id

  const onSubmit = () => {
    submitToggle.on()

    markStoreOrderReceived({ variables: { storeOrderId, receivedAt: fields.receivedAt.toUTC() } })
      .then(() => {
        if (reportProblem)
          return reportStoreOrderProblem({
            variables: {
              storeOrderProblem: {
                storeOrderId,
                shipmentId: null,
                type: fields.accuracyOptionId as StoreOrderProblemType,
                details: fields.accuracyDetails,
              },
            },
          })

        return Promise.resolve(undefined)
      })
      .then(() => {
        msgr.add('Order marked as received.', 'positive')
        onSuccess()
      })
      .catch(() => msgr.addUnknownError())
      .finally(() => {
        close()
        submitToggle.off()
      })
  }

  return (
    <ModalForm
      title={
        storeOrder.deliveryMethod === DeliveryMethod.Pickup ? 'Mark Picked Up' : 'Mark Received'
      }
      open={open}
      onClose={close}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
      submitButtonShowSpinner={submitting}
      submitButtonDisabled={submitting}
    >
      <div className="mt-4 space-y-6">
        <div className="space-y-2">
          <h3 className="text-base font-medium">Approximately when did you receive this?</h3>

          <DateTimeInput
            dateTime={fields.receivedAt}
            updateDateTime={(receivedAt) => receivedAt && setFields({ ...fields, receivedAt })}
            min={storeOrder.readyAt || storeOrder.submittedAt || undefined}
            max={DateTime.now()}
            required
          />

          <div className="flex gap-1 items-center">
            {(storeOrder.readyAt || storeOrder.submittedAt) && (
              <InformationCircleIcon className="w-5" />
            )}

            <span>
              {storeOrder.readyAt ? (
                <>
                  Order was ready at{' '}
                  <span className="font-medium">{storeOrder.readyAt.toFormat('D, h:mm a')}</span>
                </>
              ) : (
                storeOrder.submittedAt && (
                  <>
                    PO sent at{' '}
                    <span className="font-medium">
                      {storeOrder.submittedAt.toFormat('D, h:mm a')}
                    </span>
                  </>
                )
              )}
            </span>
          </div>
        </div>

        <div className="space-y-2">
          <h3 className="text-base font-medium">Accuracy</h3>
          <p>Is there a problem with your Order?</p>

          <Select
            currentId={fields.accuracyOptionId}
            options={StoreOrder.accuracyOptions}
            onChange={(id) =>
              setFields({ ...fields, accuracyOptionId: id as SelectStoreOrderProblem })
            }
          />

          {reportProblem && (
            <TextArea
              className="h-32"
              placeholder="Please describe the problem in detail. This information will be sent to your Vendor, and will help them resolve the issue."
              value={fields.accuracyDetails}
              setValue={(accuracyDetails) => setFields({ ...fields, accuracyDetails })}
              required
            />
          )}
        </div>
      </div>
    </ModalForm>
  )
}

export default MarkReceivedModal
