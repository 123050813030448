import { useExternalVendorsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'

import Select from '@/gf/components/next/Select'
import { useMemo } from 'react'

const ExternalVendors = ({ value, onChange }) => {
  const { data, loading } = useExternalVendorsQuery({
    client: useGqlClient(),
  })

  const options = useMemo(
    () => data?.externalVendors.map((v) => ({ value: v.id, label: v.name })) ?? [],
    [data]
  )

  const selectedOption = useMemo(() => options.find((o) => o.value === value), [options, value])

  return (
    <Select
      isLoading={loading}
      options={options}
      value={selectedOption}
      onChange={(o) => (o ? onChange(o.value) : undefined)}
      placeholder="Select a vendor..."
      className="text-sm w-full max-w-xs"
    />
  )
}

export default ExternalVendors
