import { useNavigate, useParams } from 'react-router-dom'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'

import type { Org as LimitedFrameOrg } from '../LimitedOrder'

import useSession from '@/buyers/hooks/useSession'
import {
  DeliveryMethod,
  FetchConversationsQueryResult,
  StoreOrderMessagesQuery,
  StoreOrderQuery,
  useStoreOrderMessagesQuery,
} from '@/buyers/_gen/gql'

import Layout from '@/gf/components/Layout'
import Spinner from '@/gf/components/Spinner'
import Inbox from './Messages/Inbox'
import useGqlClient from '@/buyers/hooks/useGqlClient'

const useQueryParamOpts = withDefault(NumberParam, 1)

type Org = Exclude<StoreOrderQuery['org'], null> | LimitedFrameOrg

const transformStoreOrder = (storeOrder: Exclude<StoreOrderMessagesQuery['storeOrder'], null>) => ({
  ...storeOrder,
  pickup: storeOrder.deliveryMethod === DeliveryMethod.Pickup,
  accountMachines: storeOrder.machines,
  order: {
    ...storeOrder.order,
    requestForQuote: storeOrder.order.requestForQuote
      ? {
          ...storeOrder.order.requestForQuote,
          user: storeOrder.order.requestForQuote.user
            ? {
                ...storeOrder.order.requestForQuote.user,
                organization: storeOrder.order.requestForQuote.user.organization || undefined,
              }
            : undefined,
          storeOrders: storeOrder.order.requestForQuote.storeOrders.map((so) => ({
            ...so,
            pickup: so.deliveryMethod === DeliveryMethod.Pickup,
          })),
        }
      : undefined,
  },
})

export type StoreOrder = ReturnType<typeof transformStoreOrder>

const Messages = ({
  org,
  refetchStoreOrder,
  convosResult,
  internalOrg,
}: {
  org: Org
  refetchStoreOrder: () => Promise<unknown>
  convosResult: FetchConversationsQueryResult
  internalOrg?: boolean
}) => {
  const { user } = useSession()
  const [page, setPage] = useQueryParam('page', useQueryParamOpts)
  const { storeOrderId } = useParams<{ storeOrderId: string }>() as { storeOrderId: string }
  const { conversationId, storeId } = useParams()
  const navigate = useNavigate()

  const { data, ...queryResult } = useStoreOrderMessagesQuery({
    variables: { storeOrderFilter: JSON.stringify(['id_eq', storeOrderId]) },
    client: useGqlClient(),
  })

  const storeOrder = data?.storeOrder && transformStoreOrder(data.storeOrder)

  if (!storeOrder) return null

  return (
    <div className="flex grow">
      {convosResult.error ? (
        <Layout.FullPageLayout>
          <p className="text-sm text-gray-700">{convosResult.error.message}</p>
        </Layout.FullPageLayout>
      ) : !convosResult.data ? (
        <Layout.FullPageLayout>
          <Spinner />
        </Layout.FullPageLayout>
      ) : (
        <Inbox
          org={org}
          conversations={convosResult.data.fetchConversations.conversations}
          refetchConversations={convosResult.refetch}
          page={page}
          setPage={setPage}
          pagination={convosResult.data.fetchConversations.pagination}
          user={user}
          storeOrder={storeOrder}
          selectedConversationId={conversationId}
          selectedStoreId={storeId}
          selectedInternalOrg={internalOrg}
          onSelectedConversationIdChange={(selectedConversationId) =>
            selectedConversationId
              ? navigate(`/orders/${storeOrder.id}/messages/${selectedConversationId}`, {
                  replace: true,
                })
              : navigate(`/orders/${storeOrder.id}`)
          }
          refetchStoreOrder={() => Promise.all([queryResult.refetch(), refetchStoreOrder()])}
          marginTop={false}
        />
      )}
    </div>
  )
}

export default Messages
