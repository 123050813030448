import { useMutation } from '@apollo/client'
import { SET_STORE_ORDER_ACCOUNT_MACHINES } from '../mutations/storeOrders'
import SEARCH_STORE_ORDERS from '../queries/storeOrders'

interface SetStoreOrderAccountMachinesVars {
  storeOrderId: string
  accountMachineIds: string[]
}
interface SetStoreOrderAccountMachinesData {
  setStoreOrderAccountMachines: string[]
}
const useSetStoreOrderAccountMachines = () =>
  useMutation<SetStoreOrderAccountMachinesData, SetStoreOrderAccountMachinesVars>(
    SET_STORE_ORDER_ACCOUNT_MACHINES,
    { refetchQueries: [SEARCH_STORE_ORDERS] }
  )

export default useSetStoreOrderAccountMachines
